'use strict';

/**
 *
 * @param $scope
 * @param $rootScope
 * @param $state
 * @param companyDetails
 * @param globalFunc
 * @param toastr
 * @param $uibModal
 * @param chargesList
 * @param companyOtherChargesGroupService
 * @param getOtherChargesGroup
 * @param getAllOtherCharges
 * @param $stateParams
 * @param $location
 */
function otherChargesGroupCtrl($scope, $rootScope, $state, companyDetails, globalFunc, toastr, $uibModal, chargesList,
                               companyOtherChargesGroupService, getOtherChargesGroup, getAllOtherCharges, $stateParams,
                               $location) {

  $scope.chargesList = chargesList.data;
  $scope.search = search;
  $scope.query = $stateParams.query;
  $scope.searchText = $stateParams.query;
  $scope.meta = chargesList.meta;
  $scope.company = companyDetails.data;
  $scope.companyOtherChargesGroupService = companyOtherChargesGroupService;
  $scope.getOtherChargesGroup = getOtherChargesGroup;
  $scope.back = back;
  $scope.showColumn = showColumn;
  $scope.addOtherChargesGroup = addOtherChargesGroup;
  $scope.editOtherChargesGroupDetails = editOtherChargesGroupDetails;
  $scope.getActiveStatusName = getActiveStatusName;
  $scope.otherCharges = {};
  $scope.storageKey = 'companies-other-charges-group-selected-columns';
  $scope.columns = [
    { id: 'other_charges_group_code', label: 'CODE' },
    { id: 'other_charges_group_name', label: 'NAME' },
    { id: 'supplier', label: 'SUPPLIER' },
    { id: 'created_at', label: 'CREATED AT' },
    { id: 'created_by', label: 'CREATED BY' },
    { id: 'updated_at', label: 'UPDATED AT' },
    { id: 'updated_by', label: 'UPDATED BY' },
    { id: 'is_active', label: 'STATUS' }
  ];
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();
  
  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function getActiveStatusName(val) {
    return !!val ? 'Active' : 'Inactive';
  }

  function prepareImportParam() {
    $scope.params = {
      'class': 'Metabuyer\\Models\\OtherChargesGroup',
      'additional_params[company_code]': $scope.company.code
    }
  }

  function prepareHistory() {
    var version = 1;
    _.forEach($scope.history, function (value) {
      value.version = version++;
    });
  }

  function editOtherChargesGroupDetails(index) {
    var updateOtherChargesGroup = $uibModal.open({
      templateUrl: "app/companiesManagement/companies/otherChargesGroup/details/details.html",
      controller: 'detailOtherChargesGroupCtrl',
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      windowClass: 'budget-apportion-details',
      resolve: {
        otherChargesGroups: function ($q) {
          var deferred = $q.defer();
          getOtherChargesGroup.get(
            {
              uuid: $scope.chargesList[index].uuid
            },
            function (resource) {
              deferred.resolve(resource.content || { data: [] });
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        }
      }
    });

    updateOtherChargesGroup.result.then(function (newValue) {
      if (!!newValue) {
        _.forEach($scope.chargesList, function (chargesList) {
          if (chargesList._id === newValue._id) {
            chargesList.uuid = newValue.uuid;
            chargesList.code = newValue.code;
            chargesList.name = newValue.name;
            chargesList.supplier = newValue.supplier;
            chargesList.creator_info = newValue.creator_info;
            chargesList.created_at = newValue.created_at;
            chargesList.updater_info = newValue.updater_info;
            chargesList.updated_at = newValue.updated_at;
            chargesList.is_active = newValue.is_active;
          }
        });
      }
    }, function () {
    });
  }


  function addOtherChargesGroup() {
    var modalInstance = $uibModal.open({
      templateUrl: "app/companiesManagement/companies/otherChargesGroup/details/add.html",
      controller: 'addOtherChargesGroupCtrl',
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      scope: $scope,
      windowClass: 'budget-apportion-details',
      resolve: {
        company: function () {
          return $scope.company;
        }
      }
    });

    modalInstance.result.then(function (result) {
      var newValue = result.content.data;
      $scope.chargesList.unshift({
        _id: newValue._id,
        uuid: newValue.uuid,
        code: newValue.code,
        name: newValue.name,
        supplier: newValue.supplier,
        is_active: newValue.is_active,
        created_at: newValue.created_at,
        updated_at: newValue.updated_at,
        creator_info: newValue.creator_info,
        updater_info: newValue.updater_info
      });
    });
  }
  /**
    /**
     * Goes back to company details
     */
  function back() {
    if (!!$scope.company && !!$scope.companyId)
      $state.go('main.user.companiesMgmt.detailCompanies', { id: $scope.companyId });
    else
      window.history.back();
  }

  function initialize() {
    var tabData = {
      tab: $scope.$parent.tabData[0],
      noTransition: true
    };

    $rootScope.$broadcast('loadSpecificTab', tabData);
    prepareImportParam();
    prepareHistory();
  }
  function search(query) {
    $location.search({query: query});
  }

  initialize();
}

otherChargesGroupCtrl.$inject = ['$scope', '$rootScope', '$state', 'companyDetails', 'globalFunc', 'toastr', '$uibModal'
  , 'chargesList', 'companyOtherChargesGroupService', 'getOtherChargesGroup', 'getAllOtherCharges', '$stateParams',
  '$location'];
angular.module('metabuyer')
  .controller('otherChargesGroupCtrl', otherChargesGroupCtrl);
