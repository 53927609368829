'use strict';

angular.module('metabuyer')
  .directive('approvalLimitTraceReport', function($rootScope) {
    return {
      restrict: 'E',
      templateUrl: 'components/reportV2/approvalLimitTrace/approvalLimitTraceTemplate.html',
      controller: approvalLimitTraceCtrl,
      scope: {
        loadUsers: "&",
        datepickerOpened: "=",
        openDatepicker: "&",
        startDateOptions: "=",
        endDateOptions: "=",
        datePickerTimezone: "=",
        startingDate: "=",
        endDate: "=",
        currencyData: "=",
        generateReports: "&",
      }
    }
  }
)

approvalLimitTraceCtrl.$inject = ['$scope', '$filter', 'userApprovalLimitList', 'globalFunc'];

function approvalLimitTraceCtrl($scope, $filter, userApprovalLimitList, globalFunc) {
  $scope.approvalLimits = [];
  initialize();
  disabledFields();

  $scope.approvalLimitSelectionHtml = function (approvalLimit) {
    if (!approvalLimit) {
      return "";
    }
    var allow_decimal = $scope.selectedCurrency.allow_decimal;
    var code = approvalLimit.approval_limits.code !== undefined ? approvalLimit.approval_limits.code : "";
    var html = "<div>" +
            code + 
            ": " +
            $filter('formatDecimal')(approvalLimit.approval_limits.amount, 2, allow_decimal) +
            " - " +
            approvalLimit.approval_limits.start_date +
            " to " +
            approvalLimit.approval_limits.end_date +
            "<div class='pull-right'>" +
            approvalLimit.approval_limits.status +
            "</div></div>"
    return html;
  }
  $scope.selectUser = function (user) {
    $scope.selectedUser = user;
    $scope.payload.userIdSelected = user._id;
    $scope.$emit("approvalLimitTraceReportPayload", $scope.payload);
  }
  $scope.selectCurrency = function (currency) {
    $scope.selectedCurrency = currency;
    $scope.payload.currencyCode = currency.code;
    $scope.$emit("approvalLimitTraceReportPayload", $scope.payload);
  }
  $scope.selectApprovalLimit = function (approvalLimit) {
    $scope.payload.approvalLimitUuid = approvalLimit.approval_limits.approval_limit_uuid;
    $scope.$emit("approvalLimitTraceReportPayload", $scope.payload);
  }
  $scope.approvalLimitSelection = function (field) {
    disabledFields();
    var isValidate = false
    if (!!$scope.selectedUser) {
      if ((!!$scope.startingDate && $scope.startingDate.date !== null) &&
          (!!$scope.endDate && $scope.endDate.date !== null)
      ){
        if (!!$scope.selectedCurrency) {
          $scope.isApprovalLimitDisabled = false;
          isValidate = true;
        }
        $scope.isCurrencyDisabled = false;
      }
      $scope.isStartingDateDisabled = false;
      $scope.isEndDateDisabled = false;
    }

    // clear Approval Limit Selection value
    $scope.approvalLimit = {
      selected: null
    };

    if (isValidate) {
      var startDate = globalFunc.convertDateToTimestamp($scope.startingDate.date);
      var endDate = globalFunc.convertDateToTimestamp($scope.endDate.date);
      userApprovalLimitList.get(
        {
          user_uuid: $scope.selectedUser.uuid,
          currency_code: $scope.selectedCurrency.code,
          start_date: startDate,
          end_date: endDate,
        },
        function (resource) {
          if (resource.content && resource.content.data) {
            $scope.approvalLimits = resource.content.data;
            $scope.payload.approvalLimitUuid = null;
          }
        },
        function (err) {
          globalFunc.objectErrorMessage(err);
        }
      )
    }
  };

  $scope.checkIsEmpty = function () {
    if (!$scope.user) {
      $scope.selectedUser = null;
      $scope.payload.userIdSelected = null;
      $scope.approvalLimitSelection();
    }
  }

  function disabledFields () {
    $scope.isStartingDateDisabled = true;
    $scope.isEndDateDisabled = true;
    $scope.isCurrencyDisabled = true;
    $scope.isApprovalLimitDisabled = true;
  }

  function initialize() {
    $scope.payload = {
      userIdSelected: null,
      currencyCode: null,
      approvalLimitUuid: null,
    }
  }

  $scope.$on("initializeApprovalLimitTraceReportPayload", function(evt, payload){
    initialize();
    $scope.$emit("approvalLimitTraceReportPayload", $scope.payload);
  });
}
