'use strict';

/**
 * Budget V2 management states
 * @description
 * Contains the states in budget and handles the resolve for data
 *
 * @states main.user.budget.manage
 * State for budget V2 listing
 *
 * @states main.user.budgetV2.details
 * State for budget v2 details by id
 *
 * @author Ameer Asraf aka Jamal <ameerasraf_92@yahoo.com>
 * @copyright 2020 Metacloud Sdn. Bhd.
 */
angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('main.user.budgetV2', {
        url: '/budgetManagementV2',
        controller: 'budgetManagementCtrlV2',
        templateUrl: 'app/budgetManagementV2/budgetManagement.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state, budgetFunctions, globalFunc, $rootScope) {
            // Initialize budget setting for company to false
            budgetFunctions.setHasBudgetSettingOn(false);
            // Set true if tenant super admin
            if (!!profile && !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN')) {
              budgetFunctions.setHasBudgetSettingOn(true);
            }
            else if (!!profile && !!(globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Auditor') ||
            globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'System Support'))) {
              budgetFunctions.setHasBudgetSettingOn(true);
            }
            _.forEach(profile.companies, function (company) {
              if (!!company.budget_setting.enable) {
                budgetFunctions.setHasBudgetSettingOn(true);
                return false;
              }
            });

            if (!UserPermissions.checkPermissionsAccess(profile, 'Budget', 'Read')  || !$rootScope.isV2Enabled) {
              $state.go('notAuthorized');
            } else if (!budgetFunctions.getHasBudgetSettingOn()) {
              $state.go('unauthorizedBudgetAccess');
            }
          }
        }
      })
      .state('main.user.budgetV2.manage', {
        templateUrl: 'app/budgetManagementV2/manage/manage.html',
        url: '/manage?&{status:[a-zA-Z0-9]+}&{query:[a-zA-Z0-9]+}&{page:[0-9]}&{order_by:[^~,]}&' +
        '{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}&{extraFilterKey:[a-zA-Z0-9]+}&' +
        '{extraFilterValue:[a-zA-Z0-9]+}&{tab:[a-zA-Z0-9]+}',
        controller: 'budgetListCtrlV2',
        resolve: {
          dataList: function ($q, $stateParams, searchModule, budgetFunctions, exportSearch) {
            var deferred = $q.defer();
            var status = budgetFunctions.getBudgetStatus($stateParams.status);

            var params = {
              module: 'budgets',
              offset: 20,
              page: $stateParams.page,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'updated_at',
              order: !!$stateParams.order ? $stateParams.order : 0,
            };

            /**
             * searching
             */
            if (!!$stateParams.query) {
              /**
               * searching with a specific filter
               */
              if (!!$stateParams.filter) {
                params['criteria_operator'] = 'and';
                var temp = '';
                if ($stateParams.type === 'date') {
                  temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                }
                else if ($stateParams.type === 'double') {
                  temp = 'criteria[1][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                }
                else {
                  params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
                }
              }
              /**
               * searching all filters
               */
              else {
                params['criteria[1][code]'] = $stateParams.query;
                params['criteria[1][descr]'] = $stateParams.query;
                params['criteria[1][company|descr]'] = $stateParams.query;
                params['criteria[1][cost_center|descr]'] = $stateParams.query;
                params['criteria[1][waitingOn.display_name]'] = $stateParams.query;
                params['criteria[1][creator_info|display_name]'] = $stateParams.query;
                params['criteria[1][updater_info|display_name]'] = $stateParams.query;
                params['criteria[1][years|value]'] = $stateParams.query;
                params['criteria[1][company|descr]'] = $stateParams.query;
                params['criteria[1][erp_budget_id]'] = $stateParams.query;
                params['criteria[1][shadow_companies|descr]'] = $stateParams.query;
                params['criteria[1][shadow_companies|code]'] = $stateParams.query;
                params['criteria[1][$operator]'] = 'or';
                params['criteria_operator'] = 'and';
              }
            }

            if ($stateParams.extraFilterKey === 'criteria[creator_info|_id][]' && $stateParams.status === 'pending') {
              params['criteria[2][creator_info|_id]'] = $stateParams.extraFilterValue;
              params['criteria[2][revisor_info|_id]'] = $stateParams.extraFilterValue;
              params['criteria[2][$operator]'] = 'or';
              params['criteria_operator'] = 'and';
            }

            if ($stateParams.extraFilterKey === 'criteria[waiting_on][]' && $stateParams.status === 'pending') {
              params['criteria[1][waiting_on][]'] = $stateParams.extraFilterValue;
              params['criteria_operator'] = 'and';
            }

            if ($stateParams.status === 'all') {
              params['criteria[0][status]'] = 'all';
            }

            else if (status === undefined) {
              params['criteria[0][status][]'] = [0, 1, 2, 3, 4, 5, 6, 7, 8]; // Every status except revision (9)
            }
            else {
              params['criteria[0][status]'] = status;
            }
            exportSearch.setParams(params);

            params['criteria[3][$ignore_gl_account]'] = true;
            searchModule.get(
              params,
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data) {
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve({data: []});
                }
              },
              function (error) {
                deferred.resolve({data: []});
              }
            );
            return deferred.promise;
          },
          companyList: function ($q, getAllCompanies){
            var deferred = $q.defer();

            getAllCompanies.get({
              'fields[0]': 'code',
              'fields[1]': 'budget_setting',
              'fields[2]' : 'descr'
            }, function (resource) {
              if (!!resource.content && !!resource.content.data) {
                deferred.resolve(resource.content);
              } else {
                deferred.resolve({data: []});
              }
            }, function (error) {
              deferred.resolve({data: []});
            });

            return deferred.promise;
          },
          $title: function ($stateParams) {
            return 'Budget - Listing' + (!!$stateParams.status ? ' - ' + $stateParams.status : '');
          }
        }
      })
      .state('main.user.budgetV2.details', {
        templateUrl: 'app/budgetManagementV2/details/details.html',
        url: '/details/{id:[a-zA-Z0-9]+}',
        controller: 'budgetDetailsCtrlV2',
        resolve: {
          budgetData: function ($q, budgets, $stateParams, globalFunc, toastr, $location) {
            var deferred = $q.defer();
            budgets.get(
              {id: $stateParams.id, ignore_gl_account: true},
              function (resource) {
                if (!!resource.content && !!resource.content.data) {
                  // if the budget with revision status, redirect to parent budget
                  if (!!resource.content.data.status && resource.content.data.status === 'revision' &&
                    !!resource.content.data.parent_id
                  ) {
                    $location.path('/user/budgetManagementV2/details/' + resource.content.data.parent_id).search('').replace();
                    $state.reload();
                  }
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
              }
            );
            return deferred.promise;
          },
          budgetRevisionData: function ($q, revisionSummary, $stateParams, globalFunc, budgetData) {
            var deferred = $q.defer();
            revisionSummary.get(
              {id: budgetData._id},
              function (resource) {
                deferred.resolve(resource.content) || {};
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve({});
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Budget - Details';
          }
        }
      })
  }]);
