'use strict';

function bifrostMappingManageCtrl($scope, $state, dataList, type, module, $http, pathConstants, $filter, toastr, $stateParams, globalFunc) {
  $scope.dataList = dataList.data;
  $scope.title = type;
  $scope.meta = dataList.meta;
  $scope.modelParams = {};
  $scope.canImport = false;
  $scope.canExport = false;

  $scope.showColumn = showColumn;
  $scope.deleteMapping = deleteMapping;
  $scope.exportFile = exportFile;

  function showColumn (id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function deleteMapping(id){
    swal({
      title: 'Confirm to delete this mapping?',
      text: 'This action is not revertable.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Confirm',
      closeOnConfirm: true,
    }, function (isConfirm) {
      if (isConfirm) {
        confirmDelete(id);
      }
    });
  }

  function confirmDelete(id) {
    var path = $filter('format')(pathConstants.apiUrls.bifrost.mappingModule);
    $http.delete(
      path,
      {
        params: {
          module: module,
          module_id: id,
        }
      }
    ).then(function onSuccess(response){
      toastr.success("Mapping deleted.");
      $state.reload();
    }).catch(function onError (error){
      toastr.error("Failed to delete mapping info.");
    });
  }

  function prepareImportableAndExportable() {
    var models = {
      'purchaseGroup': {
        'name': 'Purchase Group',
        'class': 'Metabuyer\\Bifrost\\Models\\MappingPurchaseGroup'
      },
      'purchaseOrganization': {
        'name': 'Purchase Organization',
        'class': 'Metabuyer\\Bifrost\\Models\\MappingPurchaseOrganization'
      },
      'opexBudgetTitle': {
        'name': 'Opex Budget Title',
        'class': 'Metabuyer\\Bifrost\\Models\\MappingOpexBudgetTitle'
      },
      'companyCode': {
        'name': 'Company Code',
        'class': 'Metabuyer\\Bifrost\\Models\\MappingCompanyCode'
      },
      'companyAccountCodes': {
        'name': 'Company Account Codes',
        'class': 'Metabuyer\\Bifrost\\Models\\MappingCompanyAccountCodes'
      },
      'focItems': {
        'name': 'FOC Items',
        'class': 'Metabuyer\\Bifrost\\Models\\MappingFocItems'
      },
      'plant': {
        'name': 'Plant',
        'class': 'Metabuyer\\Bifrost\\Models\\MappingPlant'
      },
      'mappingBudgetOwner': {
        'name': 'Budget Owner',
        'class': 'Metabuyer\\Bifrost\\Models\\MappingBudgetOwner'
      },
    };

    $scope.canImport = false;
    $scope.canExport = false;
    _.forEach(models, function (param, state) {
      if ($state.current.name.indexOf(state) > -1) {
        $scope.modelParams = param;
        $scope.canImport = true;
        $scope.canExport = true;
      }
    });
  }

  function exportFile() {
    var params = {
      module: module,
      limit: 0,
      class: $scope.modelParams.class,
      query: $stateParams.query || ''
    };

    $http({
      method: 'GET',
      url: pathConstants.apiUrls.bifrost.exportMappingModule,
      params: params
    }).then(function (response) {
      var blob = new Blob([response.data]);
      var link = document.createElement('a');
      document.body.appendChild(link);
      link.href = window.URL.createObjectURL(blob);
      link.download = module + $filter('date')(new Date(), 'dd-MMM-yyyy') +'.csv';
      link.click();
      toastr.success('Export file has been successfully generated.');
    }, function () {
      toastr.error('Failed to generate export file.');
    });
  }

  $scope.$on('$stateChangeStart', function() {
    prepareImportableAndExportable();
  });

  function initialize() {
    prepareImportableAndExportable();
    $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();
  }

  initialize();
}

bifrostMappingManageCtrl.$inject = ['$scope', '$state', 'dataList', 'type', 'module', '$http', 'pathConstants',
  '$filter', 'toastr', '$stateParams', 'globalFunc'];

angular
  .module('metabuyer')
  .controller('bifrostMappingManageCtrl', bifrostMappingManageCtrl);
