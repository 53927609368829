'use strict';

/**
 *
 * @param $scope
 * @param $rootScope
 * @param $state
 * @param companyDetails
 * @param globalFunc
 * @param toastr
 * @param $uibModal
 * @param dataList
 * @param companyOpexIoService
 */
function opexIOCtrl($scope, $rootScope, $state, companyDetails, globalFunc, toastr, $uibModal, dataList,
                    companyOpexIoService, $location) {
  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.company = companyDetails.data;
  $scope.companyOpexIoService = companyOpexIoService;
  $scope.search = search;
  $scope.back = back;
  $scope.showColumn = showColumn;
  $scope.addOpexIO = addOpexIO;
  $scope.editIODetail = editIODetail;
  $scope.getActiveStatusName = getActiveStatusName;
  $scope.opexIO = {};
  $scope.storageKey = 'companies-opex-io-selected-columns';
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();

  // $scope.history = itemTaxesHistory.data;

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function getActiveStatusName(val) {
    return !!val ? 'Active' : 'Inactive';
  }

  function prepareImportParam() {
    $scope.params = {
      'class': 'Metabuyer\\Models\\CompanyOpexIo',
      'additional_params[company_code]': $scope.company.code
    }
  }

  function prepareHistory() {
    var version = 1;
    _.forEach($scope.history, function (value) {
      value.version = version++;
    });
  }

  function editIODetail($index) {
    var updateOpexIO = $uibModal.open({
      templateUrl: "app/companiesManagement/companies/opexIo/detail/details.html",
      controller: 'detailOpexIOCtrl',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        opexIo: function ($q, companyOpexIoService) {
          var deferred = $q.defer();
          companyOpexIoService.get(
            {
              id: $scope.dataList[$index].uuid
            },
            function (resource) {
              deferred.resolve(resource.content || {data: []});
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        }
      }
    });

    updateOpexIO.result.then(function (newValue) {
      if (!!newValue) {
        _.forEach($scope.dataList, function (dataList) {
          if (dataList._id === newValue._id) {
            dataList.uuid = newValue.uuid;
            dataList.code = newValue.code;
            dataList.name = newValue.name;
            dataList.creator_info = newValue.creator_info;
            dataList.created_at = newValue.created_at;
            dataList.updater_info = newValue.updater_info;
            dataList.updated_at = newValue.updated_at;
            dataList.is_active = newValue.is_active;
          }
        });
      }
    }, function () {
    });
  }

  function addOpexIO() {
    var addOpexIO = $uibModal.open({
      templateUrl: "app/companiesManagement/companies/opexIo/add.html",
      controller: 'addOpexIOCtrl',
      backdrop: 'static',
      keyboard: false,
      scope: $scope,
      resolve: {
        company: function () {
          return $scope.company;
        }
      }
    });

    addOpexIO.result.then(function (result) {
      var newValue = result.content.data;
      $scope.dataList.unshift({
        _id: newValue._id,
        uuid: newValue.uuid,
        code: newValue.code,
        name: newValue.name,
        is_active: newValue.is_active,
        created_at: newValue.created_at,
        updated_at: newValue.updated_at,
        creator_info: newValue.creator_info,
        updater_info: newValue.updater_info
      });
    });
  }

  /**
   * Goes back to company details
   */
  function back() {
    if (!!$scope.company && !!$scope.company._id)
      $state.go('main.user.companiesMgmt.detailCompanies', {id: $scope.company._id});
    else
      window.history.back();
  }

  function search(query) {
    $location.search({query: query});
  }

  function initialize() {
    var tabData = {
      tab: $scope.$parent.tabData[0],
      noTransition: true
    };

    $rootScope.$broadcast('loadSpecificTab', tabData);
    prepareImportParam();
    prepareHistory();
  }

  initialize();
}

opexIOCtrl.$inject = ['$scope', '$rootScope', '$state', 'companyDetails', 'globalFunc', 'toastr', '$uibModal',
  'dataList', 'companyOpexIoService', '$location'];
angular.module('metabuyer')
  .controller('opexIOCtrl', opexIOCtrl);
