'use strict';

function nonPurchasingCostCtrl($scope, toastr, nonPurchasingCost, globalFunc) {
  $scope.submitNonPuchasingCost = submitNonPuchasingCost;
  $scope.nonPurchasingCostDisableTextbox =
    ($scope.budget.status.toLowerCase() === 'approved' || $scope.budget.status.toLowerCase() === 'draft') ? false : true;
  $scope.disableByStartDateEndDateCheck = disableByStartDateEndDateCheck;
  $scope.exportNonPuchasingCost = exportNonPuchasingCost;
  $scope.checkCostAmount = checkCostAmount;
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();

  function exportNonPuchasingCost() {
    var params = {
      budget_id: $scope.budget._id
    };

    $http.post($filter('format')(pathConstants.apiUrls.budget.nonPurchasingCostExport, null), {
      data: params,
      ignoreLoadingBar: true
    }).then(function (response) {
      var blob = new Blob([response.data]);
      var link = document.createElement('a');
      document.body.appendChild(link);
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Non-Purchasing-Cost-' +
        $filter('date')(new Date(), 'dd-MMM-yyyy') +'.csv';
      link.click();
      toastr.success('Non Purchasing Cost has been successfully exported.');
    }, function () {
      toastr.error('Failed to generate file.');
    });
  }

  function checkCostAmount(month) {
    if (!!month && !!month.amount) {
      // validate decimals
      var entry = month.amount.toString();
      var validValue = checkDecimal(entry, !$scope.budget.currency.allow_decimal);
      if (!validValue){
        month.amount = Number(entry.slice(0, entry.indexOf('.')));
      }
    }
  }

  function checkDecimal(entry, preventDecimal){
    var entry = entry.toString();
    if (!!preventDecimal) {
      var dotCount = (entry.match(/\./g) || []).length;
      if (dotCount > 0){
        toastr.error('Decimal is not allowed for this currency.');
        return false;
      }
    }

    return true;
  }

  function getMonthsBreakdown (num) {
    var arr = new Array(num);
    _.forEach(arr, function (singleIndex, key) {
      arr[key] = {
        month: key + 1,
        amount: 0
      };
    });
    return arr;
  }

  function submitNonPuchasingCost() {
    if (!validateInput()) {
      return false;
    }

    var params = {
      budget_id: $scope.budget._id,
      company_code: $scope.budget.company.code,
      erp_budget_id: $scope.budget.erp_budget_id,
      non_purchasing_costs: $scope.budget.non_purchasing_costs
    };

    nonPurchasingCost.post({
        data: params
      }, function (resource) {
        if (!!resource && !!resource.content) {
          updateNonPurchasingCost(resource.content.total_non_purchasing_cost);
          $scope.$broadcast('refreshComments');
          toastr.success('Non purchasing cost is submitted');
        }
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function updateNonPurchasingCost(newTotalNonPurchasingCost){
    // recalculate available balance after updating non purchasing cost
    $scope.budget.available_balance = $scope.budget.available_balance + $scope.budget.total_non_purchasing_cost - newTotalNonPurchasingCost;

    $scope.budget.total_non_purchasing_cost = newTotalNonPurchasingCost;
  }

  function validateInput() {
    if ($scope.budget.company.code === null) {
      toastr.error('Please choose company');
      return false;
    }
    if ($scope.budget._id === null) {
      toastr.error('Budget id undefined');
      return false;
    }
    return true;
  }

  function disableByStartDateEndDateCheck(year, yearIndex, monthIndex, value) {
    var month = monthIndex+1;

    var budgetStartYear = moment($scope.budget.start_date).format('YYYY').toString();
    var budgetEndYear = moment($scope.budget.end_date).format('YYYY').toString();

    if ($scope.budget.start_date === null && $scope.budget.end_date === null) {
      return true;
    }

    if (year < budgetStartYear || year > budgetEndYear) {
      return true;
    }

    var budgetStartMonth = moment($scope.budget.start_date).format('M').toString();
    var budgetEndMonth = moment($scope.budget.end_date).format('M').toString();

    if (year == budgetStartYear && month < budgetStartMonth) {
      checkValueInMonthBreakdown(value, yearIndex, monthIndex);
      return true;
    }

    if (year == budgetEndYear && month > budgetEndMonth) {
      checkValueInMonthBreakdown(value, yearIndex, monthIndex);
      return true;
    }

    return false;

  }

  function checkValueInMonthBreakdown(value, yearIndex, monthIndex) {
    if (!!value) {
      if (!_.isEmpty($scope.budget.non_purchasing_costs[yearIndex]) &&
        !_.isEmpty($scope.budget.non_purchasing_costs[yearIndex].monthBreakdown)) {
        // slice from monthBreakdown array
        delete $scope.budget.non_purchasing_costs[yearIndex].monthBreakdown[monthIndex];
      }
    }
  }

  function init(){
    if ($scope.budget.non_purchasing_costs.length === 0) {
      $scope.budget.non_purchasing_costs = [];
      _.forEach($scope.budget.years, function(year){

        $scope.budget.non_purchasing_costs.push({
          year: year.value,
          monthBreakdown: getMonthsBreakdown(12)
        });
      });
    }
  }
  init();

}

nonPurchasingCostCtrl.$inject = ['$scope', 'toastr', 'nonPurchasingCost', 'globalFunc'];

angular
  .module('metabuyer')
  .directive('nonPurchasingCost', function () {
    return {
      restrict: 'E',
      templateUrl: 'components/budgetV2/nonPurchasingCost/nonPurchasingCost.html',
      controller: 'nonPurchasingCostCtrl',
      scope: false
    }
  })
  .controller('nonPurchasingCostCtrl', nonPurchasingCostCtrl);
