'use strict';

function detailOtherChargesGroupCtrl($scope, $uibModalInstance, toastr, globalFunc, otherChargesGroups,
                                     getOtherChargesGroup, loadCacheableData, $http, $filter, pathConstants,
                                     getAllOtherCharges, commentsHistory) {
  $scope.formSubmitted = false;
  $scope.otherCharges = otherChargesGroups.data;
  $scope.editOtherCharges = false;
  $scope.selectedCharges = $scope.otherCharges.other_charges;
  $scope.tab = 1;
  $scope.cancel = cancel;
  $scope.removeCharges = removeCharges;
  $scope.checkCalculationSelection = checkCalculationSelection;
  $scope.renderBlock = renderBlock;

  $scope.commentsHistory = commentsHistory;
  $scope.prepareLogFields = prepareLogFields;
  $scope.embeddedParams = {
    referenceId: $scope.otherCharges._id,
    offset: 5
  };
  $scope.histories = [];
  $scope.meta = [];

  function prepareLogFields(related) {
    var fields = [];
    _.forEach(related, function(v, k) {
      // var item = k + ': ' + v.old + ' => ' + v.new;
      var item = {
        v : k + ': ' + v.old + ' => ' + v.new
      };
      fields.push(item);
      });
    return fields;
  }

  $scope.searchSuppliers = searchSuppliers;
  $scope.onSelectSupplier = onSelectSupplier;
  $scope.toggleTab = toggleTab;
  $scope.updateOtherChargesGroup = updateOtherChargesGroup;
  $scope.editOtherChargesGroup = editOtherChargesGroup;
  $scope.onSelectOtherCharges = onSelectOtherCharges;
  $scope.calculationMethod = [
    {
      code: 'PERCENT_OF_EXTENDED_COST',
      descr: 'PERCENT OF EXTENDED COST',
      is_percentage: true
    },
    {
      code: 'FLAT_AMOUNT',
      descr: 'FLAT AMOUNT',
      is_percentage: false
    },
    {
      code: 'FLAT_AMOUNT_PER_UNIT',
      descr: 'FLAT AMOUNT PER UNIT',
      is_percentage: false
    }
  ];
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();

    /**
     * a small check to avoid rendering strings in object places
     * @param newField
     * @param oldField
     * @param type
     * @returns {boolean}
     */
  function renderBlock(newField, oldField, type) {
      return (!!newField || !!oldField) && (typeof newField === type || oldField === type);
  }

  function removeCharges(index) {
    $scope.selectedCharges.splice(index, 1);
  }

  function onSelectOtherCharges(charge) {
    if (!!validateOtherCharges(charge)) {
      $scope.selectedCharges.push(charge);
    }
  }

  function validateOtherCharges(charge) {
    if ($scope.selectedCharges.length !== 0) {
      for (var i = 0; i < $scope.selectedCharges.length; i++) {
        if ($scope.selectedCharges[i].code === charge.code) {
          toastr.error(charge.code + " already added");
          return false;
        }
      }
    }

    return true;
  }

  function toggleTab(tab) {
    return $scope.tab = tab;
  }

  function removeCharge(code) {
    var result = globalFunc.findInArray($scope.otherChargesGroupList, 'code', code);
    if (!!result) {
      result.calculationMethod = 0;
      result.unitPrice = 0;
      result.percentage = 0;
      result.tax = 0;
      result.currency = 0;
      result.isSelected = false;
    }
  }

  function searchSuppliers(val) {
    var moduleParam = {
      module: 'suppliers'
    };

    var additionalParams = {
      'criteria[0][basic_info|descr]': val,
      'criteria[0][basic_info|status][0]': 'active',
      criteria_operator: 'and',
      offset: 5
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams,
      ignoreLoadingBar: true
    }).then(function (response) {
      if (response.data.content.data.length === 0) {
        $scope.supplierSearching = false;
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          $scope.supplierSearching = false;
          return item
        });
      }
    });
  }

  function onSelectSupplier(supplier) {
    $scope.otherCharges.supplier = supplier.basic_info;
  }


  function cancel() {
    $uibModalInstance.close();
  }

  function editOtherChargesGroup() {
    $scope.editOtherCharges = true;
  }

  function updateOtherChargesGroup() {
    $scope.formSubmitted = true;
    getOtherChargesGroup.put(
      {
        uuid: $scope.otherCharges.uuid
      },
      {
        code: $scope.otherCharges.code,
        name: $scope.otherCharges.name,
        supplier_code: $scope.otherCharges.supplier.code,
        is_active: $scope.otherCharges.is_active,
        other_charges: $scope.otherCharges.other_charges
      },
      function (resource) {
        toastr.success('Company Other Charges Group has been updated');
        $uibModalInstance.close(resource.content.data);
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        return false;
      }
    );
  }

  function getOtherChargesData() {
    getAllOtherCharges.get(
      {},
      function (resource) {
        $scope.otherChargesList = resource.data;
      },
      function () {
        $scope.otherChargesList = [];
      }
    );
  }

  function getTaxData() {
    loadCacheableData.loadData({
      module: 'tax',
      'criteria[is_active]': 1,
      offset: 0
    }, 'taxes', 'localStorage').then(function (data) {
      $scope.taxesList = data;
    });
  }

  function getCurrencyData() {
    loadCacheableData.loadData({
      module: 'currencies',
      'criteria[is_active]': 1,
      offset: 0
    }, 'currencies', 'localStorage').then(function (data) {
      $scope.currenciesList = data;
    });
  }

  function checkCalculationSelection(charge) {
    charge.percentage = undefined;
    charge.unit_price = undefined;
  }

  function loadComments() {
      commentsHistory.get(
          {
              referenceId: $scope.otherCharges._id,
              offset: 5
          },
          function (resource) {
            if(!!resource.content)
            {
                $scope.histories = resource.content.data;
                $scope.meta = resource.content.meta;
            }
          }
      );
  }

  function init() {
    getOtherChargesData();
    getTaxData();
    getCurrencyData();
    loadComments()
  }

  init()
}

detailOtherChargesGroupCtrl.$inject = ['$scope', '$uibModalInstance', 'toastr', 'globalFunc', 'otherChargesGroups',
  'getOtherChargesGroup', 'loadCacheableData', '$http', '$filter', 'pathConstants', 'getAllOtherCharges', 'commentsHistory'];

angular
  .module('metabuyer')
  .controller('detailOtherChargesGroupCtrl', detailOtherChargesGroupCtrl);



