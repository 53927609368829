'use strict';
/**
 * @name tenderDetailCtrl
 * @desc Controller for add tender
 */
function tenderDetailCtrl(
  $scope, $stateParams, $state, pathConstants, $uibModal, $filter, HighlightId, globalFunc,
  $rootScope, toastr, tender, initialData, tenderFunction, tenderCommentService, profile,
  tenderAttachmentService, Approvals, approvalPreview, revisions
) {

  $scope.tender = tender.data;
  $scope.initialData = initialData;
  $scope.profile = profile;
  $scope.attachments = [];
  $scope.attachmentParam = {
    id: tender.data._id,
    attachmentId: null,
    maxFilesize: 10
  };
  $scope.hideIsVisibleToSupplier = true;
  $scope.attachmentUrl = $filter('format')(
    pathConstants.apiUrls.tender.attachment.list, { id: tender.data._id }
  );
  $scope.revisions = revisions.data.revisions;
  $scope.getEditingStatus = getEditingStatus;
  $scope.singleAttachmentService = tenderAttachmentService;
  $scope.approvalsPreview = approvalsPreview;
  $scope.canEditAttachmentAndComment = canEditAttachmentAndComment;
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();

  $scope.numericPatternRestrict = $scope.tender.allow_decimal ? '^[0-9]*(?:\.[0-9]{0,2})?(?:\%{0,1})?$' : '^[0-9]*$';
  $scope.numericPatternRestrictAllowNegative = $scope.tender.allow_decimal ? '^[-]?[0-9]*(?:\.[0-9]{0,2})?(?:\%{0,1})?$' : '^[-]?[0-9]*$';
  $scope.numericPatternRestrictUnitPrice = $scope.tender.allow_decimal ? '^[0-9]*(?:\.[0-9]{0,4})?(?:\%{0,1})?$' : '^[0-9]*$';

  getApprovalFlow();

  function approvalsPreview() {
    approvalPreview.get({
      context_type: 'App\\Metabuyer\\Tender\\Models\\Tender',
      context_id: $scope.tender._id
    }, function(resource) {
      $scope.approvalFlowData = resource.content.data;
    }, function(error) {
      $scope.approvalFlowData = [];
      globalFunc.objectErrorMessage(error);
    });
  }

  function getApprovalFlow() {
    if (!$scope.tender) {
      return;
    }

    if ($scope.tender.detail.status === 'draft' || $scope.tender.detail.status === 'deleted' || $scope.tender.detail.status === 'vo_draft') {
      return;
    }

    Approvals.get({
        id: tender.data.approval_id
      },
      function(resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.approvalFlowData = resource.content.data;
        } else {
          $scope.approvalFlowData = [];
        }
      });
  }

  function getEditingStatus() {
    return tenderFunction.isEditable()
  }

  function getTenderAttachments() {
    tenderAttachmentService.getListTenderAttachment({
      id: tender.data._id
    }, function(resource) {
      if (!!resource && !!resource.content)
        $scope.attachments = resource.content.data;
    }, function(error) {
      globalFunc.objectErrorMessage(error);
    })
  }

  /**
   * Function to disabled comment and attachment for system support role
   * @returns boolean
   */
  function canEditAttachmentAndComment() {
    var disableEdit = false;
    if ($scope.isSystemSupportRole) {
      disableEdit = true;
    }
    return disableEdit;
  }

  $scope.commentService = tenderCommentService;

  function initialize() {
    tenderFunction.setEditableState()
    tenderFunction.setErrorsOnSubmit([]);
    getTenderAttachments();
  }

  initialize();

  $scope.$on('updateNumericPattern', function(event, result) {
    $scope.numericPatternRestrict = result.data ? '^[0-9]*(?:\.[0-9]{0,2})?(?:\%{0,1})?$' : '^[0-9]*$';
    $scope.numericPatternRestrictAllowNegative = result.data ? '^[-]?[0-9]*(?:\.[0-9]{0,2})?(?:\%{0,1})?$' : '^[-]?[0-9]*$';
    $scope.numericPatternRestrictUnitPrice = result.data ? '^[0-9]*(?:\.[0-9]{0,4})?(?:\%{0,1})?$' : '^[0-9]*$';
  });

}

tenderDetailCtrl.$inject = [
  '$scope', '$stateParams', '$state', 'pathConstants', '$uibModal', '$filter', 'HighlightId', 'globalFunc',
  '$rootScope', 'toastr', 'tender', 'initialData', 'tenderFunction', 'tenderCommentService', 'profile',
  'tenderAttachmentService', 'Approvals', 'approvalPreview', 'revisions'
];

angular
  .module('metabuyer')
  .controller('tenderDetailCtrl', tenderDetailCtrl);
