'use strict';
/**
 * @name positionAssignCtrl
 * @desc Controller for listing of position assignment
 */
function positionAssignCtrl(
  $scope, $uibModal, companies, positions, positionAssignment, positionAssignmentListing, globalFunc,
  searchModule, positionAssignmentSingle, toastr, $http, pathConstants, $filter, catalogGroups, alcGroups, $rootScope
) {

  $scope.importParams = {
    class: 'Metabuyer\\ApprovalFlow\\Positions\\Models\\PositionAssignment',
    'additional_params[]': ''
  };

  $scope.columns = [
    {id: 'row', label: '#', unsortable: true},
    {id: 'code', label: 'Code', unsortable: true},
    {id: 'position', label: 'Position', unsortable: true},
    {id: 'company', label: 'Company', unsortable: true},
    {id: 'cost_center', label: 'Cost Center', unsortable: true},
    {id: 'group_code', label: 'Group', unsortable: true},
    {id: 'assignee_name', label: 'User'},
    {id: 'type', label: 'Type', unsortable: true},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'created_by', label: 'Created By', unsortable: true, unsearchable: true},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updated_by', label: 'Updated By', unsortable: true, unsearchable: true},
    {id: 'status', label: 'Status'}
    // {id: 'remove', label: 'Remove', unsortable: true}
  ];

  $scope.positionAssign = [];
  $scope.meta = [];
  $scope.page = 0;
  $scope.limit = 30;
  $scope.currentStatus = {
    col: null,
    order: null
  };
  $scope.timezoneDiff = '';
  $scope.colSorting = null;
  $scope.colOrder = null;
  $scope.storageKey = 'position-management-assign-selected-columns';
  $scope.disableSearch = false;

  $scope.positionListing = !!positions && positions.data && !!positions.data.position ? positions.data.position : null;
  $scope.companyListing = [];
  $scope.companyListing.push({
    code: 'SYSTEM',
    descr: 'System Wide'
  });
  $scope.groupListing = [];

  //$scope.costCenterListing = !!costCenter && !!costCenter.data ? costCenter.data : null;
  $scope.costCenterListing = null;

  $scope.uiCompany = {};
  $scope.uiCompany.list = [];
  $scope.uiPosition = {};
  $scope.uiPositionCode = {};
  $scope.uiPosition.list = [];
  $scope.uiPositionCode.list = [];
  $scope.uiCostCenter = {};
  $scope.uiCostCenter.list = [];
  $scope.uiUsername = {};
  $scope.uiUsername.list = [];
  $scope.uiUsername.id = [];
  $scope.statusListing = [
    {
      code: '0',
      descr: 'Active'
    },
    {
      code: '1',
      descr: 'Inactive'
    }
  ];
  $scope.uiGroup = {};
  $scope.uiGroup.list = [];
  $scope.status = {};
  $scope.status.list = [];
  $scope.moduleName = 'Position Assignment';
  $scope.visibleColumn = visibleColumn;
  $scope.processData = processData;
  $scope.returnDate = returnDate;
  $scope.returnStatus = returnStatus;
  $scope.search = search;
  $scope.exportPosition = exportPosition;
  $scope.addPositionAssign = addPositionAssign;
  $scope.isCompanySelected = isCompanySelected;
  $scope.processCostCenterList = processCostCenterList;
  $scope.showDetails = showDetails;
  $scope.removePositionAssign = removePositionAssign;
  $scope.resetSelection = resetSelection;
  $scope.hasUserPermission = globalFunc.checkUserPermission($scope.$root.currentUser, $scope.moduleName);
  $scope.searchUsers = searchUsers;
  $scope.getGroupListing = getGroupListing;
  $scope.userSearching = false;
  $scope.userListing = [];
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();

  function getGroupListing(query){
    var filteredGroupListing = _.cloneDeep($scope.groupListing);
    if (!!query){
      filteredGroupListing = _.filter($scope.groupListing, function(group) {
        return _.contains(group.descr.toUpperCase(), query.toUpperCase()) || _.contains(group.code.toUpperCase(), query.toUpperCase());
      });
    }

    return filteredGroupListing;
  }

  function visibleColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function processData() {
    $scope.processedData = [];
    $scope.processedData = $scope.positionAssign;
    $scope.meta['currentPageNumber'] = $scope.positionAssign['meta']['current'];
    $scope.meta['totalPageNumber'] = $scope.positionAssign['meta']['pages'];
    $scope.meta['totalRec'] = $scope.positionAssign['meta']['count'];
    $scope.meta['startRecNo'] = $scope.limit * $scope.page + 1;
    $scope.meta['endRecNo'] = $scope.meta['totalRec'] - ((1 + $scope.meta['currentPageNumber']) * $scope.limit) >= 0 ?
      (1 + $scope.meta['currentPageNumber']) * $scope.limit :
      (($scope.meta['currentPageNumber']) * $scope.limit) + $scope.meta['totalRec'] % $scope.limit;
    $scope.positionAssign = $scope.processedData;
  }

  function returnDate(date) {
    return globalFunc.convertToLocalDate(date);
  }

  function returnStatus(status) {
    var statusString = '';
    switch (status) {
      case 0:
        statusString = 'Active';
        break;
      case 1:
        statusString = 'Inactive';
        break;
    }
    return statusString;
  }

  function getOrganizationId() {
    //convert to single string for cost_center / company searching
    var organizationId = '';
    if (!!$scope.uiCompany.list && !!$scope.uiCompany.list.code) {
      organizationId += _.isEmpty(organizationId) ? '' : ';';
      organizationId += ($scope.uiCompany.list.code.toLowerCase() === 'system' ? 'all' : $scope.uiCompany.list.code);
    }
    if (!!$scope.uiCostCenter.list && !!$scope.uiCostCenter.list.code) {
      organizationId = $scope.uiCostCenter.list.code;
    }

    if (!!$scope.uiGroup.list && !!$scope.uiGroup.list.code) {
      organizationId = $scope.uiGroup.list.code;
    }

    return organizationId;
  }

  function search(page, sortingCol, sortingOrder) {
    $scope.page = page;
    $scope.colSorting = sortingCol;
    $scope.colOrder = sortingOrder;
    var params = {
      'sort[0][key]': sortingCol,
      'sort[0][direction]': sortingOrder,
      'sort[1][key]': 'status',
      'sort[1][direction]': 'ASC',
      'sort[2][key]': 'updated_at',
      'sort[2][direction]': 'DESC',
      'filter_groups[0][or]': 1,
      'filter_groups[0][filters][0][key]': 'organizationId',
      'filter_groups[0][filters][0][value]': getOrganizationId(),
      'filter_groups[0][filters][0][operator]': 'in',
      'filter_groups[1][filters][0][key]': 'status',
      'filter_groups[1][filters][0][value]': !!$scope.status.list.code ? $scope.status.list.code : '',
      'filter_groups[1][filters][0][operator]': 'ct',
      'filter_groups[2][filters][0][key]': 'position',
      'filter_groups[2][filters][0][value]': !!$scope.uiPosition.list.code ? $scope.uiPosition.list.code : '',
      'filter_groups[2][filters][0][operator]': 'eq',
      'filter_groups[3][filters][0][key]': 'position',
      'filter_groups[3][filters][0][value]': !!$scope.uiPositionCode.list.code ? $scope.uiPositionCode.list.code : '',
      'filter_groups[3][filters][0][operator]': 'eq',
      limit: $scope.limit,
      page: page
    };

    for (var i = 0; i < $scope.userListing.length; i++) {
      if ($scope.uiUsername.id === $scope.userListing[i]._id) {
        $scope.uiUsername.list = $scope.userListing[i];
      }
    }

    if (!!$scope.uiUsername.list && !!$scope.uiUsername.list._id) {
      params['filter_groups[4][filters][0][key]'] = 'assignee_id';
      params['filter_groups[4][filters][0][value]'] = $scope.uiUsername.list._id;
      params['filter_groups[4][filters][0][operator]'] = 'ct';
    }

    positionAssignment.get(
      params,
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.positionAssign = resource.content.data;
          $scope.positionAssign['meta'] = resource.content.meta.cursor;
          processData();
        }
      },
      function (resource) {
        if (!!resource.data && !!resource.data.content && !!resource.data.content.message && !!resource.data.content.message.errors) {
          toastr.error(_.map(resource.data.content.message.errors, _.property('detail')).toString());
        }
        else {
          toastr.error('Failed to search.');
        }
      }
    );
  }

  function exportPosition() {
    $http({
      method: 'POST',
      url: pathConstants.apiUrls.approvalEngine.positionAssign.export,
      params: {
        'sort[0][key]': $scope.sortingCol,
        'sort[0][direction]': $scope.sortingOrder,
        'sort[1][key]': 'updated_at',
        'sort[1][direction]': 'DESC',
        'filter_groups[0][or]': 1,
        'filter_groups[0][filters][0][key]': 'organizationId',
        'filter_groups[0][filters][0][value]': getOrganizationId(),
        'filter_groups[0][filters][0][operator]': 'ct',
        'filter_groups[1][filters][0][key]': 'assignee_id',
        'filter_groups[1][filters][0][value]': !!$scope.uiUsername.list._id ? $scope.uiUsername.list._id : '',
        'filter_groups[1][filters][0][operator]': 'ct',
        'filter_groups[2][filters][0][key]': 'status',
        'filter_groups[2][filters][0][value]': !!$scope.status.list.code ? $scope.status.list.code : '',
        'filter_groups[2][filters][0][operator]': 'ct',
        'filter_groups[3][filters][0][key]': 'position',
        'filter_groups[3][filters][0][value]': !!$scope.uiPosition.list.code ? $scope.uiPosition.list.code : '',
        'filter_groups[3][filters][0][operator]': 'eq',
        'filter_groups[4][filters][0][key]': 'position',
        'filter_groups[4][filters][0][value]': !!$scope.uiPositionCode.list.code ? $scope.uiPositionCode.list.code : '',
        'filter_groups[4][filters][0][operator]': 'eq'
      }

      // TODO ~HAN~ include position in searching
    }).then(function (response) {
      var blob = new Blob([response.data]);
      var link = document.createElement('a');
      document.body.appendChild(link);
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Position-Assignment ' + $filter('date')(new Date(), 'dd-MMM-yyyy') + '.csv';
      link.click();
      toastr.success('Position assignment file has been successfully generated.');
    }, function () {
      toastr.error('Failed to generate position assignment file.');
    });
  }

  function addPositionAssign() {
    $uibModal.open({
      templateUrl: "app/positionManagement/assign/details.html",
      backdrop: 'static',
      keyboard: false,
      scope: $scope,
      controller: 'positionAssignDetailsController',
      resolve: {
        isEditable: function () {
          return true;
        },
        data: function () {
          return [];
        },
        assignHistory: function () {
          return null;
        },
        isEditWindow: function () {
          return false;
        }
      }
    });
  }

  function isCompanySelected() {
    if (!!$scope.uiCompany.list) {
      if (!!$scope.uiCompany.list.code) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  $scope.onSelectUser = function($item) {
    $scope.uiUsername.id = $item._id;
  }

  // Company and Group search cannot be used together,
  // they are both stored as organization_id in approval engine
  $scope.onSelectCompany = function($item) {
    $scope.uiGroup.list = [];
    $scope.uiCompany.list = $item;
    processCostCenterList();
  };

  $scope.onSelectGroup = function($item) {
    $scope.uiCompany.list = [];
    $scope.uiCostCenter.list = [];
    $scope.uiGroup.list = $item;
  };

  function processCostCenterList() {
    $scope.uiCostCenter.list = [];
    //stop when no company is selected
    if ($scope.uiCompany.list.length === 0) {
      $scope.costCenterListing = [];
      return;
    }

    var params = {
      module: 'cost-center',
      offset: 0,
      criteria_operator: 'and',
      order_by: 'descr',
      order: 1
    };

    if ($scope.uiCompany.list.code !== 'SYSTEM')
      params['criteria[company_code]'] = $scope.uiCompany.list.code;

    searchModule.get(
      params,
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.costCenterListing = resource.content.data;
        }
      }
    )
  }

  function showDetails(data) {
    $scope.editClicked = false;
    var modalInstance = $uibModal.open({
      templateUrl: "app/positionManagement/assign/details.html",
      backdrop: 'static',
      keyboard: false,
      scope: $scope,
      controller: 'positionAssignDetailsController',
      resolve: {
        isEditable: function () {
          return false;
        },
        data: function () {
          return data;
        },
        assignHistory: function ($q, approvalTemplateHistory) {
          var deferred = $q.defer();
          approvalTemplateHistory.get(
            {
              'sort[1][key]': 'created_at',
              'sort[1][direction]': 'DESC',
              'filter_groups[0][filters][0][key]': 'auditable_id',
              'filter_groups[0][filters][0][value]': data.id,
              'filter_groups[0][filters][0][operator]': 'eq',
              'filter_groups[1][filters][0][key]': 'auditable_type',
              'filter_groups[1][filters][0][value]': 'Api\\Positions\\Models\\UserAssignment',
              'filter_groups[1][filters][0][operator]': 'eq',
              limit: 10,
              page: 0
            },
            function (resource) {
              if (!!resource.content) {
                deferred.resolve(resource.content);
              }
            },
            function (error) {
              deferred.resolve([]);
            }
          );
          return deferred.promise;
        },
        isEditWindow: function () {
          return true;
        }
      }
    });

    modalInstance.result.then(function (newValue) {
      if(!!newValue){
        for(var i in $scope.positionAssign) {
          if ($scope.positionAssign[i].id === newValue.id) {
            if(newValue.isDeleted){
              $scope.positionAssign.splice(i, 1);
              break;
            }
            $scope.positionAssign[i].status = newValue.status;
          }
        }
      }
    }, function () {
    });
  }

  function removePositionAssign(position) {
    swal({
        title: 'Warning!',
        text: 'Are you sure you want to delete? \n Position: ' + position.position_assignment.position.description +
          '\n Company: ' + position.company.descr + '\n Cost Center: ' + position.cost_center.descr +
          '\n User: ' + position.assignee_name,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          positionAssignmentSingle.delete(
            {
              id: position.id
            },
            function () {
              search($scope.meta['currentPageNumber']);
              toastr.success('Position assignment has been successfully deleted.');
            },
            function (resource) {
              if (!!resource.data && !!resource.data.content && !!resource.data.content.message && !!resource.data.content.message.errors) {
                toastr.error(_.map(resource.data.content.message.errors, _.property('detail')).toString());
              }
              else {
                toastr.error('Failed to delete selected position assignment.');
              }
            }
          )
        }
      });
  }

  function searchUsers(name) {
    if (!!name && name.length > 2) {
      $scope.userSearching = true;

      return $http.get(pathConstants.apiUrls.genericSearch.searchBasePath + '/users', {
        params: {
          module: 'users',
          'criteria[1][display_name]': name,
          criteria_operator: 'and'
        }
      }).then(function (response) {
        $scope.userSearching = false;
        $scope.userListing = response.data.content.data;
        return response.data.content.data;
      }, function (error) {
        $scope.userSearching = false;
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  function resetSelection() {
    $scope.uiCompany.list = [];
    $scope.uiPosition.list = [];
    $scope.uiPositionCode.list = [];
    $scope.uiCostCenter.list = [];
    $scope.uiUsername.list = [];
    $scope.uiUsername.id = [];
    $scope.status.list = [];
    $scope.uiGroup.list = [];
  }

  function initialize() {
    // Initialize Company search data
    if (!!companies && !!companies.data) {
      [].push.apply($scope.companyListing, companies.data);
    }

    // Initialize Group search data
    if (!!catalogGroups && !!catalogGroups.data) {
      [].push.apply($scope.groupListing, catalogGroups.data);
    }
    if (!!alcGroups && !!alcGroups.data) {
      [].push.apply($scope.groupListing, alcGroups.data);
    }

    $scope.timezoneDiff = globalFunc.getTimezoneDiff();
    $scope.positionAssign = positionAssignmentListing.data;
    $scope.positionAssign['meta'] = positionAssignmentListing.meta.cursor;
    processData();

  }

  initialize();

}

positionAssignCtrl.$inject = [
  '$scope', '$uibModal', 'companies', 'positions', 'positionAssignment',
  'positionAssignmentListing', 'globalFunc', 'searchModule', 'positionAssignmentSingle', 'toastr', '$http',
  'pathConstants', '$filter', 'catalogGroups', 'alcGroups', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('positionAssignCtrl', positionAssignCtrl);
