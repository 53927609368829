'use strict';

/**
 * @name editPaymentTermCtrl
 * @desc Controller for master data payment terms details controller
 */
function paymentMethodDetailsCtrl($scope, toastr, paymentMethodDetails, singlePaymentMethod, $state,
                               globalFunc, $uibModalInstance, $rootScope) {

  $scope.paymentMethodDetails = paymentMethodDetails.data;
  $scope.submitted = false;
  $scope.editClicked = false;
  $scope.originalCode = $scope.paymentMethodDetails.code;

  $scope.editMode = editMode;
  $scope.savePaymentMethod = savePaymentMethod;
  $scope.duplicationCheck = duplicationCheck;
  $scope.cancel = cancel;
  $scope.toggleTab = toggleTab;

  $scope.paymentMethodStatuses = [
    { isActive: 'Active' },
    { isActive: 'Deactivated' }
  ];

  $scope.tab = 1;
  $scope.historyDependencies = {
    embeddedParams: {
      'object[0][object_id]': $scope.paymentMethodDetails._id,
      'object[0][object_type]': 'Metabuyer\\Models\\PaymentMethod',
      offset: 5,
      order_by: '-created_at' // Created at, descending
    }
  };

  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();

  function cancel() {
    $uibModalInstance.close();
  }

  function toggleTab(tab) {
    $scope.tab = tab;
  }

  function editMode(){
    $scope.editClicked = true;
  }

  function validate(paymentMethod){
    return(!_.isUndefined(paymentMethod) && !_.isUndefined(paymentMethod.code) &&
    !_.isUndefined(paymentMethod.descr) && !_.isUndefined(paymentMethod.paymentMethodStatus));
  }

  function savePaymentMethod(){
    $scope.submitted = true;
    if(!!$scope.codeCheck){
      toastr.error($scope.paymentMethodDetails.code + ' is already in use');
    }
    else if(validate($scope.paymentMethodDetails)) {
      swal({
          title: 'Confirm Updating the Payment Method',
          text: 'This will update the details for this Payment Method',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true,
          closeOnCancel: true
        },
        function (confirmed) {
          if (!!confirmed) {

            // set status in integer to pass it as parameter
            var tempStatus;
            switch($scope.paymentMethodDetails.paymentMethodStatus.isActive.toLowerCase()){
              case 'active':
                tempStatus = 1;
                break;
              case 'deactivated':
                tempStatus = 0;
                break;
              case 'disabled':
                tempStatus = 3;
                break;
            }

            $scope.paymentMethodDetails.is_active = $scope.paymentMethodDetails.paymentMethodStatus.isActive.toUpperCase();
            $scope.paymentMethodDetails.updated_at = new Date();

            singlePaymentMethod.put(
              {
                id: $scope.paymentMethodDetails._id
              },
              {
                code: $scope.paymentMethodDetails.code,
                descr: $scope.paymentMethodDetails.descr,
                is_active: tempStatus
              },
              function (resource) {
                toastr.success('Payment Method has been updated');
                $uibModalInstance.close(resource.content.data);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                return false;
              }
            );
          } else {
            return false;
          }
        }
      );
    }
  }

  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model,field,value,checker){
    globalFunc.duplicationCheck(model,field,value,checker, function(callback){
      switch (callback){
        case 'codeCheckTrue':
          if($scope.paymentMethodDetails.code != $scope.originalCode){
            $scope.codeCheck = true;
          }
          else{
            $scope.codeCheck = false;
          }
          break;
        case 'codeCheckFalse':
          $scope.codeCheck = false;
          break;
      }
    });
  }

  function initialize() {
    if(!!$scope.paymentMethodDetails.is_active) {
      $scope.paymentMethodDetails.paymentMethodStatus = {isActive: globalFunc.capitalizeFirstCharacter($scope.paymentMethodDetails.is_active)};
    }
  }

  initialize();
}

paymentMethodDetailsCtrl.$inject = ['$scope', 'toastr', 'modalPaymentMethod', 'singlePaymentMethod',
  '$state', 'globalFunc', '$uibModalInstance', '$rootScope'];

angular
  .module('metabuyer')
  .controller('paymentMethodDetailsCtrl', paymentMethodDetailsCtrl);
