'use strict';


/**
 *
 * @param $scope
 * @param items
 * @param globalFunc
 * @param usedQuotations
 * @param prV2Function
 * @param pathConstants
 * @param $http
 * @param $uibModal
 * @param $filter
 * @param createQuotation
 * @param updateQuotation
 * @param deleteQuotation
 * @param toastr
 * @param loadCacheableData
 * @param $rootScope
 * @param defaultQuotations
 * @param purchaseRequisitionsV2Services
 */

function quotationViewCtrl ($scope, items, globalFunc, usedQuotations, prV2Function, pathConstants, $http,
                            $uibModal, $filter, createQuotation, updateQuotation, deleteQuotation, toastr,
                            loadCacheableData, $rootScope, defaultQuotations, purchaseRequisitionsV2Services,
                            quotationAttachments, quotationSingleAttachment) {
  $scope.prItems = items;
  $scope.usedQuotations = usedQuotations;
  $scope.defaultQuotations = defaultQuotations;
  $scope.quotations = [];
  //5 quotation in addition to the original one
  var maxQuotation = 6;
  var emptyObject = {
    editable: false,
    product_availability: undefined,
    unit_price: undefined,
    amount: undefined,
    discount: 0,
    other_charges_total_amount: 0,
    insurance: 0,
    freight: 0,
    transportation: 0,
    bahan_bakar_tax: 0,
    withholding_tax: 0,
    miscellaneous: 0
  };

  // $scope.attachmentFileTypes = '.xls, .doc, .ppt, .txt, .pdf, .jpg, .png, .gif, .bmp, .xlsx, .docx, .pptx, .jpeg, .msg';
  $scope.attachmentUrl = pathConstants.apiUrls.quotation.attachments;
  $scope.attachmentParam = {quotation_id: $scope.PR._id};
  $scope.singleAttachmentService = quotationSingleAttachment;
  $scope.searchSuppliers = searchSuppliers;
  $scope.onSelectSupplier = onSelectSupplier;
  $scope.addItem = addItem;
  $scope.changeEditMode = changeEditMode;
  $scope.updateQuotationObject = updateQuotationObject;
  $scope.addSupplier = addSupplier;
  $scope.deleteItem = deleteItem;
  $scope.save = save;
  $scope.changeSelectedItem = changeSelectedItem;
  $scope.getSelectedQuotation = getSelectedQuotation;
  $scope.deleteSingleQuotation = deleteSingleQuotation;
  $scope.openDatePicker = openDatePicker;
  $scope.clearNeededByDate = clearNeededByDate;
  $scope.updateDate = updateDate;
  $scope.updatePriceFields = updatePriceFields;
  $scope.updateOtherChargesFields = updateOtherChargesFields;
  $scope.selectedItemIndex = 0;
  $scope.selectedQuotation = {};
  $scope.updateNoOtherQuotation = updateNoOtherQuotation;
  $scope.getQuotationNumber = getQuotationNumber;
  $scope.editMode = false;
  $scope.dateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };
  $scope.datePickerTimezone = $rootScope.datePickerTimezone;
  $scope.openSearchQuotationModal = openSearchQuotationModal;
  $scope.addItemCondition = addItemCondition;
  $scope.showNoQuotationAvailable = showNoQuotationAvailable;
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();

  loadCacheableData.loadData({
    module: 'currencies',
    'criteria[is_active]': 1,
    offset: 0
  }, 'currencies', 'localStorage').then(function(data){
    $scope.currenciesList = data;
  });

  loadCacheableData.loadData({
    module: 'payment-term',
    'criteria[is_active]': 1,
    offset: 0
  }, 'payment-term', 'localStorage').then(function(data){
    $scope.paymentTermsList = data;
  });

  function addItemCondition(index, sup) {

    if ($scope.quotations[index] !== undefined && $scope.quotations[index][0].readonly && !sup.item_id) {
      return false;
    }

    return !!$scope.editMode && index !== 0 && !sup.item_id;
  }

  function showNoQuotationAvailable(index, sup) {

    if ($scope.quotations[index] !== undefined &&  !!$scope.quotations[index] && $scope.quotations[index][0].readonly && !sup.item_id) {
      return true;
    }

    return !$scope.editMode && index !== 0 && !sup.item_id;
  }

  function openSearchQuotationModal() {
    $uibModal.open({
      templateUrl: 'components/priceComparisonComponents/searchQuotationModal/priceComparisonSearchQuotationModal.html',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'revision-wrapper',
      size: 'lg',
      scope: $scope,
      resolve: {
        pc: function() {
          return {};
        },
        pr: function() {
          return prV2Function.getPRData();
        },
        mode: function() {
          return 'pr';
        }
      },
      controller: 'priceComparisonSearchQuotationModal'
    });
  }

  function updateNoOtherQuotation(item){
    var param = {
      'status' : item.no_other_quotation_available.status,
      'reason' : ''
    };

    if (!!item.no_other_quotation_available.status){
      swal({
        html: true,
        title: 'Reason',
        type: 'input',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true,
        inputType: 'text',
        inputPlaceholder: 'Write a reason'
      },
      function (inputValue) {
        if (inputValue === false) {
          item.no_other_quotation_available.status = false;
          return false;
        }

        if (inputValue === "") {
          item.no_other_quotation_available.status = false;
          toastr.error('Reason is mandatory');
          return false;
        }

        if (!!inputValue) {
          var trimmedValue = inputValue.trim();
        }

        if (trimmedValue === '') {
          item.no_other_quotation_available.status = false;
          toastr.error('Reason is mandatory');
          return false;
        }

        item.no_other_quotation_available.reason = trimmedValue;
        param.reason = trimmedValue;
        setPrItem(item, param);
      });
    } else {
      item.no_other_quotation_available.reason = '';
      setPrItem(item, param);
    }
  }

  function setPrItem(item, param) {
    purchaseRequisitionsV2Services.setItem({
      requisition_item_id: item._id,
      action: 'no-other-quotation'
    }, param, function(resource) {
      if (!!resource && !!resource.content && !!resource.content.data)
        prV2Function.setPRData(resource.content.data);

      prV2Function.setLoading('lineItemTable', false);
    }, function(error) {
      prV2Function.setLoading('lineItemTable', false);
      globalFunc.objectErrorMessage(error);
      item.no_other_quotation_available.status = false;
    });
  }

  function updateDate(index){
    //convert date to milliseconds
    $scope.quotations[index][0].other_details.validity_from =
      globalFunc.convertDateToTimestamp($scope.quotations[index][0].date_from.needed_by_date);
    $scope.quotations[index][0].other_details.validity_to =
      globalFunc.convertDateToTimestamp($scope.quotations[index][0].date_to.needed_by_date);

    //Validation for the dates not to cross
    if(!!$scope.quotations[index][0].other_details.validity_from
      && !!$scope.quotations[index][0].other_details.validity_to) {
      if($scope.quotations[index][0].other_details.validity_to
        < $scope.quotations[index][0].other_details.validity_from) {
        $scope.quotations[index][0].date_to.needed_by_date = undefined;
        toastr.error('End date cannot be before start date.');
        return;
      }
    }

    updateQuotationObject(index);
  }

  function openDatePicker($event, quotation){
    $event.preventDefault();
    $event.stopPropagation();
    quotation.neededByDatePanel = true;
  }

  function clearNeededByDate(index, $event, quotation){
    $event.preventDefault();
    $event.stopPropagation();
    $scope.quotations[index][0].other_details.validity_from = null;
    $scope.quotations[index][0].other_details.validity_to = null;
    quotation.needed_by_date = null;
    updateQuotationObject(index);
  }

  function deleteSingleQuotation(quotationIndex){
    var quotation = $scope.quotations[quotationIndex];
    if (!quotation){
      _.forEach($scope.prItems, function(item){
        item.quotationsSupplier.splice(quotationIndex, 1)
      })
    }
    else{
      var quotationId = quotation[0]._id;
      deleteQuotation.delete(
        {
          quotation_id: quotationId
        },
        {

        },
        function (resource) {
          if (resource.content && resource.content.message){
            _.forEach($scope.prItems, function(item){
              item.quotationsSupplier.splice(quotationIndex, 1)
            });
            var pr = prV2Function.getPRData();
            var index = pr.quotation_ids.indexOf($scope.quotations[quotationIndex]._id);
            pr.quotation_ids.splice(index,1);
            $scope.quotations.splice(quotationIndex, 1);
            supplier.supplierSelected = false;
            toastr.success(resource.content.message)
          }
        },
        function (error) {

        }
      )
    }
  }

  function deleteSelectedQuotation(quotationIndex, supplier){
    var quotation = $scope.quotations[quotationIndex]
    var quotationId = quotation[0]._id;
    deleteQuotation.delete(
      {
        quotation_id: quotationId
      },
      {

      },
      function (resource) {
        if (resource.content && resource.content.message){
          $scope.quotations.splice(quotationIndex, 1);
          supplier.supplierSelected = false;

          createNewQuotation(quotationIndex, supplier);
        }
      },
      function (error) {

      }
    )
  }

  function changeSelectedItem(index) {
    $scope.selectedItemIndex = index;
    $scope.selectedQuotation = getSelectedQuotation(index);
  }

  function getSelectedQuotation(index) {
    return _.find($scope.quotations[0], function (quotation) {
      return quotation.supplier.basic_info.descr === $scope.prItems[index].supplier.descr;
    });
  }
  function save() {
    var anyError = false;
    _.forEach($scope.quotations, function (quotation, index) {
      var numberOfItems = quotation[0].items.length;
      var emptyField = 0;

      if (index !== 0 && !quotation[0].readonly) {
        if (_.isEmpty(quotation[0].supplier)) {
          anyError = true;
          $scope.editMode = true;
          toastr.error('Please select supplier');
          return;
        }
        var supplierName = quotation[0].supplier.basic_info.descr;

        _.forEach(quotation[0].items, function(item) {
          // check if current item in that quotation has field inserted
          if (!_.isEmpty(item.item_id)) {
            if (_.isEmpty(item.tax)) {
              anyError = true;
              $scope.editMode = true;
              toastr.error('Tax is compulsory for supplier ' + supplierName);
            } else if (_.isEmpty(item.product_availability)) {
              anyError = true;
              $scope.editMode = true;
              toastr.error('Product Availability is compulsory for supplier ' + supplierName);
            } else if (item.unit_price === null || isNaN(item.unit_price)) {
              anyError = true;
              $scope.editMode = true;
              toastr.error('Unit price is compulsory for supplier ' + supplierName);
            } else if (_.isEmpty(item.currency)) {
                anyError = true;
                $scope.editMode = true;
                toastr.error('Currency is compulsory for supplier ' + supplierName);
            } else {
              $scope.editMode = false;
            }
          } else {
            emptyField ++;
          }
        });
        if (emptyField === numberOfItems) {
          anyError = true;
          toastr.error('Quotation under ' + supplierName + ' is empty, quotations should have minimal 1 line item with pricing.');
        }
      } else {
        if ($scope.quotations.length <= 1) {
          // loop PR Items to check if no-other-quotation is ticked
          _.forEach($scope.prItems, function(prItem) {
            if (prItem.no_other_quotation_available.status === false) {
              // if not ticked then show error message
              anyError = true;
              $scope.editMode = true;
            } else {
              $scope.editMode = false;
            }
          });

          if (anyError === true) {
            toastr.error('No new quotation is added. Please insert a quotation.');
          }
          return;
        }
      }
    });

    if (anyError === true) {
      $scope.editMode = true;
      toastr.info('Quotation form is incomplete.');
    } else {
      validateQuotationOtherDetail();
    }
  }

  function validateQuotationOtherDetail() {
    var anyError = false;
    _.forEach($scope.quotations, function (quotation, index) {
      if (index !== 0 && !quotation[0].readonly) {
        var itemOtherDetail = quotation[0]['other_details'];
        var supplierName = quotation[0].supplier.basic_info.descr;


        if (itemOtherDetail.lead_time === null || isNaN(itemOtherDetail.lead_time) || itemOtherDetail.lead_time === "") {
          anyError = true;
          $scope.editMode = true;
          toastr.error('Lead Time is compulsory for supplier ' + supplierName);
        } else if (itemOtherDetail.validity_from === null || itemOtherDetail.validity_from === 0) {
          anyError = true;
          $scope.editMode = true;
          anyError = true;
          toastr.error('Validity from is compulsory for supplier ' + supplierName);
        } else if (itemOtherDetail.validity_to === null || itemOtherDetail.validity_to === 0) {
          anyError = true;
          $scope.editMode = true;
          toastr.error('Validity to is compulsory for supplier ' + supplierName);
        } else if (_.isEmpty(itemOtherDetail.remark)) {
          anyError = true;
          $scope.editMode = true;
          toastr.error('Remarks is compulsory for supplier ' + supplierName);
        } else if (_.isEmpty(itemOtherDetail.payment_term)) {
          anyError = true;
          $scope.editMode = true;
          toastr.error('Payment Term is compulsory for supplier ' + supplierName);
        } else {
          $scope.editMode = false;
        }
      }
    });

    if (anyError === true) {
      $scope.editMode = true;
      toastr.info('Quotation form is incomplete.');
    }
  }

  function changeEditMode() {
    $scope.editMode = true;

    _.forEach($scope.quotations, function (quotation, index) {
      if (index !== 0 && $scope.editMode === true) {
        quotation[0].items[0].editable = true;
      }
    });
  }

  function deleteItem(itemIndex, quotationIndex){
    // assign empty quotation object, with supplier info
    var supplierDescr = $scope.prItems[itemIndex].quotationsSupplier[quotationIndex]['supplier_descr'];
    var supplierRegNo = $scope.prItems[itemIndex].quotationsSupplier[quotationIndex]['supplier_reg_no'];

    // this is the field use to retain the item_id after user deletes the quotation
    var itemId = $scope.quotations[quotationIndex][0].items[itemIndex].item_id;

    $scope.prItems[itemIndex].quotationsSupplier[quotationIndex] = _.cloneDeep(emptyObject);
    $scope.prItems[itemIndex].quotationsSupplier[quotationIndex]['supplier_descr'] = supplierDescr;
    $scope.prItems[itemIndex].quotationsSupplier[quotationIndex]['supplier_reg_no'] = supplierRegNo;

    if (!_.isEmpty($scope.quotations[quotationIndex])) {
      $scope.quotations[quotationIndex][0].items[itemIndex] = _.cloneDeep(emptyObject);
      //retain the item_id as item_id_hidden for UI purpose, after user deletes the quotation
      $scope.quotations[quotationIndex][0].items[itemIndex].item_id_hidden = itemId;
    }

    updateQuotationObject(quotationIndex, itemIndex);
  }

  function addSupplier(){
    //Todo: update this weak validation (Robby, 29/05/2020)
    if ($scope.prItems[0].quotationsSupplier.length >= maxQuotation) {
      globalFunc.objectErrorMessage('You have inserted the maximum amount of quotation');
      return;
    }
    _.forEach($scope.prItems, function(item){
      item.quotationsSupplier.push(_.cloneDeep(emptyObject))
    })
  }

  function addItem(item, index, itemIndex) {

    if (!!$scope.quotations[index]){

      var validate = false;

      _.forEach($scope.prItems, function(prItem, prItemIndex){
        if (prItemIndex === itemIndex) {
          if (prItem.no_other_quotation_available !== undefined && prItem.no_other_quotation_available.status) {
            validate = true;
          }
        }
      });

      if (validate) {
        toastr.error('No Other Quotation Available For is checked for line item ' +(itemIndex+1)+ ' , additional quotations are not allowed.')
        return true;
      }

      $scope.quotations[index][0].items[itemIndex] = item;
    } else {
      toastr.error('Please select a supplier');
      return;
    }
    item.editable = true;
    item.item_id = $scope.prItems[itemIndex]._id;
    item.item_code = $scope.prItems[itemIndex].item_code;
    item.item_name = $scope.prItems[itemIndex].name;
    item.item_descr = $scope.prItems[itemIndex].descr;
  }

  function getFirstFieldOccurrence(){
    var addedSupplier = [];
    _.forEach($scope.defaultQuotations, function(quotation){
      var supplierId = quotation.supplier ? quotation.supplier._id : quotation.supplier_id;
      _.forEach($scope.prItems, function(item){
        if (supplierId === item.supplier._id && addedSupplier.indexOf(supplierId) === -1){
          addedSupplier.push(supplierId);
          if (!!item.payment_term){
            quotation.other_details.payment_term = item.payment_term;
          }
          if (!!item.justification){
            quotation.other_details.justification = item.justification;
          }
          if (!!item.supplier_reference_no){
            quotation.other_details.supplier_reference_no = item.supplier_reference_no;
          }
          if (!!item.quotation_validity_from){
            quotation.other_details.validity_from = item.quotation_validity_from;
          }
          if (!!item.quotation_validity_to){
            quotation.other_details.validity_to = item.quotation_validity_to;
          }
          if (!!item.lead_time){
            quotation.other_details.lead_time = item.lead_time;
          }
        }
      })
    })
  }

  /**
   * applying a single quotation on a single item
   * @param prItem
   * @param defaultQuotation
   */
  function prepareDefaultQuotationObject(prItem, defaultQuotation) {
    var found = false;

    _.forEach(defaultQuotation.items, function (quotationSingleItem) {
      if (!found && prItem._id === quotationSingleItem.item_id) {
        var obj = _.cloneDeep(quotationSingleItem);
        quotationSingleItem.editable = false;
        quotationSingleItem.supplierSelected = false;
        quotationSingleItem.supplier_descr = prItem.supplier.descr;
        quotationSingleItem.supplier_reg_no = prItem.supplier.reg_no;
        quotationSingleItem.insurance = prItem.insurance;
        quotationSingleItem.freight = prItem.freight;
        quotationSingleItem.transportation = prItem.transportation;
        quotationSingleItem.bahan_bakar_tax = prItem.bahan_bakar_tax;
        quotationSingleItem.withholding_tax = prItem.withholding_tax;
        quotationSingleItem.miscellaneous = prItem.miscellaneous;
        prItem.quotationsSupplier.push(quotationSingleItem);
        quotationSingleItem.other_charges_total_amount = prItem.other_charges_total_amount;
        found = true;
      }
    });
  }

  /**
   * search supplier
   * @param val
   */
  function searchSuppliers(val) {
    if (!!val && val.length > 2) {
      $scope.supplierSearching = true;

      var moduleParam = {
        module: 'suppliers'
      };

      var additionalParams = {
        'criteria[0][basic_info|descr]': val,
        'criteria[0][basic_info|reg_no]': val,
        'criteria[0][$operator]': 'or',
        'criteria[1][basic_info|status][0]': 'active',
        'criteria[1][basic_info|status][1]': 'blacklisted',
        'criteria[1][basic_info|status][2]': 'inactive',
        'criteria[1][basic_info|status][3]': 'active_pending',
        criteria_operator: 'and',
        company_contract: $scope.PR.company.code,
        offset: 10
      };

      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: additionalParams,
        ignoreLoadingBar: true
      }).then(function (response) {
        if (response.data.content.data.length === 0) {
          $scope.supplierSearching = false;
          item = [];
        } else {
          var item = _.filter(response.data.content.data, function (item) {
            if (!!item.selected_contract.status && item.selected_contract.status.toLowerCase() === 'active') {
              return item;
            }
          });
        }
        var searchValObject = {
          basic_info: {
            descr: val
          }
        };
        var list = [searchValObject];
        return list.concat(item);

      });
    }
  }

  function onSelectSupplier(supIdx, supplier) {
    if (!!$scope.quotations[supIdx]) {
      deleteSelectedQuotation(supIdx, supplier);
    } else {
      createNewQuotation(supIdx, supplier);
    }
  }

  function getTaxData() {
    var taxes = prV2Function.getTaxData();
    var res = [];
    _.forEach(taxes, function (tax) {
      if (tax.country_code === prV2Function.getPRData().company.company_country) {
        res.push(tax);
      }
    });

    return res;
  }

  function updatePriceFields(itemIndex, index, item) {
    if (String(item.discount).indexOf('%') > -1){
      var discountPercentage = Number(item.discount.replace('%', ''));
      item.discount_percentage = discountPercentage;
      item.discount = math.round((discountPercentage/100) * ($scope.prItems[itemIndex].qty*item.unit_price), 2);
    }
    else{
      item.discount_percentage = 0;
    }

    if (item.discount > $scope.prItems[itemIndex].qty*item.unit_price){
      toastr.error('Discount can not be more than Item total');
      item.discount = $scope.backup_items[itemIndex].quotationsSupplier[index].discount;
      item.unit_price = $scope.backup_items[itemIndex].quotationsSupplier[index].unit_price;
      item.discount_percentage = $scope.backup_items[itemIndex].quotationsSupplier[index].discount_percentage;
    }
    item.discount = Number(item.discount);
    updateQuotationObject(index, itemIndex);
  }

  /**
   * Update Other Charges Field
   * @param field           other_charges
   * @param index           index
   * @param item            usedQuotationObject
   */
  function updateOtherChargesFields(field, index, item) {
    var total = 0;
    var other_charges = ['insurance',
                         'freight',
                         'transportation',
                         'bahan_bakar_tax',
                         'withholding_tax',
                         'miscellaneous'];

    _.forEach(other_charges, function(otherCharge){
      item[otherCharge] = Number(item[otherCharge]);
      total += item[otherCharge];
    })
    item['other_charges_total_amount'] = total;
    updateQuotationObject(index);
  }

  /**
   * deletes unused field in backend
   * @param {*} quotation
   */
  function prepareUpdateData(quotation) {
    _.forEach(quotation.items, function(item, index){
      delete item['editable'];
      if (_.isEmpty(item)){
        quotation.items.splice(index,1);
      }
    });
    return quotation
  }

  function updateQuotationObject(index, itemIndex) {

    var itemIndex = (itemIndex === undefined) ? null : itemIndex;

    if (!!$scope.quotations[index]){
      var quotation = _.cloneDeep($scope.quotations[index][0]);
      quotation = prepareUpdateData(quotation);
      quotation.item_index = itemIndex;

      updateQuotation.put(
        {
          quotation_id: quotation._id
        },
          quotation
        ,
        function (resource) {
          setQuotationBackup($scope.prItems);
        },
        function (resource) {
          toastr.error(resource.data.content.error);
        }
      )
    }
  }

  function setQuotationBackup(items){
    $scope.backup_items = _.cloneDeep(items);
  }

  function prepareUsedQuotationObject(prItem, usedQuotation){
    var found = false;
    if (!!usedQuotation.items){
      _.forEach(usedQuotation.items, function (quotationSingleItem) {
        if (!found && prItem._id === quotationSingleItem.item_id) {
          quotationSingleItem.editable = false;
          quotationSingleItem.supplierSelected = false;
          quotationSingleItem.supplier_descr = usedQuotation.supplier.basic_info.descr;
          quotationSingleItem.supplier_reg_no = usedQuotation.supplier.basic_info.reg_no;
          quotationSingleItem.unit_price = parseFloat(quotationSingleItem.unit_price);
          quotationSingleItem.other_charges_total_amount = usedQuotation.items[0].other_charges_total_amount;
          quotationSingleItem.insurance = usedQuotation.items[0].insurance;
          quotationSingleItem.freight = usedQuotation.items[0].freight;
          quotationSingleItem.transportation = usedQuotation.items[0].transportation;
          quotationSingleItem.bahan_bakar_tax = usedQuotation.items[0].bahan_bakar_tax;
          quotationSingleItem.withholding_tax = usedQuotation.items[0].withholding_tax;
          quotationSingleItem.miscellaneous = usedQuotation.items[0].miscellaneous;
          quotationSingleItem.readonly = usedQuotation.readonly;
          quotationSingleItem.quotation_number = usedQuotation.quotation_number;
          prItem.quotationsSupplier.push(quotationSingleItem);
          found = true;
        }
      });
    }
    // if item is not found in quotation, initialize with emptyObject for UI
    if (!found){
      var obj = _.cloneDeep(emptyObject);
      obj.editable = false;
      obj.supplierSelected = false;

      obj.supplier_descr = usedQuotation.supplier.basic_info.descr;
      obj.supplier_reg_no = usedQuotation.supplier.basic_info.reg_no;
      prItem.quotationsSupplier.push(obj);
    }
    usedQuotation.other_details.lead_time = parseFloat(usedQuotation.other_details.lead_time);
  }


  function getQuotationAttachments() {
    _.forEach($scope.quotations, function(quotations){
      _.forEach(quotations, function(quotation) {
        quotationAttachments.get({
          quotation_id: quotation._id
        }, function(resource) {
          if (!!resource && !!resource.content)
            quotation['attachments'] = resource.content.data;
        }, function(error) {
          globalFunc.objectErrorMessage(error);
        })
      })
    })
  }

  function getQuotationNumber(supplierDescr) {
    var quotationNumber = null;
    for (var i = 0; i < $scope.newSupplierQuotationArray.length; i++) {
      if ($scope.newSupplierQuotationArray[i].supplier_descr === supplierDescr) {
        quotationNumber = $scope.newSupplierQuotationArray[i].quotation_number;
        break;
      }
    }

    return quotationNumber;
  }

  function createNewQuotation(supIdx, supplier, copyQuotation) {
    var params = {};
    if (!!supplier && !!supplier._id && !!supplier.basic_info.descr) {
      params.supplier_id = supplier._id;
      params.supplier_descr = supplier.basic_info.descr;
      params.supplier_reg_no = supplier.basic_info.reg_no;
    } else {
      params.supplier_descr = supplier.basic_info.descr;
      params.supplier_reg_no = supplier.basic_info.reg_no;
    }
    params.requisition_id = prV2Function.getPRData()._id;

    if (!!copyQuotation) {
      params.copy_quotation_id = copyQuotation._id;
    }

    createQuotation.post(
      {},
      params,
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data){
          // insert at the specific index
          $scope.quotations.splice(supIdx, 0, [resource.content.data]);
          if (!prV2Function.getPRData().quotation_ids){
            prV2Function.getPRData().quotation_ids = []
          }
          prV2Function.getPRData().quotation_ids.splice(supIdx-1, 1,resource.content.data._id);
          $scope.quotations[supIdx][0].date_to = {needed_by_date: !!resource.content.data.other_details ? resource.content.data.other_details.validity_to : ''};
          $scope.quotations[supIdx][0].date_from = {needed_by_date: !!resource.content.data.other_details ? resource.content.data.other_details.validity_from : ''};

          _.forEach($scope.prItems, function(item){

            if (!!copyQuotation) {
              prepareUsedQuotationObject(item, resource.content.data);
            }

            // if user entered item detail before selecting supplier
            if (!!item.quotationsSupplier[supIdx]){
              item.supplier_id = supplier._id;
              item.supplier_descr = supplier.basic_info && supplier.basic_info.descr;
              item.supplier_reg_no = supplier.basic_info && supplier.basic_info.reg_no;
              item.quotationsSupplier[supIdx].supplier_descr = supplier.basic_info && supplier.basic_info.descr;
              item.quotationsSupplier[supIdx].supplier_reg_no = supplier.basic_info && supplier.basic_info.reg_no;
              // pass item id as hidden for validation purpose in backend
              item.quotationsSupplier[supIdx].item_id_hidden = item._id;
              $scope.quotations[supIdx][0].items.push(item.quotationsSupplier[supIdx]);
            }
            else {
              var obj = _.cloneDeep(emptyObject);
              obj.supplier_id = supplier._id;
              obj.supplier_descr = supplier.basic_info && supplier.basic_info.descr;
              obj.supplier_reg_no = supplier.basic_info && supplier.basic_info.reg_no;
              obj.editable = true;
              obj.editMode = true;
              obj.supplierSelected = true;
              obj._id = resource.content.data._id;
              item.quotationsSupplier[supIdx] = (obj);
            }
          });
          updateQuotationObject(supIdx);

          if (!!copyQuotation) {
            var newQuotation = $scope.quotations[supIdx][0];

            quotationAttachments.get({
              quotation_id: newQuotation._id
            }, function(resource) {
              if (!!resource && !!resource.content)
                newQuotation['attachments'] = resource.content.data;
            }, function(error) {
              globalFunc.objectErrorMessage(error);
            })
          }
        }
      },
      function (error) {

      }
    );
  }

  function initialize() {
    $scope.allowEditQuotation = !$scope.editMode && (prV2Function.getEditingState() === 'editable');
    getFirstFieldOccurrence();

    if (_.isEmpty($scope.prItems)) {
      $scope.prItems = prV2Function.getPRData().pr_items;
    }

    if (!$scope.prItems)
      return;
    _.forEach($scope.prItems, function (prItem) {
      prItem.quotationsSupplier = [];

      _.forEach($scope.defaultQuotations, function (defaultQuotation) {
        prepareDefaultQuotationObject(prItem, defaultQuotation);
      });

      _.forEach($scope.usedQuotations, function (usedQuotation, index) {
        prepareUsedQuotationObject(prItem, usedQuotation);
      });
    });

    $scope.quotations.push(defaultQuotations);
    _.forEach($scope.usedQuotations, function(quotation){
      //temporary placeholder for datepicker
      quotation.date_from = {needed_by_date: !!quotation.other_details ? quotation.other_details.validity_from : ''};
      quotation.date_to = {needed_by_date: !!quotation.other_details ? quotation.other_details.validity_to : ''};
      $scope.quotations.push([quotation]);
    });

    setQuotationBackup($scope.prItems);
    $scope.taxesList = getTaxData();

    getQuotationAttachments();

    $scope.newSupplierQuotationArray = [];
    _.forEach($scope.quotations[0], function (quotation) {
      $scope.newSupplierQuotationArray.push({
          'supplier_id': quotation.supplier._id,
          'supplier_descr': quotation.supplier.basic_info.descr,
          'supplier_reg_no': quotation.supplier.basic_info.reg_no,
          'quotation_number': quotation.quotation_number
        }
      )
    });

    $scope.selectedQuotation = getSelectedQuotation($scope.selectedItemIndex);
  }

  initialize();

  $scope.$on('addPrSearchQuotation', function(event, result) {

    if ($scope.prItems[0].quotationsSupplier.length >= maxQuotation) {
      globalFunc.objectErrorMessage('You have inserted the maximum amount of quotation');
      return;
    }

    var supIdx = 1;

    for (var index in $scope.prItems) {
      var item = _.cloneDeep($scope.prItems[index]);
      item.quotationsSupplier.push(_.cloneDeep(emptyObject));
      supIdx = item.quotationsSupplier.length - 1;
    }

    createNewQuotation(supIdx, result.supplier, result.quotation)
  });

}

quotationViewCtrl.$inject = ['$scope', 'items', 'globalFunc', 'usedQuotations', 'prV2Function', 'pathConstants',
                             '$http', '$uibModal', '$filter', 'createQuotation', 'updateQuotation', 'deleteQuotation',
                             'toastr', 'loadCacheableData', '$rootScope', 'defaultQuotations', 'purchaseRequisitionsV2Services', 'quotationAttachments', 'quotationSingleAttachment'];

angular
  .module('metabuyer')
  .controller('quotationViewCtrl', quotationViewCtrl);
