'use strict';

/**
 *
 * @param $scope
 * @param $state
 * @param companyDetails
 * @param addresses
 * @param setCompanyDefaultAddress
 * @param globalFunc
 * @param toastr
 * @param $uibModal
 * @param UserPermissions
 * @param exportCompanyAddresses
 * @param pathConstants
 * @param $filter
 */
function  companyAddressesCtrl($scope, $state, companyDetails, addresses, setCompanyDefaultAddress, globalFunc, toastr,
                               $uibModal, UserPermissions, exportCompanyAddresses, pathConstants, $filter, $rootScope) {

  $scope.company = companyDetails.data;
  $scope.addressList = {};
  $scope.deliveryAddressActive = false;
  $scope.billingAddressActive = true;
  $scope.setDefaultAddress = setDefaultAddress;
  $scope.addressActiveTab = addressActiveTab;
  $scope.addNewAddress = addNewAddress;
  $scope.back = back;
  $scope.isDeveloper = isDeveloper;
  $scope.exportAddresses = exportAddresses;
  $scope.importAddresses = importAddresses;
  $scope.importLink = pathConstants.apiUrls.companies.importCompanyAddresses;
  $scope.detailAddress = detailAddress;
  $scope.updateAddressResult = updateAddressResult;
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();

  function isDeveloper() {
    return UserPermissions.isDeveloper($scope.userProfile);
  }

  /**
  * Goes back to company details
  */
  function back() {
    if (!!$scope.companyDetails && !!$scope.companyDetails._id)
      $state.go('main.user.companiesMgmt.detailCompanies', {id: $scope.companyDetails._id});
    else
      window.history.back();
  }

  function addNewAddress(type) {
    var addNewAddress = $uibModal.open({
      templateUrl: 'app/companiesManagement/companies/companyAddresses/add.html',
      backdrop: 'static',
      controller: 'addNewAddressCtrl',
      resolve: {
        addressType: function () {
          return type;
        }
      },
      keyboard: false,
      scope: $scope,
      size: 'lg'
    });

    addNewAddress.result.then(function (result) {
      if (result) {
        $scope.addressList.billing_addresses.push(result);
        $scope.addressList.delivery_addresses.push(result);
      }
    });
  }

  function setDefaultAddress(address, addressType) {
    swal({
      title: 'Set Default Address',
      text: 'Are you sure you want to change this Company\'s ' + addressType + ' Address?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      closeOnConfirm: true,
      closeOnCancel: true
    }, function (isConfirm) {
      if (isConfirm) {
        setCompanyDefaultAddress.put({
          id: $scope.company._id
        }, {
          address_type: addressType,
          address_id: address._id
        }, function (success) {
          if (addressType === 'billing') {
            _.forEach($scope.addressList.billing_addresses, function (billingAddress) {
              if (billingAddress._id !== address._id) {
                billingAddress.selected = false;
              } else {
                billingAddress.selected = true;
              }
            });
          } else if (addressType === 'delivery') {
            _.forEach($scope.addressList.delivery_addresses, function (billingAddress) {
              if (billingAddress._id !== address._id) {
                billingAddress.selected = false;
              } else {
                billingAddress.selected = true;
              }
            });
          }
          toastr.success('Company updated.');
        }, function (error) {
          address.selected = !address.selected;
          $scope.$apply();
          globalFunc.objectErrorMessage(error);
        });
      } else {
        address.selected = !address.selected;
        $scope.$apply();
      }
    });
  }

  function addressActiveTab(type){
    if(type === 'billing'){
      $scope.deliveryAddressActive = false;
      $scope.billingAddressActive = true;
    } else {
      $scope.deliveryAddressActive = true;
      $scope.billingAddressActive = false;
    }
  }

  /**
   * Generate CSV for billing address
   */
  function exportAddresses(type) {
    exportCompanyAddresses.get({
        companyId: $scope.company._id,
        type: type
      },
      function(resource) {
        if (!!resource.content && !!resource.content.data) {
          var newUrl = $filter('format')(pathConstants.apiUrls.reports.download, {
            id: resource.content.data
          });
          window.open(newUrl, '_self');
        } else {
          globalFunc.objectErrorMessage('An error occurred.');
        }
      }, function(error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function importAddresses(type) {
    $uibModal.open({
      templateUrl: "app/masterDataManagement/Import-Template.html",
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return $scope.importLink;
        },
        title: function () {
          return 'Company ' + type + ' Addresses';
        },
        type: function () {
          return type;
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title, type) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.importLink = importLink;
        $scope.title = title;
        $scope.params = {type: type.toLowerCase()};
      }
    });
  }

  function setOriginalDefaultAddresses(){
    _.forEach($scope.addressList.billing_addresses, function (address) {
      if(address._id === $scope.company.default_billing_address)
        address.selected = true;
    });

    _.forEach($scope.addressList.delivery_addresses, function (address) {
      if(address._id === $scope.company.default_delivery_address)
        address.selected = true;
    });
  }


  /**
   * function to update the Address after being edited in the edit modal
   * @param address
   * @param type
     */
  function updateAddressResult(address, type){
    var addressTemp = type === 'delivery' ? $scope.addressList.delivery_addresses : $scope.addressList.billing_addresses;
    for (var i in addressTemp) {
      if (addressTemp[i]._id === address._id) {
        addressTemp[i] = address;
      }
    }
    $scope.addressList.delivery_addresses = addressTemp;
    $scope.addressList.billing_addresses = addressTemp;
  }

  function detailAddress(address, type) {
    $uibModal.open({
      templateUrl: "app/companiesManagement/companies/addressModal/addressModal.html",
      controller: 'addressModalCtrl',
      backdrop: 'static',
      keyboard: false,
      resolve:{
        addressType: function() {
          return type;
        },
        addressDetail: function() {
          return angular.copy(address);
        },
        updateAddressResult: function(){
          return $scope.updateAddressResult;
        }
      }
    });
  }

  function init(){
    $scope.addressList.billing_addresses = _.cloneDeep(addresses);
    $scope.addressList.delivery_addresses = _.cloneDeep(addresses);
    setOriginalDefaultAddresses();
  }

  init();
}

companyAddressesCtrl.$inject = ['$scope', '$state', 'companyDetails', 'addresses', 'setCompanyDefaultAddress', 'globalFunc',
  'toastr', '$uibModal', 'UserPermissions', 'exportCompanyAddresses', 'pathConstants', '$filter', '$rootScope'];
angular.module('metabuyer')
  .controller('companyAddressesCtrl', companyAddressesCtrl);
