'use strict';

function CatalogsCtrl($scope, $state, $rootScope, globalFunc) {
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();
  $scope.advancedSearchState = 'main.user.advancedSearch.catalogV2';

  var tabDataMenu = [
    {
      heading: 'All',
      route: 'main.user.catalogs.manageCatalog',
      key: 'uploads',
      params: {
        status: 'all',
        cursor: null,
        filter: null,
        query: null,
        type: null,
        page: null
      }
    },
    {
      heading: 'Approved',
      route: 'main.user.catalogs.manageCatalog',
      key: 'uploads',
      params: {
        status: 'approved',
        cursor: null,
        filter: null,
        query: null,
        type: null,
        page: null
      }
    },
    {
      heading: 'Compose',
      route: 'main.user.catalogs.manageCatalog',
      key: 'uploads',
      params: {
        status: 'compose',
        cursor: null,
        filter: null,
        query: null,
        type: null,
        page: null
      }
    },
    {
      heading: 'Expired',
      route: 'main.user.catalogs.manageCatalog',
      key: 'uploads',
      params: {
        status: 'expired',
        query: null,
        page: null
      }
    },
    {
      heading: 'Error',
      route: 'main.user.catalogs.manageCatalog',
      key: 'uploads',
      params: {
        status: 'error',
        cursor: null,
        query: null,
        page: null
      }
    },
    {
      heading: 'Deactivated',
      route: 'main.user.catalogs.manageCatalog',
      key: 'uploads',
      params: {
        status: 'deactivated',
        cursor: null,
        filter: null,
        query: null,
        type: null,
        page: null
      }
    },
    {
      heading: 'Pending Approval',
      route: 'main.user.catalogs.manageCatalog',
      key: 'uploads',
      params: {
        status: 'pending',
        cursor: null,
        filter: null,
        query: null,
        type: null,
        page: null
      }
    },
    {
      heading: 'Rejected',
      route: 'main.user.catalogs.manageCatalog',
      key: 'uploads',
      params: {
        status: 'rejected',
        cursor: null,
        filter: null,
        query: null,
        type: null,
        page: null
      }
    },
    {
      heading: 'Cancelled',
      route: 'main.user.catalogs.manageCatalog',
      key: 'uploads',
      params: {
        status: 'cancelled',
        cursor: null,
        filter: null,
        query: null,
        type: null,
        page: null
      }
    }
  ];

  if (!!$rootScope.isV2Enabled) {
    var index = _.findIndex(tabDataMenu, function(menu) {
      return menu.heading === 'Error';
    });
    tabDataMenu.splice(index, 1);
  }

  $scope.tabData = [
    {
      heading: 'Manage Catalog Uploads',
      route: 'main.user.catalogs.manageCatalog',
      key: 'uploads',
      menu: tabDataMenu
    },
    {
      heading: 'Manage Catalog',
      route: 'main.user.catalogs.catalogGroup',
      key: 'catalog',
      menu: [
        {
          heading: 'Catalog Group',
          route: 'main.user.catalogs.catalogGroup',
          key: 'catalog',
          params: {
            status: '',
            cursor: null,
            filter: null,
            query: null,
            type: null,
            page: null
          }
        },
        {
          heading: 'Catalog Code',
          route: 'main.user.catalogs.catalogCode',
          key: 'catalog',
          params: {
            status: '',
            cursor: null,
            filter: null,
            query: null,
            type: null,
            page: null
          }
        }
      ]
    }
  ];

  if (!$rootScope.isOffline) {
    $scope.tabData.push({
      heading: 'Import Catalog',
      route: 'main.user.catalogs.import.catalog',
      key: 'import',
      params: {
        status: 'import',
        cursor: null,
        filter: null,
        query: null,
        type: null,
        page: null
      }
    });
  }

  /**
   * making sure the Stats and placeHolder changes if the Child scope changed
   */
  $scope.$on('$stateChangeStart', function( event, data ) {
    if(!!data.name && data.name.indexOf('manageCatalog')> -1){
      $scope.searchPlaceholder = 'Search Catalogs';
      $scope.searchState = 'main.user.catalogs.manageCatalog';
      $scope.parentState = 'main.user.catalogs.manageCatalog';
      $scope.parentStateParams = $state.params;
      $scope.columns = manageCatalogColumns;
    }
    else if(!!data.name && data.name.indexOf('catalogGroup') > -1){
      $scope.searchPlaceholder = 'Search Catalog Groups';
      $scope.searchState = 'main.user.catalogs.catalogGroup';
      $scope.parentState = 'main.user.catalogs.catalogGroup';
      $scope.parentStateParams = $state.params;
      $scope.columns = catalogGroupColumns;
    }
    else if(!!data.name && data.name.indexOf('catalogCode') > -1){
      $scope.searchPlaceholder = 'Search Catalog Code';
      $scope.searchState = 'main.user.catalogs.catalogCode';
      $scope.parentState = 'main.user.catalogs.catalogCode';
      $scope.parentStateParams = $state.params;
      $scope.columns = catalogCodeColumns;
    }
    else if(!!data.name && data.name.indexOf('catalogItems') > -1){
      $scope.searchPlaceholder = 'Search Catalog Items';
      $scope.searchState = 'main.user.catalogs.catalogItems';
      $scope.parentState = 'main.user.catalogs.catalogItems';
      $scope.parentStateParams = $state.params;
      $scope.columns = catalogItemColumns;
    }
    else if (!!data.name && data.name.indexOf('manageCompanies') > -1){
      $scope.columns = companyColumns;
      $scope.searchState = 'main.user.catalogs.company.manageCompanies';
      $scope.searchPlaceHolder = 'Search Company';
      $scope.parentState = 'main.user.catalogs.company.manageCompanies';
      $scope.parentStateParams = $state.params;
    }
    else if (!!data.name && data.name.indexOf('companyItems') > -1){
      $scope.columns = itemColumns;
      $scope.searchState = 'main.user.catalogs.company.companyItems';
      $scope.searchPlaceHolder = 'Search Item';
      $scope.parentState = 'main.user.catalogs.company.companyItems';
      $scope.parentStateParams = $state.params;
    }
  });

  function initialize(){
    if(!!$state.params && !!$state.params.query){
      $scope.searchText = $state.params.query;
    }
    else{
      $scope.searchText = '';
    }

    if(!!$state.current.name && $state.current.name.indexOf('manageCatalog') > -1) {
      $scope.searchPlaceholder = 'Search Catalogs';
      $scope.searchState = 'main.user.catalogs.manageCatalog';
      $scope.parentState = 'main.user.catalogs.manageCatalog';
      $scope.parentStateParams = $state.params;
      $scope.columns = manageCatalogColumns;
      $scope.activatedTab = $scope.tabData[0]
    }
    else if(!!$state.current.name && ($state.current.name.indexOf('catalogGroup') > -1)
        || ($state.current.name.indexOf('createCatalogGroup') > -1)
        || ($state.current.name.indexOf('editCatalogGroup') > -1)){
      $scope.searchPlaceholder = 'Search Catalog Groups';
      $scope.searchState = 'main.user.catalogs.catalogGroup';
      $scope.parentState = 'main.user.catalogs.catalogGroup';
      $scope.parentStateParams = $state.params;
      $scope.columns = catalogGroupColumns;
      $scope.activatedTab = $scope.tabData[1]
    }
    else if(!!$state.current.name && $state.current.name.indexOf('catalogCode') > -1){
      $scope.searchPlaceholder = 'Search Catalog Code';
      $scope.searchState = 'main.user.catalogs.catalogCode';
      $scope.parentState = 'main.user.catalogs.catalogCode';
      $scope.parentStateParams = $state.params;
      $scope.columns = catalogCodeColumns;
      $scope.activatedTab = $scope.tabData[1]
    }
    else if(!!$state.current.name && $state.current.name.indexOf('catalogItems') > -1) {
      $scope.searchPlaceholder = 'Search Catalog Items';
      $scope.searchState = 'main.user.catalogs.catalogItems';
      $scope.parentState = 'main.user.catalogs.catalogItems';
      $scope.parentStateParams = $state.params;
      $scope.columns = catalogItemColumns;
      $scope.activatedTab = $scope.tabData[1]
    }
    else if(!!$state.current.name && $state.current.name.indexOf('import') > -1) {
        $scope.searchPlaceholder = 'Search Catalogs';
        $scope.searchState = 'main.user.catalogs.manageCatalog';
        $scope.parentState = 'main.user.catalogs.manageCatalog';
        $scope.parentStateParams = $state.params;
        $scope.activatedTab = $scope.tabData[2]
    }
    else if (!!$state.current.name && $state.current.name.indexOf('manageCompanies') > -1){
      $scope.columns = companyColumns;
      $scope.searchState = 'main.user.catalogs.company.manageCompanies';
      $scope.searchPlaceHolder = 'Search Company';
      $scope.parentState = 'main.user.catalogs.company.manageCompanies';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[3];
    }
    else if (!!$state.current.name && $state.current.name.indexOf('companyItems') > -1){
      $scope.columns = itemColumns;
      $scope.searchState = 'main.user.catalogs.company.companyItems';
      $scope.searchPlaceHolder = 'Search Item';
      $scope.parentState = 'main.user.catalogs.company.companyItems';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[3];
    }
    else{
      $scope.searchPlaceholder = 'Search Catalogs';
      $scope.searchState = 'main.user.catalogs.manageCatalog';
      $scope.parentState = 'main.user.catalogs.manageCatalog';
      $scope.parentStateParams = $state.params;
      $scope.columns = manageCatalogColumns;
      $scope.activatedTab = $scope.tabData[0];
    }

    if(!!$state.params && !!$state.params.status){
      $scope.status = $state.params.status
    }

    if(!!$state.params && !!$state.params.filter){
      $scope.searchFilter = $state.params.filter;
    }
  }

  var companyColumns = [
    {id: 'descr', label: 'Company'},
    {id: 'code', label: 'Code'},
    {id: 'reg_no', label: 'Registration Code'},
    {id: 'currency_code', label: 'Preferred Currency'},
    {id: 'business_unit.descr', label: 'Business Unit'},
    {id: 'industry.descr', label: 'Industry'},
    {id: 'creator_info|display_name', label: 'Created By'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updater_info|display_name', label: 'Updated By'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    }
  ];

  var manageCatalogColumns = [
    {id: 'check box', title: '', unsortable: true, unsearchable: true, conditional: true, condition: ['pending']},
    {id: 'catalog_group.descr', label: 'Catalog Group'},
    {id: 'catalog_code.code', label: 'Catalog Code'},
    {id: 'catalog_code.descr', label: 'Catalog Description'},
    {id: 'supplier|descr', label: 'Supplier'},
    {id: 'catalog_title', label: 'Catalog Title'},
    {
      id: 'file_header|itemcount',
      label: '# item',
      type: 'double',
      unsearchable: true
    },
    {id: 'tender|name', label: 'Tender Name'},
    {
      id: 'import_effective_date',
      label: 'Effective At',
      type: 'date',
      unsearchable: true
    },
    {id: 'creator_info|display_name', label: 'Creator'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updater_info|display_name', label: 'Updated By'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    },
    {id: 'status', label: 'Status', conditional: true, condition: 'all'}
  ];

  var catalogGroupColumns = [
    {id: 'code', label: 'Code'},
    {id: 'descr', label: 'Description'},
    {id: 'creator.display_name', label: 'Created By'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updater.display_name', label: 'Updated By'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    },
    {id: 'is_active', label: 'Status', unsearchable: true}
  ];

  var catalogCodeColumns = [
    {id: 'code', label: 'Catalog Code'},
    {id: 'descr', label: 'Catalog Code Description'},
    {id: 'creator.display_name', label: 'Created By'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updater.display_name', label: 'Updated By'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    },
    {id: 'is_active', label: 'Status', unsearchable: true}
  ];

  var itemColumns = [
    {id: 'item_master_code', label: 'Item Code'},
    {id: 'name', label: 'Name'},
    {id: 'is_pcm', label: 'Purchase Control Item', unsearchable: true},
    {id: 'item_category.code', label: 'Item Category Code'},
    {id: 'item_category.name', label: 'Item Category Name'},
    {id: 'expense_type_category', label: 'CAPEX/OPEX', unsearchable: true},
    {id: 'is_stock', label: 'Stock/Non-Stock', unsearchable: true},
    {id: 'uom_code', label: 'UOM'},
    {id: 'account_code', label: 'GL Account'},
    {id: 'least_purchase_info.amount', label: 'Lowest Purchase Price', unsearchable: true},
    {id: 'last_purchase_info.amount', label: 'Last Purchase Price', unsearchable: true},
    {id: 'is_active', label: 'Status', unsearchable: true}
  ];

  var catalogItemColumns = [
    {id: 'item_name', label: 'Item Code & Item Name'},
    {id: 'description', label: 'Description'},
    {id: 'commodity_code', label: 'Commodity Code'},
    {id: 'commodity_descr', label: 'Commodity Description'},
    {id: 'supplier|descr', label: 'Supplier'},
    {id: 'supplier_part_no', label: 'Supplier Part Number'},
    {id: 'tax.rate', label: 'Tax Rate', unsortable: true},
    {id: 'payment_term.descr', label: 'Payment Term'},
    {id: 'currency.code', label: 'Currency'},
    {
      id: 'unit_price',
      label: 'Unit Price',
      type: 'double',
      unsearchable: true
    },
    {id: 'Uom.code', label: 'UOM'},
    {
      id: 'effective_at',
      label: 'Effective Date',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'expiry_at',
      label: 'Expiry Date',
      type: 'date',
      unsearchable: true
    },
    {id: 'creator_info|display_name', label: 'Created By'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true},
    {id: 'updater_info|display_name', label: 'Updated By'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    }
  ];

  initialize();

  $scope.$emit('pageTitleChanged', 'Manage Catalog');

  $scope.$on('$stateChangeStart', function(event, toState, toParams){
    if(!!toParams.status){
      $scope.parentStateParams = toParams;
    }
  });
}
CatalogsCtrl.$inject = ['$scope', '$state', '$rootScope', 'globalFunc'];


angular.module('metabuyer')
  .controller('CatalogsCtrl', CatalogsCtrl);
