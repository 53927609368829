'use strict';
/**
 * @name MasterDataManageCurrencyCtrl
 * @desc Controller for master data currency controller
 */
function MasterDataManageCurrencyCtrl($scope, $uibModal, toastr, currenciesMasterDataList,
                                      dataList, $location,pathConstants, globalFunc, $stateParams, isDeveloper, $rootScope) {

  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.currenciesService = currenciesMasterDataList;
  $scope.isDeveloper = isDeveloper;

  $scope.search = search;
  $scope.newCurrencies = newCurrencies;
  $scope.currencyDetails = currencyDetails;
  $scope.showColumn = showColumn;
  $scope.importCurrencies = importCurrencies;
  $scope.getActiveStatusName = getActiveStatusName;
  $scope.allowOnlyOneSpace = allowOnlyOneSpace;
  $scope.importLink = pathConstants.apiUrls.currencies.import;
  $scope.params = {};
  $scope.columns = [
        {id: 'code', label: 'Code'},
        {id: 'descr', label: 'Description'},
        {id: 'updated_at', label: 'Updated At'},
        {id: 'updater.display_name', label: 'Updated By'},
        {id: 'created_at', label: 'Created At'},
        {id: 'creator.display_name', label: 'Created By'},
        {id: 'is_active', label: 'Status'}
      ];

  if (!!isDeveloper){
    $scope.columns.push({id: 'allow_decimal', label: 'Allow Decimal'})
  }

  $scope.decimalStatuses = [
    'Allowed',
    'Disallowed'
  ];
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();

  $scope.storageKey = 'master-data-currencies-management-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  /**
   * returns the is_active name
   * @param code
   * @returns {*}
   */
  function getActiveStatusName(code){
    /**
     DEACTIVATED = 0; 
     ACTIVE = 1; 
     DELETED = 2; 
     DISABLED = 3; 
     */
    if (code === 0 || code === 'DEACTIVATED') {
      return 'Deactivated'
    }
    if (code === 1 || code === 'ACTIVE') {
      return 'Active'
    }
    if (code === 2 || code === 'DELETED') {
      return 'Deleted'
    }
    if (code === 3 || code === 'DISABLED') {
      return 'Disabled'
    }
    return 'N/A';
  }


  function initialize(){

    /**
     * setting the model with the searched text
     */
    if(!!$stateParams.search){
      $scope.searchText = $stateParams.search;
    }
    else{
      $scope.searchText = '';
    }

    $scope.isOpen = false;
  }


  function search(query) {
    $location.search({query: query});
  }

  function importCurrencies() {
    var modalInstance = $uibModal.open({
      templateUrl: 'app/masterDataManagement/Import-Template.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return $scope.importLink;
        },
        title: function () {
          return 'Currencies';
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.importLink = importLink;
        $scope.title = title;
      }
    });
  }

  function newCurrencies() {
    if(!!$scope.currency){
      $scope.currency.is_active = 1;
      $scope.currency.allow_decimal = $scope.currency.allowDecimal === 'Allowed' ? true : false;
    }

    currenciesMasterDataList.post(
      {},
      $scope.currency,
      function(resource) {
        toastr.success('Currency was added successfully');
        if(!!resource.content && !!resource.content.data) {
          var addedCurrency = resource.content.data;
          $scope.dataList.unshift({
            _id: addedCurrency._id,
            code: addedCurrency.code,
            descr: addedCurrency.descr,
            created_at: addedCurrency.created_at,
            updated_at: addedCurrency.updated_at,
            updater: [$scope.user],
            creator: [$scope.user],
            allow_decimal: addedCurrency.allow_decimal,
            is_active: 1
          });
        }
        $scope.currency.code = '';
        $scope.currency.descr = '';
        delete $scope.currency.allowDecimal;
      },
      function(error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function currencyDetails(index) {
    var currencyId = $scope.dataList[index]._id;
    var modalInstance = $uibModal.open({
      templateUrl: 'app/masterDataManagement/currency/details/details.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'currencyDetailsCtrl',
      size: 'lg',
      resolve: {
        currencyDetails: function($q, currenciesMasterDataList) {
          var deferred = $q.defer();
          currenciesMasterDataList.get(
            {
              id: currencyId
            },
            function (resource) {
              deferred.resolve(resource.content || {data: []});
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        }
      }
    });

    modalInstance.result.then(function (newValue) {
      if(!!newValue){
        _.forEach($scope.dataList, function(dataList){
          if(dataList._id === newValue._id){
            dataList.code = newValue.code;
            dataList.descr = newValue.descr;
            dataList.is_active = newValue.is_active;
            dataList.updater[0].display_name = newValue.updated_by;
            dataList.updated_at = newValue.updated_at;
            dataList.allow_decimal =  newValue.allow_decimal;
          }
        });
      }
    }, function () {
    });
  }
  
  function allowOnlyOneSpace(code) {
    if (typeof code !== 'undefined') {
      $scope.currency.code = code.replace(/\s\s+/g, ' ');
    }
  }

  initialize();
}

MasterDataManageCurrencyCtrl.$inject = [
  '$scope', '$uibModal', 'toastr', 'currenciesMasterDataList', 'dataList',
  '$location', 'pathConstants', 'globalFunc', '$stateParams', 'isDeveloper', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('MasterDataManageCurrencyCtrl', MasterDataManageCurrencyCtrl);
