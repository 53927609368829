'use strict';

function tenantSettingsCtrl($scope, tenant, globalFunc, $state, toastr, tenantSettings, $auth,
                            tenantLogo, uploadTenantLogo, pathConstants, singleTenantById,
                            $filter, pdfPreview, $rootScope) {

  $scope.tenantSetting = {};
  $scope.submitSettings = submitSettings;
  $scope.saveTenantInfo = saveTenantInfo;
  $scope.cancelSubmit = cancelSubmit;
  $scope.isUploadImageSuccess = isUploadImageSuccess;
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();

  $scope.forexUpdateFreq = [
    {value: 1, descr: 'Daily'},
    {value: 7, descr: 'Weekly'},
    {value: 30, descr: 'Monthly'}
  ];

  $scope.supplierDraft = [
    {value: 1, descr: 'Enable'},
    {value: 0, descr: 'Disable'}
  ];

  $scope.supplierWeb = [
    {value: true, descr: 'Enable'},
    {value: false, descr: 'Disable'}
  ];

  $scope.supplierContactSync = [
    {value: true, descr: 'Yes'},
    {value: false, descr: 'No'}
  ];

  $scope.companySupplierStatus = [
    {value: 'ACTIVE', descr: 'Active'},
    {value: 'INACTIVE', descr: 'Deactivated'}
  ];

  // config flowjs
  $scope.flowConfig = new Flow({
    target: uploadTenantLogo.uploadImagePath,
    singleFile: true,
    testChunks: false,
    chunkSize: 10000000,
    headers: {
      'Authorization': 'Bearer ' + $auth.getToken()
    },
    query: {
      tenant_name: 'Sunway Group'
    }
  });

  function isUploadImageSuccess(file, message) {
    if (message) {
      var response = JSON.parse(message);
      toastr.success(response.content.message);
    }
  }

  function submitSettings() {
    var data = {};
    data.config = {};

    data.config.forex_update_freq = angular.copy($scope.tenantSetting.forex.value);
    data.config.tenant_setting_display = $scope.tenantSetting.displaySetting;
    tenantSettings.put(
      {}, data,
      function () {
        saveTenantInfo(false);
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function saveTenantInfo(printFlag) {
    var submitData = angular.copy($scope.tenantData);
    submitData.terms_and_conditions = $scope.tenantTermsAndConditions;
    submitData.supplier_draft = $scope.tenantData.supplier_draft.value;
    submitData.supplier_web = $scope.tenantSetting.supplier_web.value;
    submitData.supplier_web_user_can_update_profile = $scope.tenantSetting.supplier_web_user_can_update_profile.value;
    submitData.sync_supplier_contact_person = $scope.tenantSetting.sync_supplier_contact_person.value;
    submitData.show_mb_supplier_listing = $scope.tenantSetting.show_mb_supplier_listing.value;
    submitData.prefix_revision_budget = $scope.tenantData.config.prefix_revision_budget;
    submitData.default_company_supplier_status = $scope.tenantData.default_company_supplier_status.value;
    singleTenantById.put(
      {
        id: $scope.tenantData._id
      },
      submitData,
      function () {
        toastr.success('Settings successfully applied.');
        $rootScope.currentUser.tenant.supplier_draft = submitData.supplier_draft;
        $rootScope.currentUser.tenant.config.supplier_web = submitData.supplier_web;
        $rootScope.currentUser.tenant.config.supplier_web_user_can_update_profile =
          submitData.supplier_web_user_can_update_profile;
        $rootScope.currentUser.tenant.config.sync_supplier_contact_person = submitData.sync_supplier_contact_person;
        if (!!printFlag) {
          previewTenantPDF();
        }
        else {
          $state.go('main.user.settings.mainPanel');
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function previewTenantPDF() {
    pdfPreview.post(
      {
        id: $scope.tenantData._id
      },
      {
        terms_and_conditions_level: 'tenant'
      },
      function (resource) {
        var newUrl = $filter('format')(pathConstants.apiUrls.globalPDFHashDownload, {
          pdf_hash: resource.url
        });

        setTimeout(function () {
          window.open(newUrl, '_blank')
        }, 1001);
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function cancelSubmit() {
    $state.go('main.user.settings.mainPanel');
  }

  function initialize() {
    if (!!tenant && !!tenant.data && !!tenant.data.config) {
      var settings = tenant.data.config,
        forexSetting = settings.forex_update_freq;

      if (!_.isEmpty(tenant.data.config.tenant_setting_display)) {
        $scope.tenantSetting.displaySetting = tenant.data.config.tenant_setting_display;
      } else {
        $scope.tenantSetting.displaySetting = {
          'upload_tenant_logo' : true,
          'exchange_rate_update_frequency' : true,
          'global_supplier_list' : true,
          'supplier_draft' : true,
          'supplier_web' : true,
          'supplier_web_user_update' : true,
          'sync_supplier_contact_info' : true,
          'terms_and_condition' : true,
          'company_supplier_status': true
        };
      }

      $scope.tenantSetting.forex = globalFunc.findInArray($scope.forexUpdateFreq, 'value', forexSetting);
    }

    $scope.imageBasePath = pathConstants.apiUrls.image.user;

    singleTenantById.get(
      {
        id: $rootScope.currentUser.tenant_id
      },
      function (resource) {
        if (!!resource && !!resource.content && !!resource.content.data) {
          $scope.tenantData = resource.content.data;
          if (!!resource.content.data.terms_and_conditions)
            $scope.tenantTermsAndConditions = resource.content.data.terms_and_conditions;

          $scope.tenantData.supplier_draft = globalFunc.findInArray(
            $scope.supplierDraft,
            'value',
            $scope.tenantData.supplier_draft
          );

          $scope.tenantSetting.supplier_web = globalFunc.findInArray(
            $scope.supplierWeb,
            'value',
            $scope.tenantData.config.supplier_web
          );

          $scope.tenantSetting.show_mb_supplier_listing = globalFunc.findInArray(
            $scope.supplierWeb,
            'value',
            $scope.tenantData.config.show_mb_supplier_listing
          );

          $scope.tenantSetting.sync_supplier_contact_person = globalFunc.findInArray(
            $scope.supplierContactSync,
            'value',
            $scope.tenantData.config.sync_supplier_contact_person
          );

          $scope.tenantSetting.supplier_web_user_can_update_profile = globalFunc.findInArray(
            $scope.supplierContactSync,
            'value',
            $scope.tenantData.config.supplier_web_user_can_update_profile
          );

          $scope.tenantData.default_company_supplier_status = globalFunc.findInArray(
            $scope.companySupplierStatus,
            'value',
            $scope.tenantData.default_company_supplier_status
          );
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );

    tenantLogo.get(
      {
        tenant_name: 'Sunway Group'
      },
      function (resource) {
        $scope.tenantImgUrl = resource.url;
      }
    )

  }

  initialize();
}

tenantSettingsCtrl.$inject = ['$scope', 'tenant', 'globalFunc', '$state', 'toastr', 'tenantSettings', '$auth',
  'tenantLogo', 'uploadTenantLogo', 'pathConstants', 'singleTenantById', '$filter', 'pdfPreview', '$rootScope'];

angular
  .module('metabuyer')
  .controller('tenantSettingsCtrl', tenantSettingsCtrl);
