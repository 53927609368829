'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('main.user.bifrostProcessStatus', {
        url: '/bifrost-process-status',
        controller: 'bifrostProcessStatusCtrl',
        templateUrl: 'app/bifrostProcessStatus/index.html',
        resolve: {
          accessRights: function (profile, $state, globalFunc) {
            if (!(globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN') ||
            globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'System Support'))) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.bifrostProcessStatus.manage', {
        url: '/manage?&{query:[a-zA-Z0-9]+}&{page:[0-9]}&{order_by:[^~,]}&' +
        '{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        controller: 'bifrostProcessStatusManageCtrl',
        templateUrl: 'app/bifrostProcessStatus/manage/manage.html',
        params: { params: null},
        resolve: {
          $title: function () {
            return 'Bifrost Processing Status';
          },
          dataList: function ($q, $stateParams, bifrostProcessStatus) {
            var deferred = $q.defer();
            var params = {
              page: $stateParams.page,
              limit: 20,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'updated_at',
              order: !!$stateParams.order ? $stateParams.order : 0
            };

            if (!!$stateParams.params) {
              params['filter[file_name]'] = $stateParams.params.searchFileName;
              params['filter[erp_type]'] =
                !!$stateParams.params.searchErpType ? $stateParams.params.searchErpType.key : undefined;
              params['filter[module]'] =
                !!$stateParams.params.searchModule ? $stateParams.params.searchModule.key : undefined;
              params['filter[processing_status]'] =
                !!$stateParams.params.searchProcessingStatus ? $stateParams.params.searchProcessingStatus.key : undefined;
              params['filter[transfer_status]'] =
                !!$stateParams.params.searchTransferStatus ? $stateParams.params.searchTransferStatus.key : undefined;

              if($stateParams.params.skipInitialData){
                params['filter[skip_initial_data]'] = $stateParams.params.skipInitialData;
              }

            }

            bifrostProcessStatus.get(
              params,
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              });

            return deferred.promise;
          }
        }
      })
  }]);
