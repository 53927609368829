'use strict';
/***
 * @name companyProjectCtrl
 * @desc Controller to add and update Company Project
 */
function companyProjectCtrl(
  $scope, toastr, globalFunc, $uibModalInstance, companies, companyProject, companyDetails, $rootScope, pathConstants, $http, $filter
) {
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();
  $scope.isV2Enabled = $rootScope.isV2Enabled;
  $scope.editClicked = false;
  $scope.submitted = false;
  $scope.cancel = cancel;
  $scope.toggleTab = toggleTab;
  $scope.submitCompanyProject = submitCompanyProject;
  $scope.editCompanyProject = editCompanyProject;
  $scope.save = save;
  $scope.searchBudgets = searchBudgets;
  $scope.onSelectERPBudgetCode = onSelectERPBudgetCode;
  $scope.searchAssetCategories = searchAssetCategories;
  $scope.onSelectAssetCategory = onSelectAssetCategory;
  $scope.companyDetails = companyDetails;
  $scope.projectStatuses = [
    { descr: 'Active', value: true },
    { descr: 'Deactivated', value: false }
  ];
  $scope.companies = companies;

  $scope.budgetTypes = [
    { descr: 'Budgeted', value: true },
    { descr: 'Unbudgeted', value: false }
  ];

  $scope.erpBudgetCodeSearch = {
    search: ''
  };

  $scope.assetCategorySearch = {
    search: ''
  };
  $scope.tab = 1;
  /**
   * toggling which tab is active
   * @param tab
   * @returns {*}
   */
  function toggleTab(tab) {
    return $scope.tab = tab;
  }

  if ($scope.projectModifyMode === 'update') {
    $scope.historyDependencies = {
      embeddedParams: {
        'object[0][object_id]': $scope.singleProject._id,
        'object[0][object_type]': 'App\\Metabuyer\\Project\\Models\\Project',
        offset: 5,
        order_by: '-created_at'
      }
    };
  }

  function searchBudgets(query) {
    $scope.loading = true;
    var additionalParams = {
      'criteria[0][erp_budget_id]': query,
      'criteria[1][company|code]': $scope.companyDetails.code,
      'criteria[2][status]': 1,
      criteria_operator: 'and',
      offset: 100
    };

    var moduleParam = {
      module: 'budgets'
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams
    }).then(function (response) {
      $scope.loading = false;
      return response.data.content.data.map(function (item) {
        $scope.loading = false;
        return item;
      });
    }, function (error) {
      globalFunc.objectErrorMessage(error);
    });
  }

  function searchAssetCategories(query){
    var moduleParam = {
      module: 'asset-category'
    };

    var params = {
      'criteria[0][code]': query,
      'criteria[0][name]': query,
      'criteria[0][$operator]': 'or',
      'criteria[1][company|code]': $scope.companyDetails.code,
      'criteria[1][$strict]': true,
      'criteria[2][is_active]': true,
      criteria_operator: 'and',
      offset: 10
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
        params: params
      }).then(function (response) {
      if (response.data.content.data.length === 0) {
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          return item;
        });
      }
    });
  }

  function validateProject() {
    if (!$scope.singleProject) {
      globalFunc.objectErrorMessage('All fields are empty.');
      return false;
    }

    if (!$scope.singleProject.name) {
      globalFunc.objectErrorMessage('Please fill in project name.');
      return false;
    }

    if (!$scope.singleProject.code) {
      globalFunc.objectErrorMessage('Please fill in project code.');
      return false;
    }

    if (!$scope.singleProject.billingCompany) {
      globalFunc.objectErrorMessage('Please select a billing company.');
      return false;
    }

    if (!$scope.singleProject.isBudgeted) {
      globalFunc.objectErrorMessage('Please select a budget type.');
      return false;
    }

    if (!$scope.singleProject.projectStatus) {
      globalFunc.objectErrorMessage('Please select a status.');
      return false;
    }

    if (!$scope.singleProject.budgetId && $scope.singleProject.isBudgeted.value) {
      globalFunc.objectErrorMessage('Please select a budget');
      return false;
    }

    if (!$scope.singleProject.assetCategoryCode) {
      globalFunc.objectErrorMessage('Please select an asset category.');
      return false;
    }

    return true;
  }

  function submitCompanyProject() {
    if($scope.projectModifyMode === 'create'){
      addCompanyProject();
    } else {
      updateCompanyProject();
    }
  }

  function editCompanyProject() {
    $scope.editClicked = true;
  }

  /**
   * Create new Project
   */
  function addCompanyProject() {
    $scope.submitted = true;

    if (validateProject()) {
      var submit_data = {
        code: $scope.singleProject.code,
        name: $scope.singleProject.name,
        company_code: $scope.companyDetails.code,
        billing_company_code: $scope.singleProject.billingCompany['code'],
        budget_id: $scope.singleProject.budgetId,
        asset_category_code: $scope.singleProject.assetCategoryCode,
        is_budgeted: $scope.singleProject.isBudgeted.value,
        is_active: $scope.singleProject.projectStatus.value
      };

      //add the service here
      companyProject.post(
        submit_data,
        function (resource) {
          if (!!resource.content && !!resource.content.data){
            toastr.success('Project has been added successfully.');
            $uibModalInstance.close(resource.content.data);
          }
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
  }

  /**
   * Update the Project
   */
  function updateCompanyProject() {
    $scope.submitted = true;

    if (validateProject()) {
      var submit_data = {
        name: $scope.singleProject.name,
        billing_company_code: $scope.singleProject.billingCompany['code'],
        budget_id: $scope.singleProject.budgetId,
        asset_category_code: $scope.singleProject.assetCategoryCode,
        is_budgeted: $scope.singleProject.isBudgeted.value,
        is_active: $scope.singleProject.projectStatus.value
      };

      //add the service here
      companyProject.put(
        {
          uuid: $scope.singleProject.uuid
        },
        submit_data,
        function (resource) {
          if (!!resource.content && !!resource.content.data){
            toastr.success('Project has been updated successfully.');
            $uibModalInstance.close(resource.content.data);
          }
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
  }

  function save(){
    if ($scope.projectModifyMode === 'update'){
      updateCompanyProject();
    }
    else if ($scope.projectModifyMode === 'create'){
      addCompanyProject();
    }
  }

  function onSelectERPBudgetCode(budget) {
    $scope.singleProject.budgetId = budget._id;
  }

  function onSelectAssetCategory(assetCategory) {
    $scope.singleProject.assetCategoryCode = assetCategory.code;
  }

  function cancel() {
    $uibModalInstance.close();
  }

  function init(){

    if($scope.projectModifyMode === 'update'){
      $scope.singleProject.billingCompany = globalFunc.findInArray($scope.companies, 'code', $scope.singleProject.billing_company['code']);
      if(!$scope.singleProject.billingCompany) {
        $scope.singleProject.billingCompany  = $scope.companies[0];
      }

      $scope.singleProject.isBudgeted = globalFunc.findInArray($scope.budgetTypes, 'value', $scope.singleProject.is_budgeted);
      if(!$scope.singleProject.isBudgeted) {
        $scope.singleProject.isBudgeted  = $scope.budgetTypes[0];
      }

      $scope.singleProject.projectStatus = globalFunc.findInArray($scope.projectStatuses, 'value', $scope.singleProject.is_active);
      if(!$scope.singleProject.projectStatus) {
        $scope.singleProject.projectStatus  = $scope.projectStatuses[0];
      }

      if(!!$scope.singleProject.budget) {
        $scope.singleProject.budgetId = $scope.singleProject.budget._id;
        $scope.erpBudgetCodeSearch.search = $scope.singleProject.budget.erp_budget_id;
      }

      if(!!$scope.singleProject.asset_category) {
        $scope.singleProject.assetCategoryCode = $scope.singleProject.asset_category.code;
        $scope.assetCategorySearch.search = $scope.singleProject.asset_category.code + ' - ' + $scope.singleProject.asset_category.name;
      }
    }
  }
  init();

}

companyProjectCtrl.$inject = ['$scope', 'toastr', 'globalFunc', '$uibModalInstance', 'companies', 'companyProject', 'companyDetails', '$rootScope', 'pathConstants', '$http', '$filter'];

angular
  .module('metabuyer')
  .controller('companyProjectCtrl', companyProjectCtrl);
