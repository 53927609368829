'use strict';
/**
 * @name CompaniesCtrl
 * @desc Controller for companies
 */
function CompaniesCtrl($scope, $state, globalFunc, $rootScope) {
  $scope.$emit('pageTitleChanged', 'Manage Companies');
  var tempState = _.cloneDeep($state.params);


  /**
   * making sure the Stats and placeHolder changes if the Child scope changed
   */
  $scope.$on('$stateChangeStart', function (event, data, toParams) {
    if (!!data.name && data.name.indexOf('BusinessUnits') > -1) {
      $scope.columns = BUColumns;
      $scope.searchState = 'main.user.companiesMgmt.manageBusinessUnits';
      $scope.searchPlaceHolder = 'Search Business Units';
      $scope.parentState = 'main.user.companiesMgmt.manageBusinessUnits';
      $scope.parentStateParams = $state.params;
    }
    else if (!!data.name && data.name.indexOf('Industries') > -1) {
      $scope.columns = industryColumns;
      $scope.searchState = 'main.user.companiesMgmt.manageIndustries';
      $scope.searchPlaceHolder = 'Search Industries';
      $scope.parentState = 'main.user.companiesMgmt.manageIndustries';
      $scope.parentStateParams = $state.params;
    }
    else if (!!data.name && data.name.indexOf('Users') > -1) {
      $scope.columns = companyUsersColumns;
      $scope.searchState = 'main.user.companiesMgmt.companiesUsers';
      $scope.searchPlaceHolder = 'Search Company Users';
      $scope.parentState = 'main.user.companiesMgmt.companiesUsers';
      $scope.parentStateParams = $state.params;
    }
    else if (!!data.name && data.name.indexOf('Companies') > -1) {
      $scope.columns = companyColumns;
      $scope.searchState = 'main.user.companiesMgmt.manageCompanies';
      $scope.searchPlaceHolder = 'Search Companies';
      $scope.parentState = 'main.user.companiesMgmt.manageCompanies';
      $scope.parentStateParams = $state.params;
    }
    else if (!!data.name && data.name.indexOf('ItemTax') > -1) {
      $scope.columns = itemTaxColumns;
      $scope.searchState = 'main.user.companiesMgmt.companiesItemTax';
      $scope.searchPlaceHolder = 'Search Item Tax';
      $scope.parentState = 'main.user.companiesMgmt.companiesItemTax';
      $scope.parentStateParams = $state.params;
    }
    else if (!!data.name && data.name.indexOf('OpexIO') > -1) {
      $scope.columns = opexIoColumns;
      $scope.searchState = 'main.user.companiesMgmt.companyOpexIO';
      $scope.searchPlaceHolder = 'Search Company Opex IO';
      $scope.parentState = 'main.user.companiesMgmt.companyOpexIO';
      $scope.parentStateParams = $state.params;
    }
    else if (!!data.name && data.name.indexOf('Project') > -1) {
      $scope.columns = projectColumns;
      $scope.searchState = 'main.user.companiesMgmt.companiesProject';
      $scope.searchPlaceHolder = 'Search Company Project';
      $scope.parentState = 'main.user.companiesMgmt.companiesProject';
      $scope.parentStateParams = $state.params;
    }
    else if (!!data.name && data.name.indexOf('companyItems') > -1){
      $scope.columns = itemColumns;
      $scope.searchState = 'main.user.companiesMgmt.companyItems';
      $scope.searchPlaceHolder = 'Search Item';
      $scope.parentState = 'main.user.companiesMgmt.companyItems';
      $scope.parentStateParams = $state.params;
    }
    if (!!toParams.status) {
      $scope.parentStateParams = toParams;
    }
  });


  $scope.tabData = [
    {
      heading: 'Companies',
      route: 'main.user.companiesMgmt.manageCompanies',
      key: 'companies',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'companies',
        action: 'companies',
        cursor: null
      })
    },
    {
      heading: 'Business Units',
      route: 'main.user.companiesMgmt.manageBusinessUnits',
      key: 'businessunit',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'businessunit',
        action: 'businessunit',
        cursor: null
      })
    },
    {
      heading: 'Industries',
      route: 'main.user.companiesMgmt.manageIndustries',
      key: 'industries',
      params: globalFunc.filterUrlParams(tempState, {
        status: 'industries',
        action: 'industries',
        cursor: null
      })
    },
    {
      heading: 'Add',
      key: 'add',
      menu: [
        {
          heading: 'Add Company',
          route: 'main.user.companiesMgmt.addCompany',
          key: 'add'
        },
        {
          heading: 'Add Business Unit',
          route: 'main.user.companiesMgmt.addBU',
          key: 'add'
        },
        {
          heading: 'Add Industries',
          route: 'main.user.companiesMgmt.addIndustry',
          key: 'add'
        }
      ]
    }
  ];


  if (globalFunc.isSystemSupportRole()) {
      $scope.tabData = $scope.tabData.filter(function (tab) {
          return tab.key !== 'add';
      });
  }

  // Remove Add Company menu tab
  if (!!$rootScope.isOffline) {
    _.remove($scope.tabData, function (tab) {
      return tab.key === 'add'
    });
  }

  function initialize() {

    if (!!$state.current.name && $state.current.name.indexOf('BusinessUnits') > -1) {
      $scope.columns = BUColumns;
      $scope.searchState = 'main.user.companiesMgmt.manageBusinessUnits';
      $scope.searchPlaceHolder = 'Search Business Units';
      $scope.parentState = 'main.user.companiesMgmt.manageBusinessUnits';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[1];
    } else if (!!$state.current.name && $state.current.name.indexOf('Industries') > -1) {
      $scope.columns = industryColumns;
      $scope.searchState = 'main.user.companiesMgmt.manageIndustries';
      $scope.searchPlaceHolder = 'Search Industries';
      $scope.parentState = 'main.user.companiesMgmt.manageIndustries';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[2];
    }
    else if (!!$state.current.name && $state.current.name.indexOf('companiesUsers') > -1) {
      $scope.columns = companyUsersColumns;
      $scope.searchState = 'main.user.companiesMgmt.companiesUsers';
      $scope.searchPlaceHolder = 'Search Company Users';
      $scope.parentState = 'main.user.companiesMgmt.companiesUsers';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[0];
    }
    else if (!!$state.current.name && $state.current.name.indexOf('add') > -1) {
      $scope.columns = companyColumns;
      $scope.searchState = 'main.user.companiesMgmt.manageCompanies';
      $scope.searchPlaceHolder = 'Search Companies';
      $scope.parentState = 'main.user.companiesMgmt.manageCompanies';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[3];
    }
    else if (!!$state.current.name && ($state.current.name.indexOf('Companies') > -1 ||
      $state.current.name.indexOf('companiesAccountCode') > -1)){
      $scope.columns = companyColumns;
      $scope.searchState = 'main.user.companiesMgmt.manageCompanies';
      $scope.searchPlaceHolder = 'Search Companies';
      $scope.parentState = 'main.user.companiesMgmt.manageCompanies';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[0];
    }
    else if (!!$state.current.name && $state.current.name.indexOf('ItemTax') > -1) {
      $scope.columns = itemTaxColumns;
      $scope.searchState = 'main.user.companiesMgmt.companiesItemTax';
      $scope.searchPlaceHolder = 'Search Item Tax';
      $scope.parentState = 'main.user.companiesMgmt.companiesItemTax';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[0];
    }
    else if (!!$state.current.name && $state.current.name.indexOf('OpexIO') > -1) {
      $scope.columns = opexIoColumns;
      $scope.searchState = 'main.user.companiesMgmt.companyOpexIO';
      $scope.searchPlaceHolder = 'Search Company Opex IO';
      $scope.parentState = 'main.user.companiesMgmt.companyOpexIO';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[0];
    }
    else if (!!$state.current.name && $state.current.name.indexOf('Project') > -1) {
      $scope.columns = projectColumns;
      $scope.searchState = 'main.user.companiesMgmt.companiesProject';
      $scope.searchPlaceHolder = 'Search Company Project';
      $scope.parentState = 'main.user.companiesMgmt.companiesProject';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[0];
    }
    else if (!!$state.current.name && $state.current.name.indexOf('companyItems') > -1){
      $scope.columns = itemColumns;
      $scope.searchState = 'main.user.companiesMgmt.companyItems';
      $scope.searchPlaceHolder = 'Search Item';
      $scope.parentState = 'main.user.companiesMgmt.companyItems';
      $scope.parentStateParams = $state.params;
      $scope.activatedTab = $scope.tabData[3];
    }

    if (!!$state.params && !!$state.params.query) {
      $scope.searchText = $state.params.query;
    }
    else {
      $scope.searchText = '';
    }
    if (!!$state.params && !!$state.params.status) {
      $scope.status = $state.params.status
    }
    if (!!$state.params && !!$state.params.filter) {
      $scope.searchFilter = $state.params.filter;
    }

  }

  var itemColumns = [
    {id: 'item_master_code', label: 'Item Code'},
    {id: 'name', label: 'Name'},
    {id: 'is_pcm', label: 'Purchase Control Item', unsearchable: true},
    {id: 'item_category|code', label: 'Item Category Code'},
    {id: 'item_category|name', label: 'Item Category Name'},
    {id: 'expense_type_category', label: 'CAPEX/OPEX', unsearchable: true},
    {id: 'is_stock', label: 'Stock/Non-Stock', unsearchable: true},
    {id: 'uom_code', label: 'UOM'},
    {id: 'account_code', label: 'GL Account'},
    {id: 'least_purchase_info.original_unit_price', label: 'Lowest Purchase Price', unsearchable: true},
    {id: 'last_purchase_info.original_unit_price', label: 'Last Purchase Price', unsearchable: true},
    {id: 'is_active', label: 'Status', unsearchable: true}
  ];

  var itemTaxColumns = [
    {id: 'internal_part_no', label: 'Internal Part No'},
    {id: 'tax_code', label: 'Tax Code'},
    {id: 'cost_center_code', label: 'Cost Center Code'},
    {id: 'creator.display_name', label: 'Created By'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updater.display_name', label: 'Updated By'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    }
  ];

  var projectColumns= [
    {id: 'code', label: 'PROJECT CODE'},
    {id: 'name', label: 'PROJECT NAME'},
    {id: 'billing_company|descr', label: 'BILLING COMPANY'},
    {id: 'is_budgeted', label: 'BUDGET TYPE'},
    {id: 'created_at', label: 'CREATED AT'},
    {id: 'created_by', label: 'CREATED BY'},
    {id: 'updated_at', label: 'UPDATED AT'},
    {id: 'updated_by', label: 'UPDATED BY'},
    {id: 'is_active', label: 'STATUS'}
  ];

  var opexIoColumns= [
    {id: 'opex_io_code', label: 'OPEX IO CODE'},
    {id: 'name', label: 'OPEX IO NAME'},
    {id: 'created_at', label: 'CREATED AT'},
    {id: 'created_by', label: 'CREATED BY'},
    {id: 'updated_at', label: 'UPDATED AT'},
    {id: 'updated_by', label: 'UPDATED BY'},
    {id: 'is_active', label: 'STATUS'}
  ];

  var companyColumns = [
    {id: 'descr', label: 'Company'},
    {id: 'code', label: 'Code'},
    {id: 'reg_no', label: 'Registration Code'},
    {id: 'currency_code', label: 'Preferred Currency'},
    {id: 'business_unit.descr', label: 'Business Unit'},
    {id: 'industry.descr', label: 'Industry'},
    {id: 'creator.display_name', label: 'Created By'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updater.display_name', label: 'Updated By'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    }
  ];

  var BUColumns = [
    {id: 'descr', label: 'Business Unit'},
    {id: 'code', label: 'Code'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'is_active',
      label: 'Status',
      unsearchable: true
    }
  ];

  var industryColumns = [
    {id: 'descr', label: 'Industry'},
    {id: 'code', label: 'Industry Code'},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    }
  ];

  var companyUsersColumns = [
    {id: 'display_name', label: 'Full Name'},
    {id: 'email', label: 'Email'},
    {id: 'manager', label: 'Superior', unsortable: true},
    {
      id: 'created_at',
      label: 'Created At',
      type: 'date',
      unsearchable: true
    },
    {id: 'creator.display_name', label: 'Created By'},
    {
      id: 'updated_at',
      label: 'Updated At',
      type: 'date',
      unsearchable: true
    },
    {id: 'updater.display_name', label: 'Updated By'},
    {id: 'status', label: 'Status'}
  ];


  initialize();

}
CompaniesCtrl.$inject = ['$scope', '$state', 'globalFunc', '$rootScope'];

angular
  .module('metabuyer')
  .controller('CompaniesCtrl', CompaniesCtrl);
