'use strict';

/**
 * @name exchangeRateDetailsCtrl
 * @desc Controller for master data exchange rate details controller
 */
function exchangeRateDetailsCtrl($scope, $uibModalInstance, exchangeRateDetails,
                                 exchangeRateSpecificRate, toastr, globalFunc, currencyExchange, $rootScope) {
  $scope.exchangeRateDetails = exchangeRateDetails.data;
  $scope.currencyExchange = currencyExchange;
  $scope.editClicked = false;
  $scope.tab = 1;

  $scope.historyDependencies = {
    embeddedParams: {
      'object[0][object_id]': $scope.exchangeRateDetails._id,
      'object[0][object_type]': 'Metabuyer\\Models\\ExchangeRate',
      offset: 5,
      order_by: '-created_at' // Created at, descending
    }
  };

  $scope.toggleTab          = toggleTab;
  $scope.cancel             = cancel;
  $scope.editExchangeRate   = editExchangeRate;
  $scope.updateExchangeRate = updateExchangeRate;
  $scope.openDatepicker     = openDatepicker;
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();

  function openDatepicker($event, which) {
    // Setting minimum selection for start date to be today
    $scope.startDateOptions.minDate = new Date();

    // Setting the minimum selection for end date to be one day ahead of selected start date
    var startDateClone = _.cloneDeep($scope.exchangeRateDetails.effective_at);
    var startDate = new Date(startDateClone);
    startDate.setDate(startDate.getDate() + 1);
    $scope.endDateOptions.minDate = startDate;

    $event.preventDefault();
    $event.stopPropagation();

    $scope.datepickerOpened[which] = true;
  }

  function cancel() {
    $uibModalInstance.close();
  }

  function toggleTab(tab) {
    return $scope.tab = tab;
  }

  function editExchangeRate() {
    $scope.editClicked = true;
  }

  function validate(exchangeRate) {
    if (!exchangeRate) {
      return false;
    }
    if (!exchangeRate.code) {
      return false;
    }
    if (!exchangeRate.descr) {
      return false;
    }
    if (!exchangeRate.rate || Number(exchangeRate.rate) === 0) {
      return false;
    }
    if (!exchangeRate.effective_at) {
      return false;
    }
    if (!exchangeRate.expiry_at) {
      return false;
    }
    if (exchangeRate.effective_at >= exchangeRate.expiry_at) {
      return false;
    }
    if (!exchangeRate.exchangeStatus) {
      return false;
    }
    return true;
  }

  function validateEditedRate(rate) {
    return Number(rate) === 0;
  }

  function updateExchangeRate() {
    $scope.submitted = true;
    if (validate($scope.exchangeRateDetails)) {
      swal({
          title: 'Confirm Updating the Exchange Rate',
          text: 'This will update the details for this exchange rate',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#1ab394',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true,
          closeOnCancel: true
        },
        function (confirmed) {
          if (!!confirmed) {

            // set status in integer to pass it as parameter
            var tempStatus;
            switch ($scope.exchangeRateDetails.exchangeStatus.isActive.toLowerCase()) {
              case 'active':
                tempStatus = 1;
                break;
              case 'deactivated':
                tempStatus = 0;
                break;
              case 'disabled':
                tempStatus = 3;
                break;
            }

            //Formatting the dates before update
            $scope.exchangeRateDetails.effective_at = globalFunc.convertDateToTimestamp($scope.exchangeRateDetails.effective_at);
            $scope.exchangeRateDetails.expiry_at = globalFunc.convertDateToTimestamp($scope.exchangeRateDetails.expiry_at);

            exchangeRateSpecificRate.put(
              {
                id: ($scope.exchangeRateDetails.company_id === '') ? 'global' : $scope.exchangeRateDetails.company_id,
                rate_id: $scope.exchangeRateDetails._id
              },
              {
                code: $scope.exchangeRateDetails.code,
                descr: $scope.exchangeRateDetails.descr,
                multiplier_rate: $scope.exchangeRateDetails.rate,
                source_currency_id: $scope.exchangeRateDetails.source_currency_id,
                target_currency_id: $scope.exchangeRateDetails.target_currency_id,
                effective_at: $scope.exchangeRateDetails.effective_at,
                expiry_at: $scope.exchangeRateDetails.expiry_at,
                is_active: tempStatus
              },
              function (resource) {
                toastr.success('Exchange rate has been updated');
                $uibModalInstance.close(resource.content.data);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                return false;
              }
            );
          } else {
            return false;
          }
        }
      );
    }
    else {
      toastr.error('Please fill up all the required fields first');
    }
  }

  function initialize() {
    // Create new variable to replace exchangeRateDetails.status
    // with object to load and selected the right status
    // on the dropdown list
    if (!!$scope.exchangeRateDetails.is_active) {
      $scope.exchangeRateDetails.exchangeStatus = {isActive: globalFunc.capitalizeFirstCharacter($scope.exchangeRateDetails.is_active)};
    }
    // Statuses for dropdown in object
    $scope.exchangeRateStatuses = [
      {isActive: 'Active'},
      {isActive: 'Deactivated'}
    ];

    $scope.startDateOptions = {
      formatYear: 'yy',
      startingDay: 1
    };

    $scope.endDateOptions = {
      formatYear: 'yy',
      startingDay: 1
    };

    $scope.datepickerOpened = {
      start_date: false,
      end_date: false
    };

    $scope.validateEditedRate = validateEditedRate;
  }

  initialize();
}

exchangeRateDetailsCtrl.$inject = [
  '$scope', '$uibModalInstance', 'exchangeRateDetails', 'exchangeRateSpecificRate', 'toastr', 'globalFunc', 'currencyExchange', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('exchangeRateDetailsCtrl', exchangeRateDetailsCtrl);
