'use strict';
/**
 * @name uomDetailsCtrl
 * @desc Controller for master data UOM details controller
 */
function uomDetailsCtrl($scope, $uibModalInstance, uomDetails, singleUom, toastr, globalFunc, $rootScope, $uibModal, dataList) {
  $scope.isV2Enabled = !!$rootScope.isV2Enabled;
  $scope.editClicked = false;
  $scope.uomDetails = uomDetails.data;
  $scope.originalCode = $scope.uomDetails.code;
  $scope.openTranslationModal = openTranslationModal;

  var translationModalInstance;

  // Statuses for dropdown in object
  $scope.uomStatuses = [
    { isActive: 'Active' },
    { isActive: 'Deactivated' }
  ];

  $scope.uomFraction = [
    {
      status: 'Allow',
      value: 1
    },
    {
      status: 'Disallow',
      value: 0
    }
  ];

  $scope.tab = 1;
  $scope.historyDependencies = {
    embeddedParams: {
      'object[0][object_id]': $scope.uomDetails._id,
      'object[0][object_type]': 'Metabuyer\\Models\\UOM',
      offset: 5,
      order_by: '-created_at' // Created at, descending
    }
  };

  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();

  $scope.toggleTab        = toggleTab;
  $scope.cancel           = cancel;
  $scope.editUOM          = editUOM;
  $scope.updateUom        = updateUom;
  $scope.duplicationCheck = duplicationCheck;

  function toggleTab(tab) {
    return $scope.tab = tab;
  }

  function setFraction(uom){
    if(uom.is_fraction ===1){
      uom.is_fraction = {
        status: 'Allow',
        value: 1
      };
    }else{
      uom.is_fraction = {
        status: 'Disallow',
        value: 0
      };
    }
  }


  function cancel() {
    $uibModalInstance.close();
  }

  function editUOM(){
    $scope.editClicked = true;
  }

  function validate(uom){
    if (!uom){
      return false;
    }
    if (!uom.code){
      return false;
    }
    if (!uom.descr){
      return false;
    }
    if (!uom.uomStatus){
      return false;
    }
    if (!uom.is_fraction){
      return false;
    }
    return true;
  }

  function updateUom(isTranslationModal) {
    if(!!$scope.codeCheck){
      toastr.error($scope.uomDetails.code + ' is already in use');
    }
    else if(validate($scope.uomDetails)){
      swal({
          title: 'Confirm Updating the UOM',
          text: 'This will update the details for this UOM',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#1ab394  ',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          closeOnConfirm: true,
          closeOnCancel: true
        },
        function (confirmed) {
          if (!!confirmed) {
            // set status in integer to pass it as parameter
            var tempStatus;
            switch($scope.uomDetails.uomStatus.isActive.toLowerCase()){
              case 'active':
                tempStatus = 1;
                break;
              case 'deactivated':
                tempStatus = 0;
                break;
              case 'disabled':
                tempStatus = 3;
                break;
            }

            $scope.uomDetails.is_active = $scope.uomDetails.uomStatus.isActive.toUpperCase();
            $scope.uomDetails.updated_at = new Date();

            singleUom.put(
              {
                id: $scope.uomDetails._id
              },
              {
                code: $scope.uomDetails.code,
                descr: $scope.uomDetails.descr,
                is_active: tempStatus,
                is_fraction: !!$scope.uomDetails.is_fraction ? $scope.uomDetails.is_fraction.value : 0,
                descr_chinese: $scope.uomDetails.descr_chinese || null,
                isTranslationUpdate: isTranslationModal,
              },
              function (resource) {
                toastr.success('UOM has been updated');

                if (!!isTranslationModal) {
                  translationModalInstance.close(resource.content.data);
                } else {
                  $uibModalInstance.close(resource.content.data);
                }
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                return false;
              }
            );
          } else {
            return false;
          }
        }
      );
    }
  }

  function duplicationCheck(model,field,value,checker){
    globalFunc.duplicationCheck(model,field,value,checker, function(callback){
      switch (callback){
        case 'codeCheckTrue':
          if($scope.uomDetails.code != $scope.originalCode){
            $scope.codeCheck = true;
          }
          else{
            $scope.codeCheck = false;
          }
          break;
        case 'codeCheckFalse':
          $scope.codeCheck = false;
          break;
      }
    });
  }

  function openTranslationModal() {
    translationModalInstance = $uibModal.open({
        templateUrl: 'app/masterDataManagement/translationModal/translationModal.html',
        backdrop: 'static',
        controller: 'translationModalCtrl',
        keyboard: false,
        scope: $scope,
        resolve: {
            translationModalData: function () {
                return $scope.uomDetails
            },
            translationModalTitle: function () {
                return 'UOM';
            }
        }
    });

    translationModalInstance.result.then(function (newValue) {
      if(!!newValue){
        _.forEach(dataList, function(data){
          if(data._id === newValue._id){
            console.log(data);
            data.code = newValue.code;
            data.descr = newValue.descr;
            data.descr_chinese = newValue.descr_chinese || null;
            data.is_fraction = newValue.is_fraction;
            data.is_active = newValue.is_active;
            data.updater_info.display_name = newValue.updater_info.display_name;
            data.updated_at = newValue.updated_at;
          }
        });
      }
    }, function () {
    });
  }

  function initialize() {
    if(!!$scope.uomDetails.is_active) {
      $scope.uomDetails.uomStatus = {isActive: globalFunc.capitalizeFirstCharacter($scope.uomDetails.is_active)};
    }
    setFraction($scope.uomDetails);
  }

  $scope.$on('updateTranslation', function() {
    updateUom(true);
  })

  initialize();
}

uomDetailsCtrl.$inject = [
  '$scope', '$uibModalInstance', 'uomDetails', 'singleUom', 'toastr', 'globalFunc', '$rootScope', '$uibModal', 'dataList'
];

angular
  .module('metabuyer')
  .controller('uomDetailsCtrl', uomDetailsCtrl);
