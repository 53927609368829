'use strict';

function bifrostScheduleCtrl($scope, dataList, $http, pathConstants, toastr, $uibModal, globalFunc) {

  $scope.dataList = dataList.data;

  $scope.editSchedule = editSchedule;
  $scope.statusToggle = statusToggle;
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();

  function editSchedule(data, action) {
    $uibModal.open({
      templateUrl: 'app/settings/bifrostSettings/schedule/editScheduleModal.html',
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      resolve:{
        action: function () {
          return action;
        },
        data: function(){
          return data;
        }
      },
      controller: function ($scope, $uibModalInstance, $http, pathConstants, toastr, $state) {
        $scope.actionTitle = action === 'create' ? 'Create' : 'Update';
        if (!!data) {
          $scope.id = data.id;
          $scope.title = data.title;
          $scope.descr = data.descr;
          $scope.artisan_command = data.artisan_command;
          $scope.cron = data.cron;
          $scope.status = data.status;
          $scope.created_at = data.created_at
        }

        $scope.submit = submit;
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        function submit(){
          $scope.submitted = true;

          if (!$scope.title || !$scope.descr || !$scope.artisan_command || !$scope.cron ) {
            toastr.error('Please fill up all fields.');
            return;
          }

          swal({
            title: 'Confirm ' + action + ' Integration schedule?',
            text: 'This action is not revertable.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Confirm',
            closeOnConfirm: true
          }, function (isConfirm) {
            if (isConfirm) {
              confirmSubmit();
            }
          });
        }

        function confirmSubmit() {
          var path = pathConstants.apiUrls.bifrost.schedule;
          if (action === 'create') {
            $http.post(
              path,
              {
                id: $scope.id ,
                title: $scope.title ,
                descr: $scope.descr ,
                artisan_command: $scope.artisan_command ,
                cron: $scope.cron,
                status: ($scope.status? 1 : 0),
              }
            ).then(function onSuccess(response){
              toastr.success("Created Integration Schedule.");
              $uibModalInstance.close();
              $state.reload();
              close();
            }).catch(function onError (response){
              var error = "Failed to create Integration Schedule.";
              if (!!response.data) {
                error = response.data;
              }
              toastr.error(error);
            });
          } else {
            $http.put(
              path,
              {
                id: $scope.id ,
                title: $scope.title ,
                descr: $scope.descr ,
                artisan_command: $scope.artisan_command ,
                cron: $scope.cron,
                status: ($scope.status? 1 : 0),
              }
            ).then(function onSuccess(response){
              toastr.success("Updated Integration Schedule.");
              $uibModalInstance.close();
              $state.reload();
              close();
            }).catch(function onError (response){
              var error = "Failed to update Integration Schedule.";
              if (!!response.data) {
                error = response.data;
              }
              toastr.error(error);
            });
          }
        }
      }
    });
  }

  function statusToggle(index) {
    if (!(index in $scope.dataList)) {
      toastr.error("Something went wrong. Please try again");
      return;
    }

    var activeRow =  $scope.dataList[index];
    var newStatus = activeRow.status;
    var statusLabel = newStatus? 'ON':'OFF';

    var path = pathConstants.apiUrls.bifrost.schedule;

    $http.put(
      path,
      {
        id: activeRow.id,
        status: (newStatus? 1 : 0),
      }
    ).then(function onSuccess(response){
      toastr.success(activeRow.title + " Integration schedule is now " + statusLabel);
      activeRow.updated_at = response.data.updated_at;
    }).catch(function onError (){
      toastr.error("Failed to update Integration Schedule status.");
    });
  }

  function initialize() {
    $scope.dataList.forEach(function (data) {
      data.status = !!data.status;
    })
  }

  initialize();
}

bifrostScheduleCtrl.$inject = ['$scope', 'dataList', '$http', 'pathConstants', 'toastr', '$uibModal', 'globalFunc'];

angular
    .module('metabuyer')
    .controller('bifrostScheduleCtrl', bifrostScheduleCtrl);
