'use strict';
/**
 * @name MasterDataManageItemCategoriesCtrl
 * @desc Controller for master data item categories controller
 */
function MasterDataManageItemCategoriesCtrl($scope, dataList, itemCategoryMasterDataList, toastr, $uibModal, $location,
                                            pathConstants, globalFunc, $stateParams, $rootScope, UserPermissions) {
  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.itemCategoryMasterDataList = itemCategoryMasterDataList;
  $scope.addNewItemCategory = addNewItemCategory;
  $scope.openItemCategoryDetails = openItemCategoryDetails;
  $scope.search = search;
  $scope.showColumn = showColumn;
  $scope.importItemCategory = importItemCategory;
  $scope.params = {};
  $scope.itemCategory = {};
  $scope.convertBoolean = convertBoolean;
  $scope.getActiveStatusName = getActiveStatusName;
  $scope.showAddUi = false;
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();

  $scope.columns = [
    {id: 'code', label: 'Code'},
    {id: 'name', label: 'Name'},
    {id: 'descr', label: 'Description'},
    {id: 'is_active', label: 'Status'},
    {id: 'updated_at', label: 'Updated At'},
    {id: 'updater_info.display_name', label: 'Updated By'},
    {id: 'created_at', label: 'Created At'},
    {id: 'creator_info.display_name', label: 'Created By'}
  ];

  $scope.storageKey = 'master-data-item-categories-management-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function importItemCategory() {
    $uibModal.open({
      templateUrl: 'app/masterDataManagement/Import-Template.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return pathConstants.apiUrls.imports.now;;
        },
        params: function () {
          return {
            'class': 'App\\Metabuyer\\ItemCategory\\Models\\ItemCategory',
          }
        },

        title: function () {
          return 'Item Category';
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title, params) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.importLink = importLink;
        $scope.title = title;
        $scope.params = params;
      }
    });
  }

  function addNewItemCategory() {
    $scope.submitted = true;
    if(!validate($scope.itemCategory)) {
      return;
    }

    itemCategoryMasterDataList.post(
      {
        code: $scope.itemCategory.code,
        name: $scope.itemCategory.name,
        descr: $scope.itemCategory.descr,
      },
      function (resource) {
        toastr.success('A new Item Category has been added successfully');
        var addedItemCategory = resource.content.data;
        $scope.dataList.unshift({
          _id: addedItemCategory._id,
          code: addedItemCategory.code,
          name: addedItemCategory.name,
          descr: addedItemCategory.descr,
          created_at: addedItemCategory.created_at,
          updated_at: addedItemCategory.updated_at,
          creator_info: addedItemCategory.creator_info,
          updater_info: addedItemCategory.updater_info
        });
        $scope.submitted = false;
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function search(query) {
    $location.search({query: query});
  }

  function validate(itemCategory) {
    if (!itemCategory) {
      return false;
    }
    if (!itemCategory.code) {
      return false;
    }
    if (!itemCategory.name) {
      return false;
    }

    return true;
  }

  function openItemCategoryDetails(index) {
    var itemCategoryUuid = $scope.dataList[index].uuid;
    var modalInstance = $uibModal.open({
      templateUrl: 'app/masterDataManagement/itemCategories/details/details.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'itemCategoryDetailsCtrl',
      size: 'lg',
      resolve: {
        itemCategoryDetails: function($q, itemCategoryMasterDataList) {
          var deferred = $q.defer();
          itemCategoryMasterDataList.get(
            {
              uuid: itemCategoryUuid
            },
            function (resource) {
              deferred.resolve(resource.content || {data: []});
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        }
      }
    });

    modalInstance.result.then(
      function (newValue) {
        if (!!newValue) {
          _.forEach($scope.dataList, function (dataList) {
            if (dataList._id === newValue._id) {
              dataList.code = newValue.code;
              dataList.descr = newValue.descr;
              dataList.is_active = newValue.is_active;
              dataList.updater[0].display_name = newValue.updated_by;
              dataList.updated_at = newValue.updated_at;
            }
          });
        }
      }, function () {}
    );
  }

  /**
   * To show or hide the add item category UI
   * for GenP, they are only allowed to add via import
   * so for now this UI is hidden behind the DEVELOPER role
   *
   * @returns {boolean}
   */
  function canAddItemCategory() {
    return UserPermissions.isDeveloper($rootScope.currentUser);
  }

  function convertBoolean(value) {
    return value === true ? 'Yes' : value === false ? 'No' : value;
  }

  function getActiveStatusName(value) {
    return !value ? 'Deactivated' : 'Active';
  }

  function initialize(){
    /**
     * setting the model with the searched text
     */
    if(!!$stateParams.search){
      $scope.searchText = $stateParams.search;
    }
    else{
      $scope.searchText = '';
    }

    if (canAddItemCategory()) {
      $scope.showAddUi = true;
    }
  }

  initialize();
}

MasterDataManageItemCategoriesCtrl.$inject = [
  '$scope', 'dataList', 'itemCategoryMasterDataList', 'toastr', '$uibModal',
  '$location', 'pathConstants', 'globalFunc', '$stateParams', '$rootScope', 'UserPermissions'
];

angular
  .module('metabuyer')
  .controller('MasterDataManageItemCategoriesCtrl', MasterDataManageItemCategoriesCtrl);
