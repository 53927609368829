'use strict';

/**
 * @name approval-flow.directive
 *
 * @requires $element
 * @requires attrs
 *
 * @description Directive to display the approval flow.
 *
 * @function restructuringData
 * This function is to restructure the data passed for display purposes on the desktop version
 *
 * @function restructuringDataMobile
 * This function is to restructure the data passed for display purposes on the mobile version
 *
 * @function tooltipApproval
 * This function is to display tooltip based on step status
 *
 * @function handleChart
 * This function is to handle the drawing and connections for the approval flow (jsPlumb)
 *
 * @authors Justin Cheong Tian Yee (justin.cty90@gmail.com), Deniel Ariesta (deniel@vventures.asia)
 * @copyright Sunway Metacloud &copy; 2016
 */

angular
  .module('metabuyer')
  .directive('approvalFlow', function (
    $timeout, $rootScope, $uibModal, globalFunc, $sce, $filter, removeAdhocApprover, toastr, $state, UserPermissions, $translatePartialLoader
  ) {
    return {
      restrict: 'EA',
      scope: {
        data: '=',
        requestor: '=',
        preparer: '=',
        context: '=',
        preview: '&',
        ischanged: '=',
        waitingon: '=',
        ispc: '='
      },
      replace: true,
      templateUrl: function () {
        return $rootScope.isMobileMode ? 'components/approval-flow/approval-flow-mobile.html' :
          'components/approval-flow/approval-flow.html';
      },
      controller: function () {
        $translatePartialLoader.addPart('../components/approval-flow/lang');
      },
      link: function ($scope, $element, $attrs) {
        // this watch is for editing PR, when the context of approval flow changed
        // etc: expense, edited by...
        $scope.$watch('context', function(newData){
          if (!!newData && !!newData.status) {
            $scope.contextStatus = newData.status.toLowerCase();
          }
        });
        //#region Variables declaration

        // JsPlumb Instance
        // Using StateMachine as the graph
        // Arrow as the Connection Overlays
        var instance = null;
        var isAddingAdHoc = false;

        function initiateJSPlumbInstance() {
          instance = jsPlumb.getInstance({
            connector: 'StateMachine',
            DragOptions: {
              cursor: 'pointer',
              zIndex: 2000
            },
            ConnectionOverlays: [
              ['Arrow', {
                location: 1,
                width: 8,
                length: 10
              }]
            ],
            Container: 'approval-flow'
          });
        }

        initiateJSPlumbInstance();

        if (!$attrs.type) {
          $scope.type = 'horizontal';
        }

        if (!!$scope.context && !!$scope.context.status) {
          $scope.contextStatus = $scope.context.status.toLowerCase();
        }

        $scope.groups = [];
        $scope.drawGroups = [];
        $scope.firstStepMin = 0;
        $scope.isChangedField = true;
        $scope.firstLoad = true;
        $scope.transformedData = [];
        $scope.isRequestor = false;
        $scope.currentUser = $rootScope.currentUser;
        $scope.positionIndex = 0;
        $scope.showDebugInfo = false;

        $scope.approvalPreview = approvalPreview;
        $scope.tooltipApproval = tooltipApproval;
        $scope.addNewApprover = addNewApprover;
        $scope.deleteAdhoc = deleteAdhoc;
        $scope.checkActionLogUser = checkActionLogUser;
        $scope.toggleShowDebugInfo = toggleShowDebugInfo;
        $scope.showToggleDebugButton = showToggleDebugButton;
        //#endregion

        /**
         * Function for displaying tooltip
         * @param position
         * @param stepStatus
         * @returns {*}
         */
        function tooltipApproval(position, stepStatus) {
          if (!_.isEmpty(position.action) && !!position.action.date && !!position.action.status) {
            return $sce.trustAsHtml(position.name + '<p>' +
              (position.action.status.toLowerCase() === 'approved' ? 'Approved at: ' : 'Rejected at: ') +
              $filter('date')(position.action.date, 'dd MMM yyyy hh:mm a') + '</p>');
          }

          return (stepStatus.toLowerCase() !== 'pending' ? stepStatus : '');
        }

        //#region Restructuring the passed data
        /**
         * To restructure the data for display purposes
         */
        function restructuringData() {

          if (!!$scope.data && !!$scope.data.requested_by && (!!$scope.data.created_by ||
            !!$scope.data.prepared_by)) {
            // Add requestedBy and createdBy to approval flow
            var requestedBy = $scope.data.requested_by;
            var createdBy = $scope.data.created_by;
            var preparedBy = $scope.data.prepared_by;
            var editedBy = $scope.data.edited_by;

            if (!!requestedBy._id) {
              $scope.isRequestor = (requestedBy._id === $rootScope.currentUser._id);
            }

            if (!!createdBy._id) {
              $scope.isRequestor = (createdBy._id === $rootScope.currentUser._id);
            }

            // add additional node to add adhoc approver in the same step
            // only for pending step and if the approval flow exist (submitted context)
            _.forEach($scope.data.steps, function (step) {

              if (step.step_status.toLowerCase() === 'pending' && !!$scope.data._id && $scope.contextStatus === 'pending' &&
              !globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'System Support')) {
                var tempId = 'adhoc_' + step.seq;
                step.positions.push({
                  'id': tempId,
                  'name': 'Add approver',
                  'position_type': 'ad-hoc',
                  'action': [],
                  'node_sub': {
                    'sourceId': 'node-sub_' + step.seq
                  }
                });
              }
            });

            var requestorObject = {
              'seq': 0,
              'positions': [
                {
                  'id': !!requestedBy._id ? requestedBy._id : 0,
                  'name': 'Requestor',
                  'position_type': 'requestor',
                  'action': {},
                  'users': [
                    {
                      '_id': requestedBy._id,
                      'display_name': requestedBy.display_name,
                      'email': requestedBy.email,
                      'img_url': requestedBy.img_url,
                      'prepared_by': preparedBy,
                      'created_by': createdBy,
                      'edited_by': editedBy,
                    }
                  ]
                }
              ],
              'min_approver': 0,
              'step_progress': 0,
              'step_status': 'requestor'
            };

            // Pushing requestor object into the first step
            $scope.data.steps.splice(0, 0, requestorObject);
          }
        }

        /**
         * To select status color
         */
        function returnStatusColor(action) {
          var statusColor = '';
          switch (action) {
            case 'rejected':
              statusColor = 'rejected-role';
              break;
            case 'approved':
              statusColor = 'approved-role';
              break;
            default:
              statusColor = 'status-color-default';
          }
          return statusColor;
        }

        /**
         * get the position level styling
         */
        function processingPositionStatusStyling (position, index, temp) {
          switch (temp['step'][index]['position'][position.id]['status']) {
            case 'approved':
              temp['step'][index]['position'][position.id]['status_color'] = 'status-color-approved';
              temp['step'][index]['position'][position.id]['border_style'] = 'border-style-approved';
              break;
            case 'rejected':
              temp['step'][index]['position'][position.id]['status_color'] = 'status-color-rejected';
              temp['step'][index]['position'][position.id]['border_style'] = 'border-style-rejected';
              break;
            case 'escalated':
              temp['step'][index]['position'][position.id]['status_color'] = 'status-color-escalated';
              break;
            case 'pending':
              temp['step'][index]['position'][position.id]['status_color'] = 'status-color-pending';
              break;
          }
          return temp;
        }

        /**
         * split up this section to process user level and below
         */
        function processingUserLevelData (position, index, temp) {
          temp['step'][index]['position'][position.id]['user'] = {};
          _.forEach(position.users, function (user) {
            var tempStatus = 'pending';
            if (!_.isEmpty(position.action.status) && position.action.user._id === user._id){
              tempStatus = position.action.status;
            }
            temp['step'][index]['position'][position.id]['user'][user._id] = {
              'id': user._id,
              'name': user.display_name,
              'status': tempStatus,
              'status_color': returnStatusColor(tempStatus),
              'log': {}
            };

            _.forEach(user.logs, function (log, currIndex) {
              var tempStatus = 'pending';
              if (!_.isEmpty(position.action.status) && position.action.user._id === log.to_user._id){
                tempStatus = position.action.status;
              }
              temp['step'][index]['position'][position.id]['user'][user._id]['log'][currIndex] = {
                'id': log.to_user._id,
                'name': log.to_user.display_name,
                'action': log.action.toLowerCase(),
                'status': tempStatus,
                'status_color': returnStatusColor(tempStatus)
              }
            });
          });
          return temp;
        }

        /**
         * To structure data to be display in mobile
         */
        var restructuringDataMobile = function () {

          var temp = [];
          temp['step'] = [];

          _.forEach($scope.data.steps, function (step, index) {
            index++;
            temp['step'][index] = [];
            temp['step'][index]['seq'] = step.seq;
            temp['step'][index]['step_status'] = step.step_status;

            temp['step'][index]['position'] = {};
            _.forEach(step.positions, function (position) {
              temp['step'][index]['position'][position.id] = {};
              temp['step'][index]['position'][position.id]['id'] = position.id;
              temp['step'][index]['position'][position.id]['name'] = position.name;
              temp['step'][index]['position'][position.id]['status'] = (!!position.action && !!position.action.status) ?
                position.action.status.toLowerCase() : step.step_status.toLowerCase();
              temp['step'][index]['position'][position.id]['end_at'] = position.action.date;
              temp['step'][index]['position'][position.id]['status_color'] = 'status-color-default';
              temp['step'][index]['position'][position.id]['border_style'] = '';

              temp = processingPositionStatusStyling(position, index, temp);
              temp = processingUserLevelData(position, index, temp);

            });
          });
          $scope.transformedData = temp;
        };
        //#endregion

        //#region Drawing the chart and connections
        /**
         * Function responsible to handle the drawing of the connections
         */
        var handleChart = function () {

          var options = {
            radius: 0.5,
            stroke: {
              lineWidth: 2,
              outlineWidth: 2
            },
            color: {
              transparent: 'transparent',
              red: '#FF0000',
              blue: '#0000FF',
              white: '#FFFFFF',
              green: '#7AB02C',
              darkBlue: '#216477',
              lightBlue: '#61B7CF',
              lightGray: '#C3C3C3',
              approved: '#1AB394',
              basic: '#C3C3C3',
              skipped: '#878787',
              rejected: '#FF2A2A'
            }
          };

          // Variables for connection types
          var connectorPaintStyle = {
              lineWidth: options.stroke.lineWidth,
              strokeStyle: options.color.lightGray,
              joinstyle: 'round',
              outlineColor: options.color.white,
              outlineWidth: options.stroke.outlineWidth
            },
            connectorBasic = {
              connector: 'StateMachine',
              paintStyle: {
                strokeStyle: options.color.basic,
                lineWidth: options.stroke.lineWidth
              }
            },
            connectorApproved = {
              connector: 'StateMachine',
              paintStyle: {
                strokeStyle: options.color.approved,
                lineWidth: options.stroke.lineWidth
              }
            },
            connectorRejected = {
              connector: 'StateMachine',
              paintStyle: {
                strokeStyle: options.color.rejected,
                lineWidth: options.stroke.lineWidth
              }
            };

          // Register the connection types to be called with toggleType
          instance.registerConnectionType('basic', connectorBasic);
          instance.registerConnectionType('approved', connectorApproved);
          instance.registerConnectionType('rejected', connectorRejected);

          // Suspend drawing and initialise.
          instance.batch(function () {

            var nodes = jsPlumb.getSelector('.node');

            var container = {
              width: instance.getContainer().offsetWidth,
              height: instance.getContainer().offsetHeight
            };
            var config = {
              margin: 15,
              distance: 75
            };

            var start = config.margin;

            // Looping for each step
            for (var k = 0; k < $scope.data.steps.length; k++) {

              // Get current step and next step(if any)
              var currentStep = $scope.data.steps[k];
              var nextStep = !!$scope.data.steps[k + 1] ? $scope.data.steps[k + 1] : undefined;

              var node = $(nodes[k]);

              var width = node.width(),
                height = node.height();

              switch ($scope.type) {
                case 'horizontal': {
                  node.css({
                    left: start,
                    top: parseInt((container.height - height) / 2)
                  });
                  start += width + config.distance;
                  break;
                }
                default: { // vertical
                  node.css({
                    left: parseInt((container.width - width) / 2),
                    top: start
                  });

                  start += height + config.distance;
                  break;
                }
              }

              // Looping for each position in current step
              for (var l = 0; l < currentStep.positions.length; l++) {

                var sourceForCurrentStep = 'node-sub_' + currentStep.seq + '_' + currentStep.positions[l].id;

                // If there is next step, loop for each position in next step
                if (!!nextStep) {
                  var line;
                  for (var m = 0; m < nextStep.positions.length; m++) {
                    var targetForCurrentStep = 'node-sub_' + nextStep.seq + '_' + nextStep.positions[m].id;

                    var addArray = [['Custom', {
                      create: function (component) {
                        return $('<div id="myDropDown"> + </div>');
                      },
                      id: 'addApprover',
                      events: {
                        click: function (labelOverlay, originalEvent) {
                          if (!isAddingAdHoc) {
                            if (!!$rootScope.isOffline) {
                              return;
                            }

                            addNewApprover(labelOverlay.component);
                          }
                        }
                      }
                    }]];


                    //for system support role
                    if (globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'System Support')) {
                      addArray = [];
                    }

                    // Assign variable line as a connection
                    line = instance.connect({
                      source: sourceForCurrentStep,
                      target: targetForCurrentStep,
                      anchor: ['LeftMiddle', 'RightMiddle'],
                      maxConnections: -1,
                      connector: ['Flowchart', {
                        stub: [0, 0],
                        gap: 0,
                        cornerRadius: 5,
                        alwaysRespectStubs: true
                      }],
                      connectorStyle: connectorPaintStyle,
                      overlays: addArray
                    });

                    if ($scope.contextStatus == null || $scope.contextStatus.toLowerCase() === 'withdrawn') {
                      line.hideOverlay('addApprover');
                    }

                    // Switch case to toggle connection type based on step status
                    switch (currentStep.step_status.toLowerCase()) {
                      case 'requestor':
                        line.toggleType('approved');
                        if (!!nextStep && nextStep.step_status.toLowerCase() !== 'pending') {
                          line.removeOverlay('addApprover');
                        }
                        break;
                      case 'pending':
                        line.toggleType('basic');
                        if (($scope.contextStatus === 'draft' && (!$scope.waitingon || !$scope.isRequestor)) ||
                          $scope.contextStatus === 'rejected' || $scope.contextStatus === 'on_hold') {
                          line.removeOverlay('addApprover');
                        }
                        break;
                      case 'approved':
                        if (k < $scope.data.current_step) {
                          line.toggleType('approved');
                          if (checkNextStepForAdhoc(nextStep)) {
                            line.removeOverlay('addApprover');
                          }
                        } else {
                          line.toggleType('basic');
                        }
                        break;
                      case 'rejected':
                        line.toggleType('rejected');
                        line.removeOverlay('addApprover');
                        break;
                      case 'on_hold':
                      case 'escalated':
                      case 'delegated':
                        line.removeOverlay('addApprover');
                        break;
                    }

                    if (l !== 0 || m !== 0 || $scope.contextStatus === 'draft') {
                      line.removeOverlay('addApprover');
                    }
                  }
                }

                // Looping for each user in current step's position
                _.forEach(currentStep.positions[l].users, function (user) {
                  user.is_active = true;
                  if (!!user.logs && user.logs.length > 0) {
                    var lastLog = user.logs.slice(-1)[0];
                    var lastTwoLog = user.logs.slice(-2)[0];

                    if (lastLog.action === 'escalate' ||
                      (!!lastTwoLog && lastTwoLog.action === 'escalate' && lastLog.action === 'delegate')) {
                      user.is_active = false;
                    }
                  }
                });
              }
            }
          });

          jsPlumb.fire('jsPlumbDemoLoaded', instance);
        };

        function checkActionLogUser(actionLog, userId) {
            var reversedActionLog = actionLog.slice().reverse();
            var onHoldAction = globalFunc.findInArray(reversedActionLog, 'action', 'on_hold');
            return !!onHoldAction && onHoldAction.action_by === userId;
        }

        /**
         * Check next step to allow add adhoc approver in prev step
         *
         * @param {object} nextStep
         * @returns {boolean}
         */
        function checkNextStepForAdhoc(nextStep) {
          var approvedStep = 0;
          _.forEach(nextStep.positions, function (position) {
            if (!!position.action &&
              !!position.action.status &&
              position.action.status.toLowerCase() === 'approved') {
              ++approvedStep;
            }
          });

          return !!nextStep && (nextStep.step_status.toLowerCase() !== 'pending' || approvedStep > 0);
        }

        /**
         * to get the approval flow in draft
         */
        function approvalPreview() {
          $scope.firstLoad = false;
          if (_.isEmpty($scope.context)) {
            toastr.error("Please safe draft before preview approval flow");
          } else {
            instance.reset(); // to reset the jsplumb instance before re draw
            $scope.preview();
          }
        }

        /**
         * Add New Approver (Adhoc approver)
         * @param overlaysData
         * overlays data from the click event (jsplumb)
         */
        function addNewApprover(overlaysData) {

          if (globalFunc.findRoleInRoleAssignments($rootScope.currentUser.role_assignments, 'System Support')) {
            return;
          }

          if (!!$rootScope.isOffline) {
            toastr.error("Adding ad-hoc users in offline is disabled");
            return;
          }

          if (!!overlaysData) {
            var modalInstance = $uibModal.open({
              templateUrl: 'components/approval-flow/add-approver/add-approver.html',
              backdrop: 'static',
              keyboard: false,
              resolve: {
                companyCode: function () {
                  isAddingAdHoc = true;
                  if (!!$scope.context && !!$scope.context.company && !!$scope.context.company.code ) {
                    return $scope.context.company.code;
                  }
                },
                overlays: function () {
                  return overlaysData;
                },
                approvalId: function () {
                  if (!!$scope.data && !!$scope.data._id) {
                    return $scope.data._id;
                  }
                },
                referenceClass: function() {
                  if (!!$scope.data && !!$scope.data.reference_class) {
                    return $scope.data.reference_class;
                  }
                },
              },
              controller: 'addApproverCtrl'
            });

            modalInstance.result.then(function () {
              isAddingAdHoc = false;
            });
          }
        }

        function deleteAdhoc(step, positionId) {
          swal(
            {
              title: 'Confirm remove adhoc approver?',
              text: 'Selected adhoc approver will be removed',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#ed5565',
              confirmButtonText: 'Remove',
              closeOnConfirm: true
            },
            function (confirm) {
              if (!!confirm) {
                removeAdhocApprover.delete({
                  id: $scope.data._id,
                  step: step,
                  positionId: positionId
                }, function(){
                  toastr.success('Adhoc approver has been removed.');
                  $state.reload();
                }, function (error){
                  globalFunc.objectErrorMessage(error);
                })
              }
            }
          );
        }

        function action() {
          instance.reset(); // to reset the jsplumb instance before re draw
          if (!$rootScope.isMobileMode) {
            restructuringData();
          } else {
            restructuringDataMobile();
          }
          initiateJSPlumbInstance();

          if (!!$scope.data && !!$scope.data.steps) {
            $timeout(handleChart);
          }
        }
        //#endregion

        //#region Watchers
        $scope.$watch('data', function (newData) {
          $scope.data = newData;
          action();
        });

        $scope.$watch('ischanged', function (isChangedField) {
          $scope.isChangedField = isChangedField;
        });
        //#endregion

        function scroll() {
          var scroller = document.getElementById('top-scrollbar');
          var approvalFlow = document.getElementById('approval-flow');

          scroller.onscroll = function () {
            approvalFlow.scrollLeft = scroller.scrollLeft;
          };
          approvalFlow.onscroll = function () {
            scroller.scrollLeft = approvalFlow.scrollLeft;
          };
        }

        function showToggleDebugButton() {
          return UserPermissions.isDeveloper($rootScope.currentUser) &&
            UserPermissions.hasTenantSuperAdminRole($rootScope.currentUser);
        }

        function toggleShowDebugInfo(checkbox) {
          if (!checkbox) {
            $scope.showDebugInfo = !$scope.showDebugInfo;
            approvalPreview();
          }
        }

        function initialize() {
          initiateJSPlumbInstance();
          scroll();
        }

        initialize();
      }
    }
  });
