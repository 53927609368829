'use strict';

/**
 * @name reportsGenerationV2Ctrl
 * @desc Controller for Reports generation
 */

function reportsGenerationV2Ctrl($scope, reports, toastr, businessList, singleBU, industryList, singleIndustry, $timeout, $filter, profile,
  globalFunc, checkReportStatus, $interval, pathConstants, reportTypes, $state, getIndustriesCompanies,
  searchModule, UserPermissions, reportsV2, reportsV2Config, $http, assetCategoryList, modeOfPurchaseList, billingFromCompanies, loadCacheableData, expenseTypesList, 
  lang) {

  //region Variable and scope declaration
  var usersCompaniesCode = [],
    usersCompanies = profile.companies,
    usersPerms = profile.view_perms,
    usersBUCode = [], allowedCompaniesForRole = [];
  var isSystemReportManager = globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Report Manager', 1);
  var selectedCountryCode = undefined;
  var selectedBU = undefined;
  var selectedSupplier = undefined;
  var selectedIndustry = undefined;
  $scope.replaceItemCode = replaceItemCode;
  $scope.replaceItemCodeRange = replaceItemCodeRange;
  $scope.itemCategoryCheck = itemCategoryCheck;
  $scope.assetCategoyList = assetCategoryList;

  $scope.selectedReportType = {};
  var allBUs = {
    descr: 'All Available Business Units',
    _id: 'all'
  };
  var allIndustries = {
    descr: 'All Available Industries',
    _id: 'all'
  };
  $scope.reports = {};
  $scope.prRange = {
    from: null,
    to: null
  };

  $scope.poRange = {
    from: 0,
    to: 0
  };

  $scope.itemCodeRange = {
    from: 0,
    to: 0
  };

  $scope.tenderNumberRange = {
    from: 0,
    to: 0
  };

  $scope.business = businessList;
  // Used for sorting business units when displaying them in a list
  $scope.businessId = {};
  $scope.selectedCompanies = [];
  $scope.selectedItemCategories = [];
  $scope.selectedCostCenters = [];
  $scope.selectedSuppliers = [];
  $scope.selectedBudgetType = {};
  $scope.selectedBudgetType.list = null;
  
  $scope.costCenterList = [];
  $scope.companyList = [];
  $scope.company_ids = [];
  $scope.companyCodes = [];
  $scope.supplierCodes = [];
  $scope.searchedSuppliers = [];
  $scope.searchedBillingCompanies = [];
  $scope.currencyData = [];
  $scope.approvalLimitTraceReportPayload = [];
  $scope.selectedTenderGroups = [];
  $scope.selectedTenders = [];
  $scope.tenders = [];
  $scope.tendersFilter = [];
  $scope.tenderGroupsList = [];
  $scope.tenderGroupsFilter = [];
  $scope.tenderGroupsFromCompaniesFilter = []; 
  $scope.tenderNameListings = [];
  $scope.loadTenderByRanges = loadTenderByRanges;
  $scope.selectedTenderNames = [];
  $scope.tenderIds = [];
  $scope.tenderIdFilter = [];
  $scope.onSelectDate = onSelectDate;
  $scope.prApproverSearch = {
    search: ''
  };
  $scope.docNumberSearch = {
    selected: ''
  };
  $scope.shadowCompanySearchField = {
    text: ''
  };
  $scope.tenderNumberSearchField = {
    text: ''
  };
  $scope.tenderNameSearchField = {
    text: ''
  }
  $scope.date_type = [{
    id: 'receivedDate',
    name: 'Received'
  },
  {
    id: 'GRNDate',
    name: 'GRN'
  }
  ];
  $scope.spend_date_type = [{
    id: 'PODate',
    name: 'PO Date'
  },
  {
    id: 'PRDate',
    name: 'PR Date'
  }
  ];
  $scope.budgetType = [{
    id: 'master',
    descr: 'Master'
  },
  {
    id: 'assignment',
    descr: 'Assignment'
  },
  {
    id: 'itemBreakdown',
    descr: 'Item Breakdown'
  }
  ];
  $scope.minionConfig = {};
  $scope.reportingCurrencyList = [{
    id: 'companyCurrency',
    descr: 'Company Currency'
  },
  {
    id: 'reportingCurrency',
    descr: 'Reporting Currency'
  }
  ];
  $scope.filterCompanyBy = [{
    id: 'billingCompany',
    descr: 'Billing Company',
    value: 'bill_company'
  },
  {
    id: 'industryBusinessUnit',
    descr: 'Industry & Business Unit',
    value: 'IBU'
  }
  ];
  $scope.searchMode = null;

  $scope.modeOfDisposalsList = [
    {
      value: undefined,
      descr: 'All'
    }, {
      value: 'Written Off',
      descr: 'Written Off'
    },
    {
      value: 'Disposal With Proceed',
      descr: 'Disposal With Proceed'
    },
    {
      value: 'Transfer',
      descr: 'Transfer'
    }
  ];

  $scope.selectedModeOfDisposal = {
    selected: $scope.modeOfDisposalsList[0]
  };

  $scope.prStatusSelection = [{
    key: 'all',
    value: 'All'
  },
  {
    key: 'pending',
    value: 'Pending Approval'
  },
  {
    key: 'approved',
    value: 'Approved'
  },
  {
    key: 'withdrawn',
    value: 'Withdrawn'
  },
  {
    key: 'deleted',
    value: 'Deleted'
  },
  {
    key: 'rejected',
    value: 'Rejected'
  },
  {
    key: 'on_hold',
    value: 'On Hold'
  }
  ];

  $scope.poStatusSelection = [
    {
      key: 'all',
      value: 'All'
    },
    {
      key: 'PENDING',
      value: 'Pending Approval'
    },
    {
      key: 'APPROVED',
      value: 'Approved'
    },
    {
      key: 'WITHDRAWN',
      value: 'Withdrawn'
    },
    {
      key: 'REJECTED',
      value: 'Rejected'
    },
    {
      key: 'CANCELLED',
      value: 'Cancelled'
    }
  ];

  $scope.reportModuleList = [
    {
      key: 'pr',
      value: 'PR',
    },
    {
      key: 'po',
      value: 'PO',
    }
  ];

  $scope.prTypeList = [
    {
      key: 'all',
      value: 'All'
    },
    {
      key: 'OPEX',
      value: 'Opex'
    },
    {
      key: 'CAPEX',
      value: 'Capex'
    }
  ];

  $scope.expenseTypeList = expenseTypesList;
  // add default 'All'
  $scope.expenseTypeList.unshift({
    code: '',
    category: 'all',
    descr: 'All'
  })
  $scope.filteredExpenseTypeList = _.cloneDeep($scope.expenseTypeList);

  $scope.itemCodeRangeCheckbox = false;
  $scope.itemCategoryCheckbox = false;
  $scope.itemCodeCheckbox = false;
  $scope.unbudgetedCheckbox = false;
  $scope.excludeRevisionCheckbox = false;
  $scope.filterDateByList = [
    {
      key: 'prSubmissionDate',
      value: 'PR Submission Date'
    },
    {
      key: 'prApprovedDate',
      value: 'PR Approved Date'
    }
  ];

  $scope.budgetConsumptionStatusList = [
    {
      key: 'all',
      value: 'All'
    },
    {
      key: 'APPROVED',
      value: 'Approved'
    },
    {
      key: 'EXPIRED',
      value: 'Expired'
    },
    {
      key: 'INACTIVE',
      value: 'Inactive'
    }
  ];

  $scope.budgetConsumption = {
    selected: $scope.budgetConsumptionStatusList[0]
  };

  $scope.filterDocNumberList = [
    {
      key: 'NONE',
      value: 'None'
    },
    {
      key: 'prNumber',
      value: 'PR Number'
    },
    {
      key: 'poNumber',
      value: 'PO Number'
    },
    {
      key: 'pcNumber',
      value: 'PC Number'
    }
  ];

  $scope.filterDocNumber = {
    selected: $scope.filterDocNumberList[0]
  };

  $scope.filterPurchaseLeadTimeDateByList = [
    {
      key: 'PR SUBMISSION DATE',
      value: 'PR Submission Date'
    },
    {
      key: 'PO CREATION DATE',
      value: 'PO Creation Date'
    }
  ];

  $scope.filterPurchaseLeadTimeDateBy = {
    selected: null
  };

  $scope.expenseTypeCategoryOptions = [
    {
      value: 'OPEX',
      descr: 'Opex'
    },
    {
      value: 'CAPEX',
      descr: 'Capex'
    }
  ];

  $scope.expenseTypeCategory = {
    selected: $scope.expenseTypeCategoryOptions[0]
  };

  $scope.budgetRevisedStatusOptions = [
    {
      value: 'revised_only',
      descr: 'Revised Only'
    },
    {
      value: 'all',
      descr: 'All'
    }
  ];

  $scope.budgetRevisedStatus = {
    selected: $scope.budgetRevisedStatusOptions[0]
  };

  $scope.budgetStatusOptions = [
    {
      value: 'all',
      descr: 'All'
    },
    {
      value: 'pending_approval',
      descr: 'Pending Approval'
    },
    {
      value: 'approved',
      descr: 'Approved'
    }
  ];

  $scope.budgetStatus = {
    selected: $scope.budgetStatusOptions[0]
  };

  $scope.erpBudgetCodeSearch = {
    search: ''
  }

  $scope.expenseTypeSubtypeOptions = [
    {
      value: 'GWO',
      descr: 'GWO'
    },
    {
      value: 'SPK',
      descr: 'SPK'
    }
  ];

  $scope.expenseTypeSubType = {
    selected: $scope.expenseTypeSubtypeOptions[0]
  };

  $scope.auditModuleOptions = [
    {
      value: 'user',
      descr: 'User'
    },
    {
      value: 'company',
      descr: 'Company'
    },
    {
      value: 'master_data',
      descr: 'Master Data'
    },
    {
      value: 'settings',
      descr: 'Settings'
    }
  ];

  $scope.auditModuleSubOptions =
  {
    user: [{
      value: 'management',
      descr: 'User Management',
    },
    {
      value: 'delegation',
      descr: 'User Delegation',
    },
    {
      value: 'role_assignment',
      descr: 'User Role Assignment',
    }
    ],
    company: [{
      value: 'settings',
      descr: 'Company Settings',
    },
    {
      value: 'gl_account_codes',
      descr: 'GL Account Codes',
    },
    {
      value: 'address',
      descr: 'Company Address',
    },
    {
      value: 'item_data',
      descr: 'Item Data',
    },
    {
      value: 'opex_io_data',
      descr: 'Opex IO Data',
    },
    {
      value: 'asset_master',
      descr: 'Asset Master',
    },
    {
      value: 'asset_category',
      descr: 'Asset Category',
    },
    {
      value: 'other_charges_group',
      descr: 'Other Charges Group',
    },
    {
      value: 'project_master',
      descr: 'Project Master',
    },
    {
      value: 'cost_center',
      descr: 'Cost Center',
    },
    ],
    master_data: [{
      value: 'currencies',
      descr: 'Currencies',
    },
    {
      value: 'exchange_rates',
      descr: 'Exchange Rates',
    },
    {
      value: 'uom',
      descr: 'UOM',
    },
    {
      value: 'payment_terms',
      descr: 'Payment Terms',
    },
    {
      value: 'payment_methods',
      descr: 'Payment Methods',
    },
    {
      value: 'taxes',
      descr: 'Taxes',
    },
    {
      value: 'expense_type',
      descr: 'Expense Type',
    },
    {
      value: 'mode_of_purchase',
      descr: 'Mode of Purchase',
    },
    {
      value: 'item_master',
      descr: 'Item Master',
    },
    {
      value: 'item_categories',
      descr: 'Item Categories',
    },
    {
      value: 'stock_information_group',
      descr: 'Stock Information Group',
    },
    {
      value: 'alc_group',
      descr: 'ALC Group',
    },
    {
      value: 'category_type',
      descr: 'Category Type',
    },
    {
      value: 'item_group',
      descr: 'Item Group',
    },
    ],
    settings: [{
      value: 'tenant',
      descr: 'Tenant Settings',
    },
    ],
  };

  $scope.assetCategoryOptions = [
    {
      value: 'all',
      descr: 'All'
    },
  ];

    $scope.tenderStatusOptions = [
      {
        value: 'draft',
        descr: 'DRAFT'
      },
      {
        value: 'pending approval',
        descr: 'PENDING'
      },
      {
        value: 'approved',
        descr: 'APPROVED'
      },
      {
        value: 'expired',
        descr: 'EXPIRED'
      },
      {
        value: 'deactivated',
        descr: 'DEACTIVATED'
      },
      {
        value: 'deleted',
        descr: 'DELETED'
      },
      {
        value: 'withdrawn',
        descr: 'WITHDRAWN'
      },
      {
        value: 'rejected',
        descr: 'REJECTED'
      }
    ];

    $scope.selectedTenderStatus = [];

    $scope.tenderSuppliersList = [{
      value: 'all',
      descr: 'All'
    }];

    $scope.tenderSuppliers = {
      selected: $scope.tenderSuppliersList[0]
    };

    $scope.tenderNamesList = ['All'];

    $scope.tenderNames = {
      selected: $scope.tenderNamesList[0]
    };
  /**
   * filters
   */
  function resetFilters() {
    $scope.buSelect = false;
    $scope.supplierSelect = false;
    $scope.spendReportBasedOn = false;
    $scope.dateTypeSelect = false;
    $scope.dateSelect = false;
    $scope.monthYearSelect = false;
    $scope.companyListSelect = false;
    $scope.tenderListSelect = false;
    $scope.reportingCurrencySelect = false;
    $scope.modeOfDisposalSelect = false;
    $scope.prStatusSelect = false;
    $scope.poStatusSelect = false;
    $scope.countrySelect = false;
    $scope.modeOfPurchaseSelect = false;
    $scope.reportingModuleSelect = false;
    $scope.prTypeSelect = false;
    $scope.expenseTypeSelect = false;
    $scope.prApproverSelect = false;
    $scope.itemCodeSelect = false;
    $scope.singleSupplierSelect = false;
    $scope.itemCodeRangeCheckbox = false;
    $scope.itemCategoryCheckbox = false;
    $scope.itemCodeCheckbox = false;
    $scope.unbudgetedCheckbox = false;
    $scope.excludeRevisionCheckbox = false;
    $scope.allowedItemCodeRange = false;
    $scope.filterDateBySelect = false;
    $scope.itemCategorySelect = false;
    $scope.poRequestorSelect = false;
    $scope.expenseTypeCategorySelect = false;
    $scope.budgetRevisedStatusSelect = false;
    $scope.budgetStatusSelect = false;
    $scope.erpBudgetCodeSelect = false;
    $scope.excludeRevisionSelect = false;
    $scope.unbudgetedItemsSelect = false;
    $scope.expenseTypeSubTypeSelect = false;
    $scope.tenderStatusSelect = false;
    $scope.tenderGroupsSelect = false;
    $scope.allowedTenderNumberRange = false;
    $scope.budgetConsumptionStatusSelect = false;
    $scope.assetCategorySelect = false;
    $scope.filterDocNumberSelect = false;
    $scope.filterPurchaseLeadTimeDateBySelect = false;
    $scope.docNumberSearch = {
      selected: ''
    };
    $scope.filterDocNumber = {
      selected: $scope.filterDocNumberList[0]
    };
    $scope.filterPurchaseLeadTimeDateBy = {
      selected: null
    };
  }

  $scope.modeOfPurchaseList = modeOfPurchaseList;
  $scope.modeOfPurchaseList.unshift({
    'code': undefined,
    'name': 'All'
  });

  $scope.modeOfPurchase = {
    selected: $scope.modeOfPurchaseList[0]
  };

  $scope.onSelectModePurchase = onSelectModePurchase;
  $scope.onSelectedCompanyCountry = onSelectedCompanyCountry;
  $scope.onSelectedCountry = onSelectedCountry;
  $scope.addToSelectedCompany = addToSelectedCompany;
  $scope.onSelectItemCategory = onSelectItemCategory;
  $scope.prStatus = {
    selected: $scope.prStatusSelection[0]
  };
  $scope.poStatus = {
    selected: $scope.poStatusSelection[0]
  };
  $scope.searchCompanies = {};
  $scope.searchCompanies = searchCompanies;
  $scope.industryList = industryList;
  $scope.industryList.unshift(allIndustries);
  $scope.industryId = {};
  $scope.onSelectIndustry = onSelectIndustry;
  $scope.sortedIndustry = sortedIndustry;
  $scope.onSelectedFilterCompanyBy = onSelectedFilterCompanyBy;
  $scope.reportingCurrency = {
    selected: $scope.reportingCurrencyList[0]
  };
  $scope.modeOfDisposal = {
    selected: null
  };
  $scope.reportModule = {
    selected: null
  };
  $scope.prType = {
    selected: $scope.prTypeList[0]
  };
  $scope.expenseType = {
    selected: $scope.expenseTypeList[0]
  };
  $scope.assetCategory = {
    selected: $scope.assetCategoryOptions[0]
  };
  $scope.singleSupplier = {
    selected: {
      _id: null,
      basic_info: {
        descr: 'All'
      }
    }
  };
  $scope.filterDateBy = {
    selected: null
  };
  $scope.selectedCompanyItem = {};
  $scope.budgetList = [];
  $scope.selectedErpBudgetCodes = [];
  $scope.selectedBilingCompany = null;
  $scope.selectedBillingCompany = null;
  $scope.selectedUserArray = [];
  $scope.selectedUser = null;
  $scope.selectedTenderStatus = [];
  $scope.selectedTenderGroups = [];

  $scope.getCompanies = getCompanies;
  $scope.generateReports = generateReports;
  $scope.hasSupplierTagPermission = hasSupplierTagPermission;
  $scope.generateOptionsBasedOnReportType = generateOptionsBasedOnReportType;
  $scope.onSelectBusinessUnit = onSelectBusinessUnit;
  $scope.onSelectSupplier = onSelectSupplier;
  $scope.onSelectCompany = onSelectCompany;
  $scope.openDatepicker = openDatepicker;
  $scope.onSelectDateType = onSelectDateType;
  $scope.sortedBusiness = sortedBusiness;
  $scope.getSelectedCompanies = getSelectedCompanies;
  $scope.onSelectedCompanyChanges = onSelectedCompanyChanges;
  $scope.onSelectSingle = onSelectSingle;
  $scope.onSelectAll = onSelectAll;
  $scope.onSelectNone = onSelectNone;
  $scope.onSupplierSelect = onSupplierSelect;
  $scope.getSuppliers = getSuppliers;
  $scope.loadAssetCategoryData = loadAssetCategoryData;
  $scope.onSelectBillingCompany = onSelectBillingCompany;
  $scope.onSelectIndustry = onSelectIndustry;
  $scope.getSelectedCompaniesCount = getSelectedCompaniesCount;
  $scope.prTypeSelected = prTypeSelected;
  $scope.searchUsers = searchUsers;
  $scope.searchDocNumber = searchDocNumber;
  $scope.onSelectPrApprover = onSelectPrApprover;
  $scope.searchItems = searchItems;
  $scope.onItemSelect = onItemSelect;
  $scope.onFilterDateBySelect = onFilterDateBySelect;
  $scope.onFilterDocNumberSelect = onFilterDocNumberSelect;
  $scope.onFilterPurchaseLeadTimeDateBySelect = onFilterPurchaseLeadTimeDateBySelect;
  $scope.onSelectPoRequestor = onSelectPoRequestor;
  $scope.loadBudgetList = loadBudgetList;
  $scope.onSelectERPBudgetCode = onSelectERPBudgetCode;
  $scope.onSelectExpenseType = onSelectExpenseType;
  $scope.clearBudgetList = clearBudgetList;
  $scope.selectUser = selectUser;
  $scope.loadUsers = loadUsers;
  $scope.loadUsersHasApprovalLimit = loadUsersHasApprovalLimit;
  $scope.clearUserLine = clearUserLine;
  $scope.onSelectDocNumber = onSelectDocNumber;
  $scope.loadTenders = loadTenders;
  $scope.loadTenderGroups = loadTenderGroups;
  $scope.loadTendersFilter = loadTendersFilter;
  $scope.loadTenderGroupsFromCompanies = loadTenderGroupsFromCompanies;
  $scope.getTenderSuppliers = getTenderSuppliers;
  $scope.onSelectTenderNumber = onSelectTenderNumber;
  $scope.onSelectTenderName = onSelectTenderName;
  $scope.searchTenderName = searchTenderName;
  $scope.tenderNumberLists = [];

  $scope.reportTypes = reportTypes.getReportTypes();

  $scope.datepickerOpened = {
    startDate: false,
    endDate: false,
    monthYear: false,
    year: false,
  };
  $scope.auditModule = {
    selected: $scope.auditModuleOptions[0]
  };
  $scope.auditModuleSub = {
    selected: $scope.auditModuleSubOptions[$scope.auditModule.selected.value][0]
  };
  $scope.auditModuleSubOptionsCheck = auditModuleSubOptionsCheck;

  //endregion

  function auditModuleSubOptionsCheck() {
    return $scope.auditModuleSubOptions[$scope.auditModule.selected.value];
  }

  function loadBudgetList() {
    if ($scope.selectedReportType.code === 'EXCEL_BUDGET_CONSUMPTION_REPORT' && getSelectedCompaniesCount() > 1)
      return;

    if (!!$scope.selectedCompanies[0].code) {
      $scope.loading = true;

      var companyCodes = _.map($scope.selectedCompanies, function (company) {
        return company.code;
      });

      var additionalParams = {
        criteria: [
          {
            'company|code': companyCodes
          },
          {
            'status': [1, 6, 7]
          }
        ],
        criteria_operator: 'and',
        transformType: 'report',
        offset: 1000
      };

      if ($scope.selectedReportType.code === 'EXCEL_APPROVED_CAPEX_PR_SUMMARY_REPORT_ERP_BUDGET_CODE' ||
        $scope.selectedReportType.code === 'EXCEL_APPROVED_CAPEX_PR_DETAIL_REPORT') {
        additionalParams['criteria'].push({ 'expense_type_category': 'CAPEX' });
      } else if ($scope.selectedReportType.code === 'EXCEL_APPROVED_OPEX_PR_SUMMARY_REPORT' || 
        $scope.selectedReportType.code === 'EXCEL_APPROVED_OPEX_PR_DETAIL_REPORT') {
        additionalParams['criteria'].push({ 'expense_type_category': 'OPEX' });
      } else if ($scope.selectedReportType.code === 'EXCEL_BUDGET_V2_REPORT') {
        additionalParams['criteria'].push({'expense_type_category': $scope.expenseTypeCategory.selected.value});
      } else if ($scope.selectedReportType.code === 'EXCEL_BUDGET_CONSUMPTION_REPORT') {
        clearBudgetList();
        if ($scope.prType.selected.key !== 'all')
          additionalParams['criteria'].push({ 'expense_type_category': $scope.prType.selected.key });
      }

      var moduleParam = {
        module: 'budgets-report',
      };

      $http.post($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), additionalParams).then(function (response) {
        $scope.loading = false;
        $scope.budgetList = response.data.content.data;
        // remove empty erp budget id
        _.remove($scope.budgetList, function (budget) {
          return !budget.erp_budget_id;
        });

        if ($scope.selectedReportType.code === 'EXCEL_BUDGET_CONSUMPTION_REPORT') {
          var budgetList = [];
          _.forEach($scope.budgetList, function (budget) {
            if ((!!budget && !!budget.years) && budget.years.includes($scope.budgetYear.date.getFullYear()))
              budgetList.push(budget);
          })
          $scope.budgetList = budgetList;
        }
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });
    }
  }

  function clearBudgetList() {
    $scope.budgetList = [];
    $scope.selectedErpBudgetCodes = [];
  }

  function searchUsers(query) {
    $scope.loading = true;
    return $http.get(pathConstants.apiUrls.genericSearch.searchBasePath + '/users', {
      params: {
        module: 'users',
        'criteria[1][display_name]': query,
        'criteria[1][email]': query,
        'criteria[0][status]': 1,
        'criteria[1][$operator]': 'or',
        criteria_operator: 'and'
      }
    }).then(function (response) {
      $scope.loading = false;
      return response.data.content.data.map(function (item) {
        $scope.loading = false;
        return item;
      });
    });
  }

  /**
   * search PC/PR/PO number based on filter doc number and company code
   * @param query
   */
  function searchDocNumber(query) {
    var filterDocNumberSelected = $scope.filterDocNumber.selected.key;

    // get only company code from company array
    var companyCodes = _.map($scope.selectedCompanies, function (company) {
      return company.code;
    });

    // to only get PO/PC/PR number that are in draft, pending or approved status
    var statusesForPcAndPo = ['DRAFT', 'PENDING', 'APPROVED'];
    var statusesForPr = ['draft', 'pending', 'approved'];

    // set PC as default module
    var moduleParam = {
      module: 'price-comparison',
    };

    var additionalParams = {
      criteria: [
        {
          'pc_number': query
        },
        {
          'pr_info|company|code': companyCodes,
          '$operator': 'or',
        },
        {
          'status': statusesForPcAndPo,
          '$operator': 'or',
        }
      ],
      criteria_operator: 'and',
      offset: 0
    };

    if (filterDocNumberSelected === 'prNumber') {
      moduleParam = {
        module: 'Requisition'
      };

      additionalParams = {
        criteria: [
          {
            'header_info|custom_reference': query
          },
          {
            'header_info|company|code': companyCodes,
            '$operator': 'or',
          },
          {
            'header_info|status': statusesForPr,
            '$operator': 'or',
          },
        ],
        criteria_operator: 'and',
        offset: 0
      };
    }

    if (filterDocNumberSelected === 'poNumber') {
      moduleParam = {
        module: 'PO'
      };

      additionalParams = {
        criteria: [
          {
            'po_custom_number': query
          },
          {
            'billing|company|code': companyCodes,
            '$operator': 'or',
          },
          {
            'status': statusesForPcAndPo,
            '$operator': 'or',
          },
        ],
        criteria_operator: 'and',
        offset: 0
      };
    }

    return $http.post($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), additionalParams).then(function (response) {
      $scope.loading = false;
      var docNumberData = [];
      return response.data.content.data.map(function (item) {
        if (filterDocNumberSelected === 'pcNumber') {
          docNumberData = {
            '_id': item._id,
            'docNumber': item['pc_number'],
          };
        } else if (filterDocNumberSelected === 'prNumber') {
          docNumberData = {
            '_id': item._id,
            'docNumber': item['header_info']['custom_reference'],
          };
        } else {
          docNumberData = {
            '_id': item._id,
            'docNumber': item['po_custom_number'],
          };
        }
        return docNumberData;
      });

    }, function (error) {
      toastr.error('Please select company');
    });
  };

  /**
   * search the items from company items, based on code and name
   * @param query
   */
  function searchItems(query) {
    // single company report only
    var additionalParams = {
      'criteria[0][company|code]': $scope.selectedCompanies[0].code,
      'criteria[1][item_master_code]': query,
      'criteria[1][name]': query,
      'criteria[1][$operator]': 'or',
      'criteria[2][is_active]': true,
      'criteria_operator': 'and',
      offset: 100
    };

    var moduleParam = {
      module: 'company-item'
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams
    }).then(function (response) {
      $scope.loading = false;
      return response.data.content.data.map(function (item) {
        $scope.loading = false;
        return item;
      });
    }, function (error) {
      globalFunc.objectErrorMessage(error);
    });
  }

  function searchTenderName(query) {
    if (query.length > 2) {
      return _.filter($scope.tenders, function (tender) {
        return tender.detail.name.toUpperCase().indexOf(query.toUpperCase()) !== -1;
      })
    }
    return $scope.tenders;
  }

  function loadTenders() {
    var additionalParams = {
      'criteria[0][detail|status][0]': 'draft',
      'criteria[0][detail|status][1]': 'vo_draft',
      'criteria[0][detail|status][2]': 'pending',
      'criteria[0][detail|status][3]': 'approved',
      'criteria[0][detail|status][4]': 'deleted',
      'criteria[0][detail|status][5]': 'withdrawn',
      'criteria[0][detail|status][6]': 'rejected',
      'criteria_operator': 'and',
      offset: 50
    };

    var moduleParam = {
      module: 'tender'
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams
    }).then(function (response) {
      $scope.loading = false;
      return response.data.content.data.map(function (item) {
        $scope.loading = false;
        if (item)
          $scope.tenders.push(item);
      })
    }, function (error) {
      globalFunc.objectErrorMessage(error);
    })
  }

  function loadTenderByRanges() {
    if ($scope.tenderNumberRange.from !== undefined && $scope.tenderNumberRange.to !== undefined 
      && $scope.tenderNumberRange.to >= $scope.tenderNumberRange.from) {

      var additionalParams = {
        'criteria[0][detail|status]': 'APPROVED',
        'criteria[0][numerical_reference_counter][from]': $scope.tenderNumberRange.from,
        'criteria[0][numerical_reference_counter][to]': $scope.tenderNumberRange.to,
        'criteria[0][$operator]': 'and',
        offset: 200
      };

      var moduleParam = {
        module: 'tender'
      };

      return $http.get($filter('format')(pathConstants.apiUrls.advancedSearch, moduleParam), {
        params: additionalParams
      }).then(function (response) {

        if (response.data.content.data.length > 0) {
          var datas = response.data.content.data;
          var tenderNameListings = [];

          _.forEach(datas, function (data) {
            tenderNameListings.push({
              value: data._id,
              descr: data.original_code + ' - ' + data.detail.name
            })
          })

          $scope.tenderNameListings = tenderNameListings;
        }

      }, function (error) {
        globalFunc.objectErrorMessage(error);
      })

    }
  }

  function replaceItemCode() {
    $scope.itemSearchText = '';
  }

  function replaceItemCodeRange() {
    $scope.itemCodeRange.from = '';
    $scope.itemCodeRange.to = '';
  }

  function onItemSelect(item) {
    $scope.selectedCompanyItem = item;
  }

  function onSelectPrApprover(approver) {
    $scope.prApprover = approver;
  }

  function onSelectERPBudgetCode(budget) {
    $scope.erpBudgetCode = budget.erp_budget_id;
  }

  function onSelectPoRequestor(requestor) {
    $scope.poRequestor = requestor;
  }

  function onSelectDocNumber(Number) {
    $scope.docNumberSearch.selected = Number.docNumber;
  }

  // Return string of selected company
  function getSelectedCompanies(array, key) {
    return globalFunc.createStringBasedOnObjectKeyInArray(array, key);
  }

  function getSelectedCompaniesCount() {
    return !!$scope.selectedCompanies ? $scope.selectedCompanies.length : -1;
  }

  // Function for sorting BU on list
  function sortedBusiness(business) {
    if (business.descr === 'All Available Business Units') {
      return -1;
    }
    return business.descr;
  }

  function sortedIndustry(industry) {
    if (industry.descr === 'All Available Industries') {
      return -1;
    }
    return industry.descr;
  }

  // updates expense type based on selected pr type
  function prTypeSelected() {
    // resets expense type
    $scope.expenseType = {
      selected: null
    }

    loadBudgetList();
    switch ($scope.prType.selected.key) {
      case 'all':
        $scope.filteredExpenseTypeList = _.cloneDeep($scope.expenseTypeList);
        break;
      case 'OPEX':
        $scope.filteredExpenseTypeList = _.filter($scope.expenseTypeList, function (e) {
          return e.category === 'OPEX' || e.category === 'all';
        });
        break;
      case 'CAPEX':
        $scope.filteredExpenseTypeList = _.filter($scope.expenseTypeList, function (e) {
          return e.category === 'CAPEX' || e.category === 'all';
        });
        break;
    }
  }

  function onFilterDateBySelect(selection) {
    switch (selection.key) {
      case 'prApprovedDate':
        $scope.filterDateBy = {
          selected: selection
        }
        break;
      case 'prSubmissionDate':
        $scope.filterDateBy = {
          selected: selection
        }
        break;
    }
  }

  function onFilterDocNumberSelect(selection) {
    $scope.docNumberSearch = {
      selected: ''
    };
    switch (selection.key) {
      case 'NONE':
        $scope.filterDocNumber = {
          selected: selection
        }
        break;
      case 'PR NUMBER':
        $scope.filterDocNumber = {
          selected: selection
        }
        break;
      case 'PO NUMBER':
        $scope.filterDocNumber = {
          selected: selection
        }
        break;
      case 'PC NUMBER':
        $scope.filterDocNumber = {
          selected: selection
        }
        break;
    }
  }

  function onFilterPurchaseLeadTimeDateBySelect(selection) {
    switch (selection.key) {
      case 'PR SUBMISSION DATE':
        $scope.filterPurchaseLeadTimeDateBy = {
          selected: selection
        }
        break;
      case 'PO CREATION DATE':
        $scope.filterPurchaseLeadTimeDateBy = {
          selected: selection
        }
        break;
    }
  }

  function loadTenderGroups() {

    var moduleParam = {
      module: 'tender-group'
    };

    var additionalParams = {
      offset: 200
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams
    }).then(function (response) {
        $scope.loading = false;
        return response.data.content.data.map(function (item) {
          $scope.loading = false;
          $scope.tenderGroupsList.push(item);
        });
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });
  }

  function loadTenderGroupsFromCompanies(company_id) {
    $scope.tenderGroupsFromCompaniesFilter = [];    
    var moduleParam = {
      module: 'tender-group'
    };

    var additionalParams = {
      'criteria[0][companies|company_id]': company_id,
      'criteria_operator': 'or',
      offset: 50
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams
    }).then(function (response) {
        $scope.loading = false;
        return response.data.content.data.map(function (item) {
          $scope.loading = false;
          if (!$scope.tenderGroupsFromCompaniesFilter.includes(item._id)) {
            $scope.tenderGroupsFromCompaniesFilter.push(item._id);
          }
        });
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });  
  }

  function getTenderSuppliers(tenderGroup) {
    _.forEach($scope.tenders, function (tender) {
      var tenderGroups = tender.groups.split(', ');
      if (tenderGroups.length > 0) 
        if (tenderGroups.includes(tenderGroup.code))
          assignTenderSuppliers(tender);
    })
  }

  function loadTendersFilter(tender_group_id) {
    var additionalParams = {
      'criteria[0][suppliers|supplier_id]': !!selectedSupplier ? selectedSupplier.basic_info.descr : undefined,
      'criteria[0][components|tender_group_id]': !!tender_group_id ? tender_group_id : undefined,
      'criteria_operator': 'and',
      'criteria[0][detail|status]': 'APPROVED',
      offset: 500
    };
  
    var moduleParam = {
      module: 'tender'
    };
  
    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams
    }).then(function (response) {
      $scope.loading = false;
      response.data.content.data.forEach(function (item) {
        var isDuplicate = $scope.tendersFilter.some(function (existingItem) {
          return existingItem.value === item._id;
        });
  
        if (!isDuplicate) {
          $scope.tendersFilter.push({
            value: item._id,
            code: item.code,
            name: item.detail.name,
            descr: item.code + ' - ' + item.detail.name
          });
        }
      });
    }, function (error) {
      globalFunc.objectErrorMessage(error);
    });
  }
  
  function getTenderNames(tenderGroup) {
    _.forEach($scope.tenders, function (tender) {
      if (tenderGroup)
        if (Array.isArray(tender.tender_group_ids) && tender.tender_group_ids.includes(tenderGroup._id))
          $scope.tenderNamesList.push(tender.detail.name);
        else
          if (Object.values(tender.tender_group_ids).includes(tenderGroup._id))
            $scope.tenderNamesList.push(tender.detail.name);
            $scope.tenderNamesList = _.filter($scope.tenderNamesList, function (tenderName, idx, self) {
              return self.indexOf(tenderName) === idx
            })
    })
  }

  function assignTenderSuppliers(tender) {
    if (tender.suppliers)
      for (var i = 0; i < tender.suppliers; i++) {}
      _.forEach(tender.suppliers, function (supplier) {
        if (checkTenderSupplier(supplier)) {
          $scope.tenderSuppliersList.push({
            value: supplier.basic_info.code,
            descr: supplier.basic_info.descr
          })
        }
      })
  }

  function checkTenderSupplier(supplier) {
    if ($scope.tenderSuppliersList.length === 1) 
      return true;

    for (var i = 1; i < $scope.tenderSuppliersList.length; i++) {
      if ($scope.tenderSuppliersList[i].value === supplier.basic_info.code) {
        return false;
      }
    }

    return true;
  }
  //region On select functions

  // Set selection based on field type
  function onSelectSingle(fieldType, selection) {
    switch (fieldType) {
      case 'company':
        $scope.selectedCompanies = selection;
        var selectedCompanyIds = $scope.selectedCompanies.map(function(company) {
          return company._id;
        });

        // Initial promise to start the chain
        var promiseChain = Promise.resolve(); 

        $scope.tenderGroupsFromCompaniesFilter = [];
        $scope.tendersFilter = [];
        if (selectedCompanyIds.length > 0) {
          selectedCompanyIds.forEach(function(companyId) {
            promiseChain = promiseChain.then(function() {
              return loadTenderGroupsFromCompanies(companyId);
            }).then(function() {
              $scope.tenderGroupsFromCompaniesFilter.forEach(function(item) {
                loadTendersFilter(item);
              });
            });
          });
        } else {
          loadTendersFilter();
        }

        if ($scope.selectedCompanies.length === 0) {
          clearBudgetList();
          break;
        }

        if (($scope.selectedCompanies.length === 1 || !!$scope.multiCompaniesBudgetCodeSearch)
          && ['EXCEL_APPROVED_CAPEX_PR_DETAIL_REPORT',
            'EXCEL_BUDGET_V2_REPORT',
            'EXCEL_APPROVED_OPEX_PR_SUMMARY_REPORT',
            'EXCEL_APPROVED_CAPEX_PR_SUMMARY_REPORT_ERP_BUDGET_CODE',
            'EXCEL_APPROVED_OPEX_PR_DETAIL_REPORT',
            'EXCEL_BUDGET_CONSUMPTION_REPORT'].includes($scope.selectedReportType.code)) {

          loadBudgetList();
          loadAssetCategoryData();
        } else {
          clearBudgetList();
        }
        break;
      case 'costCenter':
        $scope.selectedCostCenters = selection;
        break;
      case 'supplier':
        $scope.selectedSuppliers = selection;
        break;
      case 'budget':
        $scope.selectedErpBudgetCodes = selection;
        break;
      case 'tender':
        $scope.selectedTenders = selection;
        break;
      case 'tenderStatus':
        $scope.selectedTenderStatus = selection;
        break;
      case 'tenderGroups':
        $scope.selectedTenderGroups = selection;
        $scope.tenderSuppliersList = [{
          value: 'all',
          descr: 'All'
        }];
        $scope.tenderSuppliers = {
          selected: $scope.tenderSuppliersList[0]
        }
        if ($scope.selectedTenderGroups.length > 0)
          _.forEach($scope.selectedTenderGroups, function (selectedTenderGroup) {
            getTenderSuppliers(selectedTenderGroup);
            getTenderNames(selectedTenderGroup);
          })
        else
          $scope.tenderSuppliersList = [{
            value: 'all',
            descr: 'All'
          }];
        break;
      case 'tenderNames':
        $scope.selectedTenderNames = selection;
        break;
    }
  }

  function onSelectItemCategory(items) {
    $scope.selectedItemCategories = items;
  }

  function resetItemCategory() {
    _.forEach($scope.categoryList, function (category) {
      if (!!category.ticked) {
        category.ticked = false;
      }
    });
  }

  function resetApproverLimitTraceReport() {
    $scope.$broadcast("initializeApprovalLimitTraceReportPayload");
  }

  function resetTenders() {
    _.forEach($scope.tendersFilter, function (tender) {
      if (!!tender.ticked) {
       tender.ticked = false;
      }
    });
  }

  // Set selection to all based on field type
  function onSelectAll(fieldType, selectionList) {
    switch (fieldType) {
      case 'company':
        $scope.selectedCompanies = selectionList;
        $scope.tenderGroupsFromCompaniesFilter = [];
        if (!selectedSupplier){
          $scope.tendersFilter = $scope.tenderList;
        } else {
          $scope.tendersFilter = [];
          loadTendersFilter();
        }
        
        if (!!$scope.multiCompaniesBudgetCodeSearch) {
          loadBudgetList();
        } else {
          clearBudgetList();
        }
        break;
      case 'costCenter':
        $scope.selectedCostCenters = selectionList;
        break;
      case 'supplier':
        $scope.selectedSuppliers = selectionList;
        break;
      case 'tender':
        $scope.selectedTenders = selectionList;
        break;
      case 'budget':
        $scope.selectedErpBudgetCodes = selectionList;
        break;
      case 'tenderStatus':
        $scope.selectedTenderStatus = selectionList;
        break;
      case 'tenderGroups':
        $scope.selectedTenderGroups = selectionList;
        $scope.tenderSuppliersList = [{
          value: 'all',
          descr: 'All'
        }];
        _.forEach($scope.selectedTenderGroups, function (selectedTenderGroup) {
          getTenderSuppliers(selectedTenderGroup);
          getTenderNames(selectedTenderGroup);
        })
        break;
      case 'tenderNames':
        $scope.selectedTenderNames = selectionList;
        break;
    }
  }

  // Set selection to none based on field type
  function onSelectNone(fieldType) {
    switch (fieldType) {
      case 'company':
        $scope.selectedCompanies = [];
        $scope.tendersFilter = [];
        clearBudgetList();
        break;
      case 'costCenter':
        $scope.selectedCostCenters = [];
        break;
      case 'supplier':
        $scope.selectedSuppliers = [];
        break;
      case 'budget':
        $scope.selectedErpBudgetCodes = [];
        break;
      case 'tender':
        $scope.selectedTenders = [];
      case 'tenderStatus':
        $scope.selectedTenderStatus = [];
        break;
      case 'tenderGroups':
        $scope.selectedTenderGroups = [];
        $scope.tenderSuppliersList = [{
          value: 'all',
          descr: 'All'
        }];
        $scope.tenderNamesList = ['All']
        $scope.tenderSuppliers = {
          selected: $scope.tenderSuppliersList[0]
        }
        $scope.tenderNames = {
          selected: $scope.tenderNamesList[0]
        }
        break;
      case 'tenderNames':
        $scope.selectedTenderNames = [];
        break;
    }
  }

  function clearCompanySelection() {
    _.forEach($scope.companyList, function (company) {
      company.ticked = false;
    })
  }

  function setCompaniesAvailable(reportType) {
    allowedCompaniesForRole = [];
    _.forEach(reportType.allowedRoles, function (role) {
      var companies = globalFunc.getUserCompanyListForRole(profile, role);
      _.forEach(companies, function (company) {
        allowedCompaniesForRole.indexOf(company.code) === -1 ? allowedCompaniesForRole.push(company.code) : undefined;
      });
    });
  }

  function generateOptionsBasedOnReportType(reportType) {
    $scope.selectedReportType = reportType;
    $scope.reportModule.selected = {};
    $scope.selectedCompanies = [];
    $scope.selectedItemCategories = [];
    $scope.selectedSuppliers = [];
    $scope.selectedBilingCompany = null;
    $scope.selectedTenderNumber = {
      selected: null
    };
    $scope.selectedBillingCompany = null;
    $scope.companyList = [];
    resetFilters();
    clearCompanySelection();
    setCompaniesAvailable(reportType);
    $scope.buSelect = false;
    $scope.supplierSelect = false;
    $scope.spendReportBasedOn = false;
    $scope.dateTypeSelect = false;
    $scope.dateSelect = false;
    $scope.companyListSelect = false;
    $scope.reportingCurrencySelect = false;
    $scope.modeOfDisposalSelect = false;
    $scope.prStatusSelect = false;
    $scope.poStatusSelect = false;
    $scope.countrySelect = false;
    $scope.modeOfPurchaseSelect = false;
    $scope.allowedPrRange = false;
    $scope.allowedPoRange = false;
    $scope.allowedTenderNumberRange = false;
    $scope.erpBudgetCodeSelect = false;
    $scope.unbudgetedItemsSelect = false;
    $scope.excludeRevisionSelect = false;
    $scope.monthYearSelect = false;
    $scope.multiCompaniesBudgetCodeSearch = false;
    $scope.auditModuleSelect = false;
    $scope.auditModuleSubSelect = false;
    $scope.tenderNumberSelect = false;
    $scope.budgetList = false;
    $scope.dateSelectStartLabel = 'Submitted Date (From)';
    $scope.dateSelectEndLabel = 'Submitted Date (To)';
    switch (reportType.code) {
      case 'SPEND':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = true;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = false;
        $scope.reportingCurrencySelect = false;
        $scope.modeOfDisposalSelect = false;
        $scope.poStatusSelect = false;
        $scope.prStatusSelect = false;
        $scope.countrySelect = false;
        $scope.modeOfPurchaseSelect = false;
        break;
      case 'RECEIVING':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = true;
        $scope.dateSelect = true;
        $scope.companyListSelect = false;
        $scope.reportingCurrencySelect = false;
        $scope.modeOfDisposalSelect = false;
        $scope.poStatusSelect = false;
        $scope.prStatusSelect = false;
        $scope.countrySelect = false;
        $scope.modeOfPurchaseSelect = false;
        break;
      case 'REQUISITION_COUNTER':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = false;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = false;
        $scope.modeOfPurchaseSelect = false;
        break;
      case 'PR_PO_COUNT_PER_COMPANY':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = false;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = false;
        $scope.modeOfPurchaseSelect = false;
        break;
      case 'REQUISITION':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = false;
        $scope.reportingCurrencySelect = false;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = false;
        $scope.modeOfPurchaseSelect = false;
        break;
      case 'REQUISITION_DETAILS':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = false;
        $scope.reportingCurrencySelect = false;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = false;
        $scope.modeOfPurchaseSelect = false;
        $scope.unbudgetedItemsSelect = false;
        break;
      case 'SUPPLIER_TAGGING':
        $scope.buSelect = true;
        $scope.supplierSelect = true;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = false;
        $scope.companyListSelect = false;
        $scope.reportingCurrencySelect = false;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = false;
        $scope.modeOfPurchaseSelect = false;
        break;
      case 'BUDGET':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = false;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = false;
        $scope.modeOfPurchaseSelect = false;
        break;
      case 'EXCEL_APPROVED_CAPEX_PR_DETAIL_REPORT':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = true;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = true;
        $scope.modeOfPurchaseSelect = false;
        $scope.allowedPrRange = true;
        $scope.allowedPoRange = false;
        $scope.erpBudgetCodeSelect = true;
        $scope.dateSelectStartLabel = 'PR Approved Date From';
        $scope.dateSelectEndLabel = 'PR Approved Date To';
        $scope.unbudgetedItemsSelect = true;
        $scope.excludeRevisionSelect = false;
        break;
      case 'EXCEL_APPROVED_OPEX_PR_DETAIL_REPORT':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = true;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = true;
        $scope.erpBudgetCodeSelect = true;
        $scope.modeOfPurchaseSelect = false;
        $scope.allowedPrRange = true;
        $scope.allowedPoRange = false;
        $scope.unbudgetedItemsSelect = true;
        $scope.excludeRevisionSelect = false;
        $scope.multiCompaniesBudgetCodeSearch = true;
        break;
      case 'EXCEL_DISPOSAL_REPORT':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = false;
        $scope.modeOfDisposalSelect = true;
        $scope.prStatusSelect = true;
        $scope.poStatusSelect = false;
        $scope.countrySelect = true;
        $scope.modeOfPurchaseSelect = false;
        $scope.filterDateBySelect = true;
        $scope.allowedPrRange = false;
        $scope.unbudgetedItemsSelect = false;
        $scope.excludeRevisionSelect = false;
        break;
      case 'EXCEL_PR_DETAIL_REPORT':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = true;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = true;
        $scope.poStatusSelect = false;
        $scope.countrySelect = true;
        $scope.modeOfPurchaseSelect = true;
        $scope.allowedPrRange = true;
        $scope.allowedPoRange = false;
        $scope.singleSupplierSelect = true;
        $scope.expenseTypeSelect = true;
        $scope.unbudgetedItemsSelect = false;
        $scope.excludeRevisionSelect = false;
        $scope.itemCategoryCheckbox = true; // Hack to bypass validation (to pass item category data to minion)
        break;
      case 'EXCEL_PO_DETAIL_REPORT':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = true;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = true;
        $scope.countrySelect = true;
        $scope.modeOfPurchaseSelect = false;
        $scope.allowedPoRange = true;
        $scope.singleSupplierSelect = true;
        $scope.itemCategorySelect = true;
        $scope.poRequestorSelect = true;
        $scope.allowedPrRange = false;
        $scope.unbudgetedItemsSelect = false;
        $scope.excludeRevisionSelect = false;
        $scope.itemCategoryCheckbox = true; // Hack to bypass validation (to pass item category data to minion)
        break;
      case 'EXCEL_PURCHASE_STATUS_REPORT':
        $scope.buSelect = true;
        $scope.supplierSelect = true;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = true;
        $scope.tenderListSelect = true;
        $scope.reportingCurrencySelect = true;
        $scope.reportingModuleSelect = true;
        $scope.prTypeSelect = true;
        $scope.expenseTypeSelect = true;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = true;
        $scope.poStatusSelect = true;
        $scope.countrySelect = true;
        $scope.modeOfPurchaseSelect = true;
        $scope.allowedPrRange = false;
        $scope.allowedPoRange = false;
        $scope.prApproverSelect = true;
        $scope.itemCodeSelect = true;
        $scope.allowedItemCodeRange = true;
        $scope.singleSupplierSelect = true;
        $scope.tenderListSelect = true;
        $scope.unbudgetedItemsSelect = false;
        $scope.excludeRevisionSelect = false;
        break;
      case 'EXCEL_BUDGET_V2_REPORT':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = false;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = true;
        $scope.poStatusSelect = false;
        $scope.countrySelect = true;
        $scope.modeOfPurchaseSelect = false;
        $scope.filterDateBySelect = false;
        $scope.allowedPrRange = false;
        $scope.itemCategorySelect = false;
        $scope.expenseTypeCategorySelect = true;
        $scope.budgetRevisedStatusSelect = true;
        $scope.budgetStatusSelect = true;
        $scope.erpBudgetCodeSelect = true;
        $scope.unbudgetedItemsSelect = false;
        $scope.excludeRevisionSelect = true;
        break;
      case 'EXCEL_GWO_SUMMARY_REPORT':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = false;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = true;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = true;
        $scope.poStatusSelect = false;
        $scope.countrySelect = true;
        $scope.modeOfPurchaseSelect = false;
        $scope.filterDateBySelect = false;
        $scope.allowedPrRange = false;
        $scope.itemCategorySelect = false;
        $scope.expenseTypeSubTypeSelect = true;
        $scope.monthYearSelect = true;
        $scope.unbudgetedItemsSelect = false;
        $scope.excludeRevisionSelect = false;
        break;
      case 'EXCEL_APPROVED_OPEX_PR_SUMMARY_REPORT':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = false;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = true;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = true;
        $scope.modeOfPurchaseSelect = false;
        $scope.allowedPrRange = false;
        $scope.allowedPoRange = false;
        $scope.erpBudgetCodeSelect = true;
        $scope.unbudgetedItemsSelect = true;
        $scope.monthYearSelect = true;
        $scope.multiCompaniesBudgetCodeSearch = true;
        $scope.excludeRevisionSelect = false;
        break;
      case 'EXCEL_APPROVED_CAPEX_PR_SUMMARY_REPORT_ERP_BUDGET_CODE':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = false;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = true;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = true;
        $scope.modeOfPurchaseSelect = false;
        $scope.allowedPrRange = false;
        $scope.allowedPoRange = false;
        $scope.erpBudgetCodeSelect = true;
        $scope.unbudgetedItemsSelect = true;
        $scope.monthYearSelect = true;
        $scope.multiCompaniesBudgetCodeSearch = true;
        $scope.excludeRevisionSelect = false;
        break;
      case 'EXCEL_APPROVED_CAPEX_PR_SUMMARY_REPORT_ASSET_CATEGORY':
        $scope.buSelect = true;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = false;
        $scope.companyListSelect = true;
        $scope.reportingCurrencySelect = true;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = true;
        $scope.modeOfPurchaseSelect = false;
        $scope.allowedPrRange = false;
        $scope.allowedPoRange = false;
        $scope.erpBudgetCodeSelect = false;
        $scope.unbudgetedItemsSelect = false;
        $scope.excludeRevisionSelect = false;
        $scope.monthYearSelect = true;
        $scope.multiCompaniesBudgetCodeSearch = true;
        break;
      case 'EXCEL_AUDIT_TRAIL_REPORT':
        $scope.auditModuleSelect = true;
        $scope.auditModuleSubSelect = true;
        $scope.dateSelect = true;
        $scope.dateSelectStartLabel = 'Date (From)';
        $scope.dateSelectEndLabel = 'Date (To)';
        $scope.getCompanies('all');
        break;
      case 'EXCEL_TENDER_LISTING_REPORT':
        $scope.tenderStatusSelect = true;
        $scope.tenderGroupsSelect = true;
        $scope.dateSelect = true;
        $scope.dateSelectStartLabel = 'Tender Validity Date (From)';
        $scope.dateSelectEndLabel = 'Tender Validity Date (To)';
        $scope.allowedTenderNumberRange = true;
        $scope.tenderNumberRange.from = undefined;
        $scope.tenderNumberRange.to = undefined;
        loadTenders();
        loadTenderGroups()
        break;
      case 'EXCEL_TENDER_SUMMARY_REPORT':
        $scope.tenderStatusSelect = true;
        $scope.tenderGroupsSelect = true;
        $scope.dateSelect = true;
        $scope.dateSelectStartLabel = 'Tender Validity Date (From)';
        $scope.dateSelectEndLabel = 'Tender Validity Date (To)';
        $scope.allowedTenderNumberRange = true;
        $scope.tenderNumberRange.from = null;
        $scope.tenderNumberRange.to = null;
        loadTenders();
        loadTenderGroups();
        break;
      case 'EXCEL_TENDER_DETAILS_REPORT':
        $scope.dateSelect = true;
        $scope.dateSelectStartLabel = 'Manual Approved Date (From)';
        $scope.dateSelectEndLabel = 'Manual Approved Date (To)';
        $scope.unbudgetedItemsSelect = false;
        $scope.excludeRevisionSelect = false;
        //to initialize the tender selection for default date (current days)
        onSelectDate();

        break;
      case 'EXCEL_BUDGET_CONSUMPTION_REPORT':
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = false;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.modeOfPurchaseSelect = false;
        $scope.allowedPrRange = false;
        $scope.allowedPoRange = false;
        $scope.buSelect = true;
        $scope.countrySelect = true;
        $scope.companyListSelect = true;
        $scope.prTypeSelect = true;
        $scope.erpBudgetCodeSelect = true;
        $scope.budgetConsumptionStatusSelect = true;
        $scope.assetCategorySelect = true;
        $scope.multiCompaniesBudgetCodeSearch = false;
        $scope.excludeRevisionSelect = false;
        break;
      case 'EXCEL_PURCHASE_LEAD_TIME_REPORT':
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = false;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.modeOfPurchaseSelect = false;
        $scope.allowedPrRange = false;
        $scope.allowedPoRange = false;
        $scope.buSelect = true;
        $scope.countrySelect = true;
        $scope.companyListSelect = true;
        $scope.prTypeSelect = true;
        $scope.erpBudgetCodeSelect = false;
        $scope.budgetConsumptionStatusSelect = true;
        $scope.assetCategorySelect = true;
        $scope.multiCompaniesBudgetCodeSearch = true;
        $scope.expenseTypeSelect = true;
        $scope.excludeRevisionSelect = false;
        // date related fields
        $scope.dateTypeSelect = false;
        $scope.dateSelect = true;
        $scope.dateSelectStartLabel = 'Date (From)';
        $scope.dateSelectEndLabel = 'Date (To)';
        // new fields for Purchase Lead Time Report
        $scope.filterDocNumberSelect = true;
        $scope.filterPurchaseLeadTimeDateBySelect = true;
        break;
      default:
        $scope.buSelect = false;
        $scope.supplierSelect = false;
        $scope.spendReportBasedOn = false;
        $scope.dateTypeSelect = false;
        $scope.dateSelect = false;
        $scope.companyListSelect = false;
        $scope.reportingCurrencySelect = false;
        $scope.modeOfDisposalSelect = false;
        $scope.prStatusSelect = false;
        $scope.poStatusSelect = false;
        $scope.countrySelect = false;
        $scope.modeOfPurchaseSelect = false;
        break;
    }
  }

  function loadUsers(val, option) {
    var moduleParam = {
      module: 'users'
    };

    var additionalParams = {
      'criteria[0][display_name]': val,
      'criteria[0][is_metabuyer_user]': true,
      criteria_operator: 'and',
      offset: 10
    };

    if (option === undefined) {
      additionalParams['criteria[0][status]'] = 1;
    }

    if (option === 'approvalLimit') {
      additionalParams['criteria[0][approval_limits|0]'] = true;
    }

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams
    }).then(function (response) {
      $scope.loading = false;
      return response.data.content.data.map(function (item) {
        $scope.loading = false;
        return item;
      });
    }, function (error) {
      globalFunc.objectErrorMessage(error);
    });
  }

  function loadUsersHasApprovalLimit(val){
    return loadUsers(val, 'approvalLimit');
  }

  function clearUserLine(index) {
    $scope.selectedUserArray.splice(index, 1);
  }

  function selectUser(user) {
    if (!!validateUser(user)) {
      $scope.selectedUserArray.push({
        _id: user._id,
        display_name: user.display_name,
        email: user.email,
      });
    }
  }

  function validateUser(user) {
    if ($scope.selectedUserArray.length !== 0) {
      for (var i = 0; i < $scope.selectedUserArray.length; i++) {
        if ($scope.selectedUserArray[i]._id === user._id) {
          toastr.error('This user is already added');
          return false;
        }
      }
    }
    return true;
  }

  function onSelectBusinessUnit(businessUnit) {
    // resetCompaniesSelection();
    $scope.businessUnitSelected = true;
    selectedBU = businessUnit;
    $scope.inputId = businessUnit._id;
    loadBuAndIndustryCompanies();
  }

  function onSelectSupplier(supplier) {
    // resetCompaniesSelection();
    $scope.tendersFilter = [];
    $scope.supplierSelected = true;
    selectedSupplier = supplier;
    $scope.inputId = supplier._id;
    if ($scope.tenderGroupsFromCompaniesFilter.length > 0) {
      $scope.tenderGroupsFromCompaniesFilter.forEach(function(item) {
        loadTendersFilter(item);
      });
    } else {
      loadTendersFilter();
    }
  }

  function onSelectCompany(company) {
    // resetCompaniesSelection();
    $scope.companySelected = true;
    selectedCompanies = company;
    $scope.inputId = company._id;
  }

  function onSelectDateType(type) {
    $scope.input_date_type = type;
  }
  //endregion

  function onSelectDate(date, which) {

    if ($scope.reports.type.code === 'EXCEL_TENDER_DETAILS_REPORT') {

      var startDate = moment($scope.startingDate.date).set({h: 0, m: 1}).valueOf();
      var endDate = moment($scope.endDate.date).set({h: 23, m: 59}).valueOf();

      var additionalParams = {
        'criteria[0][$operator]': 'and',
        'criteria[0][detail|approved_date][from]': startDate,
        'criteria[0][detail|approved_date][to]': endDate,
        'criteria[0][detail|status]': 'APPROVED',
        offset: 200
      };

      var moduleParam = {
        module: 'tender'
      };

      return $http.get($filter('format')(pathConstants.apiUrls.advancedSearch, moduleParam), {
        params: additionalParams
      }).then(function (response) {
        $scope.loading = false;
        $scope.tenderNumberLists = [];
        return response.data.content.data.map(function (item) {
          $scope.loading = false;
          $scope.tenderNumberLists.push({
            value : item._id,
            label : item.code + ' (' + item.detail.description +')',
            descr : item.code
          });
        })
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      })

    }
  }

  function openDatepicker($event, which) {
    if ($scope.datepickerOpened[which]) {
      $event.preventDefault();
      $event.stopPropagation();
    } else {
      $timeout(function () {
        $scope.datepickerOpened[which] = true;
      })
    }

    if (!!$scope.endDate && !!$scope.endDate.date) {
      $scope.startDateOptions = {
        formatYear: 'yy',
        maxDate: $scope.endDate.date,
      };
    }

    if (!!$scope.startingDate && !!$scope.startingDate.date) {
      $scope.endDateOptions = {
        formatYear: 'yy',
        minDate: $scope.startingDate.date
      };
    }

    if (!!$scope.reports.type && !!$scope.reports.type.code && $scope.reports.type.code !== 'BUDGET') {
      $scope.startDateOptions.maxDate = new Date();
      $scope.endDateOptions.maxDate = new Date();
    }

    if ($scope.reports.type.code === 'EXCEL_TENDER_LISTING_REPORT' || $scope.reports.type.code === 'EXCEL_TENDER_SUMMARY_REPORT' || $scope.reports.type.code === 'EXCEL_TENDER_DETAILS_REPORT') {
      $scope.startDateOptions.maxDate = null;
      $scope.endDateOptions.maxDate = null;
    }
  }

  //region Functions for generating array for payload
  function extractCompanyId() {
    $scope.company_ids = [];
    _.forEach($scope.selectedCompanies, function (company) {
      if (!!company && !!company._id)
        $scope.company_ids.push(company._id);
    });
  }

  function extractTender() {
    $scope.tenderIds = [];
    _.forEach($scope.selectedTenderNames, function (tender) {
      $scope.tenderIds.push(tender.value);
    });
  }

  function extractTenderFilter() {
    $scope.tenderIdFilter = [];
    _.forEach($scope.selectedTenders, function (tender) {
      $scope.tenderIdFilter.push(tender.value);
    });
  }

  function extractCompanyCode() {
    $scope.companyCodes = [];
    _.forEach($scope.selectedCompanies, function (company) {
      if (!!company && !!company.code)
        $scope.companyCodes.push(company.code);
    });
  }

  function extractSupplierCode() {
    $scope.supplierCodes = [];
    _.forEach($scope.selectedSuppliers, function (supplier) {
      if (!!supplier && !!supplier.basic_info && !!supplier.basic_info.code)
        $scope.supplierCodes.push(supplier.basic_info.code);
    });
  }

  /**
   * Extract cost center code
   */
  function extractCostCenterCode() {
    $scope.costCenterCodes = [];
    _.forEach($scope.selectedCostCenters, function (costCenter) {
      if (!!costCenter && !!costCenter.code)
        $scope.costCenterCodes.push(costCenter.code);
    });
  }

  function extractErpBudgetCode() {
    $scope.erpBudgetCode = [];
    _.forEach($scope.selectedErpBudgetCodes, function (budget) {
      if (!!budget && !!budget.erp_budget_id)
        $scope.erpBudgetCode.push(budget.erp_budget_id);
    });
  }
  //endregion

  //region Functions for getting companies and supplier listing
  function getCompanies(businessUnit) {
    var param = {};
    var resource;
    if (businessUnit === 'all') {
      resource = searchModule;
      param.offset = 0;
      param.module = 'companies';
      param.order = 1;
      param.order_by = 'descr';
    } else {
      resource = singleBU;
      param.id = businessUnit;
    }


    $scope.loading = true;

    resource.get(param,
      function (success) {
        if (!!success.content.data.companies) {
          $scope.companyList = success.content.data.companies;
        } else {
          $scope.companyList = success.content.data;
        }

        if ($scope.selectedReportType.code === 'EXCEL_AUDIT_TRAIL_REPORT') {
          if (!isSystemReportManager) {
            $scope.companyList = filterUsersData($scope.companyList, 'code', allowedCompaniesForRole, true);
          }
        } else {
          if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN') ||
            isSystemReportManager
          ) {
            $scope.companyList = filterUsersData($scope.companyList, 'code', allowedCompaniesForRole, true);
          }
        }

        $scope.loading = false;
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }


  function getSuppliers(val) {
    if (!!val && val.length > 2) {

      var params = {
        module: 'suppliers',
        'criteria[0][basic_info|descr]': val,
        criteria_operator: 'and',
        offset: 10
      };

      searchModule.get(
        params,
        function (resource) {
          if (resource.content.data.length === 0) {
            $scope.searchedSuppliers = [];
          } else {
            $scope.searchedSuppliers = resource.content.data;
          }
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      );
    }
  }

  function onSupplierSelect(supplier, action) {
    if (action === 'assign') {
      $scope.tendersFilter = [];
      $scope.selectedSuppliers.push(supplier);
      $scope.selectedSuppliers.forEach(function(supplier) {
      selectedSupplier = supplier;
      if ($scope.tenderGroupsFromCompaniesFilter.length > 0) {
        $scope.tenderGroupsFromCompaniesFilter.forEach(function(item) {
          loadTendersFilter(item);
        });
        } else {
          loadTendersFilter();
        }
      });

    } else {
      $scope.selectedSuppliers = globalFunc.removeValueFromArray($scope.selectedCompanyArray, '_id', supplier._id);
    }
  }

  function loadAssetCategoryData() {
    if (!$scope.assetCategorySelect || $scope.selectedCompanies.length === 0) {
      return;
    }

    if ($scope.selectedCompanies.length === 1) {
      assetCategoryList.get(
        {
          code: $scope.selectedCompanies[0].code
        },
        function (resource) {
          if (!!resource.data) {
            _.forEach(resource.data, function (assetCategory) {
              $scope.assetCategoryOptions.push({
                value: assetCategory.code,
                descr: assetCategory.name
              })
            })
          }
        }
      )
    }
  }

  //region Functions for checking permission and validation
  function hasSupplierTagPermission() {
    return (!!UserPermissions.checkPermissionsAccess(profile, 'SupplierTag', 'Create') &&
      !!UserPermissions.checkPermissionsAccess(profile, 'SupplierTag', 'Update'));
  }

  function checkForGlobalReportRole() {
    var role = globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Report Manager');
    if (!role)
      return false;

    if (!!role.company_code && role.company_code.length > 0)
      return false;

    return true;
  }

  /**
   * validation for report generation form
   * @returns {boolean}
   */
  function validate() {
    extractCompanyId();
    extractCompanyCode();
    extractSupplierCode();
    extractCostCenterCode();
    extractErpBudgetCode();

    if (!$scope.reports.type) {
      return false;
    }

    // UI filters validation only, this is for display
    if (!$scope.searchMode &&
      $scope.reports.type.code !== 'EXCEL_AUDIT_TRAIL_REPORT' &&
      $scope.reports.type.code !== 'EXCEL_APPROVER_LIMIT_TRACE_REPORT' &&
      $scope.reports.type.code !== 'EXCEL_TENDER_LISTING_REPORT' &&
      $scope.reports.type.code !== 'EXCEL_TENDER_SUMMARY_REPORT' &&
      $scope.reports.type.code !== 'EXCEL_TENDER_DETAILS_REPORT'
  ) {
      toastr.error(lang.validation.required.selection + 'filter company by');
      return false;
    } else {
      if ($scope.searchMode === 'bill_company') {
        if (!$scope.shadowCompanySearchField.text) {
          toastr.error(lang.validation.required.selection + 'a billing company');
          return false;
        }
      } else if ($scope.searchMode === 'IBU') {
        if (!$scope.reports.industryId) {
          toastr.error(lang.validation.required.selection + 'an industry');
          return false;
        }

        if (!$scope.reports.businessId) {
          toastr.error(lang.validation.required.selection + 'a business unit');
          return false;
        }
      }
    }

    if (_.isEmpty($scope.selectedCompanies) &&
        (($scope.reports.type.code !== 'EXCEL_AUDIT_TRAIL_REPORT' && $scope.reports.type.code !== 'EXCEL_TENDER_LISTING_REPORT' && $scope.reports.type.code !== 'EXCEL_TENDER_SUMMARY_REPORT' && $scope.reports.type.code !== 'EXCEL_TENDER_DETAILS_REPORT' && $scope.reports.type.code !== 'EXCEL_APPROVER_LIMIT_TRACE_REPORT') ||
          ($scope.auditModuleSelect && $scope.auditModule.selected && $scope.auditModule.selected.value === 'company'))) {
      toastr.error(lang.validation.required.selection + 'companies');
      return false;
    }

    if (!validateAuditTrailReport()) {
      return false;
    }

    if (!validateDisposalReport()) {
      return false;
    }

    if (!validatePurchaseLeadTimeReport()) {
      return false;
    }

    if (!validatePurchaseStatusReport()) {
      return false;
    }

    if (!validatePRDetailsReport()) {
      return false;
    }

    if (!validatePODetailsReport()) {
      return false;
    }

    if (!validateTenderListingReport()){
      return false;
    }

    if (!validateTenderSummaryReport()){
      return false;
    }

    if (!validateTenderDetailReport()){
      return false;
    }

    if (!validateBudgetConsumptionReport()){
      return false;
    }

    if (!validateApproverLimitTraceReport()){
      return false;
    }
    if (!validateApprovedCapexPRSummaryReportErpBudgetCode()){
      return false;
    }

    if (!validateApprovedCapexPRDetailReport()){
      return false;
    }

    if (!validateApprovedOpexPRDetailReport()){
      return false;
    }

    if (!validateApprovedOpexPRSummaryReport()){
      return false;
    }

    return true;
  }

  function validatePRDetailsReport() {
    if ($scope.reports.type.code === 'EXCEL_PR_DETAIL_REPORT') {
      if (_.isEmpty($scope.selectedItemCategories)) {
        toastr.error(lang.validation.required.selection + ' an item category');
        return false;
      }
      if (getSelectedCompaniesCount() === 1) {
        if ($scope.prRange.from === null && $scope.prRange.to !== null) {
          toastr.error(lang.validation.required.input + 'PR number range from');
          return false;
        }
        if ($scope.prRange.to === null && $scope.prRange.from !== null) {
          toastr.error(lang.validation.required.input + 'PR number range to');
          return false;
        }
      }
      if (_.isEmpty($scope.reportingCurrency.selected)) {
        toastr.error(lang.validation.required.selection + ' a reporting currency');
        return false;
      }
    }
    return true;
  }

  function validatePODetailsReport() {
    if ($scope.reports.type.code === 'EXCEL_PO_DETAIL_REPORT') {
      if (_.isEmpty($scope.selectedItemCategories)) {
        toastr.error(lang.validation.required.selection + ' an item category');
        return false;
      }
      if (getSelectedCompaniesCount() === 1) {
        if ($scope.poRange.from === undefined) {
          toastr.error(lang.validation.required.input + 'PO number range from');
          return false;
        }
        if ($scope.poRange.to === undefined) {
          toastr.error(lang.validation.required.input + 'PO number range to');
          return false;
        }
      }
      if (_.isEmpty($scope.reportingCurrency.selected)) {
        toastr.error(lang.validation.required.selection + ' a reporting currency');
        return false;
      }
    }
    return true;
  }

  function validatePurchaseStatusReport() {
    if ($scope.reports.type.code === 'EXCEL_PURCHASE_STATUS_REPORT') {
      if (_.isEmpty($scope.reportModule.selected)) {
        toastr.error(lang.validation.required.selection + 'a module(PR or PO)');
        return false;
      }
      if (getSelectedCompaniesCount() === 1) {

        if (!($scope.itemCategoryCheckbox || $scope.itemCodeRangeCheckbox || $scope.itemCodeCheckbox)) {
          toastr.error(lang.validation.required.input + 'an item filter');
          return false;
        } else {
          if ($scope.itemCategoryCheckbox && _.isEmpty($scope.selectedItemCategories)) {
            toastr.error(lang.validation.required.selection + 'an item category');
            return false;
          }

          if ($scope.itemCodeRangeCheckbox) {
            if (!(!!$scope.itemCodeRange.to && !!$scope.itemCodeRange.from)) {
              toastr.error(lang.validation.required.input + 'item code range');
              return false;
            }
          }

          if ($scope.itemCodeCheckbox && _.isEmpty($scope.selectedCompanyItem)) {
            toastr.error(lang.validation.required.selection + 'item code');
            return false;
          }
        }

        if ($scope.reportModule.selected.key === 'pr') {
          if ($scope.prRange.from === null && $scope.prRange.to !== null) {
            toastr.error(lang.validation.required.input + 'PR number range from');
            return false;
          }
          if ($scope.prRange.to === null && $scope.prRange.from !== null) {
            toastr.error(lang.validation.required.input + 'PR number range to');
            return false;
          }
        } else if ($scope.reportModule.selected.key === 'po') {
          if ($scope.poRange.from === undefined) {
            toastr.error(lang.validation.required.input + 'PO number range from');
            return false;
          }
          if ($scope.poRange.to === undefined) {
            toastr.error(lang.validation.required.input + 'PO number range to');
            return false;
          }
        }
      } else {
        if (_.isEmpty($scope.selectedItemCategories)) {
          toastr.error(lang.validation.required.selection + ' an item category');
          return false;
        }
      }
      if (_.isEmpty($scope.reportingCurrency.selected)) {
        toastr.error(lang.validation.required.selection + ' a reporting currency');
        return false;
      }
    }
    extractTenderFilter();
    return true;
  }

  function validateDisposalReport() {
    if ($scope.reports.type.code === 'EXCEL_DISPOSAL_REPORT') {
      if (_.isEmpty($scope.filterDateBy.selected)) {
        toastr.error(lang.validation.required.selection + 'a date filter');
        return false;
      }
    }
    return true;
  }

  function validateAuditTrailReport() {
    if ($scope.reports.type.code === 'EXCEL_AUDIT_TRAIL_REPORT') {
      if (!($scope.auditModuleSubSelect && $scope.auditModuleSub.selected)) {
        toastr.error(lang.validation.required.selection + 'sub module');
        return false;
      }
      if ($scope.auditModule.selected.value === 'user') {
        if (_.isEmpty($scope.selectedUserArray)) {
          toastr.error(lang.validation.required.selection + 'user');
          return false;
        }
      }
    }
    return true;
  }

 function validatePurchaseLeadTimeReport() {
    if ($scope.reports.type.code === 'EXCEL_PURCHASE_LEAD_TIME_REPORT') {
      if (_.isEmpty($scope.docNumberSearch.selected) && $scope.filterDocNumber.selected.key !== 'NONE') {
        toastr.error(lang.validation.required.selection + 'a document number');
        return false;
      }

      if ($scope.filterPurchaseLeadTimeDateBy.selected === null && $scope.filterDocNumber.selected.key === 'NONE') {
        toastr.error(lang.validation.required.selection + 'filter date by');
        return false;
      }
    }
    return true;
  }

  function validateBudgetConsumptionReport() {
    if ($scope.reports.type.code === 'EXCEL_BUDGET_CONSUMPTION_REPORT') {
      if (getSelectedCompaniesCount() === 0) {
        toastr.error(lang.validation.required.selection + 'companies');
        return false;
      }
      if (_.isEmpty($scope.selectedErpBudgetCodes) && getSelectedCompaniesCount() <= 1) {
          toastr.error(lang.validation.required.selection + 'erp budget code');
          return false;
      }
    }
    return true;
  }

  function validateApproverLimitTraceReport () {
    if ($scope.reports.type.code !== 'EXCEL_APPROVER_LIMIT_TRACE_REPORT') {
      return true;
    }
    if ($scope.approvalLimitTraceReportPayload.userIdSelected === null ||
        !$scope.approvalLimitTraceReportPayload.userIdSelected) {
        toastr.error(lang.validation.required.selection + 'user');
        return false;
    }
    if ($scope.approvalLimitTraceReportPayload.currencyCode === null ||
        !$scope.approvalLimitTraceReportPayload.currencyCode) {
        toastr.error(lang.validation.required.selection + ' limit currency');
        return false;
    }
    if ($scope.approvalLimitTraceReportPayload.approvalLimitUuid === null ||
        !$scope.approvalLimitTraceReportPayload.approvalLimitUuid) {
        toastr.error(lang.validation.required.selection + ' approval limit selection');
        return false;
    }
    return true;
  }
  
  function validateTenderListingReport() {
    if ($scope.reports.type.code === 'EXCEL_TENDER_LISTING_REPORT') {
      if (!$scope.startingDate.date) {
        toastr.error(lang.validation.required.input + 'Tender validity date (from)');
        return false;
      }
      if (!$scope.endDate.date) {
        toastr.error(lang.validation.required.input + 'Tender validity date (to)');
        return false;
      }
      if ($scope.startingDate.date > $scope.endDate.date) {
        toastr.error('Please select another date for Tender validity date (to)');
        return false;
      }
      if (_.isEmpty($scope.selectedTenderStatus)) {
        toastr.error(lang.validation.required.input + 'Tender status');
        return false;
      }
      if (_.isEmpty($scope.selectedTenderGroups)) {
        toastr.error(lang.validation.required.input + 'Tender groups');
        return false;
      }
      if ($scope.tenderNumberRange.from !== undefined || $scope.tenderNumberRange.to !== undefined) {
        if ($scope.tenderNumberRange.from === undefined) {
          toastr.error(lang.validation.required.input + 'Tender number range from');
          return false;
        }
        if ($scope.tenderNumberRange.to === undefined) {
          toastr.error(lang.validation.required.input + 'Tender number range to');
          return false;
        }
        if (($scope.tenderNumberRange.from !== null || $scope.tenderNumberRange.to !== null) && 
            $scope.tenderNumberRange.from >= $scope.tenderNumberRange.to) {
          toastr.error('Tender number range from can\'t be more than or equals to Tender number range to')
          return false;
        }
      }
    }
    return true;
  }

  function validateTenderSummaryReport() {

    if ($scope.reports.type.code === 'EXCEL_TENDER_SUMMARY_REPORT') {
      if (!$scope.startingDate.date) {
        toastr.error(lang.validation.required.input + 'Tender validity date (from)');
        return false;
      }
      if (!$scope.endDate.date) {
        toastr.error(lang.validation.required.input + 'Tender validity date (to)');
        return false;
      }
      if ($scope.startingDate.date > $scope.endDate.date) {
        toastr.error('Please select another date for Tender validity date (to)');
        return false;
      }
      if ($scope.selectedTenderStatus.length === 0) {
        toastr.error(lang.validation.required.input + 'Tender status');
        return false;  
      }
      if ($scope.selectedTenderGroups.length === 0) {
        toastr.error(lang.validation.required.input + 'Tender groups');
        return false;
      }
      if ($scope.tenderNumberRange.from || $scope.tenderNumberRange.to) {
        if (!$scope.tenderNumberRange.from) {
          toastr.error(lang.validation.required.input + 'Tender number range from');
          return false;
        }
        if (!$scope.tenderNumberRange.to) {
          toastr.error(lang.validation.required.input + 'Tender number range to');
          return false;
        }
        if ($scope.tenderNumberRange.from >= $scope.tenderNumberRange.to) {
          toastr.error('Tender number range from can\'t be more than or equals to Tender number range to')
          return false;
        }
      }
    }

    extractTender();
    return true;
  }

  function validateTenderDetailReport() {

    if ($scope.reports.type.code === 'EXCEL_TENDER_DETAILS_REPORT') {
      if (!$scope.startingDate.date) {
        toastr.error(lang.validation.required.input + 'Tender validity date (from)');
        return false;
      }
      if (!$scope.endDate.date) {
        toastr.error(lang.validation.required.input + 'Tender validity date (to)');
        return false;
      }
      if ($scope.startingDate.date > $scope.endDate.date) {
        toastr.error('Please select another date for Tender validity date (to)');
        return false;
      }
      if ($scope.selectedTenderNumber.selected.value === undefined) {
        toastr.error('Please select Tender number');
        return false;
      }
    }

    return true;
  }
  //endregion


  function validateApprovedCapexPRSummaryReportErpBudgetCode() {
    if ($scope.reports.type.code === 'EXCEL_APPROVED_CAPEX_PR_SUMMARY_REPORT_ERP_BUDGET_CODE') {
      if (_.isEmpty($scope.selectedErpBudgetCodes) && getSelectedCompaniesCount() <= 1) {
        toastr.error(lang.validation.required.selection + 'erp budget code');
        return false;
      }
    }
    return true;
  }

  function validateApprovedCapexPRDetailReport() {
    if ($scope.reports.type.code === 'EXCEL_APPROVED_CAPEX_PR_DETAIL_REPORT') {
      if (_.isEmpty($scope.selectedErpBudgetCodes) && getSelectedCompaniesCount() <= 1) {
        toastr.error(lang.validation.required.selection + 'erp budget code');
        return false;
      }
    }
    return true;
  }

  function validateApprovedOpexPRDetailReport() {
    if ($scope.reports.type.code === 'EXCEL_APPROVED_OPEX_PR_DETAIL_REPORT') {
      if (_.isEmpty($scope.selectedErpBudgetCodes) && getSelectedCompaniesCount() <= 1) {
        toastr.error(lang.validation.required.selection + 'erp budget code');
        return false;
      }
    }
    return true;
  }

  function validateApprovedOpexPRSummaryReport() {
    if ($scope.reports.type.code === 'EXCEL_APPROVED_OPEX_PR_SUMMARY_REPORT') {
      if (_.isEmpty($scope.selectedErpBudgetCodes) && getSelectedCompaniesCount() <= 1) {
        toastr.error(lang.validation.required.selection + 'erp budget code');
        return false;
      }
    }
    return true;
  }

  //region Functions for generating report and check
  function downloadReport(hash) {
    var newUrl = $filter('format')(pathConstants.apiUrls.reports.download, {
      id: hash
    });
    window.open(newUrl, '_self');
  }

  /**
   * Function to check the export status with intervals
   * @param id
   */
  function checkReport(id) {

    checkReportStatus.get({
      id: id
    },
      function (resource) {
        if (!!resource.content && !!resource.content.data &&
          !!resource.content.message && !!resource.content.data.status) {

          /**
           * Clearing the toastr before every call for status checking to simulate 'refreshing' the toastr
           */
          toastr.clear();

          var checkingStatus = true;

          /**
           * Status for report generation
           * 0 = PENDING
           * 1 = IN_PROGRESS
           * 2 = DONE
           * 3 = FAILED
           * 4 = CANCELLED
           */
          switch (resource.content.data.status) {
            case 0:
              toastr.info(resource.content.message, {
                timeOut: 0,
                extendedTimeOut: 0
              });
              break;
            case 1:
              toastr.info(resource.content.message, {
                timeOut: 0,
                extendedTimeOut: 0
              });
              break;
            case 2:
              if (!!resource.content.data.hash) {
                toastr.success(resource.content.message, {
                  timeOut: 1500,
                  extendedTimeOut: 0,
                  onClose: downloadReport(resource.content.data.hash)
                });
              } else {
                toastr.error(resource.content.message, {
                  timeOut: 0,
                  extendedTimeOut: 0
                });
              }
              checkingStatus = false;
              $interval.cancel($scope.startChecking);
              break;
            case 3:
              toastr.error(resource.content.message, {
                timeOut: 0,
                extendedTimeOut: 0
              });
              checkingStatus = false;
              $interval.cancel($scope.startChecking);
              break;
            case 4:
              toastr.info(resource.content.message, {
                timeOut: 0,
                extendedTimeOut: 0
              });
              checkingStatus = false;
              $interval.cancel($scope.startChecking);
              break;
          }
          $scope.checkingReportStatus = checkingStatus;
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    )
  }

  function resetAfterReportGenSubmit() {
    $scope.checkingReportStatus = false;
    $scope.input_date_type = '';
    $scope.supplierSelect = '';
    $scope.selectedSuppliers = '';
    $scope.buSelect = '';
    $scope.reports.type = '';
    $scope.reports.businessId = '';
    $scope.reports.industryId = '';
    $scope.reports.companyCountry = $scope.countries[0];
    $scope.selectedCompanies = [];
    $scope.selectedItemCategories = [];
    $scope.selectedReportType = {};
    selectedBU = undefined;
    $scope.spendReportBasedOn = false;
    $scope.dateTypeSelect = false;
    $scope.dateSelect = false;
    $scope.monthYearSelect = false;
    $scope.monthYear = {
      date: new Date()
    };
    $scope.startingDate = {
      date: new Date()
    };
    $scope.endDate = {
      date: new Date()
    };
    $scope.budgetYear = {
      date: new Date()
    };
    $scope.selectedCostCenters = [];
    $scope.selectedBudgetType.list = null;
    $scope.companyList = [];
    $scope.reportingCurrencySelect = false;
    $scope.reportingCurrency = {
      selected: $scope.reportingCurrencyList[0]
    };
    $scope.modeOfDisposalSelect = false;
    $scope.modeOfDisposal = {
      selected: null
    };
    $scope.selectedModeOfDisposal = {
      selected: $scope.modeOfDisposalsList[0]
    };
    $scope.prStatusSelect = false;
    $scope.prStatus = {
      selected: $scope.prStatusSelection[0]
    };
    $scope.poStatusSelect = false;
    $scope.poStatus = {
      selected: $scope.poStatusSelection[0]
    };

    $scope.modeOfPurchaseSelect = false;
    $scope.modeOfPurchase = {
      selected: $scope.modeOfPurchaseList[0]
    };

    $scope.countrySelect = false;

    $scope.reportingModuleSelect = false;
    $scope.reportModule = {
      selected: null
    };

    $scope.prTypeSelect = false;
    $scope.prType = {
      selected: $scope.prTypeList[0]
    };

    $scope.expenseTypeSelect = false;
    $scope.expenseType = {
      selected: $scope.expenseTypeList[0]
    };

    $scope.prApproverSelect = false;
    $scope.prApproverSearch = {
      search: ''
    };
    $scope.prApprover = null;

    $scope.itemCodeSelect = false;
    $scope.selectedCompanyItem = null;
    $scope.itemSearchText = '';

    $scope.allowedItemCodeRange = false;
    $scope.itemCodeRange = {
      from: 0,
      to: 0
    };

    $scope.singleSupplierSelect = false;

    $scope.filterDateBySelect = false;
    $scope.filterDateBy = {
      selected: null
    };

    $scope.itemCategorySelect = false;
    $scope.poRequestorSelect = false;
    $scope.tenderListSelect = false;

    $scope.shadowCompanySearchField = {
      text: ''
    };

    $scope.tenderNumberSearchField = {
      text: ''
    };

    $scope.tenderNameSearchField = {
      text: ''
    };

    $scope.singleSupplier = {
      selected: {
        _id: null,
        basic_info: {
          descr: 'All'
        }
      }
    };

    $scope.prRange = {
      from: null,
      to: null
    };

    $scope.poRange = {
      from: 0,
      to: 0
    };

    $scope.expenseTypeCategorySelect = false;
    $scope.expenseTypeCategory = {
      selected: $scope.expenseTypeCategoryOptions[0]
    };

    $scope.budgetRevisedStatusSelect = false;
    $scope.budgetRevisedStatus = {
      selected: $scope.budgetRevisedStatusOptions[0]
    };

    $scope.budgetConsumptionStatusSelect = false;
    $scope.budgetConsumption = {
      selected: $scope.budgetConsumptionStatusList[0]
    };

    $scope.assetCategorySelect = false;
    $scope.assetCategory = {
      selected: $scope.assetCategoryOptions[0]
    };

    $scope.budgetStatusSelect = false;
    $scope.budgetStatus = {
      selected: $scope.budgetStatusOptions[0]
    };

    $scope.erpBudgetCodeSelect = false;
    $scope.unbudgetedItemsSelect = false;
    $scope.excludeRevisionSelect = false;
    $scope.prApprover = null;
    $scope.docNumberSearch = {
      selected: ''
    };

    $scope.expenseTypeSubTypeSelect = false;
    $scope.expenseTypeSubType = {
      selected: $scope.expenseTypeSubtypeOptions[0]
    };

    $scope.filterDocNumberSelect = false;
    $scope.filterDocNumber = {
      selected: $scope.filterDocNumberList[0]
    };

    $scope.filterPurchaseLeadTimeDateBySelect = false;
    $scope.filterPurchaseLeadTimeDateBy = {
      selected: null
    };

    $scope.selectedBilingCompany = null;
    $scope.tenderStatusSelect = false;
    $scope.tenderGroupsSelect = false;
    $scope.allowedTenderNumberRange = false;
    $scope.selectedBillingCompany = null;

    if ($scope.selectedTenderStatus.length > 0) {
      _.forEach($scope.tenderStatusOptions, function(tenderStatus) {
        tenderStatus.ticked = false;
      })

      $scope.selectedTenderStatus = [];
    }

    if ($scope.selectedTenderGroups.length > 0) {
      _.forEach($scope.tenderGroupsList, function(tenderGroup) {
        tenderGroup.ticked = false;
      })

      $scope.selectedTenderGroups = [];
      $scope.tenderGroupsList = [];
    }

    $scope.selectedTenderNumber = {
      selected: null
    };

    $scope.tenderSuppliers = {
      selected: $scope.tenderSuppliersList[0]
    }
    $scope.tenderNames = {
      selected: $scope.tenderNamesList[0]
    }
    $scope.tenderNumberRange = {
      from: 0,
      to: 0
    }

    if ($scope.selectedTenderNames.length > 0) {
      $scope.selectedTenderNames = [];
      $scope.tenderIds = [];
    }

    if ($scope.selectedTenders.length > 0) {
      $scope.selectedTenders = [];
      $scope.tenderIdFilter = [];
    }
    resetItemCategory();
    resetApproverLimitTraceReport();
    resetTenders();
  }

  /**
   * Report Generation
   */
  function generateReports(startDate, endDate) {
    $scope.checkingReportStatus = true;
    startDate = globalFunc.convertDateToTimestamp(startDate);
    endDate = globalFunc.convertDateToTimestamp(endDate);

    if (!validate()) {
      $scope.checkingReportStatus = false;
    } else {
      var inputDateType = '';
      if ($scope.reports.type.code === 'RECEIVING' || $scope.reports.type.code === 'SPEND') {
        inputDateType = $scope.input_date_type.id;
      }

      var submitData = {
        reportType: $scope.reports.type.code,
        dateFrom: startDate,
        dateTo: endDate,
        country: $scope.reports.companyCountry,
        companyCodes: $scope.companyCodes,
        supplierCodes: $scope.supplierCodes,
        companyIDs: $scope.company_ids,
        costCenterCodes: !!$scope.costCenterCodes ? $scope.costCenterCodes : [],
        budgetType: !!$scope.selectedBudgetType.list && !!$scope.selectedBudgetType.list.id ?
          $scope.selectedBudgetType.list.id : null,
        dateType: inputDateType,
        reportingCurrency: ($scope.reportingCurrencySelect && !!$scope.reportingCurrency.selected)
          ? $scope.reportingCurrency.selected.id : '',
        modeOfDisposal: ($scope.selectedModeOfDisposal && $scope.selectedModeOfDisposal.selected) ?
          $scope.selectedModeOfDisposal.selected.value : '',
        prStatus: $scope.prStatusSelect ? $scope.prStatus.selected.key : '',
        poStatus: $scope.poStatusSelect ? $scope.poStatus.selected.key : '',
        modeOfPurchase: ($scope.modeOfPurchaseSelect && $scope.modeOfPurchase.selected)
          ? $scope.modeOfPurchase.selected.code : '',
        prRangeFrom: ($scope.companyCodes.length === 1 && !!$scope.prRange.from) ? $scope.prRange.from : undefined,
        prRangeTo: ($scope.companyCodes.length === 1 && !!$scope.prRange.to) ? $scope.prRange.to : undefined,
        poRangeFrom: ($scope.companyCodes.length === 1 && !!$scope.poRange.from) ? $scope.poRange.from : undefined,
        poRangeTo: ($scope.companyCodes.length === 1 && !!$scope.poRange.to) ? $scope.poRange.to : undefined,
        itemCategories: !!$scope.itemCategoryCheckbox && !_.isEmpty($scope.selectedItemCategories) ?
          _.map($scope.selectedItemCategories, function (item) { return item.code }) : undefined,
        module: !!$scope.reportModule.selected ? $scope.reportModule.selected.key : undefined,
        prType: !!$scope.prType.selected ? $scope.prType.selected.key : undefined,
        expenseType: !!$scope.expenseType.selected ? $scope.expenseType.selected.code : undefined,
        prLastApprover: !!$scope.prApprover ? $scope.prApprover._id : undefined,
        companyItem: !!$scope.itemCodeCheckbox && !!$scope.itemCodeSelect && !_.isEmpty($scope.selectedCompanyItem) ? {
          company_code: $scope.selectedCompanyItem.company.code,
          item_master_code: $scope.selectedCompanyItem.item_master_code
        } : undefined,
        itemCodeRangeTo: (!!$scope.itemCodeRangeCheckbox && $scope.companyCodes.length === 1 && !!$scope.itemCodeRange.to) ? $scope.itemCodeRange.to : undefined,
        itemCodeRangeFrom: (!!$scope.itemCodeRangeCheckbox && $scope.companyCodes.length === 1 && !!$scope.itemCodeRange.from) ? $scope.itemCodeRange.from : undefined,
        singleSupplier: !!$scope.singleSupplier.selected && $scope.singleSupplier.selected._id ? $scope.singleSupplier.selected._id : undefined,
        filterDateBy: !!$scope.filterDateBy.selected ? $scope.filterDateBy.selected.key : '',
        poRequestor: !!$scope.poRequestor ? $scope.poRequestor._id : undefined,
        expenseTypeCategory: $scope.expenseTypeCategorySelect ? $scope.expenseTypeCategory.selected.value : undefined,
        budgetRevisedStatus: $scope.budgetRevisedStatusSelect ? $scope.budgetRevisedStatus.selected.value : undefined,
        budgetStatus: $scope.budgetStatusSelect ? $scope.budgetStatus.selected.descr : undefined,
        budgetConsumptionStatus: $scope.budgetConsumptionStatusSelect ? $scope.budgetConsumption.selected.value : '',
        assetCategory: $scope.assetCategorySelect ? $scope.assetCategory.selected.value : '',
        budgetYear: $scope.budgetYear.date.getFullYear(),
        budgetStatus: $scope.budgetStatus ? $scope.budgetStatus.selected.descr : undefined,
        //filterDocfield
        docNumberType: !!$scope.filterDocNumber.selected ? $scope.filterDocNumber.selected.key : undefined,
        filterPurchaseLeadTimeDateBy: !!$scope.filterPurchaseLeadTimeDateBy.selected ? $scope.filterPurchaseLeadTimeDateBy.selected.key : undefined,
        docNumber: !!$scope.docNumberSearch.selected ? $scope.docNumberSearch.selected : undefined,
        // Temporarily cast to array for this field
        erpBudgetCode: !!$scope.erpBudgetCode ? $scope.erpBudgetCode : [],
        allowUnbudgeted: !!$scope.unbudgetedCheckbox ? $scope.unbudgetedCheckbox : false,
        excludeRevision: !!$scope.excludeRevisionCheckbox ? $scope.excludeRevisionCheckbox : false,
        expenseTypeSubType: !!$scope.expenseTypeSubTypeSelect ? $scope.expenseTypeSubType.selected.descr : undefined,
        monthYear: $scope.monthYearSelect ? globalFunc.convertDateToTimestamp($scope.monthYear.date) : undefined,
        billingCompanyId: $scope.searchMode === 'bill_company' ? $scope.selectedBillingCompany._id : null,
        billingCompanyDescr: $scope.searchMode === 'bill_company' ? $scope.selectedBillingCompany.descr : null,
        industryId: $scope.searchMode === 'IBU' && !!$scope.selectedIndustry ? $scope.selectedIndustry : null,
        businessUnitId: $scope.searchMode === 'IBU' && !!$scope.selectedBU ? $scope.selectedBU : null,
        auditModule: ($scope.auditModuleSelect && $scope.auditModule.selected)
          ? $scope.auditModule.selected.value : '',
        auditModuleSub: ($scope.auditModuleSubSelect && $scope.auditModuleSub.selected)
          ? $scope.auditModuleSub.selected.value : '',
        userIDs: $scope.selectedUserArray,
        tenderStatus: $scope.tenderStatusSelect ? $scope.selectedTenderStatus : [],
        tenderGroups: $scope.tenderGroupsSelect ? $scope.selectedTenderGroups : [],
        tenderSupplier: $scope.tenderGroupsSelect ? $scope.tenderSuppliers.selected.value : null,
        tenderNumber: $scope.reports.type.code === 'EXCEL_TENDER_DETAILS_REPORT' ? $scope.selectedTenderNumber.selected.descr : '',
        tenderName: $scope.tenderGroupsSelect && $scope.reports.type.code === 'EXCEL_TENDER_SUMMARY_REPORT' ? $scope.tenderNames.selected : '',
        tenderNumberRangeFrom: ($scope.allowedTenderNumberRange && !!$scope.tenderNumberRange.from) ? $scope.tenderNumberRange.from : undefined,
        tenderNumberRangeTo: ($scope.allowedTenderNumberRange && !!$scope.tenderNumberRange.to) ? $scope.tenderNumberRange.to : undefined,
        tenderIds: $scope.tenderIds ? $scope.tenderIds : undefined,
        tenderIdFilter: $scope.tenderIdFilter ? $scope.tenderIdFilter : []
      };

      if ($scope.reports.type.code === 'EXCEL_APPROVER_LIMIT_TRACE_REPORT') {
        submitData['userIdSelected'] = $scope.approvalLimitTraceReportPayload.userIdSelected;
        submitData['currencyCode'] = $scope.approvalLimitTraceReportPayload.currencyCode;
        submitData['approvalLimitUuid'] = $scope.approvalLimitTraceReportPayload.approvalLimitUuid;
      }

      reportsV2.post({},
        submitData,
        function (resource) {
          toastr.info('Report is being generated. The download will start automatically', {
            timeOut: 0,
            extendedTimeOut: 0
          });
          $scope.startChecking = $interval(function () {
            checkReport(resource.content.data[0]);
          }, 5000);
          resetAfterReportGenSubmit();
        },
        function (error) {
          $scope.checkingReportStatus = false;
          globalFunc.objectErrorMessage(error);
        }
      )
    }
  }
  //endregion

  //region Functions for filtering data
  /**
   * filter data based on the params
   * @param array
   * @param filteredKey
   * @param filterData
   * @param reject
   * true or false to reject data
   * @returns {*}
   */
  function filterUsersData(array, filteredKey, filterData, reject) {
    if (filterData.length === 0) return array;

    return _.reject(array, function (val) {
      if (!!reject)
        return filterData.indexOf(val[filteredKey]) === -1;
      else
        return filterData.indexOf(val[filteredKey]) > -1;
    });
  }
  //endregion

  /**
   * Get cost center list when any selected company changes
   */
  function onSelectedCompanyChanges() {
    $scope.costCenterList = [];

    //stop when no company is selected or is not in budget report
    if (!$scope.selectedCompanies.length || $scope.reports.type.code !== 'BUDGET') {
      return;
    }

    extractCompanyCode();

    var params = {
      module: 'cost-center',
      offset: 0,
      criteria_operator: 'or',
      order_by: 'descr',
      order: 1
    };

    _.forEach($scope.companyCodes, function (companyCode, index) {
      params['criteria[company_code][' + index + ']'] = companyCode;
    });

    searchModule.get(
      params,
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.costCenterList = resource.content.data;
          _.forEach($scope.costCenterList, function (costcenter) {
            costcenter.descr = costcenter.descr + ' [' + costcenter.company_name + ']';
          });
        }
      }
    )
  }

  function initialize() {
    $scope.$watch('selectedCompanies.length', function () {
      onSelectedCompanyChanges();
    });

    $scope.countries = globalFunc.countryCode();
    $scope.countries.unshift({
      'code': 'all',
      'descr': 'All'
    });
    $scope.reports.companyCountry = $scope.countries[0];


    if (!!$scope.$parent)
      $scope.$parent.activatedTab = $scope.$parent.tabData[1];
    else
      $scope.activatedTab = $scope.tabData[1];

    $scope.startingDate = {
      date: new Date()
    };
    $scope.endDate = {
      date: new Date()
    };

    $scope.startDateOptions = {
      formatYear: 'yy',
      maxDate: new Date(),
      startingDay: 1
    };
    $scope.endDateOptions = {
      formatYear: 'yy',
      maxDate: new Date(),
      startingDay: 1
    };

    $scope.monthYear = {
      date: new Date()
    };

    $scope.monthYearOptions = {
      formatYear: 'yy',
      maxDate: new Date(),
      minMode: 'month',
      maxMode: 'month'
    };

    $scope.budgetYear = {
      date: new Date()
    };

    $scope.budgetYearOptions = {
      formatYear: 'yyyy',
      minMode: 'year',
      maxMode: 'year'
    }

    if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN') &&
      !globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Report Manager', 1)
    ) {
      // According to KyeYin, normal report manager dont need select by business unit
      // https://github.com/Metabuyer/synergy/issues/9041
      // If needed, just enable next line
      // $scope.business = filterUsersData($scope.business, 'code', usersBUCode, true);
      $scope.business = []; // remove this if need filter by business unit

      // loop companies and find if they are assigned as report manager for that company
      _.forEach(profile.companies, function (company) {
        if (!!company && !!company._id) {
          var role = globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Report Manager', 3, company._id);
          // if yes, push current BU into BU dropdown list
          if (!!role) {
            // have to check if that BU is already inside the BU's array
            // filter to check if current BU is inside the array
            var isDuplicatedBu = $scope.business.filter(function (existingBu) {
              return existingBu._id === company.business_unit._id
            });
            // if current BU is not inside the array, then push into the array
            if (_.isEmpty(isDuplicatedBu)) {
              $scope.business.push(company.business_unit);
            }
          }
        }
      })
    }

    $scope.business.unshift(allBUs);

    $scope.multiselectTranslation = {
      selectAll: 'Select all',
      selectNone: 'Select none',
      reset: 'Reset',
      search: 'Type here to search...',
      nothingSelected: 'Select'
    };

    reportsV2Config.get({}, function (res) {
      $scope.minionConfig = res.content.data.config
    });
  }

  function loadBuAndIndustryCompanies() {

    searchModule.get(
      {
        module: 'companies',
        'criteria[0][industry|code]': !!selectedIndustry ? selectedIndustry.code : undefined,
        'criteria[0][business_unit|code]': !!selectedBU ? selectedBU.code : undefined,
        'criteria[0][company_country]': !!selectedCountryCode ? selectedCountryCode : undefined,
        'criteria[0][$operator]': 'and',
        offset: 0,
        criteria_operator: 'or'
      },
      function (success) {

        var list = success.content.data;
        if (globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN') ||
          isSystemReportManager
        ) {
          $scope.companyList = list;
        } else {
          $scope.companyList = filterUsersData(list, 'code', allowedCompaniesForRole, true);
        }
      });
  }

  
  

  function onSelectIndustry(industry) {
    // resetCompaniesSelection();
    $scope.industrySelected = true;
    selectedIndustry = industry;
    $scope.inputId = industry._id; //TODO: verify why we need this
    loadBuAndIndustryCompanies();

  }

  function searchCompanies(val) {
    if (!!val && val.length > 2) {
      var moduleParam = {
        module: 'companies'
      };

      var additionalParams = {
        params: {
          'criteria[0][descr]': val,
          'criteria[0][code]': val,
          'criteria[0][country]': selectedCountryCode,
          criteria_operator: 'or',
          'offset': 10
        }
      };

      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), additionalParams)
        .then(function (response) {
          if (response.data.content.data.length === 0) {
            return [];
          }

          return response.data.content.data.map(function (company) {
            return company;
          });
        });
    }
  }

  function onSelectedFilterCompanyBy(selected) {
    $scope.searchMode = selected.value;
    // To clear the billing company, industry & business unit by changing filter company by selection
    if (selected.value === 'bill_company') {
      $scope.reports.businessId = '';
      $scope.reports.industryId = '';
    }
    else (selected.value === 'IBU')
    {
      $scope.shadowCompanySearchField = {
        text: ''
      };
    }
  }

  function addToSelectedCompany(selected) {
    if (!globalFunc.findInArray($scope.searchedBillingCompanies, '_id', selected._id)) {
      $scope.searchedBillingCompanies.push(selected);
      $scope.selectedCompanies.push(selected);
    }
    $scope.shadowCompanySearchField = {
      text: ''
    };
  }

  function onSelectedCompanyCountry(cc) {
    if (cc.code === 'all') {
      selectedCountryCode = undefined;
    } else {
      selectedCountryCode = cc.descr; //DB uses the descr
    }
    filterSelectedCompanies();
  }

  function filterSelectedCompanies() {

    if (!selectedCountryCode) {
      onSelectBillingCompany($scope.selectedBillingCompany);
      return;
    }

    if (!$scope.companyList.length)
      return;

    var backupList = _.cloneDeep($scope.companyList);
    $scope.companyList = [];
    var t = globalFunc.removeValueFromArray(backupList, 'company_country', selectedCountryCode);
    _.map(backupList, function (company) {
      if (selectedCountryCode) {
        (selectedCountryCode === company.company_country) ? $scope.companyList.push(company) : '';
      }
      else
        $scope.companyList.push(company);
    });
    $scope.selectedCompanies = _.filter($scope.selectedCompanies, function (selectedCompany) {
      return selectedCompany.company_country === selectedCountryCode;
    })
  }

  function onSelectedCountry(country) {
    $scope.corresponding_address.state = undefined;
    $scope.corresponding_address.country = country.descr;
    $scope.selectedMalaysia = (country.descr === 'Malaysia');
  }

  function onSelectBillingCompany(company) {
    $scope.selectedBillingCompany = company;
    //clear function
    $scope.companyList = [];
    billingFromCompanies.get({
      code: company.code
    }, function (resource) {
      if (!!resource.content && !!resource.content.data) {
        if (resource.content.data.length < 1)
          toastr.warning('No company bills to ' + company.descr); //warning to show the users no options will be added

        _.map(resource.content.data, function (company) {
          if (isSystemReportManager || allowedCompaniesForRole.indexOf(company.code) > -1) {
            if (selectedCountryCode) {
              (selectedCountryCode === company.company_country) ? $scope.companyList.push(company) : '';
            }
            else
              $scope.companyList.push(company);
          }
        });
      }
    });
  }

  function itemCategoryCheck(value) {
    if (!value) {
      $scope.selectedItemCategories = [];
      angular.forEach($scope.categoryList, function (value) {
        value.ticked = false;
      });
    }
  }

  function onSelectTenderNumber(tender) {
    $scope.selectedTenderNumber = tender.code;
    $scope.tenderNameSearchField.text = tender.detail.name;
  }

  function onSelectTenderName(tender) {
    $scope.tenderNames.selected = tender.detail.name;
    $scope.tenderNumberSearchField.text = tender.code;
  }

  function onSelectModePurchase(mode) { }

  function onSelectExpenseType(type) {
    $scope.erpBudgetCodeSelect = type.value === 'OPEX' ? true : false;
    if (type.value === 'OPEX')
      loadBudgetList();
  }

  //load items categories
  loadCacheableData.loadData({
    module: 'item-category',
    offset: 0
  }, 'itemCategories', 'localStorage').then(function(data){
    $scope.categoryList = _.map(data, function (item) {return {code: item.code, descr: item.descr, label: item.code + ' - ' + item.descr }});
  });

  // load currencies
  loadCacheableData.loadData({
    module: 'currencies',
    'criteria[is_active]': 1,
    offset: 0
  }, 'currencies', 'localStorage').then(function (data) {
    $scope.currencyData = data;
  });

  $scope.$on("approvalLimitTraceReportPayload", function(evt, payload){
    $scope.approvalLimitTraceReportPayload = payload;
  });
  //load tenders data
  loadCacheableData.loadData({
    module: 'tender',
    'criteria[0][detail|status]': 'APPROVED',
    offset: 0,
    only_tender: true
  }, 'tenders', 'localStorage').then(function(data){
    $scope.tenderList = _.map(data, function (item) {return { 
      value: item._id,
      code: item.code,
      name: item.detail.name,
      descr: item.code + ' - ' + item.detail.name }});
  });

  $scope.$watch('tenderNameSearchField.text', function (newVal, oldVal) {
    if (newVal === '')
      $scope.tenderNumberSearchField.text = '';
  }, true)

  $scope.$watch('tenderNumberSearchField.text', function (newVal, oldVal) {
    if (newVal === '')
      $scope.tenderNameSearchField.text = '';
  }, true)

  initialize();
}

reportsGenerationV2Ctrl.$inject = [
  '$scope', 'reports', 'toastr', 'businessList', 'singleBU', 'industryList', 'singleIndustry', '$timeout', '$filter', 'profile',
  'globalFunc', 'checkReportStatus', '$interval', 'pathConstants', 'reportTypes', '$state', 'getIndustriesCompanies', 'searchModule',
  'UserPermissions', 'reportsV2', 'reportsV2Config', '$http', 'assetCategoryList', 'modeOfPurchaseList', 'billingFromCompanies', 'loadCacheableData', 'expenseTypesList', 
  'lang'
];

angular
  .module('metabuyer')
  .controller('reportsGenerationV2Ctrl', reportsGenerationV2Ctrl);
