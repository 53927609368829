'use strict';
/**
 * @name stockInformationGroupDetailsCtrl
 * @desc Controller for master data StockInformationGroup details controller
 */
function stockInformationGroupDetailsCtrl($scope, $uibModalInstance, stockInformationGroupDetails,
                                          stockInformationGroupMasterDataList, globalFunc, toastr, companies, $rootScope) {
  $scope.companies = companies;
  $scope.editClicked = false;
  $scope.stockInformationGroupDetails = stockInformationGroupDetails.data;
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();

  $scope.stockInformationGroupStatuses = [
    { isActive: 'Active' },
    { isActive: 'Deactivated' }
  ];
  $scope.originalCode = $scope.stockInformationGroupDetails.code;
  $scope.tab = 1;
  $scope.historyDependencies = {
    embeddedParams: {
      'object[0][object_id]': $scope.stockInformationGroupDetails._id,
      'object[0][object_type]': 'App\\Metabuyer\\StockInformationGroup\\Models\\StockInformationGroup',
      offset: 5,
      order_by: '-created_at' // Created at, descending
    }
  };

  $scope.selectedCompanies = [];
  $scope.toggleTab = toggleTab;
  $scope.cancel = cancel;
  $scope.editStockInformationGroup = editStockInformationGroup;
  $scope.updateStockInformationGroup = updateStockInformationGroup;

  function cancel() {
    $uibModalInstance.close();
  }

  function toggleTab(tab) {
    return $scope.tab = tab;
  }

  function editStockInformationGroup(){
    $scope.editClicked = true;
  }

  function validate(stockInformationGroupDetails){
    if (!stockInformationGroupDetails){
      return false;
    }
    if (!stockInformationGroupDetails.code){
      return false;
    }
    if ($scope.selectedCompanies.selected.length === 0) {
      return false;
    }
    if (!stockInformationGroupDetails.stockInformationGroupStatus){
      return false;
    }
    return true;
  }

  function updateStockInformationGroup() {
    if (!validate($scope.stockInformationGroupDetails)) {
      return;
    }

    swal({
        title: 'Confirm Updating the Stock Information Group',
        text: 'This will update the details for this Stock Information Group',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (confirmed) {
        if (!confirmed) {
          return false;
        }

        // set status in integer to pass it as parameter
        var tempStatus;
        switch ($scope.stockInformationGroupDetails.stockInformationGroupStatus.isActive.toLowerCase()) {
          case 'active':
            tempStatus = 1;
            break;
          case 'deactivated':
            tempStatus = 0;
            break;
        }

        var companyCodes = $scope.selectedCompanies.selected.map(function (value, key) {
          return value.code;
        });

        stockInformationGroupMasterDataList.put(
          {
            uuid: $scope.stockInformationGroupDetails.uuid
          },
          {
            name: $scope.stockInformationGroupDetails.name,
            descr: $scope.stockInformationGroupDetails.descr,
            company_codes: companyCodes,
            is_active: tempStatus
          },
          function (resource) {
            toastr.success('Stock Information Group has been updated');
            $uibModalInstance.close(resource.content.data);
          },
          function (error) {
            globalFunc.objectErrorMessage(error);
            return false;
          }
        );
      }
    );
  }

  function initialize() {
    $scope.stockInformationGroupDetails.stockInformationGroupStatus = {isActive: ($scope.stockInformationGroupDetails.is_active ? 'Active' : 'Deactivated')};

    var selectedCompanies = [];
    _.forEach($scope.stockInformationGroupDetails.companies, function(company){
      var selected = _.find($scope.companies, function(e){
        return company.code === e.code;
      });
      selectedCompanies.push(selected);
    })
    $scope.selectedCompanies.selected = selectedCompanies;
  }

  initialize();
}

stockInformationGroupDetailsCtrl.$inject = [
  '$scope', '$uibModalInstance', 'stockInformationGroupDetails', 'stockInformationGroupMasterDataList', 'globalFunc',
  'toastr', 'companies', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('stockInformationGroupDetailsCtrl', stockInformationGroupDetailsCtrl);
