'use strict';

/**
 * Controller for editing catalog groups
 * @param $scope
 * @param toastr
 * @param globalFunc
 * @param catalogGroupDetails
 * @param companyGroupsList
 * @param $state
 * @param companies
 * @param companyGroupsAction
 * @param $rootScope
 */

function editCatalogGroupCtrl($scope, toastr, globalFunc, catalogGroupDetails, companyGroupsList, $state, companies,
                              companyGroupsAction, $rootScope) {
  $scope.deleteGroup = deleteGroup;
  $scope.selectCompany = selectCompany;
  $scope.editMode = editMode;
  $scope.saveChanges = saveChanges;
  $scope.cancelUpdate = cancelUpdate;
  $scope.duplicationCheck = duplicationCheck;
  $scope.catalogBackup = angular.copy(catalogGroupDetails.data);
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();
  $scope.statusList = [
    {
      code: 'ACTIVE',
      descr: 'Active'
    },
    {
      code: 'DEACTIVATED',
      descr: 'Deactivated'
    }
  ];

  function deleteGroup(){
    companyGroupsList.delete(
      {
        groupId: $scope.catalogGroupDetails._id
      },
      function (resource) {
        toastr.info('Catalog group deleted');
        $state.go('main.user.items.catalogGroup');
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      });
  }

  /**
   * add / remove a company from a group
   * @param data
   * @param action
   */
  function selectCompany(data, action){
    if(!!$scope.catalogGroupDetails && !!$scope.catalogGroupDetails._id) {
      $scope.saving = true;
      companyGroupsAction.put({
          groupId: $scope.catalogGroupDetails._id,
          companyId: data.code
        },
        {
          action: action
        },
        function (resource) {
          if(action === 'assign')
            toastr.success('Company assigned');
          else
            toastr.success('Company unassigned');

          $scope.saving = false;
        },
        function (error) {
          $scope.saving = false;
          globalFunc.objectErrorMessage(error);
        });
    }
  }

  function editMode(){
    $scope.editClicked = true;
  }

  function validate() {
    if ($scope.catalogGroupDetails.length === 0) {
      return false;
    }

    if (!$scope.catalogGroupDetails.code && !$scope.catalogGroupDetails.descr) {
      return false;
    }

    return true;
  }

  function saveChanges(){
    $scope.submitted = true;
    if(validate()) {

      //TODO: pass tempStatus when status is affecting catalog group (Justin 14/11/16)
      // set status in integer to pass it as parameter
      // var tempStatus;
      // switch ($scope.catalogGroupDetails.status.isActive.toLowerCase()) {
      //   case 'active':
      //     tempStatus = 1;
      //     break;
      //   case 'deactivated':
      //     tempStatus = 0;
      //     break;
      //   case 'disabled':
      //     tempStatus = 3;
      //     break;
      // }

      var newData = prepareParam($scope.catalogGroupDetails);
      companyGroupsList.put(
        {
          groupId: $scope.catalogGroupDetails._id
        },
        newData,
        function (resource) {
          toastr.success('Catalog group edit successful');
          $state.go('main.user.items.catalogGroup');
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      )
    }
    else{
      toastr.error('Please fill all the required fields before saving')
    }
  }

  function cancelUpdate(){
    if($scope.editClicked === true){
      $scope.editClicked = false;
      $scope.submitted = false;

      // reset editable fields
      $scope.catalogGroupDetails.is_active = $scope.catalogBackup.is_active;
      $scope.catalogGroupDetails.descr = $scope.catalogBackup.descr;

      toastr.success('Changes reverted!');
      $state.go('main.user.items.editCatalogGroup',{'id': $scope.catalogGroupDetails._id});
    }
    else {
      window.history.back();
    }
  }

  function prepareParam(data) {
    var param = {};
    param.code = data.code;
    param.descr = data.descr;
    param.is_active = data.is_active === "ACTIVE" ? 1 : 0;

    return param;
  }

  function loadGroupsCompanies(group){
    $scope.loadingCompaniesList = true;
    $scope.selectedCompanies = [];
    var temp;
    _.forEach(companies.data, function(company){
      temp = checkCompany(company, group);
      if (!!temp) {
        $scope.selectedCompanies.push(temp);
      }
    });
    $scope.selectedCompanies = globalFunc.sortObjectsArray($scope.selectedCompanies, 'descr');
  }

  /**
   * Checks inside the company array
   * @param company
   * @param group
   * returns {*}
   */
  function checkCompany(company, group){
    var temp = null;
    if (!!company.catalog_groups) {
      _.forEach(company.catalog_groups, function (companyGroup) {
        if (companyGroup._id === group._id) {
          temp = company;
        }
      });
    }
    return temp;
  }

  /**
   * @function duplicationCheck
   * Calls the global function's duplication check
   * Checks callback function return for assigning scope values for validation
   **/
  function duplicationCheck(model,field,value,checker){
    globalFunc.duplicationCheck(model,field,value,checker, function(callback){
      switch (callback){
        case 'codeCheckTrue':
          $scope.codeCheck = true;
          break;
        case 'codeCheckFalse':
          $scope.codeCheck = false;
          break;
      }
    });
  }

  function initialize(){

    var tabData = {
      tab: $scope.$parent.tabData[1],
      noTransition: true
    };
    $rootScope.$broadcast('loadSpecificTab', tabData);

    $scope.editClicked = false;
    $scope.catalogGroupDetails = catalogGroupDetails.data;
    $scope.companies = companies.data;
    loadGroupsCompanies($scope.catalogGroupDetails);
    $scope.catalogGroupStatuses = [
      { isActive: 'Active' },
      { isActive: 'Deactivated' }
    ];

    if(!!$scope.catalogGroupDetails.is_active) {
      $scope.catalogGroupDetails.status = {isActive: globalFunc.capitalizeFirstCharacter($scope.catalogGroupDetails.is_active)};
    }
  }

  initialize();
}

editCatalogGroupCtrl.$inject = ['$scope', 'toastr', 'globalFunc', 'catalogGroupDetails', 'companyGroupsList', '$state',
                                'companies', 'companyGroupsAction', '$rootScope'];

angular.module('metabuyer')
  .controller('editCatalogGroupCtrl', editCatalogGroupCtrl);
