'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider) {

    $stateProvider
      .state('main.user.logViewer', {
        url: '/log-viewer',
        controller: 'logViewerMainCtrl',
        templateUrl: 'app/logViewer/logViewer.html',
        resolve: {
          accessRights: function (profile, $state, globalFunc) {
            if (!(globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN') ||
            globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'System Support'))) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.logViewer.manage', {
        url: '/manage',
        controller: 'logViewerManagementCtrl',
        templateUrl: 'app/logViewer/manage/logViewerManage.html',
        resolve: {
          $title: function () {
            return 'Laravel Log Viewer';
          }
        }
      })
  }]);

