'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('main.user.settings', {
        url: '/system-settings',
        controller: 'settingsCtrl',
        templateUrl: 'app/settings/settingsTemplate.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            var check = false;

            //TODO: Need to enhance this section
            if (!!profile.role_assignments && profile.role_assignments.length > 0) {
              for (var index in profile.role_assignments) {
                for (var i in profile.role_assignments[index].roles) {

                  if(profile.role_assignments[index].roles[i].name.toLowerCase() == 'admin' ||
                    profile.role_assignments[index].roles[i].name.toLowerCase() == 'tenant_super_admin' ||
                    profile.role_assignments[index].roles[i].name.toLowerCase() == 'system support'){
                    check = true;
                    break;
                  }
                }
              }
            }
            if (!check) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.settings.mainPanel', {
        url: '/main',
        controller: 'mainPanelSettingsCtrl',
        templateUrl: 'app/settings/mainPanel/mainPanelSettingsTemplate.html',
        resolve:{
          $title: function(){
            return "Settings";
          },
          featureToggles: function ($q, $stateParams, FeatureToggleService, toastr) {
            var deferred = $q.defer();

            FeatureToggleService.getMultiple(['bifrost', 'syncEngine'])
              .then(function(response) {
                deferred.resolve(response.data);
              },
              function (e) {
                deferred.resolve({});
                toastr.error('Something went wrong')
              });

            return deferred.promise;
          }
        }
      })
      .state('main.user.settings.adminSettings',{
        url: '/admin-settings',
        controller: 'adminSettingsCtrl',
        templateUrl: 'app/settings/adminSettings/adminSettings.html',
        resolve:{
          settings: function($q, adminSettings) {
            var deferred = $q.defer();
            adminSettings.get(
              {},
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          }
        }
      })
      .state('main.user.settings.PRPOSettings', {
        url: '/PR-PO-Settings',
        controller: 'PRPOSettingsCtrl',
        abstract: true,
        templateUrl: 'app/settings/PR-POSettings/PRPOSettingsTemplate.html'
      })
      .state('main.user.settings.tenant',{
        url: '/tenant',
        controller: 'tenantSettingsCtrl',
        templateUrl: 'app/settings/tenantSettings/tenantSettings.html',
        resolve:{
          tenant: function($q, tenantSettings) {
            var deferred = $q.defer();
            tenantSettings.get(
              {},
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function(){
            return "Tenant Settings";
          }
        }
      })
      .state('main.user.settings.PRPOSettings.prefix', {
        url: '/Prefix',
        controller: 'PRPOPrefixCtrl',
        templateUrl: 'app/settings/PR-POSettings/prefix/PRPOPrefixTemplate.html',
        resolve:{
          companies: function($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'companies',
                offset: 0,
                order: 1,
                order_by: 'descr'
              },
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function(){
            return "Company Prefix";
          }
        }
      })
      .state('main.user.settings.emailAnnouncement', {
        url: '/emailAnnouncement',
        controller: 'emailAnnouncementCtrl',
        templateUrl: 'app/settings/emailAnnouncement/emailAnnouncementTemplate.html',
        resolve:{
          $title: function(){
            return 'Admin announcements';
          }
        }
      })
      .state('main.user.settings.bifrost', {
        url: '/bifrostSettings',
        controller: 'bifrostSettingsCtrl',
        templateUrl: 'app/settings/bifrostSettings/bifrostSettings.html',
        resolve: {
          $title: function(){
            return "Bifrost Settings";
          }
        }
      })
      .state('main.user.settings.bifrost.environments', {
        url: '/environments',
        controller: 'bifrostEnvironmentsCtrl',
        templateUrl: 'app/settings/bifrostSettings/environments/bifrostEnvironmentsTemplate.html',
        resolve: {
          dataList: function($q, searchIntegrationEnvironment) {
            var deferred = $q.defer();
            searchIntegrationEnvironment.get(
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function(){
            return "Bifrost Settings";
          }
        }
      })
      .state('main.user.settings.bifrost.schedule', {
        url: '/schedule',
        controller: 'bifrostScheduleCtrl',
        templateUrl: 'app/settings/bifrostSettings/schedule/bifrostSchedule.html',
        resolve: {
          dataList: function ($q, integrationSchedule) {
            var deferred = $q.defer();
            integrationSchedule.get(
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function(){
            return "Bifrost Settings";
          }
        }
      })
      .state('main.user.settings.privateAccess', {
        url: '/privateAccess',
        controller: 'privateAccessCtrl',
        templateUrl: 'app/settings/privateAccess/privateAccess.html',
        resolve: {
          dataList: function ($q, privateAccess) {
            var deferred = $q.defer();
            privateAccess.get(
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function() {
            return "Private Access";
          }
        }
      })
      .state('main.user.settings.bifrost.storagePaths', {
        url: '/storagePath',
        controller: 'bifrostStoragePathCtrl',
        templateUrl: 'app/settings/bifrostSettings/storagePaths/bifrostStoragePath.html',
        resolve: {
          dataList: function ($q, integrationStoragePath) {
            var deferred = $q.defer();
            integrationStoragePath.get(
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function(){
            return "Bifrost Settings";
          }
        }
      })
      .state('main.user.settings.kharon', {
        url: '/kharonSettings',
        controller: 'kharonSettingsCtrl',
        templateUrl: 'app/settings/kharonSettings/kharonSettings.html',
        resolve: {
          $title: function(){
            return "Kharon Settings";
          }
        }
      })
      .state('main.user.settings.kharon.schedule', {
        url: '/schedule',
        controller: 'kharonScheduleCtrl',
        templateUrl: 'app/settings/kharonSettings/schedule/kharonSchedule.html',
        resolve: {
          dataList: function ($q, kharonSchedulerConfigs) {
            var deferred = $q.defer();
            kharonSchedulerConfigs.get(
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function(){
            return "Kharon Settings";
          }
        }
      })
      .state('main.user.settings.kharon.user', {
        url: '/user',
        controller: 'kharonUserCtrl',
        templateUrl: 'app/settings/kharonSettings/user/kharonUser.html',
        resolve: {
          dataList: function ($q, kharonUsers) {
            var deferred = $q.defer();
            kharonUsers.get(
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          companyList: function($q, searchModule) {
            var deferred = $q.defer();

            searchModule.get(
              {
                module: 'companies',
                offset: 0,
                order: 1,
                order_by: 'descr'
              },
              function (resource) {
                deferred.resolve(resource);
              },
              function (error) {
                deferred.resolve([]);
              }
            );

            return deferred.promise;
          },
          $title: function(){
            return "Kharon Settings";
          }
        }
      });
  }]);
