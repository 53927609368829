'use strict';

function alcCtrl($scope, $state, UserPermissions, profile, globalFunc) {

  $scope.tabData = [
    {
      heading: 'Manage ALC Uploads',
      route: 'main.user.alc.manage',
      key: 'all',
      menu: [
        {
          heading: 'All',
          route: 'main.user.alc.manage',
          key: 'all',
          params: {
            status: 'all',
            cursor: null,
            filter: null,
            query: null,
            type: null,
            page: null,
            params: undefined
          }
        },
        {
          heading: 'Draft',
          route: 'main.user.alc.manage',
          key: 'all',
          params: {
            status: 'draft',
            cursor: null,
            filter: null,
            query: null,
            type: null,
            page: null,
            params: undefined
          }
        },
        {
          heading: 'Approved',
          route: 'main.user.alc.manage',
          key: 'all',
          params: {
            status: 'active',
            cursor: null,
            filter: null,
            query: null,
            type: null,
            page: null,
            params: undefined
          }
        },
        {
          heading: 'Pending Approval',
          route: 'main.user.alc.manage',
          key: 'all',
          params: {
            status: 'pending',
            cursor: null,
            filter: null,
            query: null,
            type: null,
            page: null,
            params: undefined
          }
        },
        {
          heading: 'Deactivated',
          route: 'main.user.alc.manage',
          key: 'all',
          params: {
            status: 'deactivated',
            cursor: null,
            filter: null,
            query: null,
            type: null,
            page: null,
            params: undefined
          }
        },
        {
          heading: 'Rejected',
          route: 'main.user.alc.manage',
          key: 'all',
          params: {
            status: 'rejected',
            cursor: null,
            filter: null,
            query: null,
            type: null,
            page: null,
            params: undefined
          }
        }
      ]
    },
  ];

  if (UserPermissions.hasPermission(profile, 'Alc', 'Create')) {
    $scope.tabData.push(
      {
        heading: 'Add ALC',
        route: 'main.user.alc.add',
        key: 'add',
        params: {
          status: null,
          cursor: null,
          filter: null,
          query: null,
          type: null,
          page: null
        }
      }
    );
  }

  /**
   * making sure the Stats and placeHolder changes if the Child scope changed
   */
  $scope.$on('$stateChangeStart', function( event, data ) {
    if(!!data.name && data.name.indexOf('manage')> -1){
      $scope.searchPlaceholder = 'Search ALC';
      $scope.searchState = 'main.user.alc.manage';
      $scope.parentState = 'main.user.alc.manage';
      $scope.parentStateParams = $state.params;
      $scope.columns = manageColumns;
    }
    else if(!!data.name && data.name.indexOf('details') > -1){
      $scope.searchPlaceholder = 'Search ALC';
      $scope.searchState = 'main.user.alc.details';
      $scope.parentState = 'main.user.alc.details';
      $scope.parentStateParams = $state.params;
      $scope.columns = manageColumns;
    }
  });

  function initialize(){
    if(!!$state.params && !!$state.params.query){
      $scope.searchText = $state.params.query;
    }
    else{
      $scope.searchText = '';
    }

    if(!!$state.current.name && $state.current.name.indexOf('manage') > -1) {
      $scope.searchPlaceholder = 'Search alc';
      $scope.searchState = 'main.user.alc.manage';
      $scope.parentState = 'main.user.alc.manage';
      $scope.parentStateParams = $state.params;
      $scope.columns = manageColumns;
      $scope.activatedTab = $scope.tabData[0];
    }
    else if(!!$state.current.name && $state.current.name.indexOf('details') > -1){
      $scope.searchPlaceholder = 'Search alc';
      $scope.searchState = 'main.user.alc.details';
      $scope.parentState = 'main.user.alc.details';
      $scope.parentStateParams = $state.params;
      $scope.columns = manageColumns;
      $scope.activatedTab = $scope.tabData[1];
    }
    else{
      $scope.searchPlaceholder = 'Search alc';
      $scope.searchState = 'main.user.alc.manage';
      $scope.parentState = 'main.user.alc.manage';
      $scope.parentStateParams = $state.params;
      $scope.columns = manageColumns;
      $scope.activatedTab = $scope.tabData[0];
    }

    if(!!$state.params && !!$state.params.status){
      $scope.status = $state.params.status;
    }

    if(!!$state.params && !!$state.params.filter){
      $scope.searchFilter = $state.params.filter;
    }

    $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();
  }

  var manageColumns = [
    {id: 'check box', label: '', conditional: false, unsortable: true, unsearchable: true, unSelectable: true},
    {id: 'reference', label: 'Reference'},
    {id: 'alc_code', label: 'ALC Code'},
    {id: 'alc_name', label: 'ALC Name'},
    {id: 'alc_group|code', label: 'ALC Group'},
    {id: 'item_master|category|code', label: 'Item Category'},
    {id: 'item_master|code', label: 'Item Code & name'},
    {id: 'position', label: 'Position'},
    {id: 'approval_limit_pr', label: 'Approval Limit per PR'},
    {id: 'approval_limit_month', label: 'Approval Limit per Month'},
    {id: 'start_date', label: 'Start Date'},
    {id: 'end_date', label: 'End Date'},
    {id: 'creator_info|display_name', label: 'Created By'},
    {id: 'status', label: 'Status', conditional: true, condition: 'all'}
  ];

  initialize();

  $scope.$emit('pageTitleChanged', 'Approval Limit Control');

  $scope.$on('$stateChangeStart', function(event, toState, toParams){
    if(!!toParams.status){
      $scope.parentStateParams = toParams;
    }
  });
}
alcCtrl.$inject = ['$scope', '$state', 'UserPermissions', 'profile', 'globalFunc'];


angular.module('metabuyer')
  .controller('alcCtrl', alcCtrl);
