'use strict';
/**
 * @name itemCategoryDetailsCtrl
 * @desc Controller for master data ItemCategory details controller
 */
function itemCategoryDetailsCtrl($scope, $uibModalInstance, itemCategoryDetails,
                                itemCategoryMasterDataList, globalFunc, toastr, $state, $rootScope) {
  $scope.editClicked = false;
  $scope.itemCategoryDetails = itemCategoryDetails.data;
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();

  $scope.itemCategoryStatuses = [
    { isActive: 'Active' },
    { isActive: 'Deactivated' }
  ];
  $scope.originalCode = $scope.itemCategoryDetails.code;
  $scope.tab = 1;
  $scope.historyDependencies = {
    embeddedParams: {
      'object[0][object_id]': $scope.itemCategoryDetails._id,
      'object[0][object_type]': 'App\\Metabuyer\\ItemCategory\\Models\\ItemCategory',
      offset: 5,
      order_by: '-created_at' // Created at, descending
    }
  };

  $scope.toggleTab         = toggleTab;
  $scope.cancel            = cancel;
  $scope.editItemCategory   = editItemCategory;
  $scope.updateItemCategory = updateItemCategory;

  function cancel() {
    $uibModalInstance.close();
  }

  function toggleTab(tab) {
    return $scope.tab = tab;
  }

  function editItemCategory(){
    $scope.editClicked = true;
  }

  function validate(itemCategoryDetails){
    if (!itemCategoryDetails){
      return false;
    }
    if (!itemCategoryDetails.code){
      return false;
    }
    if (!itemCategoryDetails.itemCategoryStatus){
      return false;
    }
    return true;
  }

  function updateItemCategory() {
    if (!validate($scope.itemCategoryDetails)) {
      return;
    }

    swal({
        title: 'Confirm Updating the Item Category',
        text: 'This will update the details for this Item Category',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#1ab394',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (confirmed) {
        if (!confirmed) {
          return false;
        }

        // set status in integer to pass it as parameter
        var tempStatus;
        switch ($scope.itemCategoryDetails.itemCategoryStatus.isActive.toLowerCase()) {
          case 'active':
            tempStatus = 1;
            break;
          case 'deactivated':
            tempStatus = 0;
            break;
        }

        itemCategoryMasterDataList.put(
          {
            uuid: $scope.itemCategoryDetails.uuid
          },
          {
            name: $scope.itemCategoryDetails.name,
            descr: $scope.itemCategoryDetails.descr,
            is_active: tempStatus
          },
          function (resource) {
            toastr.success('Item Category has been updated');
            $uibModalInstance.close(resource.content.data);
          },
          function (error) {
            globalFunc.objectErrorMessage(error);
            return false;
          }
        );
      }
    );
  }

  function initialize() {
    $scope.itemCategoryDetails.itemCategoryStatus = {isActive: ($scope.itemCategoryDetails.is_active ? 'Active' : 'Deactivated')};
  }

  initialize();
}

itemCategoryDetailsCtrl.$inject = [
  '$scope', '$uibModalInstance', 'itemCategoryDetails', 'itemCategoryMasterDataList', 'globalFunc',
  'toastr', '$state', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('itemCategoryDetailsCtrl', itemCategoryDetailsCtrl);
