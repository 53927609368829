'use strict';

function addOtherChargesGroupCtrl($scope, toastr, globalFunc, $uibModalInstance, companyOtherChargesGroupService,
                                  getAllOtherCharges, loadCacheableData, company, $http, $filter, pathConstants) {
  $scope.formSubmitted = false;
  $scope.company = company;
  $scope.otherCharges = {};
  $scope.otherCharges.is_active = true;
  $scope.selectedCharges = [];
  $scope.cancel = cancel;
  $scope.onSelectOtherCharges = onSelectOtherCharges;
  $scope.searchSuppliers = searchSuppliers;
  $scope.onSelectSupplier = onSelectSupplier;
  $scope.submitOtherChargesGroup = submitOtherChargesGroup;
  $scope.removeCharges = removeCharges;
  $scope.calculationMethod = [
    {
      code: 'PERCENT_OF_EXTENDED_COST',
      descr: 'PERCENT OF EXTENDED COST',
      is_percentage: true
    },
    {
      code: 'FLAT_AMOUNT',
      descr: 'FLAT AMOUNT',
      is_percentage: false
    },
    {
      code: 'FLAT_AMOUNT_PER_UNIT',
      descr: 'FLAT AMOUNT PER UNIT',
      is_percentage: false
    }
  ];
  $scope.columns = [
    {id: 'other_charges_group_name', label: 'NAME'},
    {id: 'calculation_method', label: 'CALCULATION METHOD'},
    {id: 'unit_price', label: 'UNIT PRICE'},
    {id: 'percentage', label: 'percentage'},
    {id: 'tax_code', label: 'TAX CODE'},
    {id: 'currency', label: 'CURRENCY'}
  ];
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();

  function removeCharges(index) {
    $scope.selectedCharges.splice(index, 1);
  }

  function onSelectOtherCharges(charge) {
    if (!!validateOtherCharges(charge)) {
      $scope.selectedCharges.push(charge);
    }
  }

  function validateOtherCharges(charge) {
    if ($scope.selectedCharges.length !== 0) {
      for (var i = 0; i < $scope.selectedCharges.length; i++) {
        if ($scope.selectedCharges[i].code === charge.code) {
          toastr.error(charge.code + " already added");
          return false;
        }
      }
    }

    return true;
  }

  function cancel() {
    $uibModalInstance.close();
  }

  function submitOtherChargesGroup() {
    $scope.formSubmitted = true;
    if (validate($scope.otherCharges)) {
      var data = {
        company_code: $scope.company.code,
        code: $scope.otherCharges.code,
        name: $scope.otherCharges.name,
        descr: $scope.otherCharges.name,
        supplier_code: $scope.otherCharges.supplier.basic_info.code,
        is_active: $scope.otherCharges.is_active,
        other_charges: $scope.selectedCharges
      };

      companyOtherChargesGroupService.post(
        {id: $scope.company._id},
        data,
        function (success) {
          toastr.success('Other Charges Group is successfully added!');
          $uibModalInstance.close(success);
        },
        function (error) {
          toastr.error('Other Charges Group fail to add');
          globalFunc.objectErrorMessage(error);
        }
      );
    } else {
      toastr.error('Please check all input');
    }
  }

  function searchSuppliers(val) {
    var moduleParam = {
      module: 'suppliers'
    };

    var additionalParams = {
      'criteria[0][basic_info|descr]': val,
      'criteria[0][basic_info|status][0]': 'active',
      criteria_operator: 'and',
      offset: 5
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams,
      ignoreLoadingBar: true
    }).then(function (response) {
      if (response.data.content.data.length === 0) {
        $scope.supplierSearching = false;
        return [];
      } else {
        return response.data.content.data.map(function (item) {
          $scope.supplierSearching = false;
          return item
        });
      }
    });
  }

  function onSelectSupplier(supplier) {
    $scope.otherCharges.supplier = supplier;
  }

  function validate(otherChargesData) {
    if (!otherChargesData.name) {
      return false;
    }
    if (!otherChargesData.code) {
      return false;
    }
    if (_.isEmpty(otherChargesData.supplier)) {
      return false;
    }
    return true;
  }

  function getOtherChargesData() {
    getAllOtherCharges.get(
      {},
      function (resource) {
        $scope.otherChargesList = resource.data;
      },
      function () {
        $scope.otherChargesList = [];
      }
    );
  }

  function getTaxData() {
    loadCacheableData.loadData({
      module: 'tax',
      'criteria[is_active]': 1,
      offset: 0
    }, 'taxes', 'localStorage').then(function (data) {
      $scope.taxesList = data;
    });
  }

  function getCurrencyData() {
    loadCacheableData.loadData({
      module: 'currencies',
      'criteria[is_active]': 1,
      offset: 0
    }, 'currencies', 'localStorage').then(function (data) {
      $scope.currenciesList = data;
    });
  }

  function init() {
    getOtherChargesData();
    getTaxData();
    getCurrencyData();
  }

  init()
}

addOtherChargesGroupCtrl.$inject = ['$scope', 'toastr', 'globalFunc', '$uibModalInstance', 'companyOtherChargesGroupService',
  'getAllOtherCharges', 'loadCacheableData', 'company', '$http', '$filter', 'pathConstants'];

angular
  .module('metabuyer')
  .controller('addOtherChargesGroupCtrl', addOtherChargesGroupCtrl);
