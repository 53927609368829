/**
 * @name ordersAdvancedSearchCtrl
 *
 * @description Controller for PO advanced search
 *
 * @author Khoo Jay Shen <khoojs@sunway.com.my>
 * @copyright Sunway Metacloud 2023
 */
function ordersAdvancedSearchCtrl($scope, $rootScope) {
  $scope.isV2Enabled = $rootScope.isV2Enabled;
}

ordersAdvancedSearchCtrl.$inject = [
  '$scope', '$rootScope'
];

angular.module('metabuyer')
  .controller('ordersAdvancedSearchCtrl', ordersAdvancedSearchCtrl);
