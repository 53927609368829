'use strict';

/**
 * Controller for budget v2 details
 * @description
 * Contains functions for handling budget information and editing
 *
 * @param $scope
 * @param toastr
 * @param companyExpenseTypes
 * @param userBudget
 * @param globalFunc
 * @param budgetData
 * @param singleCommentsHistory
 * @param budgetCommentList
 * @param attachmentsByReference
 * @param singleAttachment
 * @param $rootScope
 * @param $filter
 * @param pathConstants
 * @param $stateParams
 * @param budgetFunctions
 * @param Approvals
 * @param companyCurrencies
 *
 * @author Ameer Asraf aka Jamal <ameerasraf_92@yahoo.com>
 * @copyright 2020 Metacloud Sdn. Bhd.
 */
function budgetDetailsCtrlV2($scope, toastr, companyExpenseTypes, userBudget, globalFunc, budgetData, singleCommentsHistory,
                             budgetCommentList, attachmentsByReference, singleAttachment, $rootScope, $filter, pathConstants, $stateParams,
                             budgetFunctions, Approvals, companyCurrencies, $timeout, profile, accountCodesList, searchModule, assetCategoryList, budgetRevisionData, getAllCompanies) {

  $scope.costCenterList = [
    {
      'descr': 'All',
      'code': 'ALL',
      '_id': ''
    }
  ];
  $scope.shadowCompanyList = [
    {
      'descr': 'All',
      'code': 'ALL',
      '_id': ''
    }
  ];
  $scope.expenseTypeList = [];
  $scope.profile = profile;
  $scope.selectedYears = [];
  $scope.companyData = [];
  $scope.budgetCategories = [
    {
      descr: 'CAPEX',
      value: 'CAPEX'
    },
    {
      descr: 'OPEX',
      value: 'OPEX'
    }
  ];

  $scope.apportionList = [
    'N/A',
    'monthly',
    'quarterly',
    'yearly'
  ];

  $scope.ownerList = [];

  $scope.startDateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };

  $scope.endDateOptions = {
    formatYear: 'yy',
    startingDay: 1
  };

  $scope.datepickerOpened = {
    start_date: false,
    end_date: false,
    start_date_assignment: false,
    end_date_assignment: false
  };

  $scope.commodityLevel = [1, 2, 3, 4];
  $scope.budgetAssignSwitch = false;
  $scope.showItemListing = false;

  $scope.commentService = budgetCommentList;
  $scope.deleteService = singleCommentsHistory;

  $scope.singleAttachmentService = singleAttachment;
  $scope.attachmentParam = {
    referenceId: budgetData._id
  };
  $scope.attachmentUrl = $filter('format')(pathConstants.apiUrls.attachments.reference,
    {referenceId: budgetData._id});
  $scope.headerDetailsError = false;
  $scope.budgetAssignmentDetailsError = false;
  $scope.budgetItemDetailsError = false;
  $scope.disableYearSelection = true;
  $scope.currencyList = [];
  $scope.costCenterExtraSettings = {
    scrollable: true,
    idProp: '_id',
    externalIdProp: '',
    displayProp: 'descr',
    enableSearch: true,
    closeOnSelect: true,
    selectionLimit:1
  };
  $scope.shadowCompaniesExtraSettings = {
    scrollable: true,
    idProp: '_id',
    externalIdProp: '',
    displayProp: 'descr',
    enableSearch: true,
    closeOnSelect: true,
    selectionLimit:1
  };
  $scope.glAccountCodesExtraSettings = {
    idProp: 'account_code',
    displayProp: 'name',
    scrollable: true,
    enableSearch: true,
    showCheckAll: true,
    showUncheckAll: true,
    buttonDefaultText: 'Select GL Account Code',
    externalIdProp: ''
  };

  $scope.getCostCenter = getCostCenter;
  $scope.openDatepicker = openDatepicker;
  $scope.getBudgetOwners = getBudgetOwners;
  $scope.isCreator = isCreator;
  $scope.isWaitingOnCurrentUser = isWaitingOnCurrentUser;
  $scope.getEditingBudgetState = getEditingBudgetState;
  $scope.showItems = showItems;
  $scope.toggleSwitch = toggleSwitch;
  $scope.checkApportionAllocation = checkApportionAllocation;
  $scope.approvalsPreview = approvalsPreview;
  $scope.getAssignmentRemovableState = getAssignmentRemovableState;
  $scope.getItemBreakdownRemovableState = getItemBreakdownRemovableState;
  $scope.onSelectBudgetType = onSelectBudgetType;
  $scope.onSelectAssetCategory = onSelectAssetCategory;
  $scope.filterYearSelection = filterYearSelection;
  $scope.canEditAttachmentAndComment = canEditAttachmentAndComment;
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();

  /**
   * Check if item breakdown is able to be turned off
   */
  function getItemBreakdownRemovableState() {
    return budgetFunctions.getItemBreakdownRemovableState();
  }

  /**
   * Get cost center listing
   */
  function getCostCenter() {
    var createBudgetCompanies = globalFunc.getUserCompanyListForRole(profile, ['Budget Requester', 'Budget Owner', 'Company Budget Requester']);
    var createBudgetCompanyCodes = _.pluck(createBudgetCompanies, 'code');

    var createBudgetCostCenters = [];
    if (createBudgetCompanyCodes.indexOf($scope.budget.company.code) > -1) {
      createBudgetCostCenters = $rootScope.currentUser.costCenters;
    } else {
      createBudgetCostCenters = globalFunc.getUserCostCenterListForRole(profile, 'Budget Requester');
    }

    _.forEach(createBudgetCostCenters, function (costCenter) {
      if (!!$scope.budget.company.code && $scope.budget.company.code === costCenter.company_code && !!costCenter.is_active) {
        $scope.costCenterList.push(
          {
            'descr': costCenter.descr,
            'code': costCenter.code,
            '_id': costCenter._id
          }
        );
      }
    });
  }

  /**
   * For date picker
   *
   * @param {Object} $event
   * @param {string} which - start or end date
   */
  function openDatepicker($event, which) {
    $event.preventDefault();
    $event.stopPropagation();

    if (!!$scope.budget.end_date) {
      $scope.startDateOptions.maxDate = $scope.budget.end_date;
    }

    $scope.endDateOptions.minDate = new Date();

    $scope.datepickerOpened[which] = true;
  }

  /**
   * Get budget owner listing
   *
   * @param {string} companyCode
   */
  function getBudgetOwners(companyCode) {
    userBudget.get(
      {
        company_code: companyCode
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.ownerList = resource.content.data;
        }
      },
      function () {
        toastr.error('Failed to retrieve budget owner listing.');
      }
    )
  }

  /**
   * Get currency listing
   *
   * @param {string} companyId
   */
  function getCurrencies(companyId) {
    if (budgetData.status !== 'draft') {
      return;
    }

    companyCurrencies.get({
        id: companyId
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.currencyList = resource.content.data;
        }
      }, function (error) {
        globalFunc.objectErrorMessage(error);
      });
  }

  /**
   * Get budget attachments
   */
  function getBudgetAttachments() {
    attachmentsByReference.get({
      referenceId: $scope.budget._id
    }, function (resource) {
      if (!!resource && !!resource.content)
        $scope.budgetAttachments = resource.content.data;
    }, function (error) {
      globalFunc.objectErrorMessage(error);
    })
  }

  /**
   * Check if current user is budget creator
   *
   * @returns {boolean}
   */
  function isCreator() {
    return $rootScope.currentUser._id === $scope.budget.created_by._id;
  }

  /**
   * Check if approval is waiting on current user
   *
   * @returns {boolean}
   */
  function isWaitingOnCurrentUser() {
    // TODO: Add approval related logic for showing buttons in globalFunc in new approval (Justin 11/9/17)
    return (1 === 1);
  }

  /**
   * Set budget editable state
   */
  function setBudgetEditableState() {
    budgetFunctions.setEditableState(($scope.budget.status === 'draft') ? 'editable' : 'notEditable');
    if (!isCreator())
      budgetFunctions.setEditableState('notEditable');
  }

  /**
   * Set budget assignment removable state
   */
  function setAssignmentRemovableState() {
    var usedInPR = false;
    if (!!$scope.budget.assignments) {
      _.forEach($scope.budget.assignments, function (assignment) {
        if ((!!assignment.used_amount && assignment.used_amount > 0) ||
          (!!assignment.committed_amount && assignment.committed_amount > 0)
        ) {
          usedInPR = true;
        }
      });
    }

    if (getEditingBudgetState() === 'editable' && !usedInPR) {
      budgetFunctions.setAssignmentRemovableState(true);
    } else {
      budgetFunctions.setAssignmentRemovableState(false);
    }
  }

  /**
   * Get the current state for the budget, either editable or not
   *
   * @returns {*}
   */
  function getEditingBudgetState() {
    return budgetFunctions.getEditingState();
  }

  /**
   * Get the current state for the budget assignment, either removable or not
   *
   * @returns {*}
   */
  function getAssignmentRemovableState() {
    return budgetFunctions.getAssignmentRemovableState();
  }

  /**
   * Retrieves the expense types
   *
   * @param {string} companyId
   * @param {string} expenseTypeCategory
   */
  function getExpenseTypes(companyId, expenseTypeCategory) {
    companyExpenseTypes.get(
      {
        id: companyId,
        category : expenseTypeCategory
      },
      function (resource) {
        if (!!resource.content && !!resource.content.data) {
          $scope.expenseTypeList = resource.content.data;
        }
      },
      function () {
        toastr.error('Failed to retrieve expense type listing.');
      }
    )
  }

  /**
   * populate expense types based on the budget type
   */
  function onSelectBudgetType(selectedExpenseTypeCategory) {
    $scope.budget.expense_type_category = selectedExpenseTypeCategory.value;
    getExpenseTypes(budgetData.company._id, $scope.budget.expense_type_category);
  }

  function onSelectAssetCategory(selectedAssetCategory) {
    $scope.budget.asset_category = {
      code: selectedAssetCategory.code,
      name: selectedAssetCategory.name,
      account_code: selectedAssetCategory.account_code,
      asset_useful_life: selectedAssetCategory.asset_useful_life
    };
  }

  /**
   * Flag to show item to be shown or not
   */
  function showItems() {
    $scope.showItemListing = !$scope.showItemListing;
  }

  /**
   * Run when toggle value change for budget assignment
   *
   * @param {boolean} toggle Switch to on/off
   * @param {string}  type   Is either assignment or itembreakdowns
   */
  function toggleSwitch(toggle, type) {
    if (!toggle &&
      ((type === 'assignment' && !!$scope.budget.assignments.length) ||
        (type === 'itembreakdown' && !!$scope.budget.item_breakdowns.length))
    ) {
      swal({
        title: 'Confirm turn off the section?',
        text: 'Turn off this will erase the ' + type + ' data?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Confirm',
        closeOnConfirm: true
      }, function (isConfirm) {
        if (isConfirm) {
          if (type === 'assignment') {
            $scope.budget.assignments = [];
          } else {
            $scope.budget.item_breakdowns = [];
          }
        } else {
          if (type === 'assignment') {
            $scope.budgetAssignSwitch = true;
          } else {
            $scope.budgetItemBreakdownSwitch = true;
          }
          $scope.$apply();
        }
      });
    }
  }

  function filterYearSelection(startDate, endDate) {
    var startYear = startDate && startDate.getFullYear();
    var endYear = endDate && endDate.getFullYear();

    if (!!startYear && !!endYear) {
      var years = endYear - startYear;
      var newYearSelection = [];

      updateNonPurchasingCost(startYear, endYear);

      for (var i = 0; i <= years; i++){
        newYearSelection.push({
          'year': moment(startDate).add(i, 'years').format('YYYY'),
          'value': parseInt(moment(startDate).add(i, 'years').format('YYYY'))
        })
      }

      if (!_.isEmpty(newYearSelection)){
        $scope.disableYearSelection = false;
        $scope.budget.years = _.map(newYearSelection, function(e){
          return {'value': e.value};
        });
      }
    }
  }

  function getMonthsBreakdown (num) {
    var arr = new Array(num);
    _.forEach(arr, function (singleIndex, key) {
      arr[key] = {
        month: key + 1,
        amount: 0
      };
    });
    return arr;
  }

  /**
   * updates non-purchasing cost when changin start/end date
   */
  function updateNonPurchasingCost(startYear, endYear) {
    if (!_.isEmpty($scope.budget.years)){
      var currentStartYear = $scope.budget.years[0].value;
      var currentEndYear = $scope.budget.years[$scope.budget.years.length-1].value;

      var startYearDiff  = startYear - currentStartYear;
      if (startYearDiff > 0) {
        $scope.budget.non_purchasing_costs.splice(0, startYearDiff);
      } else if (startYearDiff < 0) {
        for (var i=1; i <= Math.abs(startYearDiff); i++) {
          $scope.budget.non_purchasing_costs.splice(0, 0, {
            year: currentStartYear-i,
            monthBreakdown: getMonthsBreakdown(12)
          });
        }
      }

      var endYearDiff  = endYear - currentEndYear;
      if (endYearDiff > 0) {
        for (var i=1; i <= endYearDiff; i++) {
          $scope.budget.non_purchasing_costs.push({
            year: currentEndYear+i,
            monthBreakdown: getMonthsBreakdown(12)
          });
        }
      } else if (endYearDiff < 0) {
        $scope.budget.non_purchasing_costs.splice(endYearDiff);
      }
    }
  }

  /**
   * To check if apportion allocation values will change due to field changes
   *
   * @param {*}      oldValue   old value of the field
   * @param {*}      newValue   new value of the field
   * @param {string} fieldModel Name of the model to be modified
   * @returns {boolean}
   */
  function checkApportionAllocation(oldValue, newValue, fieldModel) {
    budgetFunctions.checkApportionAllocation(
      oldValue,
      newValue,
      ($scope.budget.apportion_allocation)).then(function (resource) {
      $scope.apportionRelatedValues = resource;
      $scope.budget[fieldModel] = resource;
      $scope.originalBudgetData[fieldModel] = resource;
    });
  }

  /**
   * Run to get approval flow data for this budget
   */
  function getApprovalFlow() {
    if (!$scope.budget) {
      return;
    }

    if ($scope.budget.status === 'draft' || $scope.budget.status === 'deleted') {
      return;
    }

    Approvals.get({
      id: $scope.budget.approval_id
    },
    function (resource) {
      if (!!resource && !!resource.content && !!resource.content.data) {
        $scope.approvalFlowData = resource.content.data;
      } else {
        $scope.approvalFlowData = [];
      }
    },
    function () {
      if ($scope.budget.creator_info.display_name !== 'Bifrost') {
        toastr.error('Failed to get approval flow data for this budget.');
      }
    });
  }

  function activateTab() {
    status = _.cloneDeep($scope.budget.status.toLowerCase());

    var tab = globalFunc.findInArray($scope.$parent.tabData, 'key', status);
    if (!tab)
      tab = globalFunc.findInArray($scope.$parent.tabData.more, 'key', status);

    return !!tab ? tab : $scope.$parent.tabData[0];
  }

  /**
   * Get the approval flow to display it in draft
   */
  function approvalsPreview() {
    $rootScope.$broadcast('saveDraftBudget');
  }

  function convertToLocalDate(date) {
    return new Date(date);
  }

  function prepareCostCenter(){
    if (!$scope.budget.is_all_cost_center && !!$scope.budget.cost_center && $scope.budget.cost_center.length > 0) {
      $scope.budget.cost_center = $scope.budget.cost_center[0];
    }
    else {
      $scope.budget.cost_center = {
        'descr': 'All',
        'code': 'ALL',
        '_id': ''
      };
    }
  }

  function prepareShadowCompany(){
    if (!$scope.budget.is_all_shadow_companies && !!$scope.budget.shadow_companies && $scope.budget.shadow_companies.length > 0) {
      $scope.budget.shadow_companies = $scope.budget.shadow_companies[0];
    }
    else {
      $scope.budget.shadow_companies = {
        'descr': 'All',
        'code': 'ALL',
        '_id': ''
      };
    }
  }

  function getAssetCategoryData() {
    assetCategoryList.get(
      {
        code: $scope.budget.company.code
      },
      function(resource) {
        if (!!resource.data){
          $scope.assetCategories = resource.data;
          $scope.selectedAssetCategory = globalFunc.findInArray($scope.assetCategories, 'code', $scope.budget.asset_category);
        }
      }
    )
  }

  function loadCompanyData() {
    getAllCompanies.get({
      'fields[0]': 'code',
      'fields[1]': 'budget_setting',
      'fields[2]': 'shadow_companies'
    }, function (resource) {
      if (!!resource.content && !!resource.content.data) {
        var company = _.find(resource.content.data, function (company){
          return company._id === $scope.budget.company._id
        });

        //TODO: move 'isCorrectAppLocation' into the budget service (Ahmed Saleh, 7/7/2021)
        $scope.budget.isCorrectAppLocation = !!company.budget_setting.offline_budget === !!$rootScope.isOffline;
        budgetFunctions.setBudgetApprovalSetting($scope.budget.isCorrectAppLocation);

        if (!_.isEmpty($scope.budget.company) && _.isEmpty($scope.budget.enable_opex_grouping) && !$scope.opexBudgetGrouping) {
          $scope.opexBudgetGrouping = company.budget_setting.opex_budget_grouping || false;
        }
        _.forEach(company.shadow_companies, function(e){
          $scope.shadowCompanyList.push({
            'descr': e.descr,
            'code': e.code,
            '_id': e._id
          });
        });
      }
    }, function (error) {
      toastr.error('Failed to retrieve company data.');
    })
  }

  /**
   * Function to disabled comment and attachment for system support role
   * @returns boolean
   */
  function canEditAttachmentAndComment() {
    var disableEdit = false;
    if ($scope.isSystemSupportRole) {
      disableEdit = true;
    }
    return disableEdit;
  }

  function initialize() {
    $scope.originalBudgetData = _.cloneDeep(budgetData);
    $scope.budget = _.cloneDeep(budgetData);
    $scope.$parent.status = $stateParams.status;

    if (!_.isEmpty($scope.budget.parent_id)) {
      _.merge($scope.budget, budgetRevisionData);
    }

    $scope.selectedExpenseTypeCategory = globalFunc.findInArray($scope.budgetCategories,'value', $scope.budget.expense_type_category);
    prepareCostCenter();
    prepareShadowCompany();
    var tabData = {
      tab: activateTab(),
      noTransition: true
    };

    // TODO: remove this with the new design (Justin 27/11/17)
    $timeout(function () {
      $rootScope.$broadcast('loadSpecificTab', tabData);
    }, 300);

    setBudgetEditableState();
    setAssignmentRemovableState();
    if (!!$scope.budget.item_breakdowns) {
      budgetFunctions.setItemBreakdownRemovableState($scope.budget.item_breakdowns);
    }
    $scope.budget.start_date = !!budgetData.start_date ? convertToLocalDate(budgetData.start_date) : null;
    $scope.budget.end_date = !!budgetData.end_date ? convertToLocalDate(budgetData.end_date) : null;
    $scope.budgetItemBreakdownSwitch = !!$scope.budget.item_breakdowns.length;
    $scope.budgetAssignSwitch = !!$scope.budget.assignments.length;
    getExpenseTypes(budgetData.company._id);
    getCostCenter();
    getBudgetOwners(budgetData.company.code);
    getCurrencies(budgetData.company._id);

    if (!!$scope.budget.assignments) {
      _.forEach($scope.budget.assignments, function (budgetAssignment, index) {
        budgetAssignment.start_date = !!budgetData.assignments[index].start_date ?
          convertToLocalDate(budgetData.assignments[index].start_date) : null;
        budgetAssignment.end_date = !!budgetData.assignments[index].end_date ?
          convertToLocalDate(budgetData.assignments[index].end_date) : null;
      });
    }

    getBudgetAttachments();
    getApprovalFlow();
    getAssetCategoryData();
    loadCompanyData();

    $scope.$watch('budget.cost_center.length', function () {
      _.forEach($scope.budget.assignments, function (budgetAssignment, index) {
        if (!!budgetAssignment.cost_center) {
          var temp = globalFunc.findInArray($scope.budget.cost_center, '_id', budgetAssignment.cost_center._id);
        }
        if (!temp) {
          $scope.budget.assignments[index].cost_center = [];
        }
      });
    });

    if (!_.isEmpty($scope.budget.years)) {
      $scope.disableYearSelection = false;
      $scope.budgetYears = _.map($scope.budget.years, function(e){
        return {
          year: e.value.toString(),
          value: e.value
        }
      });
    }

    $scope.$watch('budget.expense_type', function (newValue) {
      if (!!newValue) {
        _.forEach($scope.budget.assignments, function (budgetAssignment, index) {
          $scope.budget.assignments[index].expense_type = newValue;
        });
      }
    });

    $scope.$watch('budget.apportion_type', function (newValue) {
      if (!!newValue) {
        _.forEach($scope.budget.assignments, function (budgetAssignment, index) {
          if (newValue !== 'N/A') {
            $scope.budget.assignments[index].apportion_type = newValue;
          }
        });
      }
    });
  }

  initialize();
}

budgetDetailsCtrlV2.$inject = [
  '$scope', 'toastr', 'companyExpenseTypes', 'userBudget', 'globalFunc',
  'budgetData', 'singleCommentsHistory', 'budgetCommentList', 'attachmentsByReference', 'singleAttachment',
  '$rootScope', '$filter', 'pathConstants', '$stateParams', 'budgetFunctions', 'Approvals', 'companyCurrencies',
  '$timeout', 'profile', 'accountCodesList', 'searchModule', 'assetCategoryList', 'budgetRevisionData', 'getAllCompanies'
];

angular.module('metabuyer')
  .controller('budgetDetailsCtrlV2', budgetDetailsCtrlV2);

