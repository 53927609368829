'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider) {

    $stateProvider
      .state('main.user.bifrostDataReinbound', {
        url: 'bifrost-data-reinbound',
        controller: 'bifrostDataReinboundMainCtrl',
        templateUrl: 'app/bifrostDataReinbound/index.html',
        resolve: {
          accessRights: function (profile, $state, globalFunc) {
            if (!(globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN') ||
                globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'System Support'))) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.bifrostDataReinbound.manage', {
        url: '/manage',
        controller: 'bifrostDataReinboundManageCtrl',
        templateUrl: 'app/bifrostDataReinbound/manage/manage.html',
        resolve: {
          $title: function () {
            return 'Bifrost Data Reinbound';
          }
        }
      })
  }]);

