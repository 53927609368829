'use strict';
/**
 * @name MasterDataManageExchangeRateCtrl
 * @desc Controller for master data exchange rate controller
 *
 * @param $scope
 * @param $stateParams
 * @param $uibModal
 * @param toastr
 * @param exchangeRateMasterDataList
 * @param ExchangeRate
 * @param globalFunc
 * @param currencies
 * @param $location
 * @param $filter
 * @param pathConstants
 * @param dataList
 *
 * @function newExchangeRate
 * This function is for adding a new exchange rate. By passing 'global' as company_id, exchange rate created is global level
 *
 * @function validate
 * This function is for validating the fields before adding new exchange rate
 *
 * @function exchangeRateDetail
 * This function is to open a modal displaying the details of the exchange rate
 *
 * @function search
 * This function is to search/filter the exchange rates
 *
 * @function reset
 * This function is to reset the data/clear the search results
 *
 * @function importExchangeRate
 * This function is to import the exchange rates to csv
 *
 * @author Justin Cheong Tian Yee <justin.cty90@gmail.com>, Ahmed Saleh <a.s.alsalali@gmail.com>
 * @copyright Sunway Metacloud &copy; 2016
 */
function MasterDataManageExchangeRateCtrl($scope, $stateParams, $uibModal, toastr,
                                          exchangeRateMasterDataList, ExchangeRate,
                                          globalFunc, currencies, $location, $filter, pathConstants, dataList, $rootScope) {

  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();
  
  function onSelectedFromCurrency(selected_data){
    $scope.exchangeRate.source_currency_id = selected_data._id;
  }

  function onSelectedToCurrency(selected_data){
    $scope.exchangeRate.target_currency_id = selected_data._id;
  }

  function openDatepicker($event, which) {
    /**
     * Setting minimum selection for start date to be today
     */
    $scope.startDateOptions.minDate = new Date();

    /**
     * Setting the minimum selection for end date to be one day ahead of selected start date
     */
    var startDateClone = _.cloneDeep($scope.exchangeRate.effective_at);
    var startDate = new Date(startDateClone);
    startDate.setDate(startDate.getDate() + 1);
    $scope.endDateOptions.minDate = startDate;

    $event.preventDefault();
    $event.stopPropagation();

    $scope.datepickerOpened[which] = true;
  }

  $scope.columns= [
        {id: 'code', label: 'Code'},
        {id: 'descr', label: 'Description'},
        {id: 'multiplier_rate', label: 'Rate'},
        {id: 'updated_at', label: 'Updated At'},
        {id: 'updater.display_name', label: 'Updated By'},
        {id: 'created_at', label: 'Created At'},
        {id: 'creator.display_name', label: 'Created By'},
        {id: 'effective_at', label: 'Effective At'},
        {id: 'expiry_at', label: 'Expire At'},
        {id: 'status', label: 'Status'}
      ];

  $scope.storageKey = 'master-data-exchange-rate-management-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  /**
   * returns the is_active name
   * @param code
   * @returns {*}
   */
  function getActiveStatusName(code){
    /**
     DEACTIVATED = 0; 
     ACTIVE = 1; 
     DELETED = 2; 
     DISABLED = 3; 
     */
    if (code === 0 || code === 'DEACTIVATED') {
      return 'Deactivated'
    }
    if (code === 1 || code === 'ACTIVE') {
      return 'Active'
    }
    if (code === 2 || code === 'DELETED') {
      return 'Deleted'
    }
    if (code === 3 || code === 'DISABLED') {
      return 'Disabled'
    }
    return 'N/A';
  }

  function importGlobalExchangeRate() {
    var modalInstance = $uibModal.open({
      templateUrl: 'components/exchangeRate/Import-Template.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return $scope.importLink;
        },
        title: function () {
          return 'Exchange Rate';
        },
        id: function() {
          return 'global';
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title, id) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.importLink = importLink;
        $scope.title = title;
        $scope.id = id;
      }
    });
  }

  $scope.exchangeRateStatuses = [
    {value: 5, text: 'ACTIVE'},
    {value: 6, text: 'DEACTIVATED'},
    {value: 7, text: 'DISABLED'}
  ];

  function search(query) {
    $location.search({query: query});
  }

  function clearForm(){
    $scope.exchangeRate.descr = '';
    $scope.exchangeRate.status = '';
    $scope.exchangeRate.multiplier_rate = '';
    $scope.exchangeRate.effective_at = '';
    $scope.exchangeRate.expiry_at = '';
    $scope.exchangeRate.fromCurrency = undefined;
    $scope.exchangeRate.toCurrency = undefined;
    $scope.exchangeRate.source_currency_id = undefined;
    $scope.exchangeRate.target_currency_id = undefined;
  }

    $scope.validateMultiplierRate = validateMultiplierRate;
    function validateMultiplierRate(multiplier_rate) {
        return Number(multiplier_rate) === 0;
    }

  function validate(exchangeRate){
    if(!exchangeRate.fromCurrency || !exchangeRate.toCurrency ||
      (exchangeRate.fromCurrency === exchangeRate.toCurrency)){
      return false;
    }
    if(!exchangeRate.multiplier_rate || Number(exchangeRate.multiplier_rate) === 0){
      return false;
    }
    if(!exchangeRate.descr){
      return false;
    }
    if(!exchangeRate.effective_at || !exchangeRate.expiry_at){
      return false;
    }

    if(exchangeRate.effective_at >= exchangeRate.expiry_at){
      return false;
    }

    return true;
  }

  function newExchangeRate() {
    $scope.submitted = true;

    if(!!validate($scope.exchangeRate)) {
      if(!!$scope.exchangeRate){
        $scope.exchangeRate.status = 2;
      }
      //Formatting the dates before posting
      $scope.exchangeRate.effective_at = globalFunc.convertDateToTimestamp($scope.exchangeRate.effective_at);
      $scope.exchangeRate.expiry_at = globalFunc.convertDateToTimestamp($scope.exchangeRate.expiry_at);


      var submit_data = {
        descr: $scope.exchangeRate.descr,
        status: $scope.exchangeRate.status,
        multiplier_rate: $scope.exchangeRate.multiplier_rate,
        effective_at: $scope.exchangeRate.effective_at,
        expiry_at: $scope.exchangeRate.expiry_at,
        source_currency_id: $scope.exchangeRate.source_currency_id,
        target_currency_id: $scope.exchangeRate.target_currency_id
      };

      exchangeRateMasterDataList.post(
        {
          id: 'global'
        },
        submit_data,
        function (resource) {
          toastr.success('Exchange rate was added successfully');
          if (!!resource.content) {
            if (!!resource.content.data) {
              var addedExchangeRate = resource.content.data;
              $scope.dataList.unshift({
                _id: addedExchangeRate._id,
                code: addedExchangeRate.code,
                descr: addedExchangeRate.descr,
                multiplier_rate: addedExchangeRate.rate,
                effective_at: addedExchangeRate.effective_at,
                expiry_at: addedExchangeRate.expiry_at,
                created_at: addedExchangeRate.created_at,
                updated_at: addedExchangeRate.updated_at,
                updater: [$scope.user],
                creator: [$scope.user],
                is_active: 1
              });
            }
          }

          clearForm();
          $scope.submitted = false;
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
        }
      )
    }
    else{
      toastr.error('Please fill in all the fields');
    }
  }

  function exchangeRateDetail(exchangeRate) {
    var exchangeRateId = $scope.dataList[exchangeRate]._id;
    var modalInstance = $uibModal.open({
      templateUrl: 'components/exchangeRate/details/details.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'exchangeRateDetailsCtrl',
      size: 'lg',
      resolve: {
        currencyExchange: function(){
          return $scope.currencyExchange;
        },
        exchangeRateDetails: function($q, exchangeRateSpecificRate) {
          var deferred = $q.defer();
          exchangeRateSpecificRate.get(
            {
              rate_id: exchangeRateId,
              id: 'global'
            },
            function (resource) {
              deferred.resolve(resource.content || {data: []});
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        }
      }
    });

    modalInstance.result.then(function (newValue) {
      if(!!newValue){
        _.forEach($scope.dataList, function(dataList){
          if(dataList._id === newValue._id){
            dataList.code = newValue.code;
            dataList.descr = newValue.descr;
            dataList.multiplier_rate = newValue.rate;
            dataList.effective_at = newValue.effective_at;
            dataList.expiry_at = newValue.expiry_at;
            dataList.is_active = newValue.is_active;
            dataList.updater[0].display_name = newValue.updated_by;
            dataList.updated_at = newValue.updated_at;
          }
        });
      }
    });
  }

  function reset (){
    $scope.query = false;
    exchangeRateMasterDataList.get(
      {
        id: 'global'
      },
      function (resource) {
        if(!!resource.content){
          if(!!resource.content.data) {
            $scope.dataList = resource.content.data;
          }
        }
      },
      function (error) {
        toastr.error(error);
      });
  }

  function convertScientificNotation(num) {
    // scientific numbers from bifrost gets stored in this format "5E-8",
    // while input from synergy will store "0.00000005" for the same number
    // we handle this display in the front end since it wont affect calculations
    if (_.contains(num, 'E') || _.contains(num, 'e')) {
      num = Number(num);
      num = num.toString();
    }

    return num.toLocaleString('fullwide', { useGrouping: false, maximumFractionDigits: 6});
  }

  function initialize(){

    $scope.dataList = dataList.data;
    $scope.meta = dataList.meta;
    $scope.accountCodesListService = exchangeRateMasterDataList;
    $scope.newExchangeRate = newExchangeRate;
    $scope.validate = validate;
    $scope.exchangeRateDetail = exchangeRateDetail;
    $scope.search = search;
    $scope.reset = reset;
    $scope.showColumn = showColumn;
    $scope.getActiveStatusName = getActiveStatusName;
    $scope.importGlobalExchangeRate = importGlobalExchangeRate;
    $scope.importLink = pathConstants.apiUrls.globalExchangeRate.import;
    $scope.exchangeRate = {};

    // Datepicker
    $scope.openDatepicker = openDatepicker;

    $scope.startDateOptions = {
      formatYear: 'yy',
      startingDay: 1
    };

    $scope.endDateOptions = {
      formatYear: 'yy',
      startingDay: 1
    };

    $scope.datepickerOpened = {
      start_date: false,
      end_date: false
    };
    $scope.currencyExchange = currencies.data;

    $scope.onSelectedFromCurrency = onSelectedFromCurrency;
    $scope.onSelectedToCurrency = onSelectedToCurrency;
    $scope.convertScientificNotation = convertScientificNotation;

    /**
     * setting the model with the searched text
     */
    if(!!$stateParams.search){
      $scope.searchText = $stateParams.search;
    }
    else{
      $scope.searchText = '';
    }
  }

  initialize();
}

MasterDataManageExchangeRateCtrl.$inject = [
  '$scope', '$stateParams', '$uibModal', 'toastr',
  'exchangeRateMasterDataList', 'ExchangeRate',
  'globalFunc', 'currencies', '$location', '$filter', 'pathConstants', 'dataList', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('MasterDataManageExchangeRateCtrl', MasterDataManageExchangeRateCtrl);
