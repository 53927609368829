'use strict';

/**
 * @name MasterDataManageAlcGroupsCtrl
 * @desc Controller for master data alc groups controller
 */
function MasterDataManageAlcGroupsCtrl($scope, dataList, alcGroupMasterDataList, toastr,
                                                    $uibModal, $location, pathConstants, globalFunc, $stateParams,
                                                    searchModule, $rootScope) {
  $scope.dataList = dataList.data;
  $scope.meta = dataList.meta;
  $scope.alcGroupMasterDataList = alcGroupMasterDataList;
  $scope.addNewAlcGroup = addNewAlcGroup;
  $scope.openAlcGroupDetails = openAlcGroupDetails;
  $scope.search = search;
  $scope.showColumn = showColumn;
  $scope.importAlcGroup = importAlcGroup;
  $scope.params = {};
  $scope.alcGroup = {};
  $scope.convertBoolean = convertBoolean;
  $scope.getActiveStatusName = getActiveStatusName;
  $scope.selectCompany = selectCompany;
  $scope.selectedCompanies = [];
  $scope.submitted = false;
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();

  $scope.columns = [
    {id: 'code', label: 'Code'},
    {id: 'name', label: 'Name'},
    {id: 'descr', label: 'Description'},
    {id: 'is_active', label: 'Status'},
    {id: 'updated_at', label: 'Updated At'},
    {id: 'updater_info.display_name', label: 'Updated By'},
    {id: 'created_at', label: 'Created At'},
    {id: 'creator_info.display_name', label: 'Created By'}
  ];

  $scope.storageKey = 'master-data-alc-groups-management-selected-columns';

  function showColumn(id) {
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if (!!$scope.columns[i].conditional) {
          if ($scope.status === $scope.columns[i].condition) {
            return $scope.columns[i].selected;
          }
          if ($scope.columns[i].condition instanceof Array) {
            for (var j in $scope.columns[i].condition) {
              if ($scope.status === $scope.columns[i].condition[j]) {
                return $scope.columns[i].selected;
              }
            }
          }
        } else {
          return $scope.columns[i].selected;
        }
      }
    }
  }

  function importAlcGroup() {
    $uibModal.open({
      templateUrl: 'app/masterDataManagement/Import-Template.html',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        importLink: function () {
          return pathConstants.apiUrls.imports.now;
          ;
        },
        params: function () {
          return {
            'class': 'App\\Metabuyer\\AlcGroup\\Models\\AlcGroup',
          }
        },

        title: function () {
          return 'Alc Group';
        }
      },
      controller: function ($scope, $uibModalInstance, importLink, title, params) {
        $scope.cancel = function () {
          $uibModalInstance.close();
        };

        $scope.importLink = importLink;
        $scope.title = title;
        $scope.params = params;
      }
    });
  }

  function addNewAlcGroup() {
    $scope.submitted = true;
    if (!validate($scope.alcGroup)) {
      return;
    }

    var companyCodes = $scope.selectedCompanies.map(function (value, key) {
      return value.code;
    });

    alcGroupMasterDataList.post(
      {
        code: $scope.alcGroup.code,
        name: $scope.alcGroup.name,
        descr: $scope.alcGroup.descr,
        company_codes: companyCodes
      },
      function (resource) {
        toastr.success('A new Alc Group has been added successfully');
        var addedAlcGroup = resource.content.data;
        $scope.dataList.unshift({
          _id: addedAlcGroup._id,
          code: addedAlcGroup.code,
          name: addedAlcGroup.name,
          descr: addedAlcGroup.descr,
          is_active: addedAlcGroup.is_active,
          created_at: addedAlcGroup.created_at,
          updated_at: addedAlcGroup.updated_at,
          creator_info: addedAlcGroup.creator_info,
          updater_info: addedAlcGroup.updater_info
        });

        $scope.submitted = false;
        $scope.selectedCompanies = [];
        $scope.alcGroup.code = '';
        $scope.alcGroup.name = '';
        $scope.alcGroup.descr = '';
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
      }
    );
  }

  function search(query) {
    $location.search({query: query});
  }

  function validate(alcGroup) {
    if (!alcGroup) {
      return false;
    }
    if (!alcGroup.code) {
      return false;
    }
    if ($scope.selectedCompanies.length === 0) {
      return false;
    }
    if (!alcGroup.name) {
      return false;
    }

    return true;
  }

  function openAlcGroupDetails(index) {
    var alcGroupUuid = $scope.dataList[index].uuid;
    var modalInstance = $uibModal.open({
      templateUrl: 'app/masterDataManagement/alcGroups/details/details.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'alcGroupDetailsCtrl',
      size: 'lg',
      resolve: {
        alcGroupDetails: function ($q, alcGroupMasterDataList) {
          var deferred = $q.defer();
          alcGroupMasterDataList.get(
            {
              uuid: alcGroupUuid
            },
            function (resource) {
              deferred.resolve(resource.content || {data: []});
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        },
        companies: function () {
          return $scope.companies;
        }
      }
    });

    modalInstance.result.then(
      function (newValue) {
        if (!!newValue) {
          _.forEach($scope.dataList, function (dataList) {
            if (dataList._id === newValue._id) {
              dataList.code = newValue.code;
              dataList.name = newValue.name;
              dataList.descr = newValue.descr;
              dataList.is_active = newValue.is_active;
              dataList.updater[0].display_name = newValue.updated_by;
              dataList.updated_at = newValue.updated_at;
            }
          });
        }
      }, function () {
      }
    );
  }

  function convertBoolean(value) {
    return value === true ? 'Yes' : value === false ? 'No' : value;
  }

  function getActiveStatusName(value) {
    return !value ? 'Deactivated' : 'Active';
  }

  //region Company assignment
  function loadCompanies() {
    $scope.loadingCompaniesList = true;
    searchModule.get(
      {
        module: 'companies',
        offset: 0
      },
      function (resource) {
        $scope.loadingCompaniesList = false;
        if (!!resource.content && !!resource.content.data) {
          $scope.companies = resource.content.data;
        }
      },
      function (error) {
        globalFunc.objectErrorMessage(error);
        $scope.loadingCompaniesList = false;
      }
    );
  }

  function selectCompany(company, action) {
    $scope.submitted = false;
    if (action === 'assign') {
      $scope.selectedCompanies.push(company);
    }
    else {
      $scope.selectedCompanies = globalFunc.removeValueFromArray($scope.selectedCompanies, '_id', company._id);
    }
  }
  //endregion

  function initialize() {
    /**
     * setting the model with the searched text
     */
    if (!!$stateParams.search) {
      $scope.searchText = $stateParams.search;
    }
    else {
      $scope.searchText = '';
    }

    $scope.selectedCompanies = [];
    loadCompanies();
  }

  initialize();
}

MasterDataManageAlcGroupsCtrl.$inject = [
  '$scope', 'dataList', 'alcGroupMasterDataList', 'toastr', '$uibModal',
  '$location', 'pathConstants', 'globalFunc', '$stateParams', 'searchModule', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('MasterDataManageAlcGroupsCtrl', MasterDataManageAlcGroupsCtrl);
