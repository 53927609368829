'use strict';

/**
 * @name addressModalCtrl
 * @desc Controller for edit address controller
 */
function addressModalCtrl(
  $scope, $uibModalInstance, addressType, toastr, singleAddress, globalFunc, addressDetail,
  $uibModal, updateAddressResult, $rootScope
) {

  $scope.addressType = addressType;
  $scope.addressDetail = addressDetail;
  $scope.updateAddressResult = updateAddressResult;
  $scope.onUpdatedState = onUpdatedState;
  $scope.onUpdatedCountry = onUpdatedCountry;
  $scope.close = close;
  $scope.saveEditAddress = saveEditAddress;
  $scope.viewAddressHistory = viewAddressHistory;
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();


  function viewAddressHistory() {
    $uibModal.open({
      templateUrl: 'app/companiesManagement/companies/addressHistoryModal/addressHistoryModal.html',
      backdrop: 'static',
      keyboard: false,
      controller: 'addressHistoryModalCtrl',
      size: 'lg',
      resolve: {
        histories: function ($q, commentsHistory) {
          var deferred = $q.defer();
          commentsHistory.get(
            {
              referenceId: $scope.addressDetail._id,
              offset: 5
            },
            function (resource) {
              deferred.resolve(resource.content || []);
            },
            function (error) {
              if (error.status === 404) {
                deferred.resolve([]);
              }
            }
          );
          return deferred.promise;
        },
        addressDetail: function () {
          return $scope.addressDetail;
        }
      }
    });
  }

  function close() {
    $uibModalInstance.close();
  }

  function onUpdatedCountry(country){
    $scope.address.state = undefined;
    $scope.address.country = country.descr;
    if(country.descr === "Malaysia"){
      $scope.selectedMalaysia = true;
    }
    else
    {
      $scope.selectedMalaysia = false;
    }
  }

  function onUpdatedState(state){
    $scope.address.state = state.descr;
  }

  /**
   * To save the changes address to address book endpoint
   */
  function saveEditAddress() {
    $scope.submitted = true;
    if(validate($scope.address)){
      singleAddress.put({
          id: addressDetail._id
        },$scope.address,
        function(resource){
          toastr.success("Successfully updated the address");
          $scope.updateAddressResult($scope.address, $scope.addressType);
          close();
        }, function(error){
          globalFunc.objectErrorMessage(error);
        }
      );
    } else {
      toastr.error("Fill the required field");
    }
  }

  function validate() {
      if (!$rootScope.isV2Enabled) {
        return ($scope.address.line1 && $scope.address.postal_code && $scope.address.state &&
          $scope.address.city && $scope.address.country && $scope.address.phone && $scope.address.name);
      } else {
        return ($scope.address.line1 && $scope.address.postal_code && $scope.address.country &&
          $scope.address.phone && $scope.address.name);
      }
  }

  function initialize(){
    $scope.countries = globalFunc.countryCode();
    $scope.MYStates = globalFunc.malaysiaState();
    $scope.address = addressDetail;

    if($scope.address.country === 'Malaysia')
    {
      $scope.selectedMalaysia = true;
    }else {
      $scope.selectedMalaysia = false;
    }
  }

  initialize();

}

addressModalCtrl.$inject = [
  '$scope', '$uibModalInstance', 'addressType', 'toastr', 'singleAddress', 'globalFunc',
  'addressDetail', '$uibModal', 'updateAddressResult', '$rootScope'
];

angular
  .module('metabuyer')
  .controller('addressModalCtrl', addressModalCtrl);
