angular
    .module('metabuyer')
    .directive('ordersAdvancedSearchV1', function () {
        return {
            restrict: 'E',
            templateUrl: 'app/advancedSearch/orders/v1/template.html',
            scope: {},
            controller: function ($scope, searchModule, listChild, toastr, $rootScope, advancedSearch, exportSearch, metabuyerCache) {
                $scope.searchSuppliers = $rootScope.searchSuppliers;
                var searchCacheKey = 'orderAdvancedSearch';
                var searchFields = {
                    doNumber: null,
                    poNumber: null,
                    poStatus: { selected: { id: 1, key: 'all', value: 'All' } },
                    supplier: null,
                    totalPriceFrom: null,
                    totalPriceTo: null,
                    totalCostOfPoFrom: null,
                    totalCostOfPoTo: null,
                    prNumber: null,
                    prTitle: null,
                    company: null,
                    costCenter: null,
                    itemTitle: null,
                    itemDescription: null,
                    creator: null,
                    createdOnBehalf: null,
                    commodityKeyword: null,
                    createdDateFrom: null,
                    createdDateTo: null
                };


                $scope.poStatusOptions = [
                    {
                        id: 1,
                        key: 'all',
                        value: 'All',
                    },
                    {
                        id: 2,
                        key: 'SUBMITTED',
                        value: 'Awaiting Acknowledgement',
                    },
                    {
                        id: 3,
                        key: 'DELIVERY_DATE_UNCONFIRMED',
                        value: 'Delivery Date Unconfirmed',
                    },
                    {
                        id: 4,
                        key: 'DECLINED',
                        value: 'Declined',
                    },
                    {
                        id: 5,
                        key: 'AWAITING_DELIVERY',
                        value: 'Awaiting Delivery',
                    },
                    {
                        id: 6,
                        key: 'PARTIALLY_DELIVERED',
                        value: 'Partially Delivered',
                    },
                    {
                        id: 7,
                        key: 'FULLY_DELIVERED',
                        value: 'Delivered',
                    },
                    {
                        id: 8,
                        key: 'CANCELLED',
                        value: 'Cancelled',
                    },
                    {
                        id: 9,
                        key: 'CLOSED',
                        value: 'Closed',
                    },
                    {
                        id: 10,
                        key: 'EXPIRED',
                        value: 'Expired',
                    },
                ];
                $scope.columns = [
                    { id: 'po_number', label: 'PO #', selected: true },
                    { id: 'pr_number', label: 'PR #', selected: true },
                    { id: 'title', label: 'PR Title', selected: true },
                    { id: 'attachment', unsortable: true, unsearchable: true, unSelectable: true, selected: true },
                    { id: 'billing|company|descr', label: 'Company', selected: true },
                    { id: 'supplier_object|descr', label: 'Supplier', selected: true },
                    { id: 'created_at', label: 'PO Date', type: 'date', unsearchable: true, selected: true },
                    { id: 'received_percentage', label: 'Received %', unsearchable: true, selected: true },
                    { id: 'do_number', label: 'DO #', selected: true },
                    { id: 'net_total', label: 'Amount', type: 'double', unsearchable: true, selected: true },
                    { id: 'updated_at', label: 'Updated At', selected: true },
                    { id: 'creator_info|display_name', label: 'Creator', selected: true },
                    { id: 'requestor|display_name', label: 'Requestor', selected: true }
                ];

                $scope.doNumber = null;
                $scope.poNumber = null;
                $scope.poStatus = { selected: $scope.poStatusOptions[0] };
                $scope.supplier = null;
                $scope.totalPriceFrom = null;
                $scope.totalPriceTo = null;
                $scope.totalCostOfPoFrom = null;
                $scope.totalCostOfPoTo = null;
                $scope.prNumber = null;
                $scope.prTitle = null;
                $scope.company = null;
                $scope.costCenter = null;
                $scope.itemTitle = null;
                $scope.itemDescription = null;
                $scope.commodityCode = {};
                $scope.creator = null;
                $scope.createdOnBehalf = null;
                $scope.createdDate = null;
                $scope.commodityKeyword = null;

                $scope.commoditySearching = false;
                $scope.commodityLevel = [4];
                $scope.datePickerTimezone = $rootScope.datePickerTimezone;
                $scope.datepickerOpened = {
                    createdDate: false
                };
                var dateOptions = {
                    formatYear: 'yy',
                    startingDay: 1,
                };
                $scope.datePickerOptions = {
                    createdDateFrom: _.clone(dateOptions),
                    createdDateTo: _.clone(dateOptions),
                };
                $scope.closeTreeSearch = false;
                $scope.errors = {};
                var toastrErrorMessage = null;

                $scope.resetForm = resetForm;
                $scope.submitAdvSearch = submitAdvSearch;
                $scope.searchCommodityCode = searchCommodityCode;
                $scope.openChildCommodityCode = openChildCommodityCode;
                $scope.openDatePicker = openDatePicker;
                $scope.validateAmountRange = validateAmountRange;
                $scope.clearCommodityField = clearCommodityField;
                $scope.hasError = hasError;
                $scope.addSearchCriteria = addSearchCriteria;


                /**
                 * function to reset the search form
                 */
                function resetForm() {
                    $scope.doNumber = null;
                    $scope.poNumber = null;
                    $scope.poStatus = { selected: $scope.poStatusOptions[0] };
                    $scope.supplier = null;
                    $scope.totalPriceFrom = null;
                    $scope.totalPriceTo = null;
                    $scope.totalCostOfPoFrom = null;
                    $scope.totalCostOfPoTo = null;
                    $scope.prNumber = null;
                    $scope.prTitle = null;
                    $scope.company = null;
                    $scope.costCenter = null;
                    $scope.itemTitle = null;
                    $scope.itemDescription = null;
                    $scope.commodityCode = {};
                    $scope.creator = null;
                    $scope.createdOnBehalf = null;
                    $scope.createdDateFrom = null;
                    $scope.createdDateTo = null;
                    $scope.commodityKeyword = null;
                    $scope.errors = {};
                    $scope.dataList = [];
                    metabuyerCache.remove(searchCacheKey);

                }

                /**
                 * submit search
                 */
                function submitAdvSearch() {

                    var searchParam = validateForm();

                    if (!!searchParam) {
                        $scope.embeddedParams = searchParam;

                        /**
                         * Setting the params to prepare for exporting
                         */
                        exportSearch.setParams(searchParam);

                        advancedSearch.get(
                            searchParam,
                            function (resource) {
                                $scope.dataList = resource.content.data;
                                $scope.meta = resource.content.meta;
                                $scope.emptyInput = false;

                            },
                            function (error) {
                                toastr.error('Something went wrong');
                                $scope.dataList = [];
                            }
                        );
                    } else {
                        toastr.error(toastrErrorMessage);
                        toastrErrorMessage = null;
                        $scope.emptyInput = true;
                    }

                    $('#show-search-form').removeClass('hidden');
                    $('#hide-search-form').addClass('hidden');

                }

                /**
                 * function to check if a specific field has error.
                 * @param field field name
                 * @returns {boolean}
                 */
                function hasError(field) {
                    return (field in $scope.errors);
                }

                /**
                 * function to validate form input values
                 * @returns {boolean|Object}
                 */
                function validateForm() {

                    /**
                     * logic for range fields (total price, total cost of po, and all date fields)
                     * if `from` field is not empty, set `to` field to same value as `from` value
                     * if `to` field is not empty, `from` field is mandatory.
                     * if both are empty, skip validation
                     *
                     */

                    // total price range
                    if (!!$scope.totalPriceTo) {
                        if (!!$scope.totalPriceFrom) {
                            if ($scope.totalPriceFrom > $scope.totalPriceTo) {
                                $scope.errors.totalPrice = 'Invalid Range';
                            }
                        } else {
                            $scope.errors.totalPrice = '\'From\' field is mandatory';
                        }
                    } else if (!!$scope.totalPriceFrom) {
                        $scope.totalPriceTo = $scope.totalPriceFrom;
                    }

                    // total cost of po range
                    if (!!$scope.totalCostOfPoTo) {
                        if (!!$scope.totalCostOfPoFrom) {
                            if ($scope.totalCostOfPoFrom > $scope.totalCostOfPoTo) {
                                $scope.errors.totalCostOfPo = 'Invalid Range';
                            }
                        } else {
                            $scope.errors.totalCostOfPo = '\'From\' field is mandatory';
                        }
                    } else if (!!$scope.totalCostOfPoFrom) {
                        $scope.totalCostOfPoTo = $scope.totalCostOfPoFrom;
                    }

                    //needed by date range
                    if (!!$scope.createdDateTo) {
                        if (!!$scope.createdDateFrom) {
                            if ($scope.createdDateFrom > $scope.createdDateTo) {
                                $scope.errors.createdDate = 'Invalid Range';
                            }
                        } else {
                            $scope.errors.createdDate = '\'From\' field is mandatory';
                        }
                    } else if (!!$scope.createdDateFrom) {
                        $scope.createdDateTo = $scope.createdDateFrom;
                    }

                    if (_.isEmpty($scope.errors)) {
                        var searchData = {
                            doNumber: $scope.doNumber,
                            poNumber: $scope.poNumber,
                            poStatus: $scope.poStatus.selected.key,
                            supplier: $scope.supplier,
                            totalPriceFrom: $scope.totalPriceFrom,
                            totalPriceTo: $scope.totalPriceTo,
                            totalCostOfPoFrom: $scope.totalCostOfPoFrom,
                            totalCostOfPoTo: $scope.totalCostOfPoTo,
                            prNumber: $scope.prNumber,
                            prTitle: $scope.prTitle,
                            company: $scope.company,
                            costCenter: $scope.costCenter,
                            itemTitle: $scope.itemTitle,
                            itemDescription: $scope.itemDescription,
                            commodityCode: $scope.commodityCode,
                            creator: $scope.creator,
                            createdOnBehalf: $scope.createdOnBehalf,
                            createdDateFrom: $scope.createdDateFrom,
                            createdDateTo: $scope.createdDateTo
                        };
                        return buildParam(searchData);
                    } else {
                        toastrErrorMessage = 'Invalid value in one or more fields';
                        return false;
                    }

                }

                /**
                 * function to build criteria parameters
                 * @param data object of form input values
                 * @returns {boolean|Object}
                 */
                function buildParam(data) {

                    var params = {};

                    if (!!data.doNumber) {
                        params['criteria[0][do_number]'] = data.doNumber;
                    }
                    if (!!data.poNumber) {
                        params['criteria[0][po_number]'] = data.poNumber;
                    }
                    if (!!data.prNumber) {
                        params['criteria[0][pr_number]'] = data.prNumber;
                    }
                    if (!!data.prTitle) {
                        params['criteria[0][requisition|title]'] = data.prTitle;
                    }
                    if (!!data.totalPriceFrom) {
                        params['criteria[0][items|net_total][from]'] = data.totalPriceFrom;
                        params['criteria[0][items|net_total][to]'] = data.totalPriceTo;
                    }
                    if (!!data.totalCostOfPoFrom) {
                        params['criteria[0][net_total][from]'] = data.totalCostOfPoFrom;
                        params['criteria[0][net_total][to]'] = data.totalCostOfPoTo;
                    }
                    if (!!data.itemDescription) {
                        params['criteria[0][items|item|description]'] = data.itemDescription;
                    }
                    if (!!data.itemTitle) {
                        params['criteria[0][items|item|item_name]'] = data.itemTitle;
                    }
                    if (!!data.company) {
                        params['criteria[0][billing|company|descr]'] = data.company;
                    }
                    if (!!data.costCenter) {
                        params['criteria[0][billing|cost_center|descr]'] = data.costCenter;
                    }

                    if (_.keys(data.commodityCode).length > 0) {
                        params['criteria[0][items|commodity|code]'] = data.commodityCode.code;
                    }

                    if (!!data.createdDateFrom) {
                        params['criteria[0][created_at][from]'] = moment(data.createdDateFrom).valueOf();
                        params['criteria[0][created_at][to]'] = moment(data.createdDateTo).endOf('day').valueOf();
                    }
                    if (data.poStatus !== 'all') {
                        params['criteria[0][status]'] = data.poStatus;
                    }

                    if (!!data.creator) {
                        params['criteria[0][creator_info|display_name]'] = data.creator;
                    }
                    if (!!data.createdOnBehalf) {
                        params['criteria[0][requestor|display_name]'] = data.createdOnBehalf;
                    }
                    if (!!data.supplier) {
                        params['criteria[0][supplier_object|descr]'] = data.supplier;
                    }

                    if (_.isEmpty(params)) {
                        toastrErrorMessage = 'Fill in at least one field';
                        return false;
                    } else {
                        params['module'] = 'PO';
                        params['order'] = -1;
                        params['order_by'] = '_id';
                        params['offset'] = 50;
                        params['criteria[0][$operator]'] = 'and';
                        return params;
                    }
                }

                /**
                 * function to search commodity code
                 * @param keyword
                 */
                function searchCommodityCode(keyword) {
                    if (!!keyword && keyword.length > 1) {
                        $scope.commoditySearching = true;
                        $scope.commodityCodes = [];
                        searchModule.get(
                            {
                                module: 'commodity',
                                'criteria[0][code]': keyword,
                                'criteria[0][descr]': keyword,
                                'criteria[0][$operator]': 'or',
                                'criteria[1][is_active]': 1,
                                order: 1,
                                order_by: 'code',
                                offset: 0
                            }, function (resource) {
                                $scope.commoditySearching = false;
                                if (!!resource && !!resource.content && !!resource.content.data)
                                    $scope.commodityCodes = resource.content.data;
                            }, function () {
                                $scope.commoditySearching = false;
                                $scope.commodityCodes = [];
                            });
                    }
                }

                /**
                 * clear commodity field
                 */
                function clearCommodityField() {
                    if ($scope.commodityKeyword === '') {
                        $scope.commodityCode = {};
                        $scope.closeTreeSearch = true;
                    }
                }

                /**
                 * Get children data for commodity Code
                 * @param cc
                 */
                function openChildCommodityCode(cc) {
                    listChild.get({
                        code: cc
                    }, function (resource) {
                        if (!!resource && !!resource.content && !!resource.content.data && !!resource.content.data.length > 0)
                            $scope.commodityCodes = resource.content.data;
                        else
                            toastr.error('There are no child on this commodity code');
                    }, function () {
                        toastr.error('Failed to get Commodity Codes data');
                    })
                }

                /**
                 * function to validate amount range fields, and update/remove error message
                 * @param field
                 */
                function validateAmountRange(field) {
                    if (field === 'totalPrice') {
                        if (!!$scope.totalPriceFrom && !!$scope.totalPriceTo && ($scope.totalPriceFrom > $scope.totalPriceTo)) {
                            $scope.errors.totalPrice = 'Invalid Range';
                        } else {
                            if ('totalPrice' in $scope.errors) {
                                delete $scope.errors.totalPrice;
                            }
                        }
                    } else if (field === 'totalCostOfPo') {
                        if (!!$scope.totalCostOfPoFrom && !!$scope.totalCostOfPoTo && ($scope.totalCostOfPoFrom > $scope.totalCostOfPoTo)) {
                            $scope.errors.totalCostOfPo = 'Invalid Range';
                        } else {
                            if ('totalCostOfPo' in $scope.errors) {
                                delete $scope.errors.totalCostOfPo
                            }
                        }
                    }
                }

                /**
                 * function to set datepicker fields' options.
                 * set minDate/maxDate if the related date range field is already set and remove error message
                 *
                 * @param $event
                 * @param field
                 */
                function openDatePicker($event, field) {
                    $event.preventDefault();
                    $event.stopPropagation();

                    if (field === 'createdDateFrom') {
                        $scope.datePickerOptions.createdDateFrom.maxDate = $scope.createdDateTo || null;
                        $scope.datepickerOpened.createdDateFrom = true;
                        delete $scope.errors.createdDate;
                    } else if (field === 'createdDateTo') {
                        $scope.datePickerOptions.createdDateTo.minDate = $scope.createdDateFrom || null;
                        $scope.datepickerOpened.createdDateTo = true;
                        delete $scope.errors.createdDate;
                    }
                }

                function addSearchCriteria(key, value) {

                    var tempSearchCriteriaCache = metabuyerCache.get(searchCacheKey);

                    if (!!tempSearchCriteriaCache)
                        searchFields = _.cloneDeep(tempSearchCriteriaCache);

                    searchFields[key] = value;
                    metabuyerCache.put(searchCacheKey, searchFields);

                }

                function initialize() {
                    // initialize pagination parameters
                    $scope.dataList = [];
                    $scope.embeddedParams = [];
                    $scope.meta = {};
                    $scope.searchModule = advancedSearch;

                    var searchCriteriaCache = metabuyerCache.get(searchCacheKey);
                    if (!!searchCriteriaCache && !_.isEmpty(searchCriteriaCache)) {
                        retainSearchCriteria();
                    }

                }

                function retainSearchCriteria() {

                    var searchCriteriaCache = metabuyerCache.get(searchCacheKey);

                    $scope.doNumber = searchCriteriaCache.doNumber;
                    $scope.poNumber = searchCriteriaCache.poNumber;
                    $scope.poStatus.selected = searchCriteriaCache.poStatus;
                    $scope.supplier = searchCriteriaCache.supplier;
                    $scope.totalPriceFrom = searchCriteriaCache.totalPriceFrom;
                    $scope.totalPriceTo = searchCriteriaCache.totalPriceTo;
                    $scope.totalCostOfPoFrom = searchCriteriaCache.totalCostOfPoFrom;
                    $scope.totalCostOfPoTo = searchCriteriaCache.totalCostOfPoTo;
                    $scope.prNumber = searchCriteriaCache.prNumber;
                    $scope.prTitle = searchCriteriaCache.prTitle;
                    $scope.company = searchCriteriaCache.company;
                    $scope.costCenter = searchCriteriaCache.costCenter;
                    $scope.itemTitle = searchCriteriaCache.itemTitle;
                    $scope.itemDescription = searchCriteriaCache.itemDescription;
                    $scope.creator = searchCriteriaCache.creator;
                    $scope.createdOnBehalf = searchCriteriaCache.createdOnBehalf;
                    $scope.commodityKeyword = searchCriteriaCache.commodityKeyword;
                    $scope.createdDateFrom = searchCriteriaCache.createdDateFrom;
                    $scope.createdDateTo = searchCriteriaCache.createdDateTo;
                }
                initialize();
            }
        }
    });
