'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider, pathConstants) {
    //TODO: rename the states from to 'users' to something more readable (Ahmed Saleh)
    $stateProvider
      .state('main.user.users', {
        url: '/users',
        controller: 'UsersCtrl',
        templateUrl: 'app/usersManagement/usersManagement.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            var check = false;
            //TODO: Need to enhance this section
            if (!!profile.role_assignments && profile.role_assignments.length > 0) {
              for (var index in profile.role_assignments) {
                for (var i in profile.role_assignments[index].roles) {
                  for (var j in profile.role_assignments[index].roles[i].permissions) {
                    var permission = profile.role_assignments[index].roles[i].permissions[j].name.split(' ');

                    if (permission[1] == 'Users' &&
                      (permission[0] == 'Read' || permission[0] == 'Create' || permission[0] == 'Update' || permission[0] == 'Delete')) {
                      check = true;
                      break;
                    }
                  }
                }
              }
            }
            if (!check) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.users.search', {
        url: '/manage/search?{status:[a-zA-Z0-9]+}&{query:[a-zA-Z0-9]+}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/usersManagement/manage/manage.html',
        controller: 'UsersManageCtrl',
        resolve: {
          action: function () {
            return "search";
          },
          usersList: function ($q, searchModule, $stateParams, globalFunc, exportSearch) {
            var deferred = $q.defer();

            var params = {};
            var status = globalFunc.availableStatuses('users', $stateParams.status);

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "User - search" + (!!$stateParams.query ? " - " + $stateParams.query : "");
          }
        }
      })
      .state('main.user.users.manage', {
        url: '/manage?{status:[a-zA-Z0-9]+}&{query:[a-zA-Z0-9]+}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/usersManagement/manage/manage.html',
        controller: 'UsersManageCtrl',
        resolve: {
          action: function ($stateParams) {
            if ($stateParams.query) {
              return 'search';
            }
            else {
              return 'show';
            }
          },
          usersList: function ($q, searchModule, $stateParams, globalFunc, exportSearch) {

            var status = globalFunc.availableStatuses('users', $stateParams.status);
            var deferred = $q.defer();
            var params = {};

            /**
             * First determine if search or listing
             */
            if (!!$stateParams.query) {
              /**
               * If searching , check if searching with filter
               */
              if (!!$stateParams.filter) {

                var temp = 'criteria[' + $stateParams.filter + ']';
                params = {
                  module: 'users',
                  criteria_operator: !!$stateParams.status ? 'and' : null,
                  'criteria[1][status]': $stateParams.status !== 'all' ? status : null,
                  'criteria[1][is_metabuyer_user]': true,
                  offset: 20,
                  page: $stateParams.page,
                  order_by: $stateParams.order_by,
                  order: $stateParams.order
                };

                if ($stateParams.type === 'date') {
                  temp = 'criteria[0][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                } else {
                  params['criteria[0][' + $stateParams.filter + ']'] = $stateParams.query;
                }

              }
              /**
               * If searching without filter
               */
              else {
                params = {
                  module: 'users',
                  'criteria[0][display_name]': $stateParams.query,
                  'criteria[0][email]': $stateParams.query,
                  'criteria[0][emp_no]': $stateParams.query,
                  'criteria[0][creator.display_name]': $stateParams.query,
                  'criteria[0][updater.display_name]': $stateParams.query,
                  'criteria[0][hand_phone]': $stateParams.query,
                  'criteria[0][currency|descr]': $stateParams.query,
                  'criteria[0][config|TZ]': $stateParams.query,
                  'criteria[0][config|locale]': $stateParams.query,
                  'criteria[1][status]': $stateParams.status !== 'all' ? status : null,
                  'criteria[1][is_metabuyer_user]': true,
                  'criteria[0][$operator]': !!$stateParams.query ? 'or' : null,
                  criteria_operator: !!$stateParams.query ? 'and' : null,
                  offset: 20,
                  page: $stateParams.page,
                  order_by: $stateParams.order_by,
                  order: $stateParams.order
                }
              }
            }
            /**
             * For listing
             */
            else {
              params = {
                module: 'users',
                'criteria[0][status]': $stateParams.status !== 'all' ? status : null,
                'criteria[0][is_metabuyer_user]': true,
                offset: 20,
                page: $stateParams.page,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              }
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "User - listing" + (!!$stateParams.status ? " - " + $stateParams.status : "");
          }
        }
      })
      .state('main.user.users.escalation', {
        url: '/escalation?{query:[a-zA-Z0-9]+}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/usersManagement/escalation/escalationTemplate.html',
        controller: 'usersEscalationCtrl',
        resolve: {
          usersList: function ($q, searchModule, $stateParams, exportSearch) {

            var deferred = $q.defer();
            var params = {
              module: 'user-superior',
              offset: 20,
              page: $stateParams.page,
              order_by: !!$stateParams.order_by ? $stateParams.order_by : 'display_name',
              order: !!$stateParams.order ? $stateParams.order : 1,
              'criteria[0][user_status][]': [0, 1, 3, 4], // all except inactive
              'criteria_operator': 'and'
            };

            /**
             * First determine if search or listing
             */
            if (!!$stateParams.query) {
              /**
               * If searching , check if searching with filter
               */
              if (!!$stateParams.filter) {
                params['criteria[1][' + $stateParams.filter + ']'] = $stateParams.query;
              }
              /**
               * Searching in all filters
               */
              else {
                params['criteria[1][display_name]'] = $stateParams.query;
                params['criteria[1][email]'] = $stateParams.query;
                params['criteria[1][superior_display_name]'] = $stateParams.query;
                params['criteria[1][superior_email]'] = $stateParams.query;
                params['criteria[1][company_descr]'] = $stateParams.query;
                params['criteria[1][$operator]'] = 'or';
              }
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve([]);
                }
              },
              function (error) {
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "Escalation Listing";
          }
        }
      })
      .state('main.user.users.pendingDelegation', {
        url: '/pending-delegation?{query:[a-zA-Z0-9]+}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/usersManagement/delegations/pendingDelegations/pending-delegations.html',
        controller: 'pendingDelegationCtrl',
        resolve: {
          pendingDelegationList: function($q, searchModule, $stateParams, exportSearch, globalFunc) {

            var deferred = $q.defer();
            var status = globalFunc.availableStatuses('delegations', 'pending');
            var params = {
              module: 'delegations',
              offset: 20,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            /**
             * determine search or listing
             */
            if (!!$stateParams.query) {
              /**
               * If searching , check if searching with filter
               */
              if (!!$stateParams.filter) {
                params['criteria[0][' + $stateParams.filter + ']'] = $stateParams.query;
              }
              /**
               * Searching in all filters
               */
              else {
                params['criteria[0][user_display_name]'] = $stateParams.query;
                params['criteria[0][creator_display_name]'] = $stateParams.query;
                params['criteria[0][delegatee_display_name]'] = $stateParams.query;
                params['criteria[0][company_code]'] = $stateParams.query;
                params['criteria[0][company_descr]'] = $stateParams.query;
                params['criteria_operator'] = 'or';
              }
            }
            /**
             * for listing
             */
            params['criteria[0][status]'] = status;

            exportSearch.setParams(params);

            searchModule.get(params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Pending Delegations Listing';
          }
        }
    })
      .state('main.user.users.delegation', {
        url: '/delegation?{status:[a-zA-Z0-9]+}&{query:[a-zA-Z0-9]+}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/usersManagement/delegations/delegations.html',
        controller: 'usersDelegationCtrl',
        resolve: {
          delegationList: function ($q, searchModule, $stateParams, exportSearch, globalFunc) {

            var status = globalFunc.availableStatuses('delegations', $stateParams.status);
            var deferred = $q.defer();
            var params = {
              module: 'delegations',
              offset: 20,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            /**
             * First determine if search or listing
             */
            if (!!$stateParams.query) {
              /**
               * If searching , check if searching with filter
               */
              if (!!$stateParams.filter) {

                if ($stateParams.type === 'date') {
                  var temp = 'criteria[0][' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                } else {
                  params['criteria[0][' + $stateParams.filter + ']'] = $stateParams.query;
                }
              }
              /**
               * Searching in all filters
               */
              else {
                params['criteria[0][user_display_name]'] = $stateParams.query;
                params['criteria[0][creator_display_name]'] = $stateParams.query;
                params['criteria[0][delegatee_display_name]'] = $stateParams.query;
                params['criteria[0][company_code]'] = $stateParams.query;
                params['criteria[0][company_descr]'] = $stateParams.query;
                params['criteria_operator'] = 'or';
              }
              params['criteria[0][$operator]'] = 'or';
            }
            else {
              /**
               * for listing
               */
               params['criteria[0][status]'] = $stateParams.status !== 'all' ? status : null;
            }
            exportSearch.setParams(params);

            searchModule.get(params,
              function (resource) {
                deferred.resolve(resource.content);
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return 'Delegations Listing';
          }
        }
      })
      .state('main.user.users.onlineUsers', {
        url: '/online_users?{cursor:[^~,]}',
        templateUrl: 'app/usersManagement/onlineUsers/onlineUsersTemplate.html',
        controller: 'onlineUsersCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $rootScope, $state, globalFunc) {
            if (!UserPermissions.checkPermissionsAccess(profile, 'UserSession', 'Read')) {
              $state.go('notAuthorized');
            }
          },
          activeUsersList: function ($q, activeUsers, $stateParams) {
            var deferred = $q.defer();
            activeUsers.get(
              {
                session_id: 'active',
                cursor: $stateParams.cursor,
              },
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'all online users';
          }
        }
      })
      .state('main.user.users.add', {
        url: '/add-user',
        templateUrl: 'app/usersManagement/add/add.html',
        controller: 'UsersAddUserCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $state, $rootScope) {
            if (!!$rootScope.isOffline) {
              $state.go('notAuthorized');
            }
          },
          companiesForAdminUse: function ($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'companies',
                offset: 0,
                order: 1,
                order_by: 'descr'
              },
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          userCategories: function ($q, userCategories) {
            var deferred = $q.defer();
            userCategories.get(
              {},
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          timezones: function ($q, userTimezones) {
            var deferred = $q.defer();
            userTimezones.get(
              {},
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          currenciesList: function ($q, Currencies) {
            var deferred = $q.defer();
            // TODO: remove usage of local storage said Ahmed, Shin (30/6/2020)
            if (!JSON.parse(window.localStorage.getItem('currencies'))) {
              Currencies.get(
                {},
                function (resource) {
                  window.localStorage.setItem("currencies", JSON.stringify(resource.content.data));
                  deferred.resolve(resource.content.data);
                },
                function (error) {
                  if (error.status === 404) {
                    deferred.resolve([]);
                  }
                }
              );
            }
            else{
              var content = JSON.parse(window.localStorage.getItem('currencies'));
              if(!!content){
                deferred.resolve(content);
              }
              else{
                deferred.resolve([]);
              }

            }
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "User-Mgmt - add - user";
          },
          prepareTranslation: function ($translatePartialLoader) {
            $translatePartialLoader.addPart('login/lang');
          },
        }
      })
      .state('main.user.users.editRoleAssignments', {
        url: '/edit-role-assignments?{id:[a-zA-Z0-9]+}',
        templateUrl: 'app/usersManagement/roleAssignments/roleAssignments-template.html',
        controller: 'userRoleAssignmentsCtrl',
        params: { user: null },
        resolve: {
          userDetails: function ($q, $stateParams, singleUser) {
              var deferred = $q.defer();
              //used the cached user if passed into the state
              if (!!$stateParams.user && $stateParams.user._id === $stateParams.id) {
                  deferred.resolve($stateParams.user);
              } else {
                  singleUser.get(
                      {
                          id: $stateParams.id
                      },
                      function (resource) {
                          if (!!resource.content) {
                              deferred.resolve(resource.content.data);
                          }
                      },
                      function (error) {
                          if (error.status === 404) {
                              deferred.resolve([]);
                          }
                      }
                  );
              }
              return deferred.promise;
          },
          //TODO: Consider using a service to set and get data like PR (Justin 15/11/2018)
          roleAssignmentsData: function($q, $stateParams, userRoleAssignments, globalFunc) {
            if (_.isEmpty(globalFunc.getDataByKey('roleAssignments'))) {
              var deferred = $q.defer();
              userRoleAssignments.get(
                {
                  id: $stateParams.id
                },
                function (resource) {
                  if (!!resource.content) {
                    deferred.resolve(resource.content.data);
                  }
                },
                function (error) {
                  deferred.resolve([]);
                }
              );
              return deferred.promise;
            }
            else {
              return globalFunc.getDataByKey('roleAssignments');
            }
          },
          companiesList: function ($q, getAllCompanies, globalFunc) {
            if (_.isEmpty(globalFunc.getDataByKey('companiesList'))) {
              var deferred = $q.defer();
              getAllCompanies.get(
                {
                  'fields[0]': '_id',
                  'fields[1]': 'code',
                  'fields[2]': 'descr'
                },
                function (resource) {
                  if (!!resource.content) {
                    deferred.resolve(resource.content.data);
                  }
                },
                function (error) {
                  deferred.resolve([]);
                }
              );
              return deferred.promise;
            }
            else {
              return globalFunc.getDataByKey('companiesList');
            }
          },
          catalogGroups: function($q, loadCacheableData){
            var deferred = $q.defer();
            loadCacheableData.loadData({
              module: 'catalog-groups',
              offset: 0
            }, 'catalogGroups').then(function (data) {
              deferred.resolve(data);
            });

            return deferred.promise;
          },
          alcGroups: function ($q, loadCacheableData) {
            var deferred = $q.defer();
            loadCacheableData.loadData({
              module: 'alc-group',
              offset: 0
            }, 'alcGroups').then(function (data) {
              deferred.resolve(data);
            });

            return deferred.promise;
          },
          $title: function () {
            return 'User Management - Role Assignments';
          },
        }
      })
      .state('main.user.users.import', {
        url: '/import-user',
        templateUrl: 'app/usersManagement/import/import.html',
        controller: 'UsersImportUserCtrl',
        resolve: {
          accessRights: function (profile, UserPermissions, $state, $rootScope) {
            if (!!$rootScope.isOffline) {
              $state.go('notAuthorized');
            }
          },
          $title: function ($stateParams) {
            return "User-Mgmt - import - user";
          }
        }
      })
      .state('main.user.users.setting', {
        url: '/setting',
        templateUrl: 'app/usersManagement/settings/settings.html',
        controller: 'UsersSettingCtrl',
        resolve: {
          $title: function ($stateParams) {
            return "User-Mgmt - setting";
          }
        }
      })
      .state('main.user.users.detail', {
        url: '/{id:.+}',
        templateUrl: 'app/usersManagement/detail/detail.html',
        controller: 'UsersDetailCtrl',
        params: { user: null },
        resolve: {
          profile: function ($q, $stateParams, singleUser) {
              var deferred = $q.defer();
              if (!!$stateParams.user && $stateParams.user._id === $stateParams.id) {
                  deferred.resolve($stateParams.user);
              } else {
                  singleUser.get(
                      {
                          id: $stateParams.id
                      },
                      function (resource) {
                          deferred.resolve(resource.content.data);
                      },
                      function (error) {
                          if (error.status === 404) {
                              deferred.resolve([]);
                          }
                      }
                  );
              }
              return deferred.promise;
          },
          timezones: function ($q, userTimezones) {
            var deferred = $q.defer();
            userTimezones.get(
              {},
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          userCategories: function ($q, userCategories) {
            var deferred = $q.defer();
            userCategories.get(
              {},
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          roleAssignmentsData: function($q, $stateParams, userRoleAssignments) {
            var deferred = $q.defer();
            userRoleAssignments.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          companiesList: function ($q, getAllCompanies) {
            var deferred = $q.defer();
            getAllCompanies.get(
              {
                'fields[0]': '_id',
                'fields[1]': 'code',
                'fields[2]': 'descr'
              },
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return 'User- Management - Details ' + (!!$stateParams.status ? ' - ' + $stateParams.status : '');
          }
        }
      })
     .state('main.user.userDelegation', {
      url: '/user-delegation/{id:.+}',
      templateUrl: 'components/userDelegationV2/userDelegationTemplateV2.html',
      controller: 'userDelegationCtrlV2',
      params: { roleAssignmentsData: null},
      resolve: {
        companyDetail: function () {
          return true;
        },
        userId :  function ($stateParams) {
          return $stateParams.id;
        },
        userCompanies: function ($q, searchModule, $stateParams) {
          var deferred = $q.defer();
          var params = {
            module: 'users',
            'criteria[0][string_id]': $stateParams.id,
            'criteria[1][is_metabuyer_user]': true,
            offset: 20,
            page: $stateParams.page,
            order_by: $stateParams.order_by,
            order: $stateParams.order
          };

          searchModule.get(
            params,
            function (resource) {
              deferred.resolve(resource.content || {data: []});
            }
          );

          return deferred.promise;
        },
        roleAssignmentData: function ($stateParams, userRoleAssignments, $q) {
          var deferred = $q.defer();
          if (!$stateParams.roleAssignmentsData) {
            userRoleAssignments.get(
              {
                id: $stateParams.id,
              },
              function (resource) {
                if (!!resource.content) {
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          } else {
            return $stateParams.roleAssignmentsData;
          }
        },
        waitingOnYou: function () {
          return [];
        },
        $title: function () {
          return "User - delegation"
        }
      }
    })
    .state('main.user.approvalLimit', {
      url: '/user-approval-limits/{id:[a-zA-Z0-9]+}',
      templateUrl: 'app/usersManagement/approvalLimit/approval-limit.html',
      controller: 'approvalLimitCtrl',
      resolve: {
        accessRights: function (profile, UserPermissions, $state, globalFunc) {
          if (!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'ADMIN') &&
            !globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN') &&
            !globalFunc.isSystemSupportRole() &&
            !globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'User Access Manager')) {
            $state.go('notAuthorized');
          }
        },
        userData: function ($q, $stateParams, singleUser) {
          var deferred = $q.defer();
          if (!!$stateParams.user && $stateParams.user._id === $stateParams.id) {
              deferred.resolve($stateParams.user);
          } else {
              singleUser.get(
                  {
                      id: $stateParams.id
                  },
                  function (resource) {
                      deferred.resolve(resource.content.data);
                  },
                  function (error) {
                      if (error.status === 404) {
                          deferred.resolve([]);
                      }
                  }
              );
          }
          return deferred.promise;
        },
        $title: function () {
          return "User - Approval Limit";
        }
      }
    })
    .state('main.user.approvalLimit.manage', {
      url: '/manage',
      templateUrl: 'app/usersManagement/approvalLimit/manage/manage.html',
      controller: 'approvalLimitManageCtrl',
      resolve: {
        $title: function () {
          return "User - Approval Limit";
        }
      }
    })
    .state('main.user.approvalLimit.detail', {
      url: '/approval-limit/{uuid:[a-zA-Z0-9\-]*}',
      templateUrl: 'app/usersManagement/approvalLimit/detail/detail.html',
      controller: 'approvalLimitDetailCtrl',
      params: { approvalLimit: null },
      resolve: {
        approvalLimitData: function ($stateParams, userData) {
          if (!_.isEmpty($stateParams.approvalLimit)){
            return $stateParams.approvalLimit;
          } else {
            var approvalLimit = _.find(userData.approval_limits, function (e) {
              return e.approval_limit_uuid === $stateParams.uuid;
            });

            return approvalLimit || [];
          }
        },
        $title: function () {
          return "User - Approval Limit";
        }
      }
    })
  }]);
