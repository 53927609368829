'use strict';
/**
 * @name userCtrl
 * @desc The controller to load the user once logged in to the system
 */
function userCtrl($scope, reLoginService, $auth, $state, $rootScope, Account, toastr,
                  anTinycon, ManageLocalStorage, authenticated,
                  pathConstants, UserPermissions, profile, $interval, unreadNotificationList,
                  waitingOnUserList, budgetFunctions, globalFunc, featureToggle) {


  $rootScope.navOptions = {
    pr: {
      'key': 'main.user.purchaseRequisitions.manage',
      'title': 'Purchase Requisitions',
      'icon': 'fa-pr',
      'description': 'Purchase Requisitions',
      'status': !!$rootScope.isMobileMode ? 'pending' : 'draft',
      'extraFilterKey': !!$rootScope.isMobileMode ? 'criteria[waiting_on][]' : null,
      'extraFilterValue': !!$rootScope.isMobileMode ? profile._id : null,
      'tab': !!$rootScope.isMobileMode ? 'waiting' : 'all',
      'id': 'prSidebarIcon'
    },
    prV2: {
      'key': 'main.user.purchaseRequisitionsV2.manage',
      'title': 'Purchase Requisitions',
      'icon': 'fa-pr',
      'description': 'Purchase Requisitions',
      'status': !!$rootScope.isMobileMode ? 'pending' : 'draft',
      'extraFilterKey': !!$rootScope.isMobileMode ? 'criteria[waiting_on][]' : null,
      'extraFilterValue': !!$rootScope.isMobileMode ? profile._id : null,
      'tab': !!$rootScope.isMobileMode ? 'waiting' : 'all',
      'id': 'prSidebarIcon'
    },
    po: {
      'key': 'main.user.orders.manage',
      'title': 'Purchase Orders',
      'icon': 'fa-po',
      'description': 'Purchase Orders',
      'status': 'ALL',
      'id': 'poSidebarIcon'
    },
    metaPo: {
      'key': 'main.user.orders.manage',
      'title': 'Metabuyer',
      'icon': 'fa-po',
      'description': 'Metabuyer',
      'status': 'ALL',
      'id': 'poSidebarIcon'
    },
    poConsolidation: {
      'key': 'main.user.poConsolidation.manage',
      'title': 'PO Consolidation',
      'icon': 'fa-file-text-o',
      'description': 'PO Consolidation',
      'status': 'partially_utilized',
      'id': 'poSidebarIcon'
    },
    supplierPo: {
      'key': 'main.user.suppliersWeb.po.manage',
      'title': 'Supplier',
      'icon': 'fa-po',
      'description': 'Supplier',
      'status': 'ALL',
      'id': 'poSidebarIcon'
    },
    supplierWebDashboard: {
      'key': 'main.user.suppliersWeb.dashboard',
      'title': 'Dashboard',
      'icon': 'fa-dashboard',
      'description': 'Dashboard',
      'id': 'suppliersWebDashboardSidebarIcon'
    },
    supplierWebReports: {
      'key': 'main.user.suppliersWeb.reports',
      'title': 'Supplier Web Reports',
      'icon': 'fa-file-text',
      'description': 'Supplier Web Reports',
      'id': 'reportsSidebarIcon'
    },
    supplierWebPo: {
      'key': 'main.user.suppliersWeb.po.manage',
      'title': 'Purchase Orders',
      'icon': 'fa-po',
      'description': 'Purchase Orders',
      'status': 'ALL',
      'id': 'poSidebarIcon'
    },
    supplierWebAdminDashboard: {
      'key': 'main.user.suppliersDashboard',
      'title': 'Dashboard',
      'icon': 'fa-truck',
      'description': 'Suppliers Dashboard',
      'id': 'suppliersAdminDashboardSidebarIcon'
    },
    supplierWebAdminManagement: {
      'key': 'main.user.suppliersWebManagement.tnc',
      'title': 'Management',
      'icon': 'fa-truck',
      'description': 'Management',
      'id': 'suppliersWebManagementSidebarIcon'
    },
    supplierWebAdminUsers: {
      'key': 'main.user.suppliersWebUsers.list',
      'title': 'Supplier Web Users',
      'icon': 'fa-truck',
      'description': 'Supplier Web Users',
      'status': 'active',
      'id': 'suppliersWebAdminUsersIcon'
    },
    supplier: {
      'key': 'main.user.supplier.manage',
      'title': 'Supplier Management',
      'icon': 'fa-user',
      'description': 'Suppliers',
      'status': 'active',
      'id': 'suppliersSidebarIcon'
    },
    suppliersListing: {
      'key': 'main.user.suppliersList.manage',
      'title': 'Suppliers List',
      'icon': 'fa-truck',
      'description': 'Suppliers List',
      'status': 'active',
      'id': 'suppliersListingSidebarIcon'
    },
    budget: {
      'key': 'main.user.budget.manage',
      'title': 'Budget',
      'icon': 'fa-budget',
      'description': 'Budget',
      'id': 'budgetSidebarIcon',
      'status': 'draft'
    },
    budgetV2: {
      'key': 'main.user.budgetV2.manage',
      'title': 'Budget',
      'icon': 'fa-budget',
      'description': 'Budget',
      'id': 'budgetSidebarIcon',
      'status': 'draft'
    },
    alc: {
      'key': 'main.user.alc.manage',
      'title': 'ALC',
      'icon': 'fa-car',
      'description': 'ALC',
      'id': 'alcSidebarIcon',
      'status': 'active'
    },
    analyticsReport: {
      'key': 'main.user.analytics.report',
      'title': 'Dashboard Reporting',
      'icon': 'fa-bar-chart',
      'description': 'Dashboard Reporting',
      'id': 'budgetSidebarIcon'
    },
    itemsManagement: {
      'key': 'main.user.items.manageCatalog',
      'title': 'Catalog',
      'icon': 'fa-th-list',
      'description': 'Catalog',
      'status': 'approved',
      'id': 'itemsSidebarIcon'
    },
    catalogItemView: {
      'key': 'main.user.catalogItemList',
      'title': 'Catalog',
      'icon': 'fa-tags',
      'description': 'Catalog',
      'id': 'viewCatalogItemsSidebarIcon'
    },
    catalogV2: {
      'key': 'main.user.catalogs.manageCatalog',
      'title': 'Catalog',
      'icon': 'fa-tags',
      'description': 'Catalog',
      'status': 'all',
      'id': 'catalogsSidebarIcon'
    },
    tender: {
      'key': 'main.user.tender.manage',
      'title': 'Tender',
      'icon': 'fa-files-o',
      'description': 'Tender',
      'id': 'tenderSidebarIcon',
      'status': 'all',
      'tab': 'yours',
      'extraFilterKey': '[creator_info|_id][]',
      'extraFilterValue': $rootScope.currentUser._id,
    },
    kharonSyncLog: {
      'key': 'main.user.kharon.syncLogs',
      'title': 'Kharon Management',
      'status': 'kharonLogs',
      'icon': 'fa-retweet',
      'description': 'Kharon Management',
      'id': 'kharonSidebarIcon'
    },
    bifrost: {
      'key': 'main.user.bifrost.dataOutbound',
      'title': 'Bifrost Management',
      'status': 'dataOutbound',
      'icon': 'fa-th',
      'description': 'Bifrost management',
      'id': 'bifrostSidebarIcon'
    },
    bifrostFile: {
      'key': 'main.user.bifrostFile.manage',
      'title': 'Bifrost File Viewer',
      'icon': 'fa-th-list',
      'description': 'Bifrost File Viewer',
      'id': 'bifrostFileSidebarIcon'
    },
    bifrostMapping: {
      'key': 'main.user.bifrostMapping.tax',
      'title': 'Bifrost Mapping',
      'status': 'tax',
      'icon': 'fa-th',
      'description': 'Bifrost Mapping',
      'id': 'bifrostMappingSidebarIcon'
    },
    bifrostProcessStatus: {
      'key': 'main.user.bifrostProcessStatus.manage',
      'title': 'Bifrost Processing Status',
      'icon': 'fa-th-list',
      'description': 'Bifrost Processing Status',
      'id': 'bifrostProcessStatusSidebarIcon'
    },
    bifrostDataReinbound: {
      'key': 'main.user.bifrostDataReinbound.manage',
      'title': 'Bifrost Data Reinbound',
      'icon': 'fa-th',
      'description': 'Bifrost Data Reinbound',
      'id': 'bifrostDataReinboundSidebarIcon'
    },
    more: {
      'key': 'main.user.more',
      'title': 'More',
      'icon': 'fa-ellipsis-v',
      'description': 'More',
      'id': 'moreSidebarIcon'
    },
    Companies: {
      'key': 'main.user.companiesMgmt.manageCompanies',
      'title': 'Companies',
      'icon': 'fa-briefcase',
      'description': 'Companies',
      'id': 'companiesSidebarIcon'
    },
    Users: {
      'key': 'main.user.users.manage',
      'title': 'Users',
      'icon': 'fa-user',
      'description': 'Users',
      'status': 'active',
      'id': 'usersSidebarIcon'
    },
    masterData: {
      'key': '',
      'title': 'Master Data',
      'icon': 'fa-th-list',
      'description': 'Master Data',
      'status': '',
      'id': 'masterDataSidebarIcon'
    },
    approvalFlow: {
      'key': 'main.user.approvalFlow.manage',
      'title': 'Approval Flow',
      'icon': 'fa-sitemap',
      'description': 'Approval Flow',
      'id': 'approvalFlowSidebarIcon'
    },
    // TODO: Update this if necessary for new security page (Justin 19/11/18)
    security: {
      'key': 'main.user.security',
      'title': 'Security',
      'icon': 'fa-users',
      'description': 'Security',
      'id': 'securitySidebarIcon'
    },
    settings: {
      'key': 'main.user.settings.mainPanel',
      'title': 'Settings',
      'icon': 'fa-cogs',
      'description': 'Settings',
      'id': 'settingsSidebarIcon'
    },
    reports: {
      'key': ($rootScope.isV2Enabled ? 'main.user.reports.generateV2' :  'main.user.reports.generate'),
      'title': 'Reports',
      'icon': 'fa-file-text-o',
      'description': 'Reports',
      'id': 'reportsSidebarIcon'
    },
    exports: {
      'key': 'main.user.exports.log',
      'title': 'Exports',
      'icon': 'fa-cogs',
      'description': 'Exports',
      'id': 'exportsSidebarIcon'
    },
    position: {
      'key': 'main.user.positionManagement.assign',
      'title': 'Position Management',
      'icon': 'fa-cubes',
      'description': 'Position Management',
      'id': 'positionSidebarIcon'
    },
    emails: {
      'key': 'main.user.emailLogs.emailLogsList',
      'title': 'Email Logs',
      'icon': 'fa-th-list',
      'description': 'Email Logs',
      'id': 'emailsSidebarIcon'
    },
    supplierWebEmail: {
      'key': 'main.user.suppliersWebEmailLog.manage',
      'title': 'Email Logs',
      'icon': 'fa-th-list',
      'description': 'Email Logs',
      'id': 'emailsSidebarIcon'
    },
    supplierWebGrn: {
      'key': 'main.user.suppliersWeb.grn.manage',
      'title': 'GRNs',
      'status': 'ALL',
      'icon': 'fa-files-o',
      'description': 'Goods Receive Note',
      'id': 'grnSidebarIcon'
    },
    logViewer: {
      'key': 'main.user.logViewer.manage',
      'title': 'Log Viewer',
      'icon': 'fa-files-o',
      'description': 'Laravel Log Viewer',
      'id': 'laravel-log-icon'
    },
    maintenance: {
      'key': 'main.user.maintenance',
      'title': 'Maintenance',
      'icon': 'fa-cogs',
      'description': 'Maintenance',
      'id': 'maintenanceSidebarIcon'
    },
    minion: {
      'key': 'main.user.minion.dashboard',
      'title': 'Minion',
      'icon': 'fa-cog',
      'description': 'Minion',
      'id': 'minionSidebarIcon'
    },
    developerTools : {
      'key': 'main.user.devTools.auditViewer',
      'title': 'Developer Tools',
      'icon': 'fa-cog',
      'description': 'Developers tools',
      'id': 'developerToolsSidebarIcon'
    },
    eSourcing : {
      'key': '',
      'title': 'eSourcing',
      'icon': 'fa-suitcase',
      'description': 'eSourcing',
      'id': 'eSourcingSidebarIcon'
    },
    priceComparison: {
      'key': 'main.user.priceComparison.manage',
      'title': 'Purchase Comparison',
      'icon': 'fa-pc',
      'description': 'Purchase Comparison',
      'status': !!$rootScope.isMobileMode ? 'pending' : 'draft',
      'extraFilterKey': !!$rootScope.isMobileMode ? 'criteria[waiting_on][]' : null,
      'extraFilterValue': !!$rootScope.isMobileMode ? profile._id : null,
      'tab': !!$rootScope.isMobileMode ? 'waiting' : 'all',
      'id': 'priceComparisonSidebarIcon'
    },
    policyDisclaimer: {
      'key': 'main.user.policyDisclaimer',
      'title': 'Security Policy',
      'icon': 'fa-cogs',
      'description': 'Maintenance',
      'id': 'policyDisclaimerSidebarIcon'
    }
  };

  /**
   * This function called to refresh the user Waiting on list
   * the Interval can be changed if its too fast or too slow, currently once an hour
   */
  var time = 3600000;
  $scope.intervalPromise = $interval(loadWaitingsAndNotifications, time);

  function featureIsEnabled(featureKey) {
    return (!!featureToggle[featureKey] && featureToggle[featureKey].is_enabled);
  }

  function loadWaitingsAndNotifications() {
    if ($auth.isAuthenticated()) {
      //To get updated number of waiting on you per interval
      waitingOnUserList.get(
        {},
        function (resource) {
          if (!!resource.content) {
            $rootScope.waitings = resource.content;
            $rootScope.$broadcast('newWaitingList');
            $scope.nofifyWaitingOnFavico($rootScope.waitings);
          }
        },
        function (error) {
          if (error.status === 404) {
          }
        }
      );
    } else if (!!$rootScope.currentUser && !!$rootScope.currentUser.email) {
      var currentStateData = {
        name: $state.current.name,
        params: $state.params,
        url: $state.href($state.current.name, $state.params)
      };
      reLoginService(currentStateData, $rootScope.currentUser.email, 'The session has expired');
    }
  }
  // adding base path for user image
  $rootScope.userImageBasePath = pathConstants.apiUrls.image.user;

  // notify amount of items waiting on user on favico
  $scope.nofifyWaitingOnFavico = function (waitings) {
    if (!!waitings) {
      if (!!waitings.meta) {
        if (!!waitings.meta.cursor) {
          $scope.nofifyWaiting = waitings.meta.cursor.count;
          if ($scope.nofifyWaiting > 0) {
            anTinycon.bind($scope, 'nofifyWaiting');
          }
        }
      }
    }
    else {
      anTinycon.setBubble(0);
    }
  };
  $scope.nofifyWaitingOnFavico($rootScope.waitings);

  var resetActionButtonStates = function () {
    angular.forEach($scope.actions, function (action) {
      if (action.disabled) {
        action.disabled = false;
      }
    })
  };

  $rootScope.showDialog = false;

  //$scope.$on('actionsChanged', function (event, data) {
  //  $scope.actions = data;
  //});

  $rootScope.$on('$stateChangeSuccess', function () {
    resetActionButtonStates();
  });

  $rootScope.$on('$stateChangeError', function () {
    resetActionButtonStates();
  });

  // Update side menu when user switch dashboard on click
  $rootScope.$watch('currentUser.config.current_dashboard', function () {
    if (!!$rootScope.currentUser && !!$rootScope.currentUser.config) {
      $scope.getProfile($scope.companyWorkSpace);
    }
  });

  function currentDashboardIsSupplier(dashboard) {
    return (!!dashboard && dashboard === 'supplier');
  }

  function currentDashboardIsMetabuyer(dashboard) {
    return (!!dashboard && dashboard === 'metabuyer');
  }

  $scope.getProfile = function () {
    var getNavLinkByRole = function () {
      var dashboard = $rootScope.currentUser.config.current_dashboard;
      var navLinks = {
        nonAdministrative: [],
        administrative: [],
        supplierWeb: [],
        bifrost: [],
        kharon: [],
        maintenance: [],
        eSourcing: []
      };

      if (!!$rootScope.enablePlutusLogin) {
        navLinks.eSourcing.push($rootScope.navOptions.eSourcing);
      }

      // Supplier web user menu
      if (currentDashboardIsSupplier(dashboard)) {
        // Dashboard menu
        navLinks.nonAdministrative.push($rootScope.navOptions.supplierWebDashboard);

        // PO menu
        if (!!UserPermissions.checkPermissionsAccess(profile, 'PO')) {
          navLinks.nonAdministrative.push($rootScope.navOptions.supplierWebPo);
        }

        // Add GRN Listing
        navLinks.nonAdministrative.push($rootScope.navOptions.supplierWebGrn);

        // Report Menu
        navLinks.nonAdministrative.push($rootScope.navOptions.supplierWebReports);
      } // End supplier web user menu

      // Metabuyer user menu
      if (currentDashboardIsMetabuyer(dashboard)) {
        // PR menu
        if (!!UserPermissions.checkPermissionsAccess(profile, 'PR')) {
          if (!!$rootScope.isV2Enabled) {
            navLinks.nonAdministrative.push($rootScope.navOptions.prV2);
          } else {
            navLinks.nonAdministrative.push($rootScope.navOptions.pr);  // Fallback always to v1
          }

          if (featureIsEnabled('company.config.enablePriceComparison')) {
            navLinks.nonAdministrative.push($rootScope.navOptions.priceComparison);
          }
        }

        //PO menu
        if (!!UserPermissions.checkPermissionsAccess(profile, 'PO')) {
          navLinks.nonAdministrative.push($rootScope.navOptions.po);
        }

        // PO Consolidation
        if (!!$rootScope.isV2Enabled && !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'PO Requester')) {
          navLinks.nonAdministrative.push($rootScope.navOptions.poConsolidation);
        }

        if(!!UserPermissions.checkPermissionsAccess(profile, 'Users', 'Read') || !!UserPermissions.checkPermissionsAccess(profile, 'Users', 'Create') ||
          !!UserPermissions.checkPermissionsAccess(profile, 'Users', 'Update') || !!UserPermissions.checkPermissionsAccess(profile, 'Users', 'Delete')){
          navLinks.nonAdministrative.push($rootScope.navOptions.Users);
        }

        if (!!UserPermissions.checkPermissionsAccess(profile, 'ApprovalTemplate', 'Read') || !!UserPermissions.checkPermissionsAccess(profile, 'ApprovalTemplate', 'Create') ||
          !!UserPermissions.checkPermissionsAccess(profile, 'ApprovalTemplate', 'Update') || !!UserPermissions.checkPermissionsAccess(profile, 'ApprovalTemplate', 'Delete')) {
          navLinks.nonAdministrative.push($rootScope.navOptions.position);
        }

        // user access manager role cannot view approval flow
        if ((!!UserPermissions.checkPermissionsAccess(profile, 'ApprovalTemplate', 'Read') || !!UserPermissions.checkPermissionsAccess(profile, 'ApprovalTemplate', 'Create') ||
          !!UserPermissions.checkPermissionsAccess(profile, 'ApprovalTemplate', 'Update') || !!UserPermissions.checkPermissionsAccess(profile, 'ApprovalTemplate', 'Delete')) &&
          globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'User Access Manager') === false) {
          navLinks.nonAdministrative.push($rootScope.navOptions.approvalFlow);
        }

        if(!!UserPermissions.checkPermissionsAccess(profile, 'Company', 'Create') || !!UserPermissions.checkPermissionsAccess(profile, 'Company', 'Update') ||
          !!UserPermissions.checkPermissionsAccess(profile, 'Company', 'Delete')){
          navLinks.nonAdministrative.push($rootScope.navOptions.Companies);
        }

        // Supplier listing menu
        if (!!UserPermissions.checkPermissionsAccess(profile, 'Supplier')) {
          navLinks.nonAdministrative.push($rootScope.navOptions.suppliersListing);
        }

        // Catalog menu
        if (!$rootScope.isV2Enabled) {
          if (!!UserPermissions.checkPermissionsAccess(profile, 'ImportItemSummary') &&
              !!UserPermissions.checkPermissionsAccess(profile, 'ImportItemDetail')) {
            navLinks.nonAdministrative.push($rootScope.navOptions.catalogItemView);
          }
        } else {
          // Catalogs v2 menu
          if (!!UserPermissions.checkPermissionsAccess(profile, 'CatalogV2')) {
            navLinks.nonAdministrative.push($rootScope.navOptions.catalogV2);
          }
        }

        if (featureIsEnabled('system.enableTender') && (
                !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Tender Requester') ||
                !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Tender Approver') ||
                !!UserPermissions.checkPermissionsAccess(profile, 'Tender', 'View')
            )) {
            navLinks.nonAdministrative.push($rootScope.navOptions.tender);
        }

        // ALC menu
        if (!!$rootScope.isV2Enabled && !!UserPermissions.checkPermissionsAccess(profile, 'Alc')) {
            navLinks.nonAdministrative.push($rootScope.navOptions.alc);
        }

        // Budget menu
        if (((!!UserPermissions.checkPermissionsAccess(profile, 'Budget'))) ||
          !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN')) {
          if (!!$rootScope.isV2Enabled) {
            navLinks.nonAdministrative.push($rootScope.navOptions.budgetV2);
          } else {
            navLinks.nonAdministrative.push($rootScope.navOptions.budget);
          }
        }

        // Load analytics reporting menu
        // assume only Metabuyer (exlcude Metabuyer Lite) can access
        var showAnalyticsReport = !!$rootScope.isSourcingSupplierEnabled;
        if (!!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Analyst') && showAnalyticsReport) {
          navLinks.nonAdministrative.push($rootScope.navOptions.analyticsReport);
        }
      }

      //loading the admin panel
      //TODO: Clean up the permissions to access the panels later on this time its 'OR' for Create, Delete, Update (Ahmed Saleh)

      if (!$rootScope.isV2Enabled){
        if (!!UserPermissions.checkPermissionsAccess(profile, 'ImportItemDetail', 'Create') && !!UserPermissions.checkPermissionsAccess(profile, 'ImportItemDetail', 'Update')
          && !!UserPermissions.checkPermissionsAccess(profile, 'ImportItemDetail', 'Delete') && !!UserPermissions.checkPermissionsAccess(profile, 'ImportItemSummary', 'Create')
          && !!UserPermissions.checkPermissionsAccess(profile, 'ImportItemSummary', 'Delete') && !!UserPermissions.checkPermissionsAccess(profile, 'ImportItemSummary', 'Update')) {
            navLinks.administrative.push($rootScope.navOptions.itemsManagement);
        }
      }

      if ((!!UserPermissions.checkPermissionsAccess(profile, 'Supplier', 'Create') ||
          !!UserPermissions.checkPermissionsAccess(profile, 'Supplier', 'Update') ||
          !!UserPermissions.checkPermissionsAccess(profile, 'Supplier', 'Delete')) ||
          !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN') ||
          !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Sourcing Supplier Approver') ||
          !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Sourcing Metabuyer Admin') ||
          !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Sourcing Tenant Admin') ||
          !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Sourcing and Bidding Admin')) {
        navLinks.administrative.push($rootScope.navOptions.supplier);
      }

      var isSupplierAdmin = false;
      if (!!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Admin') ||
        !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN') ||
        !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Supplier Requester') ||
        !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Supplier Manager') ||
        !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Supplier Role') ||
        !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Company Supplier Requester')) {
        isSupplierAdmin = true;
      }

      //Supplier Approver and Company Supplier Approver can not create supplier
      if (!!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Supplier Approver') ||
        !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'Company Supplier Approver')) {

        if (!isSupplierAdmin) {
          var index = navLinks.administrative.indexOf($rootScope.navOptions.supplier);
          if (index > -1) {
            navLinks.administrative.splice(index, 1);
          }
        }
      }

      /**
       * check makes sure that only add the first option of the master data to add the state with it so it transit to the correct tab
       */
      if (!!UserPermissions.checkPermissionsAccess(profile, 'Currencies', 'Create') || !!UserPermissions.checkPermissionsAccess(profile, 'Currencies', 'Update')
        || !!UserPermissions.checkPermissionsAccess(profile, 'Currencies', 'Delete')) {
        $rootScope.navOptions.masterData.key = 'main.user.masterData.currency';
        $rootScope.navOptions.masterData.status = 'currency';
        navLinks.administrative.push($rootScope.navOptions.masterData);
      }
      else if(!!UserPermissions.checkPermissionsAccess(profile, 'ExpenseTypes', 'Create') || !!UserPermissions.checkPermissionsAccess(profile, 'ExpenseTypes', 'Update')
        || !!UserPermissions.checkPermissionsAccess(profile, 'ExpenseTypes', 'Delete')) {
        $rootScope.navOptions.masterData.key = 'main.user.masterData.expenseTypes';
        $rootScope.navOptions.masterData.status = 'expense_types';
        navLinks.administrative.push($rootScope.navOptions.masterData);
      }
      else if(!!UserPermissions.checkPermissionsAccess(profile, 'PaymentTerm', 'Create') || !!UserPermissions.checkPermissionsAccess(profile, 'PaymentTerm', 'Update')
        || !!UserPermissions.checkPermissionsAccess(profile, 'PaymentTerm', 'Delete')) {
        $rootScope.navOptions.masterData.key = 'main.user.masterData.paymentTerms';
        $rootScope.navOptions.masterData.status = 'payment_term';
        navLinks.administrative.push($rootScope.navOptions.masterData);
      }
      else if(!!UserPermissions.checkPermissionsAccess(profile, 'PaymentMethod', 'Create') || !!UserPermissions.checkPermissionsAccess(profile, 'PaymentMethod', 'Update')
        || !!UserPermissions.checkPermissionsAccess(profile, 'PaymentMethod', 'Delete')) {
        $rootScope.navOptions.masterData.key = 'main.user.masterData.paymentMethods';
        $rootScope.navOptions.masterData.status = 'payment_method';
        navLinks.administrative.push($rootScope.navOptions.masterData);
      }
      else if(!!UserPermissions.checkPermissionsAccess(profile, 'Tax', 'Create') || !!UserPermissions.checkPermissionsAccess(profile, 'Tax', 'Update')
        || !!UserPermissions.checkPermissionsAccess(profile, 'Tax', 'Delete')) {
        $rootScope.navOptions.masterData.key = 'main.user.masterData.taxes';
        $rootScope.navOptions.masterData.status = 'taxes';
        navLinks.administrative.push($rootScope.navOptions.masterData);
      }
      else if (!!UserPermissions.checkPermissionsAccess(profile, 'UOM', 'Create') || !!UserPermissions.checkPermissionsAccess(profile, 'UOM', 'Update')
        || !!UserPermissions.checkPermissionsAccess(profile, 'UOM', 'Delete')) {
        $rootScope.navOptions.masterData.key = 'main.user.masterData.uom';
        $rootScope.navOptions.masterData.status = 'uom';
        navLinks.administrative.push($rootScope.navOptions.masterData);
      }
      else if(
        !!UserPermissions.checkPermissionsAccess(profile, 'Commodity', 'Create') || !!UserPermissions.checkPermissionsAccess(profile, 'Commodity', 'Update')
        || !!UserPermissions.checkPermissionsAccess(profile, 'Commodity', 'Delete')) {
        $rootScope.navOptions.masterData.key = 'main.user.masterData.commodityCode';
        $rootScope.navOptions.masterData.status = 'commodity_code';
        navLinks.administrative.push($rootScope.navOptions.masterData);
      }
      else if(
        !!UserPermissions.checkPermissionsAccess(profile, 'ExchangeRate', 'Create') || !!UserPermissions.checkPermissionsAccess(profile, 'ExchangeRate', 'Update')
        || !!UserPermissions.checkPermissionsAccess(profile, 'ExchangeRate', 'Delete')) {
        $rootScope.navOptions.masterData.key = 'main.user.masterData.exchangeRate';
        $rootScope.navOptions.masterData.status = 'exchange_rate';
        navLinks.administrative.push($rootScope.navOptions.masterData);
      }


      //TODO: once userPermissions are cleared up for exports, update here (Justin 4/5/2016)
      if(!!UserPermissions.checkPermissionsAccess(profile, 'ReportLog', 'Create') || !!UserPermissions.checkPermissionsAccess(profile, 'ReportLog', 'Update') ||
        !!UserPermissions.checkPermissionsAccess(profile, 'ReportLog', 'Delete')) {
        navLinks.administrative.push($scope.navOptions.exports);
      }

      if(!!UserPermissions.checkPermissionsAccess(profile, 'ReportLog', 'Read') || !!UserPermissions.checkPermissionsAccess(profile, 'ReportLog', 'Create') ||
        !!UserPermissions.checkPermissionsAccess(profile, 'ReportLog', 'Update') || !!UserPermissions.checkPermissionsAccess(profile, 'ReportLog', 'Delete')) {
        navLinks.nonAdministrative.push($scope.navOptions.reports);
      }


      // TODO: Update this if necessary for new security page (Justin 19/11/18)
      if(!!UserPermissions.checkPermissionsAccess(profile, 'Role', 'Create') || !!UserPermissions.checkPermissionsAccess(profile, 'Role', 'Update') ||
        !!UserPermissions.checkPermissionsAccess(profile, 'Role', 'Delete')){
        navLinks.administrative.push($scope.navOptions.security);
      }

      //start region Developer
      if (!!UserPermissions.isDeveloper(profile)) {
        navLinks.administrative.push($rootScope.navOptions.developerTools);
      }

      var organizationArray = [];
      var costCenterArray = [];
      for (var index in profile.role_assignments) {
        for (var i in profile.role_assignments[index].roles) {
          if (profile.role_assignments[index].roles[i].name == 'TENANT_SUPER_ADMIN' ||
            profile.role_assignments[index].roles[i].name == 'System Support') {
            navLinks.administrative.push($scope.navOptions.settings);
            if (! featureIsEnabled('email.disableSend')) {navLinks.administrative.push($rootScope.navOptions.emails)}
            navLinks.bifrost.push($rootScope.navOptions.bifrost);
            navLinks.bifrost.push($rootScope.navOptions.bifrostFile);
            navLinks.bifrost.push($rootScope.navOptions.bifrostMapping);
            navLinks.bifrost.push($rootScope.navOptions.bifrostProcessStatus);
            navLinks.bifrost.push($rootScope.navOptions.bifrostDataReinbound);
            if (featureIsEnabled('syncEngine')) {navLinks.kharon.push($rootScope.navOptions.kharonSyncLog);}
            navLinks.administrative.push($rootScope.navOptions.logViewer);
          }

          if (profile.role_assignments[index].roles[i].name == 'ADMIN' ||
            profile.role_assignments[index].roles[i].name == 'TENANT_SUPER_ADMIN') {
            // check duplication maintenance issue
            if (!JSON.stringify(navLinks.maintenance).includes('main.user.maintenance')) {
              navLinks.maintenance.push($rootScope.navOptions.maintenance);
            }

            if (profile.role_assignments.some(function(roleAssignment) {
                return roleAssignment.roles.some(function(role) {
                    return role.name === 'System Support';
                });
            })) {
                // empty navLinks.maintenance
                navLinks.maintenance = [];
            }
          }

          if ($rootScope.isSourcingSupplierEnabled &&
                (profile.role_assignments[index].roles[i].name == 'TENANT_SUPER_ADMIN' ||
                profile.role_assignments[index].roles[i].name == 'Sourcing Metabuyer Admin')) {
            // check duplication policyDisclaimer issue
            if (!JSON.stringify(navLinks.administrative).includes('main.user.policyDisclaimer')) {
              navLinks.administrative.push($rootScope.navOptions.policyDisclaimer);
            }
          }

          for (var j in profile.role_assignments[index].roles[i].permissions) {
            var permission = profile.role_assignments[index].roles[i].permissions[j].name.split(' ');

            if (profile.role_assignments[index].weight == 3 && (permission[0] == 'Create' && permission[1] == 'PR')) {
              organizationArray.push(profile.role_assignments[index].organization_id);
            }
            else if (profile.role_assignments[index].weight == 4 && (permission[0] == 'Create' && permission[1] == 'PR')) {
              costCenterArray.push(profile.role_assignments[index].organization_id);
            }
          }
        }
      }

      if (
        !!(globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN') ||
          globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'System Support')) &&
        $rootScope.genpAdminFeatureEnable
      ) {
        navLinks.administrative.push($rootScope.navOptions.minion);
      }

      //list of company of the cost centers
      var companyIds = [];
      for (var i = 0; i < profile.costCenters.length; i++) {
        if (costCenterArray.indexOf(profile.costCenters[i]._id) !== -1) {
          companyIds.push(profile.costCenters[i].company_id);
        }
      }

      /**
       * list all the companies that a user can create a PR from
       * @type {Array}
       */
      $rootScope.listOfCompaniesForLoggedInUser = [];
      for (var i = 0; i < profile.companies.length; i++) {
        if (organizationArray.indexOf(profile.companies[i]._id) !== -1 || companyIds.indexOf(profile.companies[i]._id) !== -1) {
            $rootScope.listOfCompaniesForLoggedInUser.push(profile.companies[i]);
        }
      }

      // Supplier web admin menu
      if (!!$rootScope.currentUser.tenant.config.supplier_web &&
        !!globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'SUPPLIER_WEB_ADMIN')) {
        navLinks.supplierWeb.push($rootScope.navOptions.supplierWebAdminDashboard);
        navLinks.supplierWeb.push($rootScope.navOptions.supplierWebAdminManagement);
        navLinks.supplierWeb.push($rootScope.navOptions.supplierWebAdminUsers);
        navLinks.supplierWeb.push($rootScope.navOptions.supplierWebEmail);
      }

      return navLinks;
    };
    if (!!authenticated && !$rootScope.isMobileMode) {
      $rootScope.navlinks = {};
      $rootScope.navlinks = getNavLinkByRole();
      $scope.user = profile;
    }

    if(!!authenticated && $rootScope.isMobileMode){
      $rootScope.navlinks = {};
      $rootScope.navlinks.nonAdministrative = [];
      if (!!UserPermissions.checkPermissionsAccess(profile, 'PR')) {
        $rootScope.navlinks.nonAdministrative.push($rootScope.navOptions.pr);
      }
      if (!!UserPermissions.checkPermissionsAccess(profile, 'PO')) {
        $rootScope.navlinks.nonAdministrative.push($rootScope.navOptions.po);
      }
      $scope.user = profile;
    }
  };


  $scope.isSidebarCollapsed = function () {
    return angular.element('body').hasClass('mini-navbar');
  };

  $scope.isProfileDropdownOpen = function () {
    return angular.element('.profile-element').hasClass('open');
  };

  $scope.getProfile($scope.companyWorkSpace);


  if ($state.current.name === 'main') {
    $state.go('main.user.mainMenu.waiting-on-you');
  }

  var unwatchSidebar = $rootScope.$watch(
    function () {
      // also check against 'pace-done' class in body
      // to ensure the animation only starts after all other stuffs are loaded
      // otherwise it would be too quick and the animation would not be smooth
      var body = $('body');
      return $('.navbar-minimalize') && body.hasClass('pace-done') && body.hasClass('mini-navbar');
    },
    function (newValue, oldValue) {
      if (newValue === true) {
        unwatchSidebar();
      }
    });

  $scope.$on('flow::fileAdded', function (event, $flow, flowFile) {
    event.preventDefault();//prevent file from uploading
  });

  $rootScope.bodyClasses = '';
  var bodyClass = profile.config.font_weight === 'bold' ? 'mini-navbar user-setting-font-weight-bold' : 'mini-navbar';
  $('body').addClass(bodyClass);
  loadWaitingsAndNotifications();
}

userCtrl.$inject = [
  '$scope', 'reLoginService', '$auth', '$state', '$rootScope', 'Account', 'toastr',
  'anTinycon', 'ManageLocalStorage', 'authenticated', 'pathConstants',
  'UserPermissions', 'profile', '$interval', 'unreadNotificationList', 'waitingOnUserList', 'budgetFunctions',
  'globalFunc', 'featureToggle'
];

angular.module('metabuyer')
  .controller('userCtrl', userCtrl);
