'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function ($stateProvider) {

    $stateProvider
      .state('main.user.companiesMgmt', {
        url: '/companies',
        controller: 'CompaniesCtrl',
        templateUrl: 'app/companiesManagement/companiesManagement.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state) {
            var check = false;

            //TODO: Need to enhance this section
            if (!!profile.role_assignments && profile.role_assignments.length > 0) {
              for (var index in profile.role_assignments) {
                for (var i in profile.role_assignments[index].roles) {
                  if(profile.role_assignments[index].roles[i].name.toLowerCase() == 'admin' ||
                    profile.role_assignments[index].roles[i].name.toLowerCase() == 'tenant_super_admin' ||
                    profile.role_assignments[index].roles[i].name.toLowerCase() == 'sourcing metabuyer admin' ||
                    profile.role_assignments[index].roles[i].name.toLowerCase() == 'sourcing tenant admin' ||
                    profile.role_assignments[index].roles[i].name.toLowerCase() == 'system support'){
                    check = true;
                    break;
                  }
                }
              }
            }
            if (!check) {
              $state.go('notAuthorized');
            }
          },
          bifrostFeatureToggle: function ($q, $stateParams, FeatureToggleService, toastr) {
            var deferred = $q.defer();

            FeatureToggleService.getOne(['bifrost'])
              .then(function (resource) {
                if (!!resource.data) {
                  deferred.resolve(resource.data);
                } else {
                  deferred.resolve({});
                  console.log("Failed to get toggle data.");
                }
              })
              .catch(function (e) {
                deferred.resolve({});
                toastr.error('Something went wrong')
              });

            return deferred.promise;
          }
        }
      })
      //Start of company states
      .state('main.user.companiesMgmt.addCompany', {
        url: '/add-company',
        templateUrl: 'app/companiesManagement/companies/add/add.html',
        controller: 'addCompanyCtrl',
        resolve:{
          accessRights: function (profile, UserPermissions, $state, globalFunc) {
            if (globalFunc.isSystemSupportRole()) {
              $state.go('notAuthorized');
            }
          },
          BU: function($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'business-units',
                offset: 0
              },
              function(resource) {
                deferred.resolve(resource.content);
              },
              function(error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          industryList: function($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'industries',
                offset: 0
              },
              function(resource) {
                deferred.resolve(resource.content);
              },
              function(error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          currenciesList: function ($q, Currencies) {
            var deferred = $q.defer();
            // TODO: remove usage of local storage said Ahmed, Shin (30/6/2020)
            if (!JSON.parse(window.localStorage.getItem('currencies'))) {
              Currencies.get(
                {},
                function (resource) {
                  window.localStorage.setItem("currencies", JSON.stringify(resource.content.data));
                  deferred.resolve(resource.content.data);
                },
                function (error) {
                  if (error.status === 404) {
                    deferred.resolve([]);
                  }
                }
              );
            }
            else{
              var content = JSON.parse(window.localStorage.getItem('currencies'));
              if(!!content){
                deferred.resolve(content);
              }
              else{
                deferred.resolve([]);
              }

            }
            return deferred.promise;
          },
          expenseTypesList: function($q, metabuyerCache, expenseTypeUserList, globalFunc) {
            var deferred = $q.defer();
            if (!!metabuyerCache.get('expenseTypes')) {
              deferred.resolve(metabuyerCache.get('expenseTypes'));
            } else {
              expenseTypeUserList.get({},
                function (resource) {
                  if (!!resource.content && !!resource.content.data) {
                    deferred.resolve(resource.content.data);
                    metabuyerCache.put('expenseTypes', resource.content.data)
                  }
                }, function (error) {
                  globalFunc.objectErrorMessage(error);
                  deferred.resolve([]);
                }
              )
            }
            return deferred.promise;
          },
          $title: function () {
            return "Companies - Add";
          }
        }
      })
      .state('main.user.companiesMgmt.manageCompanies', {
        url: '/manage?{industry_code:[a-zA-Z0-9]+}&{bu_code:[a-zA-Z0-9]+}&{query:[a-zA-Z0-9]+}&{page:[0-9]}&' +
        '{order_by:[^~,]}&{order:[^~,]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/companiesManagement/companies/manage/manage.html',
        controller: 'manageCompanyCtrl',
        resolve: {
          companies: function($q, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();
            var params = {};
            if(!!$stateParams.filter) {
              /**
               * check for the status
               */
              if ($stateParams.filter.toLowerCase() === 'status') {
                var statusCode = globalFunc.getStatusCode($stateParams.query);
                if (statusCode === null) {
                  statusCode = 1;
                }
              }

              var temp = 'criteria[0][' + $stateParams.filter + ']';
              params = {
                module: 'companies',
                offset: 20,
                page: $stateParams.page,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };

              if ($stateParams.type === 'date') {
                temp = 'criteria[' + $stateParams.filter + '][0][]';
                params[temp] = [$stateParams.query[0], $stateParams.query[1]];
              } else {
                params[temp] = (!!statusCode || statusCode === 0 ) ? statusCode : $stateParams.query;
              }
            }
            //For listing companies for a particular BU by id
            else if (!!$stateParams.bu_code) {
              searchModule.get(
                {
                  module: 'companies',
                  page: $stateParams.page,
                  'criteria[bu_code]': $stateParams.bu_code
                },
                function (resource) {
                  deferred.resolve(resource.content || {data: []});
                },
                function (error) {
                  deferred.resolve({data: []});
                }
              );
              return deferred.promise;
            }
            //For listing companies for a particular industry by id
            else if (!!$stateParams.industry_code) {
              searchModule.get(
                {
                  module: 'companies',
                  page: $stateParams.page,
                  'criteria[industry_code]': $stateParams.industry_code
                },
                function (resource) {
                  deferred.resolve(resource.content || {data: []});
                },
                function (error) {
                  deferred.resolve({data: []});
                }
              );
              return deferred.promise;
            }
            else{
              params = {
                module: 'companies',
                offset: 20,
                'criteria[0][code]': $stateParams.query,
                'criteria[0][descr]': $stateParams.query,
                'criteria[0][reg_no]': $stateParams.query,
                'criteria[0][currency_code]': $stateParams.query,
                'criteria[0][industry.descr]': $stateParams.query,
                'criteria[0][business_unit.descr]': $stateParams.query,
                'criteria[0][creator.display_name]': $stateParams.query,
                'criteria[0][updater.display_name]': $stateParams.query,
                criteria_operator: 'or',
                page: $stateParams.page,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              }
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                if(!!resource && !!resource.content && !!resource.content.data){
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve({data: []});
                }
              },
              function () {
                deferred.resolve({data: []});
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return "Companies - search";
          }
        }
      })
      .state('main.user.companiesMgmt.detailCompanies', {
        url: '/{id:[a-zA-Z0-9]+}',
        templateUrl: 'app/companiesManagement/companies/details/details.html',
        controller: 'companyDetailCtrl',
        resolve: {
          companyDetails: function($q, Companies, $stateParams, toastr, companyGlobalFunc) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
                companyGlobalFunc.setCompanyData(resource.content.data);
              },
              function (error) {
                if(!!error.data&& !!error.data.content){
                  toastr.error(error.data.content.error);
                }
              }
            );
            return deferred.promise;
          },
          cachedAddresses: function($q, singleAddress, companyDetails, metabuyerCache) {
            var deferred = $q.defer();
            if (!!companyDetails && !!companyDetails.data && !!companyDetails.data.related_addresses_ids &&
              !!companyDetails.data.related_addresses_ids.length) {
              var promises = [];
              var cleanedAddress = [];
              angular.forEach(companyDetails.data.related_addresses_ids, function (id) {
                var address = metabuyerCache.get('address - ' + id);

                if (!!address) {
                  cleanedAddress.push(address);
                } else {
                  var promise = singleAddress.get({
                    id: id
                  });
                  promises.push(promise.$promise);
                }
              });
              if (!!promises) {
                $q.all(promises).then(function (resource) {
                  angular.forEach(resource, function (address) {
                    if (!!address && !!address.content && !!address.content.data) {
                      metabuyerCache.put('address - ' + address.content.data._id, address.content.data);
                      cleanedAddress.push(address.content.data);
                    }
                  });
                }).catch().finally(function () {
                  deferred.resolve(cleanedAddress);
                });
              }
              else {
                deferred.resolve(cleanedAddress);
              }
            } else {
              deferred.resolve([]);
            }
            return deferred.promise;

          },
            companies: function($q, searchModule, $stateParams, toastr) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'companies',
                offset: 0
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                toastr.error('Error, Failed to get the companies');
              }
            );
            return deferred.promise;
          },
          currenciesList: function ($q, Currencies) {
            var deferred = $q.defer();
            // TODO: remove usage of local storage said Ahmed, Shin (30/6/2020)
            if (!JSON.parse(window.localStorage.getItem('currencies'))) {
              Currencies.get(
                {},
                function (resource) {
                  window.localStorage.setItem("currencies", JSON.stringify(resource.content.data));
                  deferred.resolve(resource.content.data);
                },
                function (error) {
                  if (error.status === 404) {
                    deferred.resolve([]);
                  }
                }
              );
            }
            else{
              var content = JSON.parse(window.localStorage.getItem('currencies'));
              if(!!content){
                deferred.resolve(content);
              }
              else{
                deferred.resolve([]);
              }

            }
            return deferred.promise;
          },
          BUs: function($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'business-units',
                offset: 0,

                // Get only active BU = 1
                'criteria[is_active]': 1
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          industryList: function($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'industries',
                offset: 0
              },
              function(resource) {
                deferred.resolve(resource.content);
              },
              function(error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          companyCurrencies: function($q, companyCurrencies, $stateParams) {
            var deferred = $q.defer();
            companyCurrencies.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          modeOfPurchaseList: function($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'mode-of-purchase',
                'criteria[0][is_active]': true,
                offset: 0
              },
              function(resource) {
                deferred.resolve(resource.content);
              },
              function(error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          expenseTypesList: function($q, metabuyerCache, expenseTypeUserList, globalFunc) {
            var deferred = $q.defer();
            if (!!metabuyerCache.get('expenseTypes')) {
              deferred.resolve(metabuyerCache.get('expenseTypes'));
            } else {
              expenseTypeUserList.get({},
                function (resource) {
                  if (!!resource.content && !!resource.content.data) {
                    deferred.resolve(resource.content.data);
                    metabuyerCache.put('expenseTypes', resource.content.data)
                  }
                }, function (error) {
                  globalFunc.objectErrorMessage(error);
                  deferred.resolve([]);
                }
              )
            }
            return deferred.promise;
          },
          toleranceLimitData: function ($q, $stateParams, toleranceLimit) {
            var deferred = $q.defer();

            toleranceLimit.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content);
                if (!!resource && !!resource.content && !!resource.content.data) {
                  deferred.resolve(resource.content);
                } else {
                  deferred.resolve({data: []});
                }
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );

            return deferred.promise;
          },
          featureToggles: function ($q, $stateParams, FeatureToggleService, toastr) {
            var deferred = $q.defer();

            FeatureToggleService.getMultiple(['company.config.toleranceLimit', 'company.config.automatedPo', 'company.config.modeOfPurchase', 'company.config.disableExchangeRate', 'company.config.disableItemTax', 'company.config.stockInformationGroup', 'company.config.enablePriceComparison'])
              .then(function (resource) {
                if (!!resource.data) {
                  deferred.resolve(resource.data);
                } else {
                  deferred.resolve([]);
                }
              })
              .catch(function (e) {
                toastr.error('Something went wrong')
              });

            return deferred.promise;
          },
          stockInformationGroup: function ($q, companyDetails, getStockInformationGroup){
            var deferred = $q.defer();

            getStockInformationGroup.get(
              {
                'criteria[0][is_active]': true,
                'criteria[1][companies|code]':  companyDetails.data.code,
              },
              function (resource) {
                if (!!resource.content && !!resource.content.data){
                  deferred.resolve(resource.content.data);
                }
              },
              function (error) {
                deferred.resolve([])
              }
            );
            return deferred.promise;
          },
          stockInformationGroupToggle: function ($q, FeatureToggleService, toastr) {
            var deferred = $q.defer();

            FeatureToggleService.getOne(['company.config.stockInformationGroup'])
              .then(function (resource) {
                if (!!resource.data) {
                  deferred.resolve(resource.data);
                } else {
                  deferred.resolve({});
                  console.log("Failed to get toggle data.");
                }
              })
              .catch(function (e) {
                deferred.resolve({});
                toastr.error('Something went wrong')
              });

            return deferred.promise;
          },
          $title: function () {
            return "Companies - details";
          }
        }
      })
      .state('main.user.companiesMgmt.companiesExchangeRate', {
        url: '/manage/{id:[a-zA-Z0-9]+}/exchange-rate?{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}',
        templateUrl: 'app/companiesManagement/companies/exchangeRate/manage.html',
        controller: 'companyExchangeRateCtrl',
        resolve: {
          //TODO: have this endpoint child of the company details so that the loading is not done twice (Ahmed Saleh, 25/7/2016)
          companyDetails: function($q, Companies, $stateParams) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          companyExchangeRate: function($q, companyDetails, searchModule,$stateParams, exportSearch){
            var deferred = $q.defer();

            var params = {
              module: 'exchange-rate',
              offset: 20,
              'criteria[company_id]': companyDetails.data._id,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          currenciesList: function($q, searchModule) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'currencies',
                offset: 0
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "Companies - Exchange Rate";
          }
        }
      })
      .state('main.user.companiesMgmt.companiesContent', {
        url: '/{id:[a-zA-Z0-9]+}/content',
        templateUrl: 'app/companiesManagement/companies/content/content.html',
        controller: 'companyContentCtrl',
        resolve: {
          allCompanies: function($q, searchModule, toastr) {
            var deferred = $q.defer();
            searchModule.get(
              {
                module: 'companies',
                offset: 0
              },
              function (resource) {
                deferred.resolve(resource.content.data || {data: []});
              },
              function (error) {
                toastr.error('Error, Failed to get the companies');
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Companies - Content Management';
          }
        }
      })
      .state('main.user.companiesMgmt.companiesUsers', {
        url: '/manage/{id:[a-zA-Z0-9]+}/users?{code:[a-zA-Z0-9]+}&{page:[0-9]}&{order_by:[^~,]}&{order:[0-1]}&{query:[a-zA-Z0-9]+}&{filter:[^~,]}&{type:[^~,]}',
        templateUrl: 'app/companiesManagement/companies/users/manage.html',
        controller: 'companyUsersCtrl',
        resolve: {
          companyDetails: function($q, Companies, $stateParams) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          companyUsers: function($q, searchModule, $stateParams, globalFunc) {
            var deferred = $q.defer();
            var params = {};
            if(!!$stateParams.filter) {
              /**
               * check for the status
               */
              if ($stateParams.filter.toLowerCase() === 'status') {
                var statusCode = globalFunc.getStatusCode($stateParams.query);
                if (statusCode === null) {
                  statusCode = 1;
                }
              }

              var temp = 'criteria[1][' + $stateParams.filter + ']';
              params = {
                module: 'users',
                'criteria[0][role_assignments|organization_id][]': $stateParams.id,
                'criteria_operator': 'and',
                offset: 20,
                page: $stateParams.page,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              };
              if ($stateParams.type === 'date') {
                temp = 'criteria[' + $stateParams.filter + '][0][]';
                params[temp] = [$stateParams.query[0], $stateParams.query[1]];
              } else {
                params[temp] = (!!statusCode || statusCode === 0 ) ? statusCode : $stateParams.query;
              }
            }else{
              params = {
                module: 'users',
                'criteria[0][role_assignments|organization_id][]': $stateParams.id,
                'criteria[1][display_name]': $stateParams.query,
                'criteria[1][email]': $stateParams.query,
                'criteria[1][emp_no]': $stateParams.query,
                'criteria[1][$operator]': !!$stateParams.query ? 'or' : null,
                'criteria_operator': 'and',
                offset: 20,
                page: $stateParams.page,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              }
            }
            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                  deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "Companies - Users";
          }
        }
      })
      .state('main.user.companiesMgmt.companiesAccountCode', {
        url: '/manage/{id:[a-zA-Z0-9]+}/account-code?{query:[^~,]}+{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}&{code:[^~,]}',
        templateUrl: 'app/companiesManagement/companies/accountCode/manage.html',
        controller: 'companyAccountCodeCtrl',
        resolve: {
          companyDetails: function($q, Companies, $stateParams) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          companyAccountCodes: function($q, companyDetails, searchModule, $stateParams, exportSearch){
            var deferred = $q.defer();

            var params = {
              module: 'account-code',
              'criteria[1][name]': $stateParams.query,
              'criteria[1][descr]': $stateParams.query,
              'criteria[1][code]': $stateParams.query,
              'criteria[1][$operator]': !!$stateParams.query ? 'or' : null,
              'criteria[0][company_code]':  companyDetails.data.code,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order,
              offset: 20
            };

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          categoryTypesList: function ($q, loadCacheableData) {
            var deferred = $q.defer();
            loadCacheableData.loadData({
              module: 'category-type',
              'criteria[0][is_active]': true,
              offset: 0
            }, 'categoryTypes').then(function (data) {
              deferred.resolve(data);
            });

            return deferred.promise;
          },
          $title: function () {
            return "Companies - details";
          }
        }
      })
      .state('main.user.companiesMgmt.companiesAssetCategory', {
        url: '/manage/{id:[a-zA-Z0-9]+}/asset-category?{code:[a-zA-Z0-9]*}&{query:[a-zA-Z0-9]*}&{page:[0-9]}&' +
        '{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/companiesManagement/companies/assetCategory/manage.html',
        controller: 'assetCategoryCodeCtrl',
        resolve: {
          companyDetails: function($q, Companies, $stateParams) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          assetCategories: function($q, companyDetails, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();
            var params = {
              module: 'asset-category',
              'criteria[0][company|code]': companyDetails.data.code,
              'criteria[0][$strict]': true,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order,
              offset: 20
            };

            if(!!$stateParams.query) {
              if(!!$stateParams.filter) {
                var filterCriteria = 'criteria[1][' + $stateParams.filter + ']';
                params[filterCriteria] = $stateParams.query;
              } else {
                params['criteria[1][code]'] = $stateParams.query;
                params['criteria[1][name]'] = $stateParams.query;
                params['criteria[1][account_code]'] = $stateParams.query;
                params['criteria[1][$operator]'] = 'or';
              }
              params['criteria_operator'] = 'and';
            }

            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return "Asset Category - search";
          }
        }
      })
      .state('main.user.companiesMgmt.companiesProject', {
        url: '/manage/{id:[a-zA-Z0-9]+}/project?{code:[a-zA-Z0-9]*}&{query:[a-zA-Z0-9]*}&{page:[0-9]}&' +
        '{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/companiesManagement/companies/project/manage.html',
        controller: 'projectCodeCtrl',
        resolve: {
          companyDetails: function($q, Companies, $stateParams) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          companyProjectList: function($q, $stateParams, companyDetails, searchModule, exportSearch) {
            var deferred = $q.defer();
            var params = {
              module: 'project',
              'criteria[0][company|code]': companyDetails.data.code,
              'criteria[0][$strict]': true,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order,
              offset: 20
            };

            if(!!$stateParams.query) {
              if(!!$stateParams.filter) {
                var filterCriteria = 'criteria[1][' + $stateParams.filter + ']';
                params[filterCriteria] = $stateParams.query;
              } else {
                params['criteria[1][code]'] = $stateParams.query;
                params['criteria[1][name]'] = $stateParams.query;
                params['criteria[1][billing_company|descr]'] = $stateParams.query;
                params['criteria[1][$operator]'] = 'or';
              }
              params['criteria_operator'] = 'and';
            }

            exportSearch.setParams(params);

            searchModule.get(
              params,
              function(resource) {
                deferred.resolve(resource.content);
              },
              function(error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return "Project - search";
          }
        }
      })
      .state('main.user.companiesMgmt.companyItems', {
        url: '/company-items/{companyId:[a-zA-Z0-9\-]*}?{code:[a-zA-Z0-9]*}&{query:[a-zA-Z0-9]*}&{page:[0-9]}&' +
        '{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/items/companyItems/itemsList/manage.html',
        controller: 'companyItemCtrl',
        params: {item: null},
        resolve: {
          companyDetails: function ($q, Companies, $stateParams) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.companyId
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          items: function ($q, companyDetails, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();
            var params = {
              module: 'company-item',
              'criteria[0][company|code]': companyDetails.data.code,
              'criteria[0][$strict]': true,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order,
              offset: 20
            };

            if (!!$stateParams.query) {
              if (!!$stateParams.filter) {
                var filterCriteria = 'criteria[1][' + $stateParams.filter + ']';
                params[filterCriteria] = $stateParams.query;
              } else {
                params['criteria[1][item_master_code]'] = $stateParams.query;
                params['criteria[1][name]'] = $stateParams.query;
                params['criteria[1][uom]'] = $stateParams.query;
                params['criteria[1][item_category|code]'] = $stateParams.query;
                params['criteria[1][item_category|name]'] = $stateParams.query;
                params['criteria[1][account_code]'] = $stateParams.query;
                params['criteria[1][$operator]'] = 'or';
              }
              params['criteria_operator'] = 'and';
            }

            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return "Companies - search";
          }
        }
      })
      .state('main.user.companiesMgmt.companyItemsDetail', {
        url: '/company-items/detail/{id:[a-zA-Z0-9\-]*}',
        templateUrl: 'app/items/companyItems/itemDetails/details.html',
        controller: 'companyItemDetailCtrl',
        params: {item: null},
        resolve: {
          managedItem: function ($q, $stateParams, companyItem, authenticated) {
            var deferred = $q.defer();
            if (!authenticated) {
              deferred.resolve(undefined);
              return deferred.promise;
            }
            if (!!$stateParams.item) {
              deferred.resolve($stateParams.item);
            }
            else {
              companyItem.get(
                {
                  uuid: $stateParams.id
                }
                ,
                function (resource) {
                  if (!!resource.content && !!resource.content.data)
                    deferred.resolve(resource.content.data);
                },
                function () {
                  deferred.resolve([]);
                }
              );
            }
            return deferred.promise;
          },
          $title: function () {
            return "Items - search";
          }
        }
      })
      .state('main.user.companiesMgmt.companiesItemTax', {
        url: '/manage/{id:[a-zA-Z0-9]+}/item-tax?{code:[a-zA-Z0-9]*}&{query:[a-zA-Z0-9]*}&{page:[0-9]}&' +
        '{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/companiesManagement/companies/itemTax/manage.html',
        controller: 'companyItemTaxCtrl',
        resolve: {
          companyDetails: function($q, Companies, $stateParams) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          itemTaxesHistory: function($q, companyItemTaxHistory, $stateParams) {
            var deferred = $q.defer();
            companyItemTaxHistory.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          itemTaxes: function($q, companyDetails, searchModule, $stateParams) {
            var deferred = $q.defer();
            var params = {
              module: 'associations',
              'criteria[0][source|company_code]': companyDetails.data.code,
              'criteria[0][source|class]': 'Metabuyer\\Models\\Item',
              'criteria[0][target|class]': 'Metabuyer\\Models\\Tax',
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order,
              offset: 20
            };

            if(!!$stateParams.query) {
              if(!!$stateParams.filter) {
                var temp = '';
                if ($stateParams.filter === 'internal_part_no' || $stateParams.filter === 'cost_center_code') {
                  temp = 'criteria[1][source|' + $stateParams.filter + ']';
                } else if ($stateParams.filter === 'tax_code') {
                  temp = 'criteria[1][target|' + $stateParams.filter + ']';
                } else {
                  temp = 'criteria[1][' + $stateParams.filter + ']';
                }
                params[temp] = $stateParams.query;
              } else {
                params['criteria[1][source|internal_part_no]'] = $stateParams.query;
                params['criteria[1][source|cost_center_code]'] = $stateParams.query;
                params['criteria[1][target|tax_code]'] = $stateParams.query;
                params['criteria[1][$operator]'] = 'or';
              }
              params['criteria_operator'] = 'and';
            }

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'Companies - Item Tax';
          }
        }
      })
      // start of company research
      .state('main.user.companiesMgmt.companyResearch', {
        url: '/{companyId:[a-zA-Z0-9]+}/research?{code:[a-zA-Z0-9]*}&{query:[a-zA-Z0-9]*}&{page:[0-9]}&' +
        '{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/companiesManagement/companies/research/manage.html',
        controller: 'researchCtrl',
        resolve: {
          companyDetails: function ($q, Companies, $stateParams) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.companyId
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          dataList: function($q, searchModule, $stateParams, globalFunc, exportSearch, companyDetails) {
            var deferred = $q.defer();

            var params = {
              module: 'research',
              'criteria[0][company_code]': companyDetails.data.code,
              'criteria[0][$strict]': true,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order,
              offset: 20
            };

            if(!!$stateParams.query) {
              if(!!$stateParams.filter) {
                var filterCriteria = 'criteria[1][' + $stateParams.filter + ']';
                params[filterCriteria] = $stateParams.query;
              } else {
                params['criteria[1][code]'] = $stateParams.query;
                params['criteria[1][name]'] = $stateParams.query;
                params['criteria[1][$operator]'] = 'or';
              }
              params['criteria_operator'] = 'and';
            }

            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return "Companies - Research - Listing";
          }
        }
      })
      // start of company special event
      .state('main.user.companiesMgmt.companySpecialEvent', {
        url: '/{companyId:[a-zA-Z0-9]+}/special-event?{code:[a-zA-Z0-9]*}&{query:[a-zA-Z0-9]*}&{page:[0-9]}&' +
        '{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/companiesManagement/companies/specialEvent/manage.html',
        controller: 'specialEventCtrl',
        resolve: {
          companyDetails: function ($q, Companies, $stateParams) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.companyId
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          dataList: function($q, searchModule, $stateParams, globalFunc, exportSearch, companyDetails) {
            var deferred = $q.defer();

            var params = {
              module: 'special-event',
              'criteria[0][company_code]': companyDetails.data.code,
              'criteria[0][$strict]': true,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order,
              offset: 20
            };

            if(!!$stateParams.query) {
              if(!!$stateParams.filter) {
                var filterCriteria = 'criteria[1][' + $stateParams.filter + ']';
                params[filterCriteria] = $stateParams.query;
              } else {
                params['criteria[1][code]'] = $stateParams.query;
                params['criteria[1][name]'] = $stateParams.query;
                params['criteria[1][$operator]'] = 'or';
              }
              params['criteria_operator'] = 'and';
            }

            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return "Companies - Special Event - Listing";
          }
        }
      })
      // start of company student life
      .state('main.user.companiesMgmt.companyStudentLife', {
        url: '/{companyId:[a-zA-Z0-9]+}/student-life?{code:[a-zA-Z0-9]*}&{query:[a-zA-Z0-9]*}&{page:[0-9]}&' +
        '{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/companiesManagement/companies/studentLifeEvent/manage.html',
        controller: 'studentLifeEventCtrl',
        resolve: {
          companyDetails: function ($q, Companies, $stateParams) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.companyId
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          dataList: function($q, searchModule, $stateParams, globalFunc, exportSearch, companyDetails) {
            var deferred = $q.defer();

            var params = {
              module: 'student-life-event',
              'criteria[0][company_code]': companyDetails.data.code,
              'criteria[0][$strict]': true,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order,
              offset: 20
            };

            if(!!$stateParams.query) {
              if(!!$stateParams.filter) {
                var filterCriteria = 'criteria[1][' + $stateParams.filter + ']';
                params[filterCriteria] = $stateParams.query;
              } else {
                params['criteria[1][code]'] = $stateParams.query;
                params['criteria[1][name]'] = $stateParams.query;
                params['criteria[1][$operator]'] = 'or';
              }
              params['criteria_operator'] = 'and';
            }

            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return "Companies - Student Life Event - Listing";
          }
        }
      })
      // Start of Company Addresses
      .state('main.user.companiesMgmt.companyAddresses', {
        url: '/{companyId:[a-zA-Z0-9]+}/addresses',
        templateUrl: 'app/companiesManagement/companies/companyAddresses/manage.html',
        controller: 'companyAddressesCtrl',
        resolve: {
          companyDetails: function($q, Companies, $stateParams) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.companyId
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          addresses: function ($q, singleAddress, companyDetails, metabuyerCache) {
            var deferred = $q.defer();
            if (!!companyDetails && !!companyDetails.data && !!companyDetails.data.related_addresses_ids &&
              !!companyDetails.data.related_addresses_ids.length) {
              var promises = [];
              var cleanedAddress = [];
              angular.forEach(companyDetails.data.related_addresses_ids, function (id) {
                var address = metabuyerCache.get('address - ' + id);

                if (!!address) {
                  cleanedAddress.push(address);
                } else {
                  var promise = singleAddress.get({
                    id: id
                  });
                  promises.push(promise.$promise);
                }
              });
              if (!!promises) {
                $q.all(promises).then(function (resource) {
                  angular.forEach(resource, function (address) {
                    if (!!address && !!address.content && !!address.content.data) {
                      metabuyerCache.put('address - ' + address.content.data._id, address.content.data);
                      cleanedAddress.push(address.content.data);
                    }
                  });
                }).catch().finally(function () {
                  deferred.resolve(cleanedAddress);
                });
              }
              else {
                deferred.resolve(cleanedAddress);
              }
            } else {
              deferred.resolve([]);
            }
            return deferred.promise;
          },
          $title: function () {
            return "Companies - Company Addresses - Listing";
          }
        }
      })
      // End of Company Address

      // Start of OPEX IO
      .state('main.user.companiesMgmt.companyOpexIO', {
        url: '/{companyId:[a-zA-Z0-9]+}/opex-io?{code:[a-zA-Z0-9]*}&{query:[a-zA-Z0-9]*}&{page:[0-9]}&' +
        '{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/companiesManagement/companies/opexIo/manage.html',
        controller: 'opexIOCtrl',
        resolve: {
          companyDetails: function ($q, Companies, $stateParams) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.companyId
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          dataList: function($q, searchModule, $stateParams, globalFunc, exportSearch, companyDetails) {
            var deferred = $q.defer();

            var params = {
              module: 'opex-ios',
              'criteria[0][company|code]': companyDetails.data.code,
              'criteria[0][$strict]': true,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order,
              offset: 20
            };

            if(!!$stateParams.query) {
              if(!!$stateParams.filter) {
                var filterCriteria = 'criteria[1][' + $stateParams.filter + ']';
                params[filterCriteria] = $stateParams.query;
              } else {
                params['criteria[1][code]'] = $stateParams.query;
                params['criteria[1][name]'] = $stateParams.query;
                params['criteria[1][$operator]'] = 'or';
              }
              params['criteria_operator'] = 'and';
            }

            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return "Companies - Opex IO - Listing";
          }
        }
      })
      // End of OPEX IO
       // Start of Other Charges Group
       .state('main.user.companiesMgmt.companyOtherChargesGroup', {
        url: '/{companyId:[a-zA-Z0-9]+}/other-charges-groups?{code:[a-zA-Z0-9]*}&{query:[a-zA-Z0-9]*}&{page:[0-9]}&' +
        '{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/companiesManagement/companies/otherChargesGroup/manage/manage.html',
        controller: 'otherChargesGroupCtrl',
        resolve: {
          companyDetails: function ($q, Companies, $stateParams) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.companyId
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          chargesList: function($q, searchModule, $stateParams, globalFunc, exportSearch,companyDetails) {
            var deferred = $q.defer();

            var params = {
              module: 'other-charges-groups',
              'criteria[0][company_code]': companyDetails.data.code,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order,
              offset: 20
            };

            if(!!$stateParams.query) {
              if(!!$stateParams.filter) {
                var filterCriteria = 'criteria[1][' + $stateParams.filter + ']';
                params[filterCriteria] = $stateParams.query;
              } else {
                params['criteria[1][code]'] = $stateParams.query;
                params['criteria[1][name]'] = $stateParams.query;
                params['criteria[1][$operator]'] = 'or';
              }
              params['criteria_operator'] = 'and';
            }

            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return "Other Charges Group - Listing";
          }
        }
      })
      // End of Other Charges


      //Start of business unit states
      .state('main.user.companiesMgmt.addBU', {
        url: '/add-BU',
        templateUrl: 'app/companiesManagement/businessUnits/add/add.html',
        controller: 'addBUCtrl',
        resolve: {
          $title: function ($stateParams) {
            return "Companies - Business Unit - Add";
          },
          accessRights: function (profile, UserPermissions, $state, globalFunc) {
            if (globalFunc.isSystemSupportRole()) {
              $state.go('notAuthorized');
            }
          },
        }
      })
      .state('main.user.companiesMgmt.manageBusinessUnits', {
        url: '/businessUnits/manage?{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}&{code:[^~,]}&{query:[^~,]}&{filter:[^~,]}&{type:[^~,]}',
        templateUrl: 'app/companiesManagement/businessUnits/manage/manage.html',
        controller: 'manageBUCtrl',
        resolve: {
          BU: function($q, searchModule, $stateParams, globalFunc, exportSearch) {
            var deferred = $q.defer();
            var params = {};
            if (!!$stateParams.query) {

              if (!!$stateParams.filter) {
                /**
                 * check for the status
                 */
                if ($stateParams.filter.toLowerCase() === 'status') {
                  var statusCode = globalFunc.getStatusCode($stateParams.query);
                  if (statusCode === null) {
                    statusCode = 1;
                  }
                }
                var temp = 'criteria[0][' + $stateParams.filter + ']';
                params = {
                  module: 'business-units',
                  offset: 20,
                  page: $stateParams.page,
                  order_by: $stateParams.order_by,
                  order: $stateParams.order
                };
                if ($stateParams.type === 'date') {
                  temp = 'criteria[' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                } else {
                  params[temp] = (!!statusCode || statusCode === 0 ) ? statusCode : $stateParams.query;
                }
              } else {
                params = {
                  module: 'business-units',
                  'criteria[0][code]': $stateParams.query,
                  'criteria[0][descr]': $stateParams.query,
                  criteria_operator: !!$stateParams.query ? 'or' : null,
                  offset: 20,
                  page: $stateParams.page,
                  order_by: $stateParams.order_by,
                  order: $stateParams.order
                }
              }
            }
            else{
              params = {
                module: 'business-units',
                offset: 20,
                page: $stateParams.page,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              }
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function(resource) {
                deferred.resolve(resource.content);
              },
              function(error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "Companies - Business Unit - Listing";
          }
        }
      })
      .state('main.user.companiesMgmt.detailBusinessUnits', {
        url: '/manage/businessUnits/{id:[a-zA-Z0-9]+}',
        templateUrl: 'app/companiesManagement/businessUnits/details/details.html',
        controller: 'BUDetailCtrl',
        resolve: {
          BUDetails: function($q, singleBU, $stateParams) {
            var deferred = $q.defer();
            singleBU.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "Companies - Business Unit - Details";
          }
        }
      })
      //End of business unit states

      //Start of industries states
      .state('main.user.companiesMgmt.addIndustry', {
        url: '/add-industry',
        templateUrl: 'app/companiesManagement/industries/add/add.html',
        controller: 'addIndustryCtrl',
        resolve: {
          $title: function ($stateParams) {
            return "Companies - Industry - Add";
          },
          accessRights: function (profile, UserPermissions, $state, globalFunc) {
            if (globalFunc.isSystemSupportRole()) {
              $state.go('notAuthorized');
            }
          },
        }
      })
      .state('main.user.companiesMgmt.manageIndustries', {
        url: '/industries/manage?{page:[0-9]}&{order_by:[^~,]}&{order:[^~,]}&{query:[^~,]}&{filter:[^~,]}&{type:[^~,]}',
        templateUrl: 'app/companiesManagement/industries/manage/manage.html',
        controller: 'manageIndustryCtrl',
        resolve: {
          industryList: function($q, searchModule, $stateParams, globalFunc, exportSearch) {
            var deferred = $q.defer();
            var params = {};
            if(!!$stateParams.query) {
              if (!!$stateParams.filter) {
                /**
                 * check for the status
                 */
                if ($stateParams.filter.toLowerCase() === 'status') {
                  var statusCode = globalFunc.getStatusCode($stateParams.query);
                  if (statusCode === null) {
                    statusCode = 1;
                  }
                }
                var temp = 'criteria[0][' + $stateParams.filter + ']';
                params = {
                  module: 'industries',
                  offset: 20,
                  page: $stateParams.page,
                  order_by: $stateParams.order_by,
                  order: $stateParams.order
                };
                if ($stateParams.type === 'date') {
                  temp = 'criteria[' + $stateParams.filter + '][0][]';
                  params[temp] = [$stateParams.query[0], $stateParams.query[1]];
                } else {
                  params[temp] = (!!statusCode || statusCode === 0 ) ? statusCode : $stateParams.query;
                }
              } else {
                params = {
                  module: 'industries',
                  'criteria[code]': $stateParams.query,
                  'criteria[descr]': $stateParams.query,
                  criteria_operator: !!$stateParams.query ? 'or' : null,
                  offset: 20,
                  page: $stateParams.page,
                  order_by: $stateParams.order_by,
                  order: $stateParams.order
                }
              }
            }
            else{
              params = {
                module: 'industries',
                offset: 20,
                page: $stateParams.page,
                order_by: $stateParams.order_by,
                order: $stateParams.order
              }
            }

            /**
             * Setting the params to prepare for exporting
             */
            exportSearch.setParams(params);

            searchModule.get(
              params,
              function(resource) {
                deferred.resolve(resource.content);
              },
              function(error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "Companies - Industries - Listing";
          }
        }
      })
      .state('main.user.companiesMgmt.detailIndustries', {
        url: '/manage/industries/{id:[a-zA-Z0-9]+}',
        templateUrl: 'app/companiesManagement/industries/details/details.html',
        controller: 'industryDetailCtrl',
        resolve: {
          industryDetails: function($q, singleIndustry, $stateParams) {
            var deferred = $q.defer();
            singleIndustry.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function ($stateParams) {
            return "Companies - Industry - Details";
          }
        }
      })
      //end of industries states

      //TODO: this state should be a child state of companyDetails to utilize the shared resolved data (Ahmed Saleh, 1/4/2018)
      .state('main.user.companiesMgmt.costCenterDetail', {
        url: '/manage/{companyId:[a-zA-Z0-9]+}/manage/cost-center/{id:[a-zA-Z0-9]+}',
        templateUrl: 'app/companiesManagement/costcenters/address/manage.html',
        controller: 'costCenterAddressesCtrl',
        resolve: {
          costCenterDetails: function ($q, CostCenters, $stateParams) {
            var deferred = $q.defer();
            CostCenters.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          companyDetails: function ($q, Companies, $stateParams) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.companyId
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          companyCostCentersList: function ($q, companyCostCenters, $stateParams) {
            var deferred = $q.defer();
            companyCostCenters.get(
              {
                id: $stateParams.companyId
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          addresses: function ($q, singleAddress, companyDetails, metabuyerCache) {
            var deferred = $q.defer();
            if (!!companyDetails && !!companyDetails.data && !!companyDetails.data.related_addresses_ids &&
              !!companyDetails.data.related_addresses_ids.length) {
              var promises = [];
              var cleanedAddress = [];
              angular.forEach(companyDetails.data.related_addresses_ids, function (id) {
                var address = metabuyerCache.get('address - ' + id);

                if (!!address) {
                  cleanedAddress.push(address);
                } else {
                  var promise = singleAddress.get({
                      id: id
                    });
                  promises.push(promise.$promise);
                }
              });
              if (!!promises) {
                $q.all(promises).then(function (resource) {
                  angular.forEach(resource, function (address) {
                    if (!!address && !!address.content && !!address.content.data) {
                      metabuyerCache.put('address - ' + address.content.data._id, address.content.data);
                      cleanedAddress.push(address.content.data);
                    }
                  });
                }).catch().finally(function () {
                  deferred.resolve(cleanedAddress);
                });
              }
              else {
                deferred.resolve(cleanedAddress);
              }
            } else {
              deferred.resolve([]);
            }
            return deferred.promise;

          },
          $title: function ($stateParams) {
            return "Companies - Cost Center - Addresses - List";
          }
        }
      })
      .state('main.user.companiesMgmt.detailCompanies.addCostCenter', {
        url: '/cost-center',
        templateUrl: 'app/companiesManagement/costcenters/add/add.html',
        params: {costCenterId: null, shadowCompanies: null},
        controller: 'addCostCenterCtrl',
        resolve: {
          companyUsers: function ($q, purchaseRequisitionsServices, globalFunc, $stateParams) {
            var deferred = $q.defer();

            purchaseRequisitionsServices.getContactPersonsList(
              {
                id: $stateParams.id
              }, function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data)
                  deferred.resolve(resource.content.data || []);
              }, function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          costCenterData: function () {
            return [];
          },
          shadowCompanies: function ($stateParams) {
            return $stateParams.shadowCompanies;
          },
          companyId: function ($stateParams) {
            return $stateParams.id;
          },
          costCenterUsers: function () {
            return [];
          }
        }
      })
      .state('main.user.companiesMgmt.detailCompanies.updateCostCenter', {
        url: '/cost-center/{costCenterId:[a-zA-Z0-9]+}?',
        templateUrl: 'app/companiesManagement/costcenters/add/add.html',
        controller: 'addCostCenterCtrl',
        resolve: {
          companyUsers: function ($q, companyActiveUsers, globalFunc, $stateParams) {
            var deferred = $q.defer();

            companyActiveUsers.get(
              {
                id: $stateParams.id
              }, function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data)
                  deferred.resolve(resource.content.data || []);
              }, function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              }
            );
            return deferred.promise;
          },
          costCenterData: function ($q, CostCenters, $stateParams) {
            var deferred = $q.defer();

            CostCenters.get(
              {
                id: $stateParams.costCenterId
              },
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data)
                  deferred.resolve(resource.content.data || []);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              }
            );

            return deferred.promise;
          },
          costCenterUsers: function ($q, purchaseRequisitionsServices, globalFunc, $stateParams) {
            var deferred = $q.defer();

            purchaseRequisitionsServices.getAvailableRequestorsList(
              {
                id: $stateParams.costCenterId
              },
              function (resource) {
                if (!!resource && !!resource.content && !!resource.content.data)
                  deferred.resolve(resource.content.data || []);
              },
              function (error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              }
            );

            return deferred.promise;
          }
        }
      })
      .state('main.user.companiesMgmt.companiesAsset', {
        url: '/manage/{id:[a-zA-Z0-9]+}/company-asset?{code:[a-zA-Z0-9]*}&{query:[a-zA-Z0-9]*}&{page:[0-9]}&' +
        '{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/companiesManagement/companies/companyAsset/manage.html',
        controller: 'companyAssetCtrl',
        resolve: {
          companyDetails: function($q, Companies, $stateParams) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.id
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          companyAssets: function($q, companyDetails, searchModule, $stateParams, exportSearch) {
            var deferred = $q.defer();
            var params = {
              module: 'company-asset',
              'criteria[0][company|code]': companyDetails.data.code,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order,
              offset: 20
            };

            if(!!$stateParams.query) {
              if(!!$stateParams.filter) {
                var filterCriteria = 'criteria[1][' + $stateParams.filter + ']';
                params[filterCriteria] = $stateParams.query;
              } else {
                params['criteria[1][code]'] = $stateParams.query;
                params['criteria[1][name]'] = $stateParams.query;
                params['criteria[1][asset_category|code]'] = $stateParams.query;
                params['criteria[1][asset_category|name]'] = $stateParams.query;
                params['criteria[1][asset_main_number]'] = $stateParams.query;
                params['criteria[1][asset_sub_number]'] = $stateParams.query;
                params['criteria[1][$operator]'] = 'or';
              }
              params['criteria_operator'] = 'and';
            }

            exportSearch.setParams(params);

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return "Asset Master - search";
          }
        }
      })
      .state('main.user.companiesMgmt.detailCompanies.activities', {
        url: '/activities',
        templateUrl: 'app/companiesManagement/companies/activities/manage.html',
        controller: 'activitiesCtrl',
        resolve: {
          company: function (companyDetails) {
            return companyDetails
          },
          $title: function () {
            return 'Cost Center Activities'
          }

        }
      })
      .state('main.user.companiesMgmt.detailCompanies.activities.details', {
        url: '/{uuid:[a-zA-Z0-9\-]*}',
        templateUrl: 'app/companiesManagement/companies/activities/details.html',
        controller: 'activitiesDetailsCtrl',
        resolve: {
          company: function (companyDetails) {
            return companyDetails
          },
          activity: function (singleActivity, $stateParams, $q) {
            var deferred = $q.defer();

            singleActivity.get({
              uuid: $stateParams.uuid
            },function(resource){
              if(!!resource.content)
                deferred.resolve(resource.content.data);
              else
                deferred.resolve({})
            });

            return deferred.promise;
          },
          $title: function () {
            return 'details - Cost Center Activities'
          }
        }
      })
      .state('main.user.companiesMgmt.glAccountCodeGroup', {
        url: '/{companyId:[a-zA-Z0-9]+}/gl-account-code-group?{code:[a-zA-Z0-9]*}&{query:[a-zA-Z0-9]*}&{page:[0-9]}&' +
        '{order_by:[^~,]}&{order:[0-1]}&{filter:[a-zA-Z0-9]+}&{type:[a-zA-Z0-9]+}',
        templateUrl: 'app/companiesManagement/companies/accountCodeGroup/manage.html',
        controller: 'glAccountCodeGroupDetailsCtrl',
        resolve: {
          company: function ($q, Companies, $stateParams) {
            var deferred = $q.defer();
            Companies.get(
              {
                id: $stateParams.companyId
              },
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          companyGroupAccountCodes: function($q, company, searchModule, $stateParams){
            var deferred = $q.defer();
            var params = {
              module: 'gl_account_code_groups',
              'criteria[0][company_code]':  company.data.code,
              page: $stateParams.page,
              order_by: $stateParams.order_by,
              order: $stateParams.order,
              offset: 20
            };

            if(!!$stateParams.query) {
              params['criteria[1][group_code]'] = $stateParams.query;
              params['criteria[1][$strict]'] = true;
              params['criteria[0][criteria_operator]'] = 'and';
            }

            searchModule.get(
              params,
              function (resource) {
                deferred.resolve(resource.content || {data: []});
              },
              function (error) {
                if (error.status === 404) {
                  deferred.resolve([]);
                }
              }
            );
            return deferred.promise;
          },
          $title: function () {
            return 'details - GL Account Code Group'
          }
        }
      })
  }]);
