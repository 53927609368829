'use strict';

function modeOfPurchaseDetailsCtrl($scope, $uibModalInstance, modeOfPurchase, $http, pathConstants, $filter,
                                   modeOfPurchaseMasterDataList, toastr, globalFunc, $rootScope) {
  $scope.editClicked = false;
  $scope.modeOfPurchase = modeOfPurchase.data;
  $scope.modeOfPurchaseMasterDataList = modeOfPurchaseMasterDataList;
  $scope.tab = 1;
  $scope.cancel = cancel;
  $scope.editModeOfPurchase = editModeOfPurchase;
  $scope.userListing = userListing;
  $scope.clearUserLine = clearUserLine;
  $scope.selectedUser = selectedUser;
  $scope.toggleModeOfPurchase = toggleModeOfPurchase;
  $scope.companyListing = companyListing;
  $scope.selectCompany = selectCompany;
  $scope.removeCompany = removeCompany;
  $scope.validatePrefix = validatePrefix;
  $scope.historyDependencies = {
    embeddedParams: {
      'object[0][object_id]': $scope.modeOfPurchase._id,
      'object[0][object_type]': 'Metabuyer\\Models\\ModeOfPurchase',
      offset: 5,
      order_by: '-created_at'
    }
  };
  $scope.toggleTab = toggleTab;
  $scope.updateModeOfPurchase = updateModeOfPurchase;
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();

  function validatePrefix(val){
    if (!(/^[A-Z]?$/.test(val)))
      toastr.error("Prefix can only be a capital letter.")
  }

  function userListing(val) {
    var moduleParam = {
      module: 'users'
    };

    var additionalParams = {
      'criteria[0][display_name]': val,
      'criteria[0][is_metabuyer_user]': true,
      'criteria[0][status]': 1,
      criteria_operator: 'and',
      offset: 10
    };

    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
      params: additionalParams
    }).then(function (response) {
      $scope.loading = false;
      return response.data.content.data.map(function (item) {
        $scope.loading = false;
        return item;
      });
    }, function (error) {
      globalFunc.objectErrorMessage(error);
    });
  }

  function selectedUser(index) {
    $scope.userSearch = '';
    $scope.modeOfPurchase.newSelectedUsers = $scope.modeOfPurchase.po_creators;
    if(!!validateUser(index)) {
      $scope.modeOfPurchase.newSelectedUsers.push(index);
    }
  }

  function validateUser(user) {
    if ($scope.modeOfPurchase.newSelectedUsers.length !== 0) {
      for (var i = 0; i < $scope.modeOfPurchase.newSelectedUsers.length; i++) {
        if ($scope.modeOfPurchase.newSelectedUsers[i]._id === user._id) {
          toastr.error('this user is already added');
          return false;
        }
      }
    }
    return true;
  }

  function clearUserLine(index) {
    $scope.modeOfPurchase.newSelectedUsers = $scope.modeOfPurchase.po_creators;
    $scope.modeOfPurchase.newSelectedUsers.splice(index, 1);
  }

  function toggleModeOfPurchase(val) {
    $scope.modeOfPurcase.is_active = !!val;
  }


  function toggleTab(tab) {
    return $scope.tab = tab;
  }

  function cancel() {
    $uibModalInstance.close();
  }

  function editModeOfPurchase() {
    $scope.editClicked = true;
    $scope.modeOfPurchase.newSelectedUsers = $scope.modeOfPurchase.po_creators;
  }

  function updateModeOfPurchase() {
    var details = _.cloneDeep($scope.modeOfPurchase);

    if (!!validate(details)) {
      modeOfPurchaseMasterDataList.put(
        {
          id: $scope.modeOfPurchase.uuid
        },
        {
          name: $scope.modeOfPurchase.name,
          prefix: $scope.modeOfPurchase.prefix,
          po_creators: globalFunc.getArrayOfKeyValues($scope.modeOfPurchase.newSelectedUsers, '_id'),
          status: $scope.modeOfPurchase.is_active,
          company_code: !!$scope.modeOfPurchase.selectedCompany ? $scope.modeOfPurchase.selectedCompany.code : undefined
        },
        function (resource) {
          toastr.success('Mode of Purchase has been updated');
          $uibModalInstance.close(resource.content.data);
        },
        function (error) {
          globalFunc.objectErrorMessage(error);
          return false;
        }
      );
    }
  }

  function selectCompany(val) {
    if(!!val)
      $scope.modeOfPurchase.selectedCompany = val;
  }

  function removeCompany() {
    $scope.modeOfPurchase.selectedCompany = undefined;
    $scope.modeOfPurchase.searchedCompany = undefined;
  }

  function companyListing(val){
    if (!!val && val.length > 2) {
      var moduleParam = {
        module: 'companies'
      };

      var additionalParams = {
        params: {
          'criteria[0][descr]': val,
          'criteria[0][code]': val,
          criteria_operator: 'or',
          'offset': 10
        }
      };

      return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), additionalParams)
        .then(function (response) {
          if (response.data.content.data.length === 0) {
            return [];
          }

          return response.data.content.data.map(function (company) {
            return company;
          });
        });
    }
  }

  function validate(details) {
    if (!details.name) {
      toastr.error('Name field is empty');
      return false;
    }
    if (details.newSelectedUsers.length === 0) {
      toastr.error('PO Creators field is empty');
      return false;
    }
    return true;
  }

  function init() {
    if (!!$scope.modeOfPurchase.approval_company) {
      $scope.modeOfPurchase.selectedCompany = $scope.modeOfPurchase.approval_company;
      $scope.modeOfPurchase.searchedCompany = $scope.modeOfPurchase.approval_company;
    }
  }

  init();
}

modeOfPurchaseDetailsCtrl.$inject = ['$scope', '$uibModalInstance', 'modeOfPurchase', '$http', 'pathConstants',
  '$filter', 'modeOfPurchaseMasterDataList', 'toastr', 'globalFunc', '$rootScope'];

angular
  .module('metabuyer')
  .controller('modeOfPurchaseDetailsCtrl', modeOfPurchaseDetailsCtrl);
