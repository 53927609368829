'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('main.user.security', {
        url: '/security',
        controller: 'securityCtrl',
        templateUrl: 'app/security/security.html',
        resolve: {
          accessRights: function (profile, UserPermissions, $state, globalFunc) {
            if (!!profile &&
              !globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'ADMIN') &&
              !globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN') &&
              !globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'System Support')) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.security.rolesList', {
        templateUrl: 'app/security/rolesList/rolesList.html',
        url: '/security/roles?{code:[a-zA-Z0-9]+}/{organizationLevel:[a-zA-Z0-9]+}',
        controller: 'rolesListingCtrl',
        params: {
          code: null,
          organizationLevel: null
        },
        resolve: {
          $title: function () {
            return 'Security - Roles Listing';
          }
        }
      })
      .state('main.user.security.permissions', {
        templateUrl: 'app/security/permissionsList/permissionsMatrix.html',
        url: '/security/roles/permissions?{roleName:[a-zA-Z0-9]+}',
        controller: 'permissionMatrixCtrl',
        params: {
          roleName: null,
          roleDescr: null,
          organizationCode: null
        },
        resolve: {
          listTitle: function ($stateParams) {
            return {
              organizationCode: $stateParams.organizationCode,
              roleName: $stateParams.roleName,
              roleDescr: $stateParams.roleDescr
            };
          },
          $title: function () {
            return 'Security - Permissions';
          }
        }
      });
  }]);

