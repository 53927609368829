'use strict';

angular.module('metabuyer')
  .config(['$stateProvider', 'pathConstants', function($stateProvider) {

    $stateProvider
      .state('main.user.bifrostFile', {
        url: 'bifrost-file',
        controller: 'bifrostFileMainCtrl',
        templateUrl: 'app/bifrostFile/index.html',
        resolve: {
          accessRights: function(profile, $state, globalFunc) {
            if (!(globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'TENANT_SUPER_ADMIN') ||
            globalFunc.findRoleInRoleAssignments(profile.role_assignments, 'System Support'))) {
              $state.go('notAuthorized');
            }
          }
        }
      })
      .state('main.user.bifrostFile.manage', {
        url: '/manage?{status:[a-zA-Z0-9]+}&{tab:[a-zA-Z0-9]+}&{page:[0-9]}&{created_at:[a-zA-Z0-9]+}&' +
          '{query:[a-zA-Z0-9]+}&{order:[0-9]+}&{order_by:[a-zA-Z0-9]+}',
        controller: 'bifrostFileManageCtrl',
        templateUrl: 'app/bifrostFile/manage/manage.html',
        resolve: {
          $title: function() {
            return 'Bifrost File Viewer';
          },
          dataList: function($q, $stateParams, bifrostFileServices) {
            var deferred = $q.defer();
            var params = {
              page: $stateParams.page,
              limit: 100
            };

            if (!!$stateParams.query) {
              params['query'] = $stateParams.query;
            }

            if (!!$stateParams.status && $stateParams.status !== 'all') {
              params['integration_type'] = $stateParams.status;
            }

            if (!!$stateParams.created_at) {
              params['created_at'] = moment.unix($stateParams.created_at / 1000).format('YYYY-MM-DD');
            }

            bifrostFileServices.get(
              params,
              function(resource) {
                deferred.resolve(resource);
              },
              function(error) {
                globalFunc.objectErrorMessage(error);
                deferred.resolve([]);
              });

            return deferred.promise;
          },
        }
      })
  }]);
