angular
    .module('metabuyer')
    .directive('ordersAdvancedSearchV2', function () {
        return {
            restrict: 'E',
            templateUrl: 'app/advancedSearch/orders/v2/template.html',
            scope: {},
            controller: function ($scope, searchModule, listChild, toastr, $rootScope, advancedSearch, exportSearch,
                                  metabuyerCache, pathConstants, billingFromCompanies, $state, $http, $filter, loadCacheableData) {
  
                $scope.searchSuppliers = $rootScope.searchSuppliers;
                $scope.hiddenColumn = {id: null, conditional: true};

                // columns listing
                $scope.columns = [
                    {id: 'po_custom_number', label: 'PO #', selected: true},
                    !!$rootScope.isV2Enabled ? {id: 'price-comparisons|pc_number', label: 'PC #', selected: true} : $scope.hiddenColumn,
                    {id: 'title', label: 'PO Title', selected: true},
                    {id: 'billing|company|descr', label: 'Company', selected: true},
                    {id: 'supplier_object|descr', label: 'Supplier', selected: true},
                    {id: 'created_at', label: 'PO Date', type: 'date', unsearchable: true, selected: true},
                    {id: 'do_number', label: 'DO #', selected: true},
                    {id: 'net_total', label: 'Amount', type: 'double', unsearchable: true, selected: true},
                    {id: 'updated_at', label: 'Updated At', selected: true},
                    {id: 'requestor|display_name', label: 'Requestor', selected: true},
                    {id: 'status', label: 'Status', selected: true},
                    {id: 'approved_by', label: 'Approved by', selected: true, unsortable: true},
                    {id: 'waiting_on', label: 'Waiting On', unsortable: true, unsearchable: true, selected: true},
                    {id: 'received_percentage', label: 'Received %', unsearchable: true, selected: true},
                    {id: 'status_date', label: 'Received At', unsearchable: true, selected: true},
                    // Received At only present if PO status is PARTIALLY_DELIVERED/ FULLY_DELIVERED
                    {id: 'grn_number', label: 'GRN No', unsearchable: true, selected: true},
                    // GRN number only present if PO status is PARTIALLY_DELIVERED/ FULLY_DELIVERED
                    {id: 'pr_numbers',label: 'PR #', selected: true},
                ];

                $scope.datePickerTimezone = $rootScope.datePickerTimezone;
                $scope.datepickerOpened = {
                    neededByDateFrom: false,
                    neededByDateTo: false,
                    poFullyApprovedDateFrom: false,
                    poFullyApprovedDateTo: false,
                    poCreatedDateFrom: false,
                    poCreatedDateTo: false
                };
                var dateOptions = {
                    formatYear: 'yy',
                    startingDay: 1
                };
                $scope.datePickerOptions = {
                    neededByDateFrom: _.clone(dateOptions),
                    neededByDateTo: _.clone(dateOptions),
                    poFullyApprovedDateFrom: _.clone(dateOptions),
                    poFullyApprovedDateTo: _.clone(dateOptions),
                    poCreatedDateFrom: _.clone(dateOptions),
                    poCreatedDateTo: _.clone(dateOptions)
                };

                $scope.errors = {};
                var toastrErrorMessage = null;
                $scope.resetForm = resetForm;
                $scope.viewPoDetail = viewPoDetail;
                $scope.showColumn = showColumn;
                $scope.submitAdvSearch = submitAdvSearch;
                $scope.openDatePicker = openDatePicker;
                $scope.validateAmountRange = validateAmountRange;
                $scope.hasError = hasError;

                // variables for search users
                $scope.searchUsers = searchUsers;
                $scope.userSearching = false;
                $scope.userListing = [];

                // variables for ERP Budget ID
                $scope.erpBudgetIdSearching = false;
                $scope.budgetListing = [];
                $scope.searchErpBudgetId = searchErpBudgetId;

                // variables for item name
                $scope.itemNameListing = [];
                $scope.searchItemName = searchItemName;
                $scope.itemNameSearching = false;
                $scope.updateItemName = updateItemName;  

                // variables for item code
                $scope.itemCodeListing = [];
                $scope.searchItemCode = searchItemCode;
                $scope.itemCodeSearching = false;
                $scope.updateItemCode = updateItemCode;

                // variables for company and billing company 
                $scope.companySearching = false;
                $scope.companyList = [];
                $scope.searchCompany = searchCompany;
                $scope.onSelectBillingCompany = onSelectBillingCompany;
                $scope.selectedBilingCompany = null;

                $scope.disablePoPrRange = disablePoPrRange;
                $scope.disableWaitingOn = disableWaitingOn;

                // variables for PC Numbers
                $scope.pcSearching = false;
                $scope.pcList = [];
                $scope.searchPcNumber = searchPcNumber;

                // variable for tender
                $scope.searchTenders = searchTenders;

                $scope.poStatusOptions = [
                    {
                    id: 1,
                    key: 'all',
                    value: 'All',
                    },
                    {
                    id: 2,
                    key: 'SUBMITTED',
                    value: 'Submitted',
                    },
                    {
                    id: 3,
                    key: 'DELIVERY_DATE_UNCONFIRMED',
                    value: 'Delivery Date Unconfirmed',
                    },
                    {
                    id: 4,
                    key: 'DECLINED',
                    value: 'Declined',
                    },
                    {
                    id: 5,
                    key: 'AWAITING_DELIVERY',
                    value: 'Awaiting Delivery',
                    },
                    {
                    id: 6,
                    key: 'PARTIALLY_DELIVERED',
                    value: 'Partially Delivered',
                    },
                    {
                    id: 7,
                    key: 'FULLY_DELIVERED',
                    value: 'Delivered',
                    },
                    {
                    id: 8,
                    key: 'CANCELLED',
                    value: 'Cancelled',
                    },
                    {
                    id: 9,
                    key: 'CLOSED',
                    value: 'Closed',
                    },
                    {
                    id: 10,
                    key: 'EXPIRED',
                    value: 'Expired',
                    },
                    {
                    id: 11,
                    key: 'PENDING',
                    value: 'Pending',
                    },
                    {
                    id: 12,
                    key: 'APPROVED',
                    value: 'Approved',
                    },
                    {
                    id: 13,
                    key: 'WITHDRAWN',
                    value: 'Withdrawn',
                    },
                    {
                    id: 14,
                    key: 'REJECTED',
                    value: 'Rejected',
                    },
                ];

                // this is to store all the fields value
                var fields = {
                    poNumberFrom: null,
                    poNumberTo: null,
                    prNumberFrom: null,
                    prNumberTo: null,
                    pcNumber: null,
                    totalCostOfPoFrom: null,
                    totalCostOfPoTo: null,
                    poTitle: null,
                    poStatus: {selected: $scope.poStatusOptions[0]},
                    doNumber : null,
                    billingCompany: null,
                    company: null,
                    requestor: null,
                    itemTitle: null,
                    itemCode: null,
                    erpBudgetId: null,
                    budgetCode: null,
                    budgetTitle: null,
                    tender: null,
                    approvedBy: null,
                    waitingOn: null,
                    supplier: null,
                    neededByDateFrom: null,
                    neededByDateTo: null,
                    poCreatedDateFrom: null,
                    poCreatedDateTo: null,
                    poFullyApprovedDateFrom: null,
                    poFullyApprovedDateTo: null,
                    modeOfPurchase: {selected: null},
                };

                // this can be used in reset form
                $scope.fields = angular.copy(fields);
                // angular.copy is used to prevent the original declare variables from being overide

                /**
                 * function to reset the search form
                 */
                function resetForm() {
                    $scope.fields = angular.copy(fields);
                    $scope.errors = {};
                    $scope.dataList = [];
                    $scope.companyList = [];
                }

                //load items categories
                loadCacheableData.loadData({
                    module: 'mode-of-purchase',
                    offset: 0
                }, 'modesOfPurchase', 'localStorage').then(function(data){
                    $scope.modeOfPurchaseList = data;
                });

                /**
                 * function to search users (approved by, waiting on, creator, created on behalf)
                 * @param name
                 */
                function searchUsers(name) {
                    if (!!name && name.length > 2) {
                    $scope.userSearching = true;
                    return $http.get(pathConstants.apiUrls.genericSearch.searchBasePath + '/users', {
                        params: {
                        module: 'users',
                        'criteria[1][display_name]': name,
                        criteria_operator: 'and'
                        }
                    }).then(function (response) {
                        $scope.userSearching = false;
                        $scope.userListing = response.data.content.data;
                        return response.data.content.data;
                    }, function (error) {
                        $scope.userSearching = false;
                        globalFunc.objectErrorMessage(error);
                    });
                    }
                }

                /**
                 * function to search and select ERP Budget ID
                 * @param id
                 */
                function searchErpBudgetId(id) {
                    if (!!id && id.length > 2) {
                    $scope.erpBudgetIdSearching = true;
                    return $http.get(pathConstants.apiUrls.genericSearch.searchBasePath + '/budgets', {
                        params: {
                        module: 'budgets',
                        'criteria[0][erp_budget_id]': id,
                        'criteria[0][$operator]': 'or',
                        criteria_operator: 'and',
                        }
                    }).then(function (response) {
                        $scope.erpBudgetIdSearching = false;
                        $scope.budgetListing = response.data.content.data;
                        return response.data.content.data;
                    }, function (error) {
                        $scope.userSearching = false;
                        globalFunc.objectErrorMessage(error);
                    });
                    }
                }

                /**
                 * function to search and select Item Name
                 * @param itemName
                 */
                function searchItemName(itemName) {
                    if (!!itemName && itemName.length > 2) {
                    $scope.itemNameSearching = true;
                    return $http.get(pathConstants.apiUrls.genericSearch.searchBasePath + '/item-master', {
                        params: {
                        module: 'item-master',
                        'criteria[0][name]': itemName,
                        'criteria[0][$operator]': 'or',
                        criteria_operator: 'and',
                        }
                    }).then(function (response) {
                        $scope.itemNameSearching = false;
                        $scope.itemNameListing = response.data.content.data;
                        return response.data.content.data;
                    }, function (error) {
                        $scope.itemNameSearching = false;
                        globalFunc.objectErrorMessage(error);
                    });
                    }
                }

                /**
                 * function to search and select Item Name
                 * @param itemName
                 */
                function searchItemName(itemName) {
                    if (!!itemName && itemName.length > 2) {
                    $scope.itemNameSearching = true;
                    return $http.get(pathConstants.apiUrls.genericSearch.searchBasePath + '/item-master', {
                        params: {
                        module: 'item-master',
                        'criteria[0][name]': itemName,
                        'criteria[0][$operator]': 'or',
                        criteria_operator: 'and',
                        }
                    }).then(function (response) {
                        $scope.itemNameSearching = false;
                        $scope.itemNameListing = response.data.content.data;
                        return response.data.content.data;
                    }, function (error) {
                        $scope.itemNameSearching = false;
                        globalFunc.objectErrorMessage(error);
                    });
                    }
                }

                /**
                 * function to search and select Item Code
                 * @param itemCode
                 */
                function searchItemCode(itemCode) {
                    if (!!itemCode && itemCode.length > 2) {
                    $scope.itemCodeSearching = true;
                    return $http.get(pathConstants.apiUrls.genericSearch.searchBasePath + '/item-master', {
                        params: {
                        module: 'item-master',
                        'criteria[0][code]': itemCode,
                        'criteria[0][$operator]': 'or',
                        criteria_operator: 'and',
                        }
                    }).then(function (response) {
                        $scope.itemCodeSearching = false;
                        $scope.itemCodeListing = response.data.content.data;
                        return response.data.content.data;
                    }, function (error) {
                        $scope.itemCodeSearching = false;
                        globalFunc.objectErrorMessage(error);
                    });
                    }
                }

                /**
                 * function to search and select company
                 * @param company
                 */
                function searchCompany(company) {
                    // clear the dropdown list
                    $scope.companyList = [];

                    if (!!company && company.length > 2) {
                    $scope.companySearching = true;
                    return $http.get(pathConstants.apiUrls.genericSearch.searchBasePath + '/companies', {
                        params: {
                        module: 'companies',
                        'criteria[0][descr]': company,
                        'criteria[0][code]': company,
                        'criteria[0][$operator]': 'or',
                        criteria_operator: 'and',
                        }
                    }).then(function (response) {
                        $scope.companySearching = false;
                        $scope.companyList = response.data.content.data;
                        return response.data.content.data;
                    }, function (error) {
                        $scope.companySearching = false;
                        globalFunc.objectErrorMessage(error);
                    });
                    }
                }

                /**
                 * function to search and select company
                 * @param company
                 */
                function searchPcNumber(pc) {
                    // clear the dropdown list
                    $scope.pcList = [];
                    if (!!pc && pc.length > 2) {
                    $scope.pcSearching = true;
                    return $http.get(pathConstants.apiUrls.genericSearch.searchBasePath + '/price-comparison', {
                        params: {
                        module: 'price-comparison',
                        'criteria[0][pc_number]': pc,
                        'criteria[0][pr_info|company|code]': $scope.fields.company.code,
                        'criteria[0][$operator]': 'and',
                        criteria_operator: 'and',
                        offset: 0,
                        }
                    }).then(function (response) {
                        $scope.pcSearching = false;
                        $scope.pcList = response.data.content.data;
                        return response.data.content.data;
                    }, function (error) {
                        $scope.pcSearching = false;
                        globalFunc.objectErrorMessage(error);
                    });
                    }
                }

                    /**
                 * typeahead for tender search
                 * @param val
                 * @returns {*}
                 */
                function searchTenders(val) {
                    if (!!val && val.length > 2) {
                    $scope.tenderSearching = true;

                    var moduleParam = {
                        module: 'tender'
                    };

                    var additionalParams = {
                        'criteria[0][code]': val,
                        'criteria[0][detail|name]': val,
                        'criteria[0][$operator]': 'or',
                        'criteria[1][detail|status][0]': 'APPROVED',
                        'criteria[1][detail|status][1]': 'DEACTIVATED',
                        'criteria[1][detail|status][2]': 'EXPIRED',
                        criteria_operator: 'and',
                        offset: 10
                    };

                    return $http.get($filter('format')(pathConstants.apiUrls.genericSearch.search, moduleParam), {
                        params: additionalParams
                    }).then(function (response) {
                        return response.data.content.data.map(function (item) {
                        return item;
                        });
                    }, function (error) {
                        globalFunc.objectErrorMessage(error);
                    });

                    }
                }

                // function to update item code field if item name is entered
                function updateItemCode() {
                    if (!!$scope.fields['itemTitle']) {
                    $scope.fields['itemCode'] = $scope.fields['itemTitle'];
                    }
                }

                // function to update item name field if item code is entered
                function updateItemName() {
                    if (!!$scope.fields['itemCode']) {
                    $scope.fields['itemTitle'] = $scope.fields['itemCode'];
                    }
                }

                // function to disable PO/PR range depending on company field
                function disablePoPrRange() {
                    if ($scope.fields['company'] === null || $scope.fields['company'] === '') {
                    $scope.fields['poNumberFrom'] = null;
                    $scope.fields['poNumberTo'] = null;
                    $scope.fields['prNumberFrom'] = null;
                    $scope.fields['prNumberTo'] = null;
                    return true;
                    }
                    else {
                    return false;
                    }
                }

                // to show dropdown list for company once billing company is selected
                function onSelectBillingCompany(company) {
                    $scope.selectedBilingCompany = company._id;
                    //clear function
                    $scope.companyList = [];
                    $scope.company = null;
                    billingFromCompanies.get({
                    code: company.code
                    }, function (resource) {
                    if (!!resource.content && !!resource.content.data) {
                        if (resource.content.data.length < 1)
                        toastr.warning('No company bills to ' + company.descr); //warning to show the users no options will be added

                        _.map(resource.content.data, function (company) {
                            $scope.companyList.push(company);
                        });
                    }
                    });
                }

                // to grey out and clear waiting on field depending on po status
                function disableWaitingOn() {
                    if (!!$scope.fields['poStatus']) {
                    if ($scope.fields['poStatus'].selected.key === 'PENDING' || $scope.fields['poStatus'].selected.key === 'WITHDRAWN' || $scope.fields['poStatus'].selected.key === 'all') {
                        return false;
                    }
                    else {
                        $scope.fields['waitingOn'] = null;
                        return true;
                    }
                    }
                }  

                /**
                 * function to set datepicker fields' options.
                 * set minDate/maxDate if the related date range field is already set and remove error message
                 *
                 * @param $event
                 * @param field
                 */
                function openDatePicker($event, field) {
                    $event.preventDefault();
                    $event.stopPropagation();

                    if (field === 'neededByDateFrom') {
                    $scope.datePickerOptions.neededByDateFrom.maxDate = $scope.fields['neededByDateTo'] || null;
                    $scope.datepickerOpened.neededByDateFrom = true;
                    delete $scope.errors.neededByDate;
                    } else if (field === 'neededByDateTo') {
                    $scope.datePickerOptions.neededByDateTo.minDate = $scope.fields['neededByDateFrom'] || null;
                    $scope.datepickerOpened.neededByDateTo = true;
                    delete $scope.errors.neededByDate;
                    } else if (field === 'poFullyApprovedDateFrom') {
                    $scope.datePickerOptions.poFullyApprovedDateFrom.maxDate = $scope.fields['poFullyApprovedDateTo'] || null;
                    $scope.datepickerOpened.poFullyApprovedDateFrom = true;
                    delete $scope.errors.poFullyApprovedDate;
                    } else if (field === 'poFullyApprovedDateTo') {
                    $scope.datePickerOptions.poFullyApprovedDateTo.minDate = $scope.fields['poFullyApprovedDateFrom'] || null;
                    $scope.datepickerOpened.poFullyApprovedDateTo = true;
                    delete $scope.errors.poFullyApprovedDate;
                    } else if (field === 'poCreatedDateFrom') {
                    $scope.datePickerOptions.poCreatedDateFrom.maxDate = $scope.fields['poCreatedDateTo'] || null;
                    $scope.datepickerOpened.poCreatedDateFrom = true;
                    delete $scope.errors.poCreatedDate;
                    } else if (field === 'poCreatedDateTo') {
                    $scope.datePickerOptions.poCreatedDateTo.minDate = $scope.fields['poCreatedDateFrom'] || null;
                    $scope.datepickerOpened.poCreatedDateTo = true;
                    delete $scope.errors.poCreatedDate;
                    }
                }  

                /**
                 * submit search
                 */
                function submitAdvSearch() {

                    var searchParam = validateForm();

                    if (!!searchParam) {
                    $scope.embeddedParams = searchParam;

                    /**
                     * Setting the params to prepare for exporting
                     */
                    exportSearch.setParams(searchParam);

                    advancedSearch.get(
                        searchParam,
                        function (resource) {
                        $scope.dataList = resource.content.data;
                        $scope.meta = resource.content.meta;
                        $scope.emptyInput = false;

                        },
                        function (error) {
                        toastr.error('Something went wrong');
                        $scope.dataList = [];
                        }
                    );
                    } else {
                    toastr.error(toastrErrorMessage);
                    toastrErrorMessage = null;
                    $scope.emptyInput = true;
                    }

                    $('#show-search-form').removeClass('hidden');
                    $('#hide-search-form').addClass('hidden');

                }

                /**
                 * function to check if a specific field has error.
                 * @param field field name
                 * @returns {boolean}
                 */
                function hasError(field) {
                    return (field in $scope.errors);
                }

                /**
                 * function to validate amount range fields, and update/remove error message
                 * @param field
                 */
                function validateAmountRange(field) {
                    if (field === 'totalCostOfPo') {
                    if (!!$scope.totalCostOfPoFrom && !!$scope.totalCostOfPoTo && ($scope.totalCostOfPoFrom > $scope.totalCostOfPoTo)) {
                        $scope.errors.totalCostOfPo = 'Invalid Range';
                    } else {
                        if ('totalCostOfPo' in $scope.errors) {
                        delete $scope.errors.totalCostOfPo
                        }
                    }
                    }
                }

                /**
                 * function to validate form input values
                 * @returns {boolean|Object}
                 */
                function validateForm() {

                    /**
                     * logic for range fields (total cost of po, and all date fields)
                     * if `from` field is not empty, set `to` field to same value as `from` value
                     * if `to` field is not empty, `from` field is mandatory.
                     * if both are empty, skip validation
                     *
                     */

                    // total cost of po range
                    if (!!$scope.fields['totalCostOfPoTo']) {
                    if (!!$scope.fields['totalCostOfPoFrom']) {
                        if ($scope.fields['totalCostOfPoFrom'] > $scope.fields['totalCostOfPoTo']) {
                        $scope.errors.totalCostOfPo = 'Invalid Range';
                        }
                    } else {
                        $scope.errors.totalCostOfPo = '\'From\' field is mandatory';
                    }
                    } else if (!!$scope.fields['totalCostOfPoFrom']) {
                    $scope.fields['totalCostOfPoTo'] = $scope.fields['totalCostOfPoFrom'];
                    }

                    //needed by date range
                    if (!!$scope.fields['neededByDateTo']) {
                    if (!!$scope.fields['neededByDateFrom']) {
                        if ($scope.fields['neededByDateFrom'] > $scope.fields['neededByDateTo']) {
                        $scope.errors.neededByDate = 'Invalid Range';
                        }
                    } else {
                        $scope.errors.neededByDate = '\'From\' field is mandatory';
                    }
                    } else if (!!$scope.fields['neededByDateFrom']) {
                    $scope.fields['neededByDateTo'] = $scope.fields['neededByDateFrom'];
                    }

                    //PO created date range
                    if (!!$scope.fields['poCreatedDateTo']) {
                    if (!!$scope.fields['poCreatedDateFrom']) {
                        if ($scope.fields['poCreatedDateFrom'] > $scope.fields['poCreatedDateTo']) {
                        $scope.errors.poCreatedDate = 'Invalid Range';
                        }
                    } else {
                        $scope.errors.poCreatedDate = '\'From\' field is mandatory';
                    }
                    } else if (!!$scope.fields['poCreatedDateFrom']) {
                    $scope.fields['poCreatedDateTo'] = $scope.fields['poCreatedDateFrom'];
                    }

                    //PO fully approved date range
                    if (!!$scope.fields['poFullyApprovedDateTo']) {
                    if (!!$scope.fields['poFullyApprovedDateFrom']) {
                        if ($scope.fields['poFullyApprovedDateFrom'] > $scope.fields['poFullyApprovedDateTo']) {
                        $scope.errors.poFullyApprovedDate = 'Invalid Range';
                        }
                    } else {
                        $scope.errors.poFullyApprovedDate = '\'From\' field is mandatory';
                    }
                    } else if (!!$scope.fields['poFullyApprovedDateFrom']) {
                    $scope.fields['poFullyApprovedDateTo'] = $scope.fields['poFullyApprovedDateFrom'];
                    }

                    if (_.isEmpty($scope.errors)) {
                    return buildParam($scope.fields);
                    } else {
                    toastrErrorMessage = 'Invalid value in one or more fields';
                    return false;
                    }
                }

                /**
                 * function to build criteria parameters
                 * @param data object of form input values
                 * @returns {boolean|Object}
                 */
                function buildParam(data) {
                    var params = {};
                    if (!!data.poNumberFrom) {
                    params['criteria[0][numerical_reference_counter][from]'] = data.poNumberFrom;
                    params['criteria[0][numerical_reference_counter][to]'] = data.poNumberTo;
                    }
                    if (!!data.prNumberFrom) {
                    params['criteria[0][pr_custom_numbers][from]'] = data.prNumberFrom;
                    params['criteria[0][pr_custom_numbers][to]'] = data.prNumberTo;
                    }
                    if (!!data.pcNumber) {
                    params['criteria[0][price_comparisons|pc_number]'] = data.pcNumber.pc_number;
                    }
                    if (!!data.totalCostOfPoFrom) {
                    params['criteria[0][net_total][from]'] = data.totalCostOfPoFrom;
                    params['criteria[0][net_total][to]'] = data.totalCostOfPoTo;
                    }
                    if (!!data.poTitle) {
                    params['criteria[0][title]'] = data.poTitle;
                    }
                    if (!!data.poStatus.selected) {
                    if (data.poStatus.selected.key !== 'all') {
                        params['criteria[0][status]'] = data.poStatus.selected.key;
                    }
                    }
                    if (!!data.doNumber) {
                    params['criteria[0][do_number]'] = data.doNumber;
                    }
                    if (!!data.billingCompany) {
                    params['criteria[0][billing|shadow_company|code]'] = data.billingCompany.code;
                    }
                    if (!!data.company) {
                    params['criteria[0][billing|company|code]'] = data.company.code;
                    }
                    if (!!data.requestor) {
                    params['criteria[0][requestor|_id]'] = data.requestor._id;
                    }
                    if (!!data.itemTitle) {
                    params['criteria[0][items|item|name]'] = data.itemTitle.name;
                    }
                    if (!!data.itemCode) {
                    params['criteria[0][items|item|item_code]'] = data.itemCode.code;
                    }
                    if (!!data.erpBudgetId) {
                    params['criteria[0][items|budget|erp_budget_id]'] = data.erpBudgetId.erp_budget_id;
                    }
                    if (!!data.budgetCode) {
                    params['criteria[0][items|budget|code]'] = data.budgetCode;
                    }
                    if (!!data.budgetTitle) {
                    params['criteria[0][items|budget|descr]'] = data.budgetTitle;
                    }
                    if (!!data.tender) {
                    params['criteria[0][tender_ids]'] = data.tender._id;
                    }
                    if (!!data.approvedBy) { // change name once the backend add new fields name
                    params['criteria[0][approved_by]'] = data.approvedBy._id;
                    }
                    if (!!data.waitingOn) {
                    params['criteria[0][waiting_on]'] = data.waitingOn._id;
                    }
                    if (!!data.supplier) {
                    params['criteria[0][supplier_id]'] = data.supplier._id;
                    }
                    if (!!data.modeOfPurchase.selected) {
                    params['criteria[0][mode_of_purchase|code]'] = data.modeOfPurchase.selected.code;
                    }
                    if (!!data.neededByDateFrom ) {
                    params['criteria[0][needed_at][from]'] = moment(data.neededByDateFrom).valueOf();
                    params['criteria[0][needed_at][to]'] = moment(data.neededByDateTo).endOf('day').valueOf();
                    }
                    if (!!data.poCreatedDateFrom ) {
                    params['criteria[0][created_at][from]'] = moment(data.poCreatedDateFrom).valueOf();
                    params['criteria[0][created_at][to]'] = moment(data.poCreatedDateTo).endOf('day').valueOf();
                    }
                    if (!!data.poFullyApprovedDateFrom ) {
                    params['criteria[0][fully_approved_date][from]'] = moment(data.poFullyApprovedDateFrom).valueOf();
                    params['criteria[0][fully_approved_date][to]'] = moment(data.poFullyApprovedDateTo).endOf('day').valueOf();
                    }

                    if (_.isEmpty(params)) {
                    toastrErrorMessage = 'Fill in at least one field';
                    return false;
                    } else {
                    params['module'] = 'PO';
                    params['offset'] = 50;
                    params['criteria[0][$operator]'] = 'and';
                    return params;
                    }
                }

                /**
                 * Function to navigate to POs by id state
                 */
                function viewPoDetail(data) {
                    $state.go('main.user.orders.detail' , {id : data._id});
                }

                /**
                 * Function to display selected available columns
                 */
                function showColumn(id) {
                    var i;
                    var columns = $scope.columns;
                    for (i in columns) {
                    if(columns.hasOwnProperty(i)) {
                        if (id === columns[i].id) {
                        if(!!columns[i].conditional) {
                            if($scope.status === columns[i].condition)
                            return columns[i].selected;
                            if(columns[i].condition instanceof Array) {
                            var j;
                            var columnCondition = $scope.columns[i].condition;
                            for(j in columnCondition) {
                                if(columnCondition.hasOwnProperty(j)) {
                                if($scope.status === columnCondition[j]) {
                                    return columns[i].selected;
                                }
                                }
                            }
                            }
                        }
                        else
                            return columns[i].selected;
                        }
                    }
                    }
                }

                function initialize() {
                    // initialize pagination parameters
                    $scope.dataList = [];
                    $scope.embeddedParams = [];
                    $scope.meta = {};
                    $scope.searchModule = advancedSearch;
                }

                initialize();
            }
        }
    });
