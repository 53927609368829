'use strict';

function OrdersManageCtrl($scope, $state, $stateParams, $filter, Orders, orders, $window, HighlightId,
                          pathConstants, $resource, $rootScope, globalFunc, $uibModal, toastr) {

  $scope.formatPercentage = formatPercentage;
  $scope.changeStringCase = changeStringCase;
  $scope.starringPO = starPO;
  $scope.checkStarred = checkStarred;
  $scope.showColumn = showColumn;
  $scope.showDetails = showDetails;
  $scope.setShowDetailsId = setShowDetailsId;
  $scope.selectPO = selectPO;
  $scope.selectAllCurrentPagePO = selectAllCurrentPagePO;
  $scope.unSelectPO = unSelectPO;
  $scope.clearAllSelectedPO = clearAllSelectedPO;
  $scope.showSelectedPOs = showSelectedPOs;
  $scope.exportSelectedPoPdf = exportSelectedPoPdf;
  $scope.getSelectedPurchaseOrder = getSelectedPurchaseOrder;
  $scope.tabSelection = tabSelection;
  $scope.showBulkPrintButton = showBulkPrintButton;
  $scope.isSystemSupportRole = globalFunc.isSystemSupportRole();
  $scope.canShowCheckBox = canShowCheckBox;

  var maxPoPerPdfCount = 100;

  /**
   * V2 only shows print button on approved or cancelled tab
   */
  function showBulkPrintButton() {
    var showButton = true;
    if (!!$rootScope.isV2Enabled) {
      if ($scope.status !== 'APPROVED' && $scope.status !== 'CANCELLED') {
        showButton = false;
      }
      if(globalFunc.findRoleInRoleAssignments($scope.currentUser.role_assignments, 'PO Approver'))
      {
        showButton = false;
      }
    }
    $scope.showBulkPrintButton = showButton;
  }

  function canShowCheckBox(order) {
    if (!!$rootScope.isV2Enabled) {
      return (order.can_print_po && $scope.showBulkPrintButton) || ($scope.status !== 'APPROVED' && $scope.status !== 'CANCELLED');
    }
    return true;
  }

  /**
   * function to navigate between the sub tabs
   * @param tab
   */
  function tabSelection(tab) {
    switch (tab) {
      case 'all': {
        $scope.selectedListTab = tab;
        $state.go('main.user.orders.manage', {
          status: $scope.status,
          tab: tab,
          extraFilterKey: null,
          extraFilterValue: null,
          page: null
        });
        break;
      }
      case 'yours': {
        $scope.selectedListTab = tab;
        $state.go('main.user.orders.manage', {
          status: $scope.status,
          tab: tab,
          extraFilterKey: 'criteria[creator_info|_id][][]',
          extraFilterValue: $rootScope.currentUser._id,
          page: null
        });
        break;
      }
      case 'waiting': {
        $scope.selectedListTab = tab;
        $state.go('main.user.orders.manage', {
          status: $scope.status,
          tab: tab,
          extraFilterKey: 'criteria[waiting_on][]',
          extraFilterValue: $rootScope.currentUser._id,
          page: null
        });
        break
      }
    }
  }

  /**
   * Gets the number of selected PO
   * @returns {Number}
   */
  $scope.selectedPOCount = function () {
    return $scope.$parent.selectedPO.length;
  };

  $scope.storageKey = 'orders-management-selected-columns';

  function showColumn(id){
    for (var i in $scope.columns) {
      if (id === $scope.columns[i].id) {
        if(!!$scope.columns[i].conditional){
          if($scope.status === $scope.columns[i].condition){
            return $scope.columns[i].selected;
          }
          if($scope.columns[i].condition instanceof Array){
            for(var j in $scope.columns[i].condition){
              if($scope.status === $scope.columns[i].condition[j]){
                return $scope.columns[i].selected;
              }
            }
          }
        }else{
          return $scope.columns[i].selected;
        }
      }
    }
  }

  /**
   * Select / unselects a PO
   *
   * @param poFromList
   */
  function selectPO(poFromList) {
    if($scope.$parent.poIsSelected(poFromList)) {
      $scope.$parent.removeFromSelectedPO(poFromList);
    } else {
      $scope.$parent.addToSelectedPO(poFromList);
    }
  }

  /**
   * Select / unselect all POs
   * @param checked
   */
  function selectAllCurrentPagePO(checked) {
    $scope.orders.forEach(function (poFromList) {
      poFromList.selected = checked;
      if (checked) {
        $scope.$parent.addToSelectedPO(poFromList);
      } else {
        $scope.$parent.removeFromSelectedPO(poFromList);
      }
      if (!canShowCheckBox(poFromList)) {
        $scope.$parent.removeFromSelectedPO(poFromList);
      }
    })
  }

  /**
   * Unselect a PO. This is used by the showSelectedPO modal
   * @param poFromSelected
   */
  function unSelectPO(poFromSelected) {
    $scope.$parent.removeFromSelectedPO(poFromSelected);
    $scope.orders.forEach(function (poFromList) {
      if (poFromList._id === poFromSelected._id) {
        poFromList.selected = false;
      }
    })
  }

  /**
   * Unselect ALL selected PO including POs selected from another page.
   * This is used by the showSelectedPO modal
   */
  function clearAllSelectedPO() {
    $scope.$parent.clearSelectedPO();

    // uncheck check all box
    angular.element('#table-header-checkbox').iCheck('uncheck');

    //uncheck individual box
    $scope.orders.forEach(function (poFromList) {
      poFromList.selected = false;
    })
  }

  /**
   * Opens the showSelectedPO modal
   */
  function showSelectedPOs() {
      $uibModal.open({
      templateUrl: 'app/orders/manage/selectPoModal/selectPoModal.html',
      backdrop: 'static',
      size: 'lg',
      scope: $scope,
      keyboard: false,
      controller: function ($scope, $uibModalInstance) {
        $scope.poList = $scope.$parent.selectedPO;

        $scope.remove = function (po) {
          $scope.unSelectPO(po);
        };

        $scope.clear = function () {
          setTimeout(function(){
            $scope.clearAllSelectedPO();
          }, 1);
          $uibModalInstance.close();
        };

        $scope.print = function () {
          $uibModalInstance.close();
          $scope.$parent.exportSelectedPoPdf();
        };

        $scope.close = function () {
          $uibModalInstance.close();
        };
      }
    });
  }

  /**
   * returns store the selected POs
   * @returns {Array}
   */
  function getSelectedPurchaseOrder() {
    var selectedPOs = [];
    _.forEach($scope.orders, function (po) {
      if (!!po.selected) {
        selectedPOs.push(po);
      }
    });

    return selectedPOs;
  }

  /**
   * Prints selected POs as PDF
   */
  function exportSelectedPoPdf() {
    if ($scope.selectedPOCount() > maxPoPerPdfCount) {
      toastr.error('Only ' + maxPoPerPdfCount + ' PO per print is allowed');
      return;
    }

    $scope.$parent.exportSelectedPoAsPdf();
    setTimeout(function(){
      $scope.clearAllSelectedPO();
    }, 1000);
  };

  /**
   * Check for checkStarred {return boolean}.
   * send to globalFunc, starFavourites function
   */
  function starPO(poId) {
    var isStarred = checkStarred(poId);
    globalFunc.starFavourites(isStarred, poId, 'PO');
  }

  /**
   * Check with User > favourites document in Users collection
   * @returns {boolean}
   */
    function checkStarred(poId) {
      if (!!$rootScope.currentUser.favourites && !!$rootScope.currentUser.favourites.PO) {
        var userFavourites = $rootScope.currentUser.favourites.PO;
        return userFavourites.indexOf(poId) > -1;
    }
      return false;
  }

  function formatPercentage(value){
    return value * 100;
  }
  // return true if show details is clicked
  function showDetails(id) {
    return (id === $scope.selectedDetail) ? true : false;
  }
  /**
   * set value of selectedDetail
   * @param id
   * @param option (set to show/hide details)
   */
  function setShowDetailsId(id, option) {
    if (option === 'show') {
      _.forEach($scope.dataList, function (item) {
        if (item['_id'] === id) {
          $scope.PO = item;
        }
      });
      $scope.selectedDetail = id;
      setPOItem();
    }
    if (option === 'hide')
      $scope.selectedDetail = null;
  }

  function changeStringCase(string){
    var newString = string.replace('_', ' ');
    newString = $filter('titleCase')(newString);
    return newString;
  }

  /**
   * v2 resets selection when changing tab
   */
  function resetPoSelection() {
    if (!!$rootScope.isV2Enabled) {
      // clears array while keeping reference
      $scope.$parent.selectedPO.length = 0;
    }
  }

  function initialize(){

    $stateParams.status = !!$stateParams.status ? $stateParams.status.toUpperCase() : '';
    $scope.status = $stateParams.status;
    $scope.$parent.status = $stateParams.status;
    $scope.orders = orders.data;
    $scope.meta = orders.meta;
    $scope.mangeOrderService = Orders;

    if($stateParams.type === 'date'){
      var ms = Number($stateParams.query[1]*1000);
      $scope.title = $filter('date')(new Date(ms), 'dd MMM yyyy');
    }
    else{
      $scope.title = $stateParams.query;
    }

    $scope.selectedListTab = $stateParams.tab;
    if(!$scope.selectedListTab){
      $scope.selectedListTab = 'all';
    }

    //TODO: have an extra filter for Closed  and Delivered for now they are both Closed (Ahmed Saleh, 9/3/2016)
    if(!!$scope.status && $scope.status.toLowerCase() === 'fully_delivered'){
      $scope.status = 'CLOSED';
    }
    //highlight row
    if(!!HighlightId.getId() && HighlightId.getId() != undefined && HighlightId.getId().length) {

      $scope.highlightId = HighlightId.getId();

      if ($scope.highlightId != null && $scope.highlightId != undefined) {

        //scroll the window to top
        $window.scroll(0, 0);
      }

      //clear current id
      HighlightId.setId(null);
    }

    //progress piety chart
    $scope.peiChart = {
      options:{
        fill: ['#1ab394', "#eeeeee"]
      }
    };

    // Check selected POs
    $scope.orders.forEach(function (po) {
      if ($scope.$parent.poIsSelected(po)) {
        po.selected = true;
      }
    })

    resetPoSelection()
    showBulkPrintButton();
  }

  initialize();
}

OrdersManageCtrl.$inject = ['$scope', '$state', '$stateParams', '$filter', 'Orders', 'orders', '$window',
                            'HighlightId', 'pathConstants', '$resource', '$rootScope', 'globalFunc', '$uibModal', 'toastr'];

angular.module('metabuyer')
    .controller('OrdersManageCtrl', OrdersManageCtrl);
